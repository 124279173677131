<template>
  <div>
    <div class="mb-4">
      <b-form-group label="Select search type">
        <b-form-radio-group
          v-model="selectedSearch"
          :options="radioOptions"
          buttons
          button-variant="outline-wfr"
         ></b-form-radio-group>
      </b-form-group>
    </div>
    <div class="search-area" v-if="selectedSearch == 'agencySearch'">
      <div class="mb-2" v-if="agencySearchHelpText">
        <p v-html="agencySearchHelpText" />
      </div>
      <div class="row">
        <agencySearch @input="agencySelected" :ref="'agencySearch'"></agencySearch>
      </div>
    </div>
    <div class="search-area" v-if="selectedSearch == 'userSearch'">
      <div class="mb-2" v-if="userSearchHelpText">
        <p v-html="userSearchHelpText" />
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <form @submit.prevent="searchUserName">
            <input type="text"
                   placeholder="Enter Name to Search"
                   v-model="searchTerm"
                   class="form-control form-el mr-3"
                   :ref="'userSearch'"
                   @input="checkValid($event.target)" />
            <button class="btn btn-wfr btn-sm" type="submit"><icon icon="search" class="mr-2"></icon>Search</button>
          </form>
        </div>
      </div>
    </div>
    <div class="search-area" v-if="selectedSearch == 'countySearch'">
      <div class="mb-2" v-if="countySearchHelpText">
        <p v-html="countySearchHelpText" />
      </div>
      <div class="row">
        <countySearch @input="countySelected" :ref="'countySearch'"></countySearch>
        <agencyDropdown v-if="countyChoices.length != 0 && selectedCounty != ''"
                        @input="agencySelectedByCounty"
                        :ref="'agencyDropdown'"
                        :county="selectedCounty"
                        :choices="countyChoices"></agencyDropdown>
        <div v-if="countyChoices.length == 0 && selectedCounty != ''"> No {{ $t('message.agencyPlural').toLowerCase()}} found in {{ selectedCounty }} county.</div>
      </div>
    </div>
  </div>
</template>
<script>
import agencySearch from './agency-autocomplete.vue';
import agencyDropdown from './agency-dropdown.vue';
import countySearch from './county-autocomplete.vue';
import { getText } from '@scripts/localized';
export default {
  name: 'ToggleSearches',
  components: {
    agencySearch,
    countySearch,
    agencyDropdown
  },
  data() {
    return {
      selectedSearch: 'agencySearch',
      radioOptions: [
        { text: `Search by ${getText('agency')}`, value: 'agencySearch' },
        { text: 'Search by User Name', value: 'userSearch' },
        { text: 'Search by County', value: 'countySearch' }
      ],
      searchTerm: '',
      selectedCounty: '',
      countyChoices: []
    };
  },
  methods: {
    agencySelected(organization) {
      if (organization && organization.key) {
        this.$emit('agencyInput', organization);
      } else {
        this.$emit('agencyInput', {});
      }
    },
    agencySelectedByCounty(organizationId) {
      if (organizationId) {
        const org = this.countyChoices.find((a) => a.key === organizationId);
        if (org) {
          this.$emit('agencyInput', org);
        } else {
          this.$emit('agencyInput', {});
        }
      } else {
        this.$emit('agencyInput', {});
      }
    },
    async countySelected(county) {
      if (county && county.key) {
        await this.getAgenciesByCounty(county.key);
        this.$emit('countyInput', county);
      } else {
        this.selectedCounty = '';
        this.countyChoices = [];
        this.$emit('countyInput', {});
      }
    },
    async getAgenciesByCounty(countyName) {
      this.isBusy = true;
      const body = { county: countyName };

      this.$store
        .dispatch('agencySearchByCountyRequest', body)
        .then((result) => {
          this.isBusy = false;
          this.selectedCounty = countyName;
          this.countyChoices = result;
        })
        .catch((errors) => {
          this.isBusy = false;
          this.selectedCounty = '';
          this.countyChoices = [];
        });
    },
    searchUserName() {
      const input = this.$refs.userSearch;
      if (input) {
        input.setCustomValidity('');
        if (this.searchTerm && this.searchTerm.length && this.searchTerm.length > 1) {
          this.$emit('nameInput', this.searchTerm);
        } else {
          this.$emit('nameInput', '');
          input.setCustomValidity('Two or more characters are required for search');
        }
      }
    },
    checkValid: function (input) {
      input.setCustomValidity('');
      if (this.searchTerm && this.searchTerm.length && this.searchTerm.length > 1) {
        return;
      } else {
        input.setCustomValidity('Two or more characters are required for search');
      }
    },
    tryFocus(val) {
      if (val && val == 'userSearch' && this.$refs.userSearch) this.$refs.userSearch.focus();
      if (
        val &&
        val == 'agencySearch' &&
        this.$refs.agencySearch &&
        this.$refs.agencySearch &&
        this.$refs.agencySearch.focusOnInput
      ) {
        this.$refs.agencySearch.focusOnInput();
      }
      if (
        val &&
        val == 'countySearch' &&
        this.$refs.countySearch &&
        this.$refs.countySearch &&
        this.$refs.countySearch.focusOnInput
      ) {
        this.$refs.countySearch.focusOnInput();
      }
    }
  },
  watch: {
    selectedSearch: function (newVal) {
      this.searchTerm = '';
      this.$emit('nameInput', '');
      this.$emit('agencyInput', {});
      this.$emit('countyInput', {});
      this.$nextTick().then(() => {
        this.tryFocus(newVal);
      });
      this.selectedCounty = '';
    }
  },
  props: {
    agencySearchHelpText: {
      type: String,
      default: undefined
    },
    userSearchHelpText: {
      type: String,
      default: undefined
    },
    countySearchHelpText: {
      type: String,
      default: undefined
    }
  }
};
</script>
