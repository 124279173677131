<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div v-if="stimulus">
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <auto-complete
      v-model="selected"
      :items="items"
      :loading="isBusy"
      item-text="value"
      :placeholder="stimulus"
      disable-filtering-by-search
      @search="onSearch"
      @focus="onFocus"
      :disabled="readOnly ? true : false"
      :value="value"
      :input-el-custom-attributes="{
        required: required,
        formelementid: id,
        autocomplete: 'new-password',
        id: controlIdFinal
      }"
    >
      <template slot="input-start">
        <icon class="ml-2" icon="search" size="xs"></icon>
      </template>
      <template slot="no-data">
        <span>{{ noData ? 'No matches found.' : warningMessage }}</span>
      </template>
      <template slot="item" slot-scope="{ item }">
        <span>{{ item.value }}</span>
      </template>
    </auto-complete>
  </div>
</template>
<script>
export default {
  name: 'entityCrossReference',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String,
      default: 'Two or more characters are required for search'
    },
    width: {
      type: Number,
      default: 12
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object,
      required: true
    },
    controlId: {
      type: String
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    peerReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      selected: null,
      items: [],
      isBusy: false,
      noData: false,
      errors: '',
      controlIdFinal: '',
      peerFilters: []
    };
  },
  created() {
    if (this.value) {
      const obj = JSON.parse(this.value);
      if (obj && obj.key && obj.value) {
        this.selected = obj;
        this.items = [obj];
      }
      if (this.controlId) this.controlIdFinal = this.controlId;
      else this.controlIdFinal = 'autocomplete' + this.id;
    }
    if (this.arguments && this.arguments.peerElements) {
      for (let i = 0; i < this.arguments.peerElements.length; i++) {
        const peerVal = this.formData[this.arguments.peerElements[i].elementId];
        let val = '';
        if (peerVal) {
          const peerObj = JSON.parse(peerVal);
          if (peerObj.key)
            val = peerObj.key.slice();
        }
        this.peerFilters.push({
          elementId: this.arguments.peerElements[i].elementId,
          propertyId: this.arguments.peerElements[i].propertyId,
          role: this.arguments.peerElements[i].role,
          clearOnPeerChange: this.arguments.peerElements[i].clearOnPeerChange ? this.arguments.peerElements[i].clearOnPeerChange: false,
          value: val
        });
      }
    }
  },
  methods: {
    hasPeerValue() {
      if (this.peerFilters.length > 0) {
        for (let i = 0; i < this.peerFilters.length; i++) {
          if (this.peerFilters[i].value != '') return true;
        }
      }
      return false;
    },
    onFocus() {
      if (this.selected == null) this.onSearch('');
    },
    async onSearch(search) {
      const lettersLimit = 2;
      this.noData = false;
      if (search.length < lettersLimit && !this.hasPeerValue()) {
        this.items = [];
        this.isBusy = false;
        return;
      }
      // remove common Unicode chars
      search = search
            .replace(/[\u2014]/g, "--")        // emdash
            .replace(/[\u2022]/g, "*")         // bullet
            .replace(/[\u2018\u2019]/g, "'")   // smart single quotes
            .replace(/[\u201C\u201D]/g, '"');  // smart double quotes
      if (!this.arguments.ecrEntityTypeId) {
        const options = { title: 'Error', size: 'sm' };
        this.$dialogs.alert('Entity Type not defined for element', options);
        return;
      }
      let searchFilter;
      if (this.arguments.ecrFilter) searchFilter = this.arguments.ecrFilter;
      this.isBusy = true;
      const body = {
        entityTypeId: this.arguments.ecrEntityTypeId,
        ecrSearchPropertyId: this.arguments.ecrSearchPropertyId,
        searchString: search,
        filter: searchFilter,
        peerFilters: this.peerFilters
      };

      this.$store
        .dispatch('entityCrossReferenceSearchRequest', body)
        .then((result) => {
          this.isBusy = false;
          this.items = result;
          if (!this.items.length) this.noData = true;
        })
        .catch((errors) => {
          this.isBusy = false;
          this.selected = null;
          this.items = [];
          this.noData = true;
        });
    },
    async peerMatch() {
      if (!this.arguments.ecrEntityTypeId) {
        const options = { title: 'Error', size: 'sm' };
        this.$dialogs.alert('Entity Type not defined for element', options);
        this.isBusy = false;
        return;
      }
      let searchFilter;
      if (this.arguments.ecrFilter) searchFilter = this.arguments.ecrFilter;
      this.isBusy = true;
      const body = {
        entityTypeId: this.arguments.ecrEntityTypeId,
        ecrSearchPropertyId: this.arguments.ecrSearchPropertyId,
        searchString: this.value,
        filter: searchFilter,
        peerFilters: this.peerFilters
      };
      this.$store
        .dispatch('entityCrossReferenceSearchRequest', body)
        .then((result) => {
          this.isBusy = false;
          if (!result.length) {
            this.selected = null;
            this.items = [];
          } else if (result.length == 1) {
            this.selected = result[0];
            this.items = [result[0]];
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.selected = null;
          this.items = [];
          this.noData = true;
        });
    }
  },
  watch: {
    selected: function (newVal) {
      if (newVal && newVal.key) {
        this.$emit('input', newVal.key.toString());
        this.$emit('crossRef', newVal);
        this.$emit('peerRef', newVal);
      } else {
        this.$emit('input', '');
        this.$emit('crossRef', '');
        this.$emit('peerRef', '');
      }
    },
    peerReference: function (val) {
      if (this.peerFilters && this.peerFilters.length) {
        for (let i = 0; i < this.peerFilters.length; i++) {
          if (val.peerElementId == this.peerFilters[i].elementId) {
            if (val.peerValue != this.peerFilters[i].value) {
              this.peerFilters[i].value = val.peerValue;
              if (this.peerFilters[i].clearOnPeerChange == true) {
                this.selected = null;
                this.items = [];
              }
              if (this.selected == null)
                this.peerMatch();
            }
          }
        }
      }
    }
  }
};
</script>
