import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
  install(Vue) {
    // use new Vue instance as an event bus
    const messageHub = new Vue();
    // every component will use this.$messageHub to access the event bus
    Vue.prototype.$messageHub = messageHub;

    // Provide methods to connect/disconnect from the SignalR hub
    let connection = null;
    let startedPromise = null;
    let manuallyClosed = false;

    Vue.prototype.startSignalR = (jwtToken) => {
      connection = new HubConnectionBuilder()
        .withUrl('/message-hub', jwtToken ? { accessTokenFactory: () => jwtToken } : null)
        .configureLogging(LogLevel.Information)
        .build();

      //Forward server side SignalR events through $messageHub, where components will listen to them
      connection.on('MessageCountChange', (userId, count) => {
        messageHub.$emit('count-changed', { userId, count });
      });

      connection.on('MessageReceived', (userId, message) => {
        messageHub.$emit('message-received', { userId, message });
      });

      connection.on('NotificationCountChange', (userId, count) => {
        messageHub.$emit('notification-count-changed', { userId, count });
      });

      connection.on('NotificationReceived', (userId, notification) => {
        messageHub.$emit('notification-received', { userId, notification });
      });

      connection.on('RolePromotionActionReceived', (userId, action) => {
        messageHub.$emit('role-promotion-action-received', { userId, action });
      });

      // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
      // Docs recommend listening onclose and handling it there.
      // This is the simplest of the strategies
      function start(retries) {
        startedPromise = connection.start().catch((err) => {
          retries--;
          if (retries > 0) {
            return new Promise((resolve, reject) => setTimeout(() => start(retries).then(resolve).catch(reject), 5000));
          } else {
            if (sessionStorage.getItem('auth-token')) {
              Vue.prototype.$dialogs.alert('A network problem occurred. Please refresh your browser.', {
                title: 'Warning',
                size: 'sm'
              });
            }
            startedPromise = null;
          }
        });
        return startedPromise;
      }

      connection.onclose(() => {
        if (!manuallyClosed) start(5);
      });

      // Start everything
      manuallyClosed = false;
      start(5);
    };

    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return;

      manuallyClosed = true;
      return startedPromise
        .then(() => connection.stop())
        .then(() => {
          startedPromise = null;
        });
    };

    // Provide methods for components to send messages back to server
    // Make sure no invocation happens until the connection is established
    messageHub.userActive = (userId) => {
      if (!startedPromise) return;
      return startedPromise
        .then(() => connection.invoke('JoinUserGroup', userId))
        .catch((error) => Vue.prototype.toast.error(error.toString(), 'Error'));
    };
    messageHub.userInactive = (userId) => {
      if (!startedPromise) return;
      return startedPromise
        .then(() => connection.invoke('LeaveUserGroup', userId))
        .catch((error) => Vue.prototype.toast.error(error.toString(), 'Error'));
    };
  }
};
