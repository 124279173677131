<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">{{ currentPageName }}</h1>
          <p>
            Completed training will be listed below. The
            <img src="../../images/icon/VERIFIED.svg"
              class="custom-svg mx-1"
              title="Training Verified" />
            indicates your training completion has been verified.
          </p>
        </div>
        <div>
          <grid
            :entityTypeId="entityTypeId"
            :formId="formId"
            :entityTypeName="entityTypeName"
            :primaryEntity="primaryentity"
            :showAddButton="userAddedRecordsAllowed"
            :showFormOnEmpty="userAddedRecordsAllowed"
            :showDelete="showDelete"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { locale } from '@scripts/localized';
export default {
  name: 'DashboardTraining',
  data() {
    return {
      entityTypeId: 3,
      formId: 7,
      entityTypeName: 'Training Record',
      currentPageName: '',
      instructionText: '',
      userAddedRecordsAllowed: locale === 'tn' ? false : true,
      showDelete: locale === 'tn' ? false : true,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    }
  }
};
</script>
