<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">
            {{ currentPageName }}
          </h1>
        </div>
        <div>
          <grid
            :entityTypeId="entityTypeId"
            :formId="formId"
            :entityTypeName="entityTypeName"
            :primaryEntity="primaryentity"
            :showFormOnEmpty="false"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
            :showEdit="false"
            :showDisable="false"
            :showAddButton="false"
            :altGridId="altGridId"
            :additionalActions="additionalActions"
            :additionalActionsOnColumn="true"       
          >
          </grid>
        </div>
      </div>
    </div>
    <modal v-show="isAgencyModalVisible" @close="closeAgencyModal" :isModalVisible="isAgencyModalVisible">
      <h2 slot="header">
        <span v-if="currentUserName">{{ currentUserName }}</span> Agencies
      </h2>
      <div slot="body">
        <div v-if="!agencyKey.startsWith('closed')">
            <Organizations :key="agencyKey" :primaryentity="currentPrimaryEntity"></Organizations>
        </div>
      </div>
    </modal>
    <modal v-show="isVerificationModalVisible" @close="closeVerificationModal" :isModalVisible="isVerificationModalVisible">
      <h2 slot="header">
        <span v-if="currentUserName">{{ currentUserName }}</span> Verification History
      </h2>
      <div slot="body">
        <div v-if="!verificationKey.startsWith('closed')">
            <VerificationHistory :key="verificationKey" :primaryentity="currentPrimaryEntity"></VerificationHistory>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import Organizations from './organizations.vue';
import VerificationHistory from './verification-history.vue';

export default {
  name: 'RegulatoryAgencyStaffManagement',
  components: {
    Organizations,
    VerificationHistory
  },
  data() {
    return {
      isAgencyModalVisible: false,
      isVerificationModalVisible: false,
      currentUserName: '',
      currentPrimaryEntity: {},
      agencyKey: 'closed',
      verificationKey: 'closed',
      entityTypeId: 1,
      formId: 1,
      entityTypeName: 'User',
      currentPageName: 'Staff Management',
      gridPageSize: 5,
      gridCurrentPage: 1,
      altGridId: 1,
      additionalActions: [
        {
            title: 'Assigned Agencies',
            icon: 'city',
            click: this.viewAgencies,
            check: this.shouldShowAction
        },
        {
            title: 'Verification History',
            icon: 'history',
            click: this.viewVerification,
            check: this.shouldShowAction
        },
      ],
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
    }
  },
  methods : {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    shouldShowAction(item) {
        return true;
    },
    viewVerification(item) {
        this.verificationKey = item.id;
        this.currentPrimaryEntity = { Id: item.id, EntityTypeId: this.entityTypeId };
        this.currentUserName = item.fullName;
        this.isVerificationModalVisible = true;
    },
    viewAgencies(item) {
        this.agencyKey = item.id;
        this.currentPrimaryEntity = { Id: item.id, EntityTypeId: this.entityTypeId };
        this.currentUserName = item.fullName;
        this.isAgencyModalVisible = true;
    },
    closeAgencyModal() {
        this.agencyKey = 'closed';
        this.currentPrimaryEntity = {};
        this.currentUserName = '';
        this.isAgencyModalVisible = false;
    },
    closeVerificationModal() {
        this.verificationKey = 'closed';
        this.currentPrimaryEntity = {};
        this.currentUserName = '';
        this.isVerificationModalVisible = false;
    },
  }
};
</script>
