<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <img
        src="../../images/icon/PROGRAM_REJECTED.svg"
        v-if="rejected"
        class="custom-svg mx-2 align-middle"
        title="An agency director has identified a problem with the submitted value.  Please contact your director and/or change the value."
      />
      <img
        src="../../images/icon/VERIFIED.svg"
        v-if="verified"
        class="custom-svg mx-2 align-middle"
        title="This email address has been verified."
      />
      <img
        src="../../images/icon/UNVERIFIED.svg"
        v-if="unverified"
        class="custom-svg mx-2 align-middle"
        title="This email address has not been verified."
      />
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <v-date-picker
      v-if="subtype == 'date' || subtype == 'datetime'"
      :mode="subtype == 'datetime' ? 'datetime' : 'date'"
      v-model="dateValue"
      :is-required="required ? true : false"
      :formelementid="id"
      :min-date="'1900-01-01'"
      :max-date="'2200-12-31'"
      :disabled-dates="readOnly ? {} : ''"
      ref="dateControl"
      v-on:blur="checkDateComparisons"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          :class="'form-control form-el' + (readOnly ? ' disabled-date form-control' : '')"
          :readonly="readOnly ? true : false"
          :placeholder="placeholder"
          :disabled="readOnly ? true : false"
          :value="inputValue"
          v-on="inputEvents"
        />
    </template>
    </v-date-picker>
    <input
      v-else
      :type="getSubtype()"
      :maxlength="maxLength"
      :pattern="getRegex()"
      :warningmessage="warningMessage"
      :required="required ? true : false"
      :value="value"
      :disabled="readOnly ? true : false"
      @input="
        checkValid($event.target);
        $emit('input', $event.target.value);
      "
      :formelementid="id"
      :placeholder="stimulus"
      :class="'form-control form-el' + (value == '' ? '' : ' has-value')"
      autocomplete="new-password"
      ref="inputControl"
    />
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'arb-text',
  props: {
    id: {
      type: Number,
      required: true
    },
    subtype: {
      type: String,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 180
    },
    width: {
      type: Number,
      default: 12
    },
    regex: {
      type: String,
      default: '(.*?)'
    },
    warningMessage: {
      type: String,
      default: 'Please fill out this field'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    rejected: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean, 
      default: false
    },
    unverified: {
      type: Boolean, 
      default: false
    },   
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      placeholder: '',
      dateValue: ''
    };
  },
  components: {
    'v-date-picker': DatePicker
  },
  created() {
    if (['date', 'datetime'].includes(this.subtype)) {
      if (this.value) {
        this.dateValue = this.value;
        if (/-/.test(this.dateValue) && this.subtype == 'datetime') this.dateValue = this.dateConvert(this.dateValue);  //this is triggered (properties only) to fix placeholder formatting issue
        this.dateValue = new Date(this.dateValue);
        if (this.subtype == 'date') {
          const tzDifference = this.dateValue.getTimezoneOffset(); //dates are adjusted here
          this.dateValue = new Date(this.dateValue.getTime() + tzDifference * 60 * 1000);
        }
      }
      if (this.subtype == 'date')
        this.placeholder = 'mm/dd/yyyy';
      else
        this.placeholder = 'mm/dd/yyyy HH:MM AM/PM';
    }
  },
  watch: {
    dateValue: function (newVal) {
      if (newVal) {
        if (this.subtype == 'date')
          newVal = newVal.toISOString().substring(0, 10);
        else { //datetimes are adjusted here
          newVal = new Date(newVal.getTime() - newVal.getTimezoneOffset() * 60 * 1000)
          newVal = newVal.toISOString();
        }
        this.$emit('input', newVal);
        this.checkValid(this.$refs.dateControl.$el.children[0]);
      } else {
        this.$emit('input', '');
      }
    }
  },
  computed: {
    today: function () {
      let today = new Date();
      today = new Date(today.toISOString().split('T')[0]);
      const tzDifference = today.getTimezoneOffset();
      return new Date(today.getTime() + tzDifference * 60 * 1000);
    },
    yesterday: function () {
      const today = new Date();
      let yesterday = new Date();
      yesterday = yesterday.setDate(today.getDate() - 1);
      yesterday = new Date(yesterday);
      yesterday =  new Date(yesterday.toISOString().split('T')[0]);
      const tzDifference = yesterday.getTimezoneOffset();
      return new Date(yesterday.getTime() + tzDifference * 60 * 1000);
    },
    tomorrow: function () {
      const today = new Date();
      let tomorrow = new Date();
      tomorrow = tomorrow.setDate(today.getDate() + 1);
      tomorrow = new Date(tomorrow);
      tomorrow =  new Date(tomorrow.toISOString().split('T')[0]);
      const tzDifference = tomorrow.getTimezoneOffset();
      return new Date(tomorrow.getTime() + tzDifference * 60 * 1000);
    },
    isDateSupported: function () {
      const input = document.createElement('input');
      const value = 'a';
      input.setAttribute('type', 'date');
      input.setAttribute('value', value);
      return input.value !== value;
    }
  },
  methods: {
    checkValid: function (input) {
      input.setCustomValidity('');
      if (input.validity.valid) {
        return;
      } else {
        input.setCustomValidity(input.attributes.warningMessage.value);
      }
    },
    getSubtype() {
      //float uses regex to control the input rather than the standard html guardrails
      return this.subtype == 'float' ? 'text' : this.subtype;
    },
    getRegex() {
      if (this.subtype == 'float' && this.regex == '(.*?)') return '^[0-9]+(.[0-9]*)?$';
      return this.regex;
    },
    dateConvert(dateValue) {
      Date.prototype.formatMMDDYYYY = function () {
        return (this.getMonth() + 1) +
          "/" + (this.getDate() + 1) +
          "/" + this.getFullYear();
      }
      const parts = dateValue.split(' ');
      parts[0] = new Date(parts[0]).formatMMDDYYYY();
      return parts.join(' ');
    },
    checkDateComparisons() {
      if (
        ['date','datetime'].includes(this.subtype) &&
        this.arguments &&
        this.arguments.comparisons &&
        (this.$refs.inputControl || this.$refs.dateControl)
      ) {
        const input = this.$refs.dateControl.$el.children[0];
        if (!input.value) {
          if (this.required) {  //show warning only if the field is required
            input.setCustomValidity('Please fill out this field.');
            input.reportValidity();
            return false;
          }
          return true;
        }
        input.setCustomValidity('');
        for (const comparison of this.arguments.comparisons) {
          if (!this.isComparisonMet(comparison)) {
            let message = 'Value is invalid';
            if (comparison.message) message = comparison.message;
            input.setCustomValidity(message);
            input.reportValidity();
            return false;
          }
        }
      }
      return true;
    },
    isComparisonMet(comparison) {
      let controlVal;
      if (['date','datetime'].includes(this.subtype)) {
        controlVal = this.$refs.dateControl.$el.children[0].value;
      } else {
        controlVal = this.$refs.inputControl.value;
      }
      //null/required handled with other code
      if (!controlVal) return true;
      if (!Date.parse(controlVal)) return true;

      const currentValue = new Date(Date.parse(controlVal));
      if (currentValue) {
        let valueToCompare = undefined;
        if (comparison.value) {
          const staticDate = Date.parse(comparison.value);
          switch (comparison.value) {
            case 'today':
              valueToCompare = this.today;
              break;
            case 'yesterday':
              valueToCompare = this.yesterday;
              break;
            case 'tomorrow':
              valueToCompare = this.tomorrow;
              break;
            case 'element':
              if (
                comparison.formElementId &&
                this.formData &&
                Object.prototype.hasOwnProperty.call(this.formData, comparison.formElementId)
              ) {
                const elementVal = this.formData[comparison.formElementId];
                const elementDate = Date.parse(elementVal);
                if (elementDate) {
                  valueToCompare = new Date(elementDate);
                  valueToCompare = new Date(valueToCompare.getTime() + valueToCompare.getTimezoneOffset() * 60 * 1000); //needed to compare like to like
                }
              }
              break;
            default:
              if (staticDate) valueToCompare = new Date(staticDate);
              break;
          }
        }
        if (valueToCompare && comparison.operator) {
          switch (comparison.operator) {
            case 'greaterThan':
              return currentValue > valueToCompare;
            case 'lessThan':
              return currentValue < valueToCompare;
            case 'greaterThanOrEqualTo':
              return currentValue >= valueToCompare;
            case 'lessThanOrEqualTo':
              return currentValue <= valueToCompare;
          }
        }
      }
      return true;
    }
  }
};
</script>
