<template>
  <div class="messages">
    <Message
      v-for="email in emails"
      :email="email"
      :starMessage="starMessage"
      :key="email.messageId"
      :formatEmailDate="formatEmailDate"
      :activeFolder="activeFolder"
      :folders="folders"
      :openMessage="openMessage"
      :userColumnValue="userColumnValue"
      :formatFullDate="formatFullDate"
    >
    </Message>
  </div>
</template>

<script>
import Message from './message-item';

export default {
  name: 'MessageList',
  props: [
    'emails',
    'starMessage',
    'formatEmailDate',
    'activeFolder',
    'folders',
    'openMessage',
    'userColumnValue',
    'formatFullDate'
  ],
  components: {
    Message
  }
};
</script>
