<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Verification History</h1>
          <p>Click on action icon to view verification history.</p>
        </div>
        <div>
          <grid
            :entityTypeId="verificationEntityTypeId"
            :formId="19"
            entityTypeName="Verification"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :primaryEntity="primaryEntity"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            ref="actionGrid"
            :additionalActionsOnColumn="true"
            :allowDownload="true"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">
        {{ activeVerification.verificationType }} Verification History Details for
        {{ activeVerification.primaryEntityName }} -
        {{ activeVerification.identifier }}
      </h2>
      <div class="text-left row" slot="body">
        <div v-if="activeVerification.id" class="w-100 m-3">
          <verificationDetails :key="activeId" :verification="activeVerification"></verificationDetails>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import verificationDetails from './verification-details.vue';
export default {
  name: 'VerificationHistory',
  components: { verificationDetails },
  props: {
      primaryentity: { 
        type: Object,
        default: () => { }
      }
  },
  data() {
    return {
      userEntityTypeId: 1,
      verificationEntityTypeId: 15,
      primaryEntity: {},
      isModalVisible: false,
      activeId: '',
      activeVerification: {},
      isDirty: false,
      additionalActions: [
        {
          title: 'View Verification Action History',
          icon: 'history',
          click: this.rowAction,
          check: this.shouldShowAction
        }
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    if (this.primaryentity)
      this.primaryEntity = this.primaryentity;
    else
      if (this.userId && this.userEntityTypeId) {
        this.primaryEntity = {
          Id: this.userId,
          EntityTypeId: this.userEntityTypeId
        };
      }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      return true;
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeVerification = item;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeId = 'closed';
      this.activeVerification = {};
    }
  }
};
</script>
