<template>
  <section>
    <div class="container has-text-centered">
      <div
        :class="'row justify-content-center my-5' + (isBusy ? ' disabled-area' : '')"
        v-if="$route.name != 'forgot-password'"
      >
        <router-link
          v-for="(route, index) in getLoginRoutes()"
          :key="index"
          :class="'nav-link-login ' + ($route.name === route.name ? 'router-link-active' : '')"
          :id="'nav-' + route.meta.display"
          data-toggle="tab"
          active-class=""
          exact-active-class=""
          :to="'/login/' + route.path"
          role="tab"
          :aria-selected="$route.name === route.name ? true : false"
        >
          <div style="display: inline" class="login-nav-content">{{ route.meta.display.trim() }}</div>
          <div class="login-nav-separator" style="display: inline" v-if="index + 1 < childLength && locale !== 'al'">|</div>
          <div class="login-nav-separator" style="display: inline" v-if="index + 1 < childLength && locale === 'al'">/</div>
        </router-link>
      </div>
      <div>
        <router-view @busy="busyToggle($event)"> </router-view>
      </div>
    </div>
  </section>
</template>

<script>
import { routes } from '../../router/routes';
import { locale } from '@scripts/localized'; 
export default {
  name: 'Login',
  props: {},
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.sequence) this.currentPosition = this.$route.meta.sequence;
    }
  },
  data() {
    return {
      locale: locale,
      isBusy: false,
      currentPageName: '',
      routes,
      currentPosition: 1,
      childLength: 0
    };
  },
  mounted () {
    if (window.location.hostname === 'uat.alabamapathways.org' && window.location.pathname.indexOf('register') == -1) {
      const options = { title: 'Warning!'};
      this.$dialogs.alert(
        `This is a testing/training environment. It is for training purposes only. If you are attempting to create an account in Alabama Pathways, the live site web address is <a href="https://www.alabamapathways.org">www.alabamapathways.org</a>.`,
        options
      );
    }
  },
  watch: {
    $route(to) {
      if (to.meta.display) {
        this.currentPageName = to.meta.display;
      }
      if (to.meta.sequence) {
        this.currentPosition = to.meta.sequence;
      }
    }
  },
  methods: {
    busyToggle(eventVal) {
      this.isBusy = eventVal;
    },
    getLoginRoutes() {
      if (this.routes) {
        const login = this.routes.filter(function (route) {
          return route.path === '/login';
        });
        if (login && login.length > 0 && login[0] && login[0].children) {
          const childRoutes = login[0].children.filter((child) => !child.meta.notMenuItem);
          this.childLength = childRoutes.length;
          return login[0].children.filter((child) => !child.meta.notMenuItem);
        }
      }
      return [];
    }
  }
};
</script>
