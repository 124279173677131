<template>
  <section class="section">
    <div class="login-form-container">
      <div>
        <h1 class="title has-text-grey" v-if="!$route.query.reset">Forgot Your Password?</h1>
        <h1 class="title has-text-grey" v-if="$route.query.reset">Set Your Password</h1>
        <p class="subtitle has-text-grey" v-if="!$route.query.reset && !successMessage">
          Please enter your information to receive a password reset email.
        </p>
        <p class="subtitle has-text-grey" v-if="$route.query.confirmed">Thank you for confirming your account!</p>
        <p class="subtitle has-text-grey" v-if="$route.query.reset">Please enter your new password.</p>
      </div>
      <div :class="isBusy ? 'disabled-area' : ''">
        <form @submit.prevent="handleSubmit">
          <div class="input-group my-3" v-if="!$route.query.reset && !successMessage">
            <input class="input form-control" type="email" placeholder="Email" v-model="email" />
          </div>
          <div v-if="locale === 'al' && $route.query.reset">
            <h3>Passwords must:</h3>
            <div class="content_block">
              <ul class="password_requirements">
                <li id="pw0">
                  <icon 
                    :icon="validPwdElements.pw0 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw0 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Be at least 8 characters long.</li>
                <li id="pw1">
                  <icon 
                    :icon="validPwdElements.pw1 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw1 ? 'checked' : ''"
                    :size="'sm'"></icon>  
                  Contain at least 1 uppercase letter.</li>
                <li id="pw2">                  <icon 
                    :icon="validPwdElements.pw2 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw2 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Contain at least 1 lowercase letter.</li>
                <li id="pw3">                  
                  <icon 
                    :icon="validPwdElements.pw3 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw3 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Contain at least 1 number.</li>
                <li id="pw4">      
                  <icon 
                    :icon="validPwdElements.pw4 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw4 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                   Password and confirmation must match.</li>
              </ul>
            </div>
          </div>
          <div class="input-group addon password-wrapper my-3" v-if="$route.query.reset">
            <input
              class="form-control"
              :type="passwordFieldType"
              placeholder="New Password"
              v-model="password"
              autocomplete="new-password"
              @keyup="validatePassword"
            />
            <div class="input-group-addon">
              <icon :icon="'eye'" class="password-view" v-touch:start="showpassword" v-touch:end="hidepassword"></icon>
            </div>
          </div>
          <div class="input-group addon password-wrapper my-3" v-if="$route.query.reset">
            <input
              class="form-control"
              :type="confirmPasswordFieldType"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              autocomplete="new-password"
              @keyup="validatePassword"
            />
            <div class="input-group-addon">
              <icon
                :icon="'eye'"
                class="password-view"
                v-touch:start="showconfirmpassword"
                v-touch:end="hideconfirmpassword"
              ></icon>
            </div>
          </div>
          <div class="text-left">
            <button class="btn btn-primary" type="submit" v-if="!successMessage">Submit</button>
          </div>
          <div v-if="successMessage">
            <p class="subtitle has-text-grey" v-if="message">{{ message }}</p>
          </div>
          <div class="errors-container" v-if="this.expiredToken">
            Your password reset link expired. Please <a href="/logon/forgot-password">click here</a>
            to obtain a new link.
          </div>
          <div class="errors-container" v-if="pwErrors">
            {{ pwErrors }}
          </div>
          <div class="errors-container" v-if="errors">
            {{ errors }}
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { locale } from '@scripts/localized';
export default {
  data() {
    return {
      isBusy: false,
      errors: '',
      pwErrors: '',
      email: '',
      password: '',
      confirmPassword: '',
      passwordValidated: locale === 'al' ? false: true,
      confirmationCode: '',
      successMessage: false,
      message: '',
      userId: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      expiredToken: false,
      validPwdElements: {
        pw0: false,
        pw1: false,
        pw2: false,
        pw3: false,
        pw4: false,
      },
      locale: locale
    };
  },
  watch: {
    isBusy: function (newValue, oldValue) {
      this.$emit('busy', newValue);
    }
  },
  created() {
    if (this.$route.query.email) {
      this.email = decodeURIComponent(this.$route.query.email);
    }
    if (this.$route.query.code) {
      this.confirmationCode = decodeURIComponent(this.$route.query.code);
    }
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId;
    }
  },
  methods: {
    handleSubmit: function (event) {
      this.errors = '';
      if (!this.$route.query.reset) {
        //we are submitting a forgot password request
        this.isBusy = true;
        const attrs = { email: this.email.trim() };
        this.$store
          .dispatch('forgotPasswordRequest', attrs)
          .then((result) => {
            this.isBusy = false;
            this.successMessage = true;
            this.message = result;
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
      } else {
        //we are resetting the password
        if (!this.passwordValidated) {
          this.pwErrors = 'Password is not valid. See rules above.';
          return;
        }
        this.isBusy = true;
        const attrs = {
          userId: this.userId,
          code: this.confirmationCode,
          password: this.password
        };
        this.$store
          .dispatch('resetPasswordRequest', attrs)
          .then(() => {
            this.$emit('busy', false);
            this.isBusy = false;
            this.$router.push({
              name: 'login-form',
              query: { email: this.email, reset: true }
            });
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                if (errArr[i][0] == 'Invalid token.') {
                  this.expiredToken = true;
                  continue;
                }
                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
      }
    },
    showpassword: function () {
      if (this.passwordFieldType == 'password') this.passwordFieldType = 'text';
      else this.passwordFieldType = 'password';
    },
    hidepassword: function () {
      this.passwordFieldType = 'password';
    },
    showconfirmpassword: function () {
      if (this.confirmPasswordFieldType == 'password') this.confirmPasswordFieldType = 'text';
      else this.confirmPasswordFieldType = 'password';
    },
    hideconfirmpassword: function () {
      this.confirmPasswordFieldType = 'password';
    },
    validatePassword: function () {
      if (locale !== 'al') return;
      let validCount = 0;
      if (this.password.length >= 8) {
        validCount++;
        this.setPwRequirementStatus(0, true);
      } else {
        this.setPwRequirementStatus(0, false);
      }
      if (/[A-Z]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(1, true);
      } else {
        this.setPwRequirementStatus(1, false);
      }
      if (/[a-z]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(2, true);
      } else {
        this.setPwRequirementStatus(2, false);
      }
      if (/[0-9]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(3, true);
      } else {
        this.setPwRequirementStatus(3, false);
      }
      if (this.password === this.confirmPassword &&
          this.password.length > 0) {
        validCount++;
        this.setPwRequirementStatus(4, true);
      } else {
        this.setPwRequirementStatus(4, false);
      }
      if (validCount === 5) {
        this.passwordValidated=true;
        this.pwErrors='';
      } else
        this.passwordValidated=false;
    },
    setPwRequirementStatus: function (itemIndex, isValid) {
      const itemId='pw' + itemIndex;
      const target_item=document.getElementById(itemId);
      //const firstChild = target_item.querySelector('icon');
      if (isValid===true){ //requirement met
        target_item.classList.add('checked');
        this.validPwdElements[itemId] = true;
      } else { //requirement not met
        target_item.classList.remove('checked');
        this.validPwdElements[itemId] = false;
      }
    }
  }
};
</script>
