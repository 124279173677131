<template>
  <section class="container-fluid">
    <!------- Top Navigation Area-------->
    <div id="profile-nav" class="profile-nav nav-tabs">
      <b-navbar toggleable="md" class="w-100 pb-md-0 mt-3 mt-md-1 py-2">
        <b-navbar-brand>
          <icon :icon="icon" />
          <span class="mr-2">{{ currentPageName }}</span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" :class="isFirstTime ? ' d-none' : ''">
          <icon :icon="'angle-down'" :size="'lg'" class="nav-toggle-down"></icon>
          <icon :icon="'angle-up'" :size="'lg'" class="nav-toggle-up"></icon>
        </b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="w-100">
            <router-link v-for="(route, index) in profileRoutes"
                         :key="index"
                         :class="
                'nav-item nav-link col mb-0 py-1' +
                (index != profileRoutes.length - 1 ? ' mr-1' : '') +
                (isFirstTime ? ' d-none' : '')
              "
                         :id="'nav-' + route.name"
                         data-toggle="tab"
                         exact
                         :to="'/dashboard/profile/' + route.path"
                         role="tab"
                         aria-selected="true">
              <icon :icon="route.meta.icon" :size="'lg'" />
              <div>
                {{ route.meta.display }}
              </div>
            </router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!------- Page Content  ------->
    <div class="profile-form-container mt-md-0">
      <div class="row">
        <div class="col mb-4 text-center">
          <h1 style="font-size: 2.1rem">{{ currentPageName }}</h1>
          <p v-if="instructionText != ''" v-html="instructionText"></p>
        </div>
      </div>
      <router-view v-bind:primaryentity="this.primaryentity"
                   @continueButton="continueButtonClick()"
                   @dirty="profileDirty($event)">
      </router-view>
    </div>
  </section>
</template>

<script>
import { locale } from '@scripts/localized';
import { mapGetters } from 'vuex';
import { routes } from '../router/routes';

export default {
  name: 'Profile',
  props: {
    userId: {
      type: String
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
      if (this.$route.meta.icon) this.icon = this.$route.meta.icon;
      if (this.$route.meta.sequence) this.currentPosition = this.$route.meta.sequence;
    }
    let user = '';
    if (this.userId) user = this.userId;
    else {
      const id = this.$store.getters['userId'];
      if (id) user = id;
    }
    if (user && this.userentitytypeid) {
      this.primaryentity = { Id: user, FirstName: this.profile.firstName, LastName: this.profile.lastName, EntityTypeId: this.userentitytypeid };
      this.entity.push(this.primaryentity);
    }
  },
  data() {
    return {
      isBusy: false,
      currentPageName: '',
      instructionText: '',
      entity: [],
      primaryentity: {},
      userentitytypeid: 1,
      routes,
      icon: 'user',
      currentPosition: 1,
      isDirty: false,
      locale: locale,
      fileCode: '',
      toolTip: ''
    };
  },
  watch: {
    $route(to) {
      if (to.meta.display) {
        this.currentPageName = to.meta.display;
      }
      this.instructionText = '';
      if (this.$route.meta.instructionText) {
        this.instructionText = this.$route.meta.instructionText;
      }
      if (to.meta.icon) {
        this.icon = to.meta.icon;
      }
      if (to.meta.sequence) {
        this.currentPosition = to.meta.sequence;
      }
    }
  },
  computed: {
    ...mapGetters(['profile', 'isFirstTime', 'isAuthenticated']),
    errors() {
      const status = this.$store.getters['profileStatus'];
      if (status == 'error') return 'error retrieving profile';
      else if (status == 'success') return '';
      else return 'profile not available';
    },
    profileRoutes() {
      if (this.routes) {
        const dash = this.routes.find((route) => route.name === 'dashboard');
        if (dash && dash.children) {
          const profile = dash.children.find((route) => route.name === 'profile');
          if (profile && profile.children) return profile.children;
        }
      }
      return [];
    }
  },
  methods: {
    continueButtonClick() {
      if (this.currentPosition && this.profileRoutes) {
        if (this.currentPosition === this.profileRoutes.length) {
          window.scrollTo(0, 0);
          this.$router.push({ name: 'dashboard-main' });
        }
        const next = this.getNextRoute(this.profileRoutes, this.currentPosition);
        if (next && next.length > 0) {
          window.scrollTo(0, 0);
          this.$router.push(next[0]);
        }
      }
    },
    getNextRoute(array, value) {
      return array.filter(function (route) {
        return route.meta.sequence === value + 1;
      });
    },
    profileDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to leave without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.isFirstTime) {
      next(false);
      return;
    }
    const userAccept = await this.dirtyCheck();
    if (userAccept) {
      next();
      return;
    }
    next(false);
  },
  async beforeRouteLeave(to, from, next) {
    if (to && to.name && to.name == 'login') {
      next();
      return;
    }
    if (this.isFirstTime) {
      next(false);
      return;
    }
    const userAccept = await this.dirtyCheck();
    if (userAccept) {
      next();
      return;
    }
    next(false);
  }
};
</script>
