<template>
  <div class="form-group col-12 col-md-5">
    <multiselect
      v-model="selectedUsers"
      id="recipient"
      :ref="'recipient'"
      label="name"
      track-by="id"
      open-direction="bottom"
      :options="users"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clearOnSelect="false"
      :closeOnSelect="true"
      :options-limit="10"
      :limit="10"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="true"
      :hide-selected="true"
      selectLabel=""
      :showNoOptions="false"
      @search-change="userSearch"
      placeholder=""
      :preserveSearch="false"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="custom__tag"
          ><span>{{ option.name }}</span
          ><span class="custom__remove" @click="remove(option)">❌</span></span
        >
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>

      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selectedUsers.length"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>

      <span slot="noResult">{{ noResultMessage }}</span>
    </multiselect>
  </div>
</template>

<script>
export default {
  name: 'MessageUserSearch',
  props: [
    'users',
    'isLoading',
    'twoCharacterMessage',
    'emptySetMessage',
    'noResultMessage',
    'limitText',
    'userSearch',
    'usersSelected',
    'defaultSelectedUsers'
  ],
  data() {
    return {
      selectedUsers: []
    };
  },
  methods: {
    clearAll() {
      this.selectedUsers = [];
    }
  },
  created() {
    if (this.$parent.$parent.defaultSelectedUsers && this.$parent.$parent.defaultSelectedUsers.length > 0) {
      this.selectedUsers = this.defaultSelectedUsers;
    }
  },
  watch: {
    selectedUsers: function (newVal) {
      this.usersSelected(newVal);
    }
  }
};
</script>
