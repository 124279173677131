<template>
  <div>
    <div>
      <grid
        :entityTypeId="entityTypeId"
        :formId="formId"
        :entityTypeName="entityTypeName"
        :primaryEntity="primaryentity"
        :showFormOnEmpty="true"
        :showContinueButton="false"
        @continueButton="$emit('continueButton')"
        continueButtonName="Continue to Dashboard"
        @dirty="$emit('dirty', $event)"
        :basePageSize="gridPageSize"
        :baseCurrentPage="gridCurrentPage"
        @setPageSize="setPageSize"
        @setCurrentPage="setCurrentPage"
      >
      </grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileMembership',
  data() {
    return {
      entityTypeId: 6,
      formId: 4,
      entityTypeName: 'Membership',
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    }   
  }
};
</script>
