<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '') + (readOnly ? ' read-only' : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <quill-editor :id="'qe_' + id"
                  ref="myTextEditor"
                  v-model="content"
                  @change="onEditorChange($event)"
                  :options="editorOption"
                  :disabled="readOnly">
    </quill-editor>
    <div role="alert" :class="(hideWarning ? 'hide justify-content-center d-flex' : 'show justify-content-center d-flex') + ' fade'" style="margin-top:8px;">
      <div class="validation">
        <div class="triangle"></div>
        <div class="warning-icon mr-2">
          <icon :icon="'exclamation'" :size="'sm'" class="align-items-center"></icon>
        </div>
        <div role="alert">Please fill out this field</div>
      </div>
    </div>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'richText',
  props: {
    id: {
      type: Number,
      required: true
    },
    subtype: {
      type: String,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 180
    },
    width: {
      type: Number,
      default: 12
    },
    regex: {
      type: String,
      default: '(.*?)'
    },
    warningMessage: {
      type: String,
      default: 'Please fill out this field'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      content: this.value,
      editorOption: {
        modules: {
          toolbar: this.getToolbarOptions()
        },
        placeholder: 'Type here...',
        theme: 'snow',
        bounds: '#qe_' + this.id
      },
      hideWarning: true
    };
  },
  methods: {
    onEditorChange(editor) {
      const regex = /(<([^>]+)>)/ig;
      if(editor.text.replace(regex,"").trim().length > 0) {
        this.hideWarning = true;
      }
      this.$emit('input', editor.html);
    },
    checkIfFieldIsPopulated() {
      if (this.required) {
        const regex = /(<([^>]+)>)/ig;
        if (this.value.replace(regex, "").trim().length == 0) {
          this.hideWarning = false;
          const el = this.$refs.myTextEditor.$el.lastElementChild.firstElementChild;
          const label = el.offsetParent.offsetParent;
          el.focus();
          //ScrollIntoView needed for when focus is already on rich-text and no text has been entered.
          label.scrollIntoView();
          setTimeout(() => this.hideWarning = true, 5000);
          return false;
        }        
      }
      return true;
    },
    getToolbarOptions() {
      const defaultOptions = [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }]];
      let options = [];
      if (this.subtype) {
        switch (this.subtype) {
          case 'hyperlinksallowed':
            options = ['link'];
            break;
          default:
            return defaultOptions;
        }
      } else {
        return defaultOptions;
      }
      defaultOptions.push(options);
      return {
        container: defaultOptions,
        handlers: {
          'link': function(value) {
            if (value) {
              const href = prompt('Enter the URL');
              this.quill.format('link', href);
            } else {
              this.quill.format('link', false);
            }
          }
        }
      }
    }
  },
  components: {
    quillEditor
  }
};
</script>
<style>
  .validation {
    border: 1px solid #999;
    background: #fff;
    border-radius: 3px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    text-align: center;
    padding: 6px 8px;
    font-family: 'grInter';
    letter-spacing: -.5px;
  }

  .warning-icon {
    height: 25px;
    width: 25px;
    color: white;
    background: #FFA300;
    border-radius: 3px;
    align-items: center;
    z-index: 2;
  }

  .triangle {
    height: 12px;
    width: 12px;
    background: white;
    align-items: center;
    transform: rotateY(0deg) rotate(45deg);
    position: absolute;
    bottom: 46px;
    border-top: 1px solid #999;
    border-left: 1px solid #999;
    margin-left: 6px;
  }
  div.read-only .ql-toolbar {
    display: none;
  }
</style>
