<template>
  <section>
    <div class="search-area">
      <form @submit.prevent="handleSubmit">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Training Name:</label><br />
            <input
              type="text"
              placeholder="Text here.."
              v-model="trainingName"
              class="form-control form-el mr-3"
              :ref="'trainingName'"
            />
          </div>
          <div class="form-group col-md-4">
            <label>Core Knowledge Area:</label><br />
            <select v-model="selectedCKA" :ref="'ckaControl'" class="custom-select form-el">
              <option></option>
              <option>Child Growth and Development (CGD)</option>
              <option>Health, Safety, and Nutrition (HSN)</option>
              <option>Learning Experiences and Enrichment (LEE)</option>
              <option>Child Observation and Assessment (Planning for Individual Needs) (COA)</option>
              <option>Diversity (DIV)</option>
              <option>Interaction with Children, Families and Communities (ICFC)</option>
              <option>Personal and Professional Development (PPD)</option>
              <option>Management and Administration (MA)</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Region:</label><br />
            <select v-model="selectedRegion" :ref="'regionControl'" class="custom-select form-el">
              <option></option>
              <option>Birmingham</option>
              <option>Dothan</option>
              <option>Fort Payne</option>
              <option>Huntsville</option>
              <option>Mobile</option>
              <option>Montgomery</option>
              <option>Opelika</option>
              <option>Talladega</option>
              <option>Tuscaloosa</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Target Age Group:</label><br />
            <select v-model="selectedAgeGroup" :ref="'ageGroupControl'" class="custom-select form-el">
              <option></option>
              <option>Infants - Birth to 12 months</option>
              <option>Toddlers - 1 and 2 year olds</option>
              <option>Preschoolers - 3 and 4 year olds</option>
              <option>School-Agers - 5 to 12 year olds</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>DHR Performance Standard:</label><br />
            <select v-model="selectedDHRStandard" :ref="'dhrControl'" class="custom-select form-el">
              <option></option>
              <option>Child Development (CD)</option>
              <option>Language Development (LD)</option>
              <option>Positive Discipline and Guidance (PDG)</option>
              <option>Health, Safety, and Universal Precautions (HSUP)</option>
              <option>Quality Child Care and Licensing (QCCL)</option>
              <option>Child Care Professional and the Family (CCPF)</option>
              <option>Administration and Management (AM)</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Child Development Associate Area (CDA):</label><br />
            <select v-model="selectedCDA" :ref="'cdaControl'" class="custom-select form-el">
              <option></option>
              <option>Planning a safe and healthy environment to invite learning (1)</option>
              <option>Steps to advance children’s physical and intellectual development (2)</option>
              <option>Positive ways to support children’s social and emotional development (3)</option>
              <option>Strategies to establish productive relationships with families (4)</option>
              <option>Strategies to manage an effective program operation (5)</option>
              <option>Maintaining a commitment to professionalism (6)</option>
              <option>Observing and recording children’s behavior (7)</option>
              <option>Principals of child growth and development (8)</option>
            </select>
          </div>
          <div class="form-group col-md-8">
            <label>CCDF - Health and Safety:</label><br />
            <select v-model="selectedCCDF" :ref="'ccdfControl'" class="custom-select form-el">
              <option></option>
              <option>1 - Prevention/Control of infectious diseases</option>
              <option>2 - Sudden Infant Death Syndrome (SIDS) prevention - sleep practices</option>
              <option>3 - Administration of Medication</option>
              <option>4 - Prevention of and response to emergencies</option>
              <option>5 - Building and physical premises safety</option>
              <option>6 - Prevention of Shaken Baby Syndrome and abusive head trauma</option>
              <option>7 - Emergency preparedness and response planning</option>
              <option>8 - Handling and storage of hazardous materials</option>
              <option>9 - If applicable, appropriate precautions in transporting children</option>
              <option>10 - First Aid and CPR</option>
              <option>11 - Recognizing/reporting child abuse/neglect</option>
              <option>12 - Identifying/serving homeless children/families</option>
              <option>13 - Promoting social, emotional, physical, and cognitive development of children</option>
              <option>14 - Caring for children in geographic areas - poverty & unemployment</option>
              <option>15 - Implementing behavior strategies</option>
              <option>16 - Engaging parents and families - culturally and linguistically</option>
              <option>17 - Understanding early neurological development</option>
              <option>18 - Using data to guide program improvement</option>
              <option>19 - Supporting positive development of school-age children</option>
              <option>20 - Improving the quality of programs/services for infants/toddlers</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label>Delivery Method:</label><br />
            <select v-model="selectedDelivery" :ref="'deliveryControl'" class="custom-select form-el">
              <option></option>
              <option>Virtual/Online</option>
              <option>Classroom</option>
              <option>Blended</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <input type="submit" class="btn btn-primary" :value="saveButtonName" />
          </div>
          <div class="form-group col-md-4"></div>
          <div class="form-group col-md-4">
            <input type="reset" class="btn btn-primary" :value="resetButtonName" @mouseup="resetForm" />
          </div>
        </div>
      </form>
    </div>
    <grid
      v-if="gridKey.startsWith('open')"
      :entityTypeId="courseSessionEntityType"
      :formId="courseSessionFormId"
      :entityTypeName="entityTypeName"
      :showFormOnEmpty="false"
      :showAddButton="false"
      :key="gridKey"
      :primaryEntity="primaryEntity"
      ref="sessionGrid"
      :allowDownload="false"
      :showDisable="false"
      :showEdit="false"
      :showView="false"
      :additionalActions="additionalActions"
      :additionalActionsOnColumn="true"
      :defaultFilters="defaultFilters"
      :altGridId="altGridId"
      :basePageSize="gridPageSize"
      :baseCurrentPage="gridCurrentPage"
      @setPageSize="setPageSize"
      @setCurrentPage="setCurrentPage"
    >
    </grid>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header" v-if="activeSession">{{ activeSession.courseName }}: {{ activeSession.sectionName }}</h2>
      <div class="text-left row" slot="body" v-if="activeKey != 'closed'">
        <div class="w-100 m-3">
          <trainingSearchDetails
            :key="activeKey"
            :section="activeSession"
            :hideRegister="hideRegister"
            :pastDeadline="pastDeadline"
            :beforeStart="beforeStart"
            @reset="setDirty"
          ></trainingSearchDetails>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import trainingSearchDetails from './training-search-details.vue';
import { mapGetters } from 'vuex';
export default {
  components: { trainingSearchDetails },
  name: 'TrainingSearch' ,
  data() {
    return {
      primaryEntity: {
        type: Object,
        default() {
          return {
            Id: '',
            EntityTypeId: 24
          };
        }
      },
      additionalActions: [
        {
          title: 'View to Register',
          icon: 'file-signature',
          click: this.rowAction,
          check: this.checkIfNotRegistered
        },
        {
          title: 'View Details',
          icon: 'file-signature',
          click: this.rowAction,
          check: this.checkIfRegistered
        },
        {
          title: 'Cancel Training',
          icon: 'calendar-times',
          click: this.cancelTraining,
          check: this.checkIfRegistered
        },
      ],
      courseSessionEntityType: 24,
      courseSessionFormId: 22,
      entityTypeName: 'Training',
      altGridId: 1,
      gridKey: 'closed',
      trainingName: '',
      endDate: '',
      regDeadlineDate: '',
      selectedRegion: '',
      selectedDelivery: '',
      selectedCKA: '',
      selectedAgeGroup: '',
      selectedDHRStandard: '',
      selectedCDA: '',
      selectedCCDF: '',
      resetButtonName: 'Clear Values',
      saveButtonName: 'Search',
      defaultFilters: [],
      isModalVisible: false,
      activeSession: {},
      activeKey: 'closed',
      hideRegister: false,
      pastDeadline: false,
      beforeStart: false,
      dirtyFlag: false,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['activeRole'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeKey = item.id;
        this.activeSession = item;
        if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName === '100') {
          const tempDate = new Date();
          const tzDifference = tempDate.getTimezoneOffset();
          const nowDate = new Date(tempDate.getTime() - tzDifference * 60 * 1000).toISOString();
          if (nowDate > item.regDeadlineDate) this.pastDeadline = true;
          if (nowDate < item.regStartDate) this.beforeStart = true;
        }
        if ((this.activeRole && this.activeRole.role && this.activeRole.role.roleName === '250') || item.status != '') {
          this.hideRegister = true;
        } else {
          this.hideRegister = false;
        }
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeKey = 'closed';
      this.activeSession = {};
      this.pastDeadline = false;
      this.beforeStart = false;
      if (this.dirtyFlag) {
        this.handleSubmit();
        this.dirtyFlag=false;
      }
    },
    setDirty() {
      this.dirtyFlag = true;
    },
    async handleSubmit(event) {
      this.defaultFilters = [];
      if (this.trainingName.trim().length > 0) {
        const nameFilter = {
          operation: 'Contains',
          filterValue: this.trainingName.trim(),
          filterColumn: { propertyName: 'CourseName' }
        };
        this.defaultFilters.push(nameFilter);
      }
      if (this.selectedCKA) {
        const ckaFilter = {
          operation: 'Contains',
          filterValue: this.selectedCKA,
          filterColumn: { propertyName: 'CoreKnowledgeArea' }
        };
        this.defaultFilters.push(ckaFilter);
      }
      if (this.selectedRegion) {
        const regionFilter = {
          operation: 'Contains',
          filterValue: this.selectedRegion,
          filterColumn: { propertyName: 'Region' }
        };
        this.defaultFilters.push(regionFilter);
      }
      if (this.selectedAgeGroup) {
        const ageFilter = {
          operation: 'Contains',
          filterValue: this.selectedAgeGroup,
          filterColumn: { propertyName: 'TargetAgeGroup' }
        };
        this.defaultFilters.push(ageFilter);
      }
      if (this.selectedDHRStandard) {
        const dhrFilter = {
          operation: 'Contains',
          filterValue: this.selectedDHRStandard,
          filterColumn: { propertyName: 'DhrMinimumStandard' }
        };
        this.defaultFilters.push(dhrFilter);
      }
      if (this.selectedCDA) {
        const cdaFilter = {
          operation: 'Contains',
          filterValue: this.selectedCDA,
          filterColumn: { propertyName: 'CdaArea' }
        };
        this.defaultFilters.push(cdaFilter);
      }
      if (this.selectedCCDF) {
        const ccdfFilter = {
          operation: 'Contains',
          filterValue: this.selectedCCDF,
          filterColumn: { propertyName: 'CcdfArea' }
        };
        this.defaultFilters.push(ccdfFilter);
      }
      if (this.selectedDelivery) {
        const deliveryFilter = {
          operation: 'Contains',
          filterValue: this.selectedDelivery,
          filterColumn: { propertyName: 'DeliveryMethod' }
        };
        this.defaultFilters.push(deliveryFilter);
      }
      this.gridKey = 'open' + Math.random();
    },
    resetForm(evt) {
      this.$emit('reset');
    },
    clearDateValidity: function (input) {
      const endDateRef = this.$refs.endDateControl;
      endDateRef.setCustomValidity('');
    },
    checkIfRegistered(item) {
      return (item.status != '');
    },
    checkIfNotRegistered(item) {
      return (item.status == '');
    },
    async cancelTraining(item) {
      const confirmTitle = "Confirm Cancellation";
      const confirmText = "Your cancellation request will be processed immediately and can not be reversed. Please click Confirm to cancel your registration.";
      const options = { title: confirmTitle, okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm(confirmText, options)
        .then((res) => {
          //if we clicked continue
          if (res && res.ok && res.ok == true) {
            const body = {
              courseSectionId: item.id,
              userIds: [this.$store.getters['userId']],
              comment: ''
            };
            this.isBusy = true;
            this.$store
              .dispatch('cancelRegistrationRequest', body)
              .then(async (response) => {
                const options = { title: 'Cancellation Request Complete!' };
                this.$dialogs.alert(`You have successfully unregistered for  ${item.courseName}: ${item.sectionName}`, options);
                this.handleSubmit();
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        });
    }
  }
};
</script>
