<template>
  <div>
    <div v-show="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area' : ''">
      <button v-if="showDownloadButton"
              @click="downloadFile"
              class="abtn mb-3 float-right light-blue-link">
        {{ downloadButtonText }}<icon icon="file-pdf" class="ml-2 fa-1_5x"></icon>
      </button>
      <iframe :src="iframeSrc"
              ref="iframe"
              style="width: 100%; height: 1060px"
              frameborder="0"
              v-if="iframeSrc"
              @load="loaded"
              id="iframe"
              name="iframe"></iframe>
    </div>
  </div>
</template>

<script>
  export default {
		name: 'doc-viewer',
    data() {
			return {
        isBusy: true,
        iframeSrc: '',
        timerId: ''
			};
    },
    methods: {
      loaded() {
        clearInterval(this.timerId);
        this.$emit('busy', false);
        this.isBusy = false;
      },
      setIFrameSrc(fileCode, fileUrl) {
        this.$emit('busy', true);
        this.isBusy = true;
        if (fileCode) {
          this.$store
            .dispatch('staticFileUrlRequest', fileCode)
            .then((response) => {
              if (response) {
                this.iframeSrc = 'https://docs.google.com/gview?url=' + encodeURIComponent(response + '') + '&embedded=true';
              }
            })
            .catch((errors) => {
              this.errors = errors;
            });
        }
        else if (fileUrl) {
          this.iframeSrc = 'https://docs.google.com/gview?url=' + encodeURIComponent(fileUrl + '') + '&embedded=true';
        }
      },
      reloadIFrame() {
        this.$refs.iframe.src += '';
      },
      async downloadFile() {
        this.$emit('busy', true);
        this.isBusy = true;
        
        if (this.fileCode) {
          await this.$downloadStaticFile(this.fileCode);
        }
        else if (this.fileUrl) {
          await this.getPdf(this.fileUrl);
        }
        const options = { title: 'Download successful.' };
        this.$dialogs.alert(`Check your Downloads folder for a PDF.`, options);
        this.$emit('busy', false);
        this.isBusy = false;
      },
      async getPdf(fileUrl) {
        let filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        filename = filename.substring(filename.lastIndexOf('?'), 0)       
        this.$store
          .dispatch('reportDownloadRequest', filename)
          .then((result) => {
            this.savePdf(result.data, filename);
          })
      },
      savePdf(strData, filename) {
        const url = window.URL.createObjectURL(new Blob([strData], { type: "application/pdf" }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename.replace(/"/g, '').replace(/%20/g,' '));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
		},
    mounted() {
      this.setIFrameSrc(this.fileCode, this.fileUrl);
      this.timerId = setInterval(this.reloadIFrame, 2000);
		},
		props: {
      fileCode: {
        type: String,
        default: ''
      },
      fileUrl: {
        type: String,
        default: ''
      },
      downloadButtonText: {
        type: String,
        default: 'Download File'
      },
      showDownloadButton: {
        type: Boolean,
        default: true
      }
    },
	}
</script>
<style>
</style>
