<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div v-if="isBusy" class="loader">
          <icon icon="spinner" pulse />
          <p><em>Loading...</em></p>
        </div>
        <div class="row">
          <div class="col-12 mb-4 text-center">
            <h1 class="title has-text-grey">
              <icon :icon="['far', 'bell']" :size="'sm'" class="mr-2"></icon>{{ currentPageName }}
            </h1>
            <p v-if="instructionText">{{ instructionText }}</p>
          </div>
        </div>
        <div :class="isBusy ? ' disabled-area' : ''">
          <Toolbar
            :bulkCheckbox="bulkCheckbox"
            :halfCheckbox="halfCheckbox"
            :emptyCheckbox="emptyCheckbox"
            :markRead="markSelectedRead"
            :markUnread="markUnread"
            :bulkSelect="bulkSelect"
            :unreadCount="unreadCount"
            :starNotification="starNotification"
            :deleteNotification="deleteNotification"
            :markReadAvailable="markReadAvailable"
            :markUnreadAvailable="markUnreadAvailable"
            :toggleArchive="toggleArchive"
            :activeFolder="activeFolder"
            :folders="folders"
            :folderChange="folderChange"
            :activeFilter="activeFilter"
            :filters="filters"
            :filterChange="filterChange"
          ></Toolbar>
          <NotificationList
            :notifications="notifications"
            :starNotification="starNotification"
            :activeFolder="activeFolder"
            :folders="folders"
            :openNotification="openNotification"
            :formatFullDate="$formatEmailDate"
          ></NotificationList>
          <div class="mt-4">
            <Paging
              :firstOnPage="firstOnPage"
              :lastOnPage="lastOnPage"
              :total="total"
              :pageSize="pageSize"
              :currentPage="currentPage"
              :pageSizeChanged="pageSizeChanged"
              :loadPage="loadPage"
            ></Paging>
          </div>
        </div>
        <div class="text-center m-5" v-if="noData && !isBusy">No Notifications</div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="this.isModalVisible" :skinName="'notification'">
      <div slot="header" class="w-100">
        <h2>{{ activeNotification.subject }}</h2>
      </div>
      <div slot="body" :key="activeNotificationId">
        <p v-html="activeNotification.body"></p>
        <div class="w-100 text-center">
          <b-button
            v-if="activeNotification.route && activeNotification.linkText"
            variant="wfr"
            class="mt-2"
            @click="goToNotificationRoute(activeNotification.route)"
            :title="activeNotification.linkText"
          >
            {{ activeNotification.linkText }}
          </b-button>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import Toolbar from './notification-toolbar';
import NotificationList from './notification-list';
import Paging from '@components/utility/paging.vue';
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';

export default {
  name: 'NotificationInbox',
  components: {
    Toolbar,
    NotificationList,
    Paging
  },
  data() {
    return {
      notifications: [],
      users: [],
      locale: locale,
      currentPageName: '',
      instructionText: '',
      noData: true,
      isLoading: false,
      isBusy: false,
      isModalVisible: false,
      activeNotification: {},
      activeNotificationId: '',
      total: 0,
      pageSize: 5,
      currentPage: 1,
      twoCharacterMessage: 'At least two characters are required for search',
      emptySetMessage: 'No results found',
      noResultMessage: 'At least two characters are required for search',
      folders: {
        inbox: 'Inbox',
        archive: 'Archive'
      },
      actions: {
        star: 'Star',
        unstar: 'Unstar',
        read: 'Read',
        unread: 'Unread',
        archive: 'Archive',
        unarchive: 'Unarchive'
      },
      activeFolder: { folder: 'Inbox' },
      activeFilter: { filter: 'None' },
      filters: {
        none: 'None',
        starred: 'Starred',
        unread: 'Unread'
      },
      switchingFolders: false
    };
  },
  async mounted() {
    await this.loadTotal();
  },
  created() {
    this.$messageHub.$on('notification-received', this.notificationReceived);
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
    }
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$messageHub.$off('notification-received', this.notificationReceived);
  },
  computed: {
    ...mapGetters(['roles', 'activeRole']),
    unreadCount() {
      return this.notifications.reduce((acc, notification) => {
        if (notification.read == false) {
          acc++;
        }
        return acc;
      }, 0);
    },
    bulkCheckbox() {
      return this.notifications.every((notification) => notification.selected) && this.notifications.length > 0;
    },
    halfCheckbox() {
      return this.notifications.some((notification) => notification.selected) && !this.bulkCheckbox;
    },
    emptyCheckbox() {
      return this.notifications.every((notification) => !notification.selected);
    },
    markReadAvailable() {
      return this.notifications.some((notification) => notification.selected && !notification.read);
    },
    markUnreadAvailable() {
      return this.notifications.some((notification) => notification.selected && notification.read);
    },
    firstOnPage: function () {
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    lastOnPage: function () {
      const max = this.currentPage * this.pageSize;
      return max > this.total ? this.total : max;
    }
  },
  methods: {
    async loadNotifications(f, t) {
      return await new Promise((resolve, reject) => {
        this.isBusy = true;
        const body = {
          folder: this.activeFolder.folder,
          filter: this.activeFilter.filter,
          from: f,
          to: t
        };
        this.$store
          .dispatch('getNotificationsRequest', body)
          .then((response) => {
            this.isBusy = false;
            this.switchingFolders = false;
            this.notifications = response.map((notification) => {
              notification.selected = false;
              return notification;
            });
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            this.switchingFolders = false;
            resolve(false);
          });
      });
    },
    notificationReceived(data) {
      if (data && data.notification) {
        this.noData = false;
        this.notifications.unshift(data.notification);
        this.total++;
        if (this.notifications.length > this.pageSize) this.notifications.pop();
      }
    },
    starNotification(notification) {
      notification.starred = !notification.starred;
      const action = notification.starred ? this.actions.star : this.actions.unstar;
      const body = { action: action, notificationIds: [notification.notificationId] };
      this.$store
        .dispatch('notificationActionRequest', body)
        .then((response) => {})
        .catch((errors) => {});
    },
    bulkSelect() {
      if (this.emptyCheckbox) {
        this.notifications.forEach((notification) => this.$set(notification, 'selected', true));
      } else {
        this.notifications.forEach((notification) => this.$set(notification, 'selected', false));
      }
    },
    toggleArchive() {
      const id = [];
      this.notifications = this.notifications.filter((notification) => {
        if (notification.selected) {
          id.push(notification.notificationId);
        }
        return !notification.selected;
      });
      const action = this.activeFolder.folder == this.folders.inbox ? this.actions.archive : this.actions.unarchive;
      const body = { action: action, notificationIds: id };
      this.isBusy = true;
      this.$store
        .dispatch('notificationActionRequest', body)
        .then((response) => {
          this.isBusy = false;
          this.loadTotal();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    folderChange(val) {
      this.switchingFolders = true;
      this.activeFilter.filter = this.filters.none;
      this.activeFolder.folder = val;
      this.loadTotal();
    },
    markSelectedRead() {
      const id = [];
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].selected && !this.notifications[i].read) {
          this.notifications[i].read = true;
          id.push(this.notifications[i].notificationId);
        }
      }
      this.markRead(id);
    },
    markRead(ids) {
      if (ids && ids.length > 0) {
        const body = { action: this.actions.read, notificationIds: ids };
        this.$store
          .dispatch('notificationActionRequest', body)
          .then((response) => {
            this.notifications = this.notifications.map((notification) => {
              notification.selected = false;
              return notification;
            });
          })
          .catch((errors) => {});
      }
    },
    markUnread() {
      const id = [];
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].selected && this.notifications[i].read) {
          this.notifications[i].read = false;
          id.push(this.notifications[i].notificationId);
        }
      }
      const body = { action: this.actions.unread, notificationIds: id };
      this.$store
        .dispatch('notificationActionRequest', body)
        .then((response) => {
          this.notifications = this.notifications.map((notification) => {
            notification.selected = false;
            return notification;
          });
        })
        .catch((errors) => {});
    },
    deleteNotification() {
      const id = [];
      this.notifications = this.notifications.filter((notification) => {
        if (notification.selected) {
          id.push(notification.messageId);
        }
        return !notification.selected;
      });
      const body = { action: this.actions.delete, messageIds: id };
      this.isBusy = true;
      this.$store
        .dispatch('notificationvActionRequest', body)
        .then((response) => {
          this.isBusy = false;
          this.loadTotal();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    filterChange(val) {
      this.activeFilter.filter = val;
      this.loadTotal();
    },
    pageSizeChanged(size) {
      if (size && !isNaN(size)) {
        this.pageSize = parseInt(size);
      }
      this.loadPage(1);
    },  
    hasRoleActive(roleNames) {
      if (roleNames.length > 0) {
        if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
          return roleNames.some((role) => role == this.activeRole.role.roleName);
        }
        //no active org role can still access 100 level
        else if (roleNames.some((role) => role == '100')) return true;
        else return false;
      } else {
        return false;
      }
    },
    async loadPage(page) {
      return await new Promise((resolve, reject) => {
        this.currentPage = page;
        const from = (page - 1) * this.pageSize;
        const to = from + this.pageSize;
        this.loadNotifications(from, to);
        resolve(true);
      });
    },
    async loadTotal() {
      return await new Promise((resolve, reject) => {
        const body = {
          folder: this.activeFolder.folder,
          filter: this.activeFilter.filter
        };
        this.isBusy = true;
        this.notifications = [];
        this.isBusy = false;

        this.$store
          .dispatch('getNotificationCountRequest', body)
          .then(async (response) => {
            this.total = response;
            this.noData = this.total == 0;
            if (!this.noData) {
              await this.loadPage(1);
            } else {
              this.notifications = [];
              this.isBusy = false;
            }
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeNotification = {};
      this.activeNotificationId = 'closed';
    },
    openNotification(notification) {
      if (notification && notification.notificationId) {
        if (!notification.read) {
          this.notifications.map((n) => {
            if (n.notificationId == notification.notificationId) n.read = true;
          });
          this.markRead([notification.notificationId]);
        }
        this.activeNotification = notification;
        this.activeNotificationId = notification.notificationId;
        this.isModalVisible = true;
      }
    },
    goToNotificationRoute(route) {
      const parsed = JSON.parse(route);
      if (parsed) route = parsed;
      if (route && route.name) {
        this.$router.push(route);
      }
    }
  }
};
</script>
