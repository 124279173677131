<template>
  <div>
    <div>
      <div v-for="(ent, entIndex) in attestationList" :key="parentEntIndex + '_' + entIndex">
        <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
                         class="p-1 mouse-pointer"
                         role="tab"
                         v-b-toggle="'accordion_' + parentEntIndex + '_' + entIndex"
              @click="setExpanded(entIndex)"
            >
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                  <icon
                    :icon="expanded == entIndex ? 'caret-down' : 'caret-right'"
                      class="ml-2"
                    style="color: dimgray"
                  ></icon>
              </button>
              <span class="ml-4">{{ ent.employer }}</span>
            </span>
          </b-card-header>
            <b-collapse
              :id="'accordion_' + parentEntIndex + '_' + entIndex"
                      accordion="access-accordion2"
                      role="tabpanel"
              :visible="entIndex == 0 ? true : false"
            >
            <b-card-body>
              <div>
                <div v-if="expanded == entIndex">
                  <Attestation :attestations="ent.attestations"></Attestation>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Attestation from './attestation';
export default {
  name: 'AttestationList',
  components: {
    Attestation
  },
  data() {
    return {
      attestationList: [],
      expanded: 0
    };
  },
  props: {
    primaryEntity: {
      type: Object,
      default: () => {}
    },
    parentEntIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    parseList(inputList) {
      const tempList = [];
      let tempIdx = -1;
      let lastAgency = '';
      for (let i=0; i < inputList.length; i++) {
          if (inputList[i].employerName != lastAgency) {
              tempList.push({'employer': inputList[i].employerName, 'attestations': []});
              tempIdx++;;
          }
          tempList[tempIdx].attestations.push(inputList[i]);
          lastAgency = inputList[i].employerName;
      }
      return tempList;
    },
    setExpanded(index) {
      if (this.expanded == index) this.expanded = -1;
      else this.expanded = index;
    }
  },
  async created() {
    const body = {
        userId : this.primaryEntity.Id
      };
    this.isBusy = true;
    this.$store
    .dispatch('attestationListRequest', body)
    .then(async (response) => {
        this.attestationList = this.parseList(response);
        this.isBusy = false;
    })
    .catch((errors) => {
        this.isBusy = false;
    });     
  }
};
</script>
