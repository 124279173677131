<template>
  <div>
    <h1 class="hidden-title">Action Details</h1>
    <div role="tablist">
      <div v-for="(action, index) in actionInfo" :key="action.id">
        <b-card no-body class="mb-1">
          <b-card-header
            header-tag="header"
            class="p-1 mouse-pointer"
            role="tab"
            v-b-toggle="'actionaccordion_' + index"
            @click="setExpanded(index)"
          >
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon
                  :icon="expanded == index ? 'caret-down' : 'caret-right'"
                  class="ml-2"
                  style="color: dimgray"
                ></icon>
              </button>
              <span class="ml-4"> </span>
              {{ action.scope }}
            </span>
          </b-card-header>
          <b-collapse :id="'actionaccordion_' + index" accordion="action-accordion" role="tabpanel">
            <b-card-body>
              <div v-if="expanded == index">
                <requestDetails :rolePromotion="action"></requestDetails>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import requestDetails from './request-details.vue';
export default {
  name: 'ActionDetails',
  components: { requestDetails },
  data() {
    return {
      isBusy: false,
      actionInfo: [],
      expanded: -1
    };
  },
  props: {
    action: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.loadInfo();
  },
  computed: {
    ...mapGetters(['activeRole'])
  },
  methods: {
    loadInfo() {
      if (this.action && this.action.id) {
        const body = {
          rolePromotionId: this.action.id,
          primaryEntityId: this.action.primaryEntityId
        };
        this.isBusy = true;
        this.$store
          .dispatch('actionHistoryRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.actionInfo = response;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    setExpanded(index) {
      if (this.expanded == index) this.expanded = -1;
      else this.expanded = index;
    }
  }
};
</script>
