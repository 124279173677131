<template>
  <transition name="modal-fade">
    <div :class="'modal-backdrop ' + (withinModal ? 'no-opaque-bg ' : 'opaque-bg ')">
      <focus-trap
        :active="isModalVisible ? true : false"
        :initial-focus="() => $refs.modalBody"
        :escapeDeactivates="false"
      >
        <div :class="skinName + '-modal'" role="dialog">
          <div v-if="modalBusy" class="loader">
            <icon icon="spinner" pulse />
            <p><em>Loading...</em></p>
          </div>
          <header :class="skinName + '-modal-header'" v-if="showHeader">
            <slot name="header" />
          </header>
          <slot name="topButton">
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close"
              v-show="showTopClose"
              :disabled="closeDisabled ? true : false"
              :style="'right:' + btnHeaderCloseRight + ';position: absolute;z-index: 2;'"
            >
              <span aria-hidden="true">x</span>
            </button>
          </slot>
          <section
            :class="skinName + '-modal-body overflow-auto'"
            id="modalDescription"
            v-on:scroll="checkHeight($event)"
            @mouseover="checkHeight($event)"
            @wheel="checkHeight($event)"
            ref="modalBody"
            tabindex="0"
          >
            <slot name="body">I'm the default body!</slot>
          </section>
          <footer class="modal-footer">
            <slot name="footer" class="footerSlot">
              <label v-if="showCopyright">&copy; {{ year }}</label>
            </slot>
            &nbsp;
            <button
              type="button"
              @click="close"
              v-show="showBottomClose"
              class="btn btn-primary"
              :disabled="closeDisabled ? true : false"
            >
              <slot name="bottomButtonText">Close</slot>
            </button>
          </footer>
        </div>
      </focus-trap>
    </div>
  </transition>
</template>
<script>
import { FocusTrap } from 'focus-trap-vue';
export default {
  name: 'modal',
  data() {
    return {
      year: new Date().getFullYear().toString(),
      hasViewedAll: false,
      btnHeaderCloseRight: '0rem'
    };
  },
  components: {
    FocusTrap: FocusTrap
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toggleBodyClass(modalVisible) {
      if (!this.withinModal) {
        const main = document.getElementById('app');
        if (main && main.classList) {
          if (modalVisible) {
            main.classList.add('no-scroll');
          } else {
            main.classList.remove('no-scroll');
          }
        }
      }
    },
    checkHeight(event) {
      if ((!this.mustViewAll || this.hasViewedAll) && this.showHeader) {
        return;
      }
      if (event && event.currentTarget) {
        const text = event.currentTarget;
        if (text.scrollHeight && text.clientHeight) {
          //will evaluate true if the full div is displayed without scrolling
          const noScroll = text.scrollHeight - text.clientHeight == 0;
          if (noScroll) {
            this.hasViewedAll = true;
            this.btnHeaderCloseRight = '0rem';
            return;
          } else {
            this.btnHeaderCloseRight = '1rem';
          }
          if (text.scrollTop) {
            const distanceToBottom = text.scrollHeight - text.scrollTop - text.clientHeight;
            if (distanceToBottom <= 0) {
              this.hasViewedAll = true;
            }
          }
        }
      }
    }
  },
  mounted() {
    this.toggleBodyClass(this.isModalVisible);
  },
  watch: {
    isModalVisible: function (val) {
      this.toggleBodyClass(val);
    }
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false
    },
    mustViewAll: {
      type: Boolean,
      default: false
    },
    showCopyright: {
      type: Boolean,
      default: false
    },
    withinModal: {
      type: Boolean,
      default: false
    },
    skinName: {
      type: String,
      default: 'wfr'
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    modalBusy: {
      type: Boolean,
      default: false
    },
    showTopClose: {
      type: Boolean,
      default: true
    },
    showBottomClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    closeDisabled: function () {
      return this.mustViewAll && !this.hasViewedAll;
    }
  }
};
</script>
