<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Closed Connections</h1>
          <p>View list of all completed coaching.</p>
        </div>
        <div>
          <grid
            :entityTypeId="coachRelationshipEntityTypeId"
            :formId="20"
            :entityTypeName="'Coach Relationship'"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :primaryEntity="getPrimaryEntity()"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            :allowDownload="true"
            ref="coachesGrid"
            :additionalShowColumns="additionalColumns"
            :showActionColumn="false"
            :defaultFilters="defaultFilters"
            :exportFilters="exportFilters"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ClosedCases',
  data() {
    return {
      userEntityTypeId: 1,
      coachRelationshipEntityTypeId: 21,
      additionalColumns: ['primaryEntityName'],
      defaultFilters: [
        {
          operation: 'Equals',
          filterValue: 'false',
          filterColumn: { propertyName: 'Active' }
        }
      ],
      exportFilters: [
        {
          operation: 'Contains',
          filterValue: 'Closed',
          filterColumn: { propertyName: 'status' }
        }
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    getPrimaryEntity() {
      return {
        Id: this.userId,
        EntityTypeId: this.userEntityTypeId
      };
    }
  }
};
</script>
