<template>
  <section class="section">
    <div class="container has-text-centered">
      <div class="login-form-container">
        <div class="py-5">
          <article class="is-success" v-if="$route.query.new">
            <div class="message-body">
              <strong>A confirmation email has been sent to the address you provided.</strong>
              <div>Please click the confirmation link and then you can login. This link expires in 24 hours.</div>
              <div class="font-italic mb-1">
                If you are not able to locate this message in your inbox, please check your spam folder.
              </div>
            </div>
          </article>
          <article class="is-success" v-if="$route.query.confirm && this.isBusy && !this.confirmed">
            <div class="message-body">
              <strong>Confirming your account</strong>
            </div>
            <div class="loader">
              <icon icon="spinner" pulse />
            </div>
          </article>
          <article class="is-success" v-if="$route.query.confirm && this.confirmed">
            <div class="message-body">
              <strong>Thank you for confirming your email</strong>
            </div>
          </article>
          <article class="is-success" v-if="$route.query.confirmSecondaryEmail && this.secondaryConfirmed">
            <div class="message-body">
              <strong>Your secondary email has been confirmed</strong>
            </div>
          </article>
          <article class="" v-if="$route.query.confirm && this.expiredToken">
            <div class="message-body">
              <strong
                ><a href="#" @click="sendconfemail"
                  >Your confirmation link has expired. Please click here to request another confirmation email.</a
                ></strong
              >
            </div>
          </article>
          <article class="is-success" v-if="$route.query.reset">
            <div class="message-body">
              <strong>Your password has been reset.</strong>
            </div>
          </article>
          <div :class="isBusy ? 'disabled-area' : ''" v-if="!($route.query.confirm && !this.confirmed)">
            <p class="subtitle has-text-grey" v-if="$route.query.confirm || $route.query.reset">
              Please login to proceed
            </p>
            <div class="box">
              <form @submit.prevent="handleSubmit">
                <div class="input-group my-3">
                  <input class="input form-control" type="email" placeholder="Email" autofocus="" v-model="userName" />
                </div>
                <div class="input-group addon password-wrapper my-3">
                  <input
                    class="form-control"
                    :type="passwordFieldType"
                    placeholder="Password"
                    v-model="password"
                    ref="password"
                  />
                  <div class="input-group-addon">
                    <icon
                      :icon="'eye'"
                      class="password-view"
                      v-touch:start="showpassword"
                      v-touch:end="hidepassword"
                    ></icon>
                  </div>
                </div>
                <div class="my-5">
                  <div style="display: inline; float: left">
                    <button class="btn btn-primary" type="submit">Login</button>
                  </div>
                  <div style="display: inline; float: right">
                    <router-link
                      :to="{
                        name: 'forgot-password',
                        query: { email: this.userName }
                      }"
                      >Forgot Password?</router-link
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="errors-container" v-if="errors">
          {{ errors }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isBusy: false,
      errors: '',
      userName: '',
      password: '',
      userId: '',
      confirmationCode: '',
      confirmed: false,
      secondaryConfirmed: false,
      passwordFieldType: 'password',
      expiredToken: false
    };
  },
  watch: {
    isBusy: function (newValue, oldValue) {
      this.$emit('busy', newValue);
    }
  },
  created() {
    if (this.$route.query.email) {
      this.userName = decodeURIComponent(this.$route.query.email);
    }
    if (this.$route.query.confirmSecondaryEmail) {
      this.isBusy = true;
      const key = encodeURIComponent(this.$route.query.confirmSecondaryEmail);
      this.$store
        .dispatch('confirmSecondaryRequest', key)
        .then((result) => {
          this.secondaryConfirmed = true;
          this.$emit('busy', false);
          this.isBusy = false;
        })
        .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                if (errArr[i][0] == 'Invalid token.') {
                  this.expiredToken = true;
                  continue;
                }

                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
    }
    if (this.$route.query.confirm) {
      if (!this.$route.query.userId || !this.$route.query.code) {
        this.errors = 'Unable to confirm account';
      } else {
        this.isBusy = true;
        this.userId = this.$route.query.userId;
        this.confirmationCode = decodeURIComponent(this.$route.query.code);
        const credentials = { userId: this.userId, code: this.confirmationCode };
        this.$store
          .dispatch('confirmationRequest', credentials)
          .then((result) => {
            this.confirmed = true;
            this.$emit('busy', false);
            this.isBusy = false;
            if (result && result.code && result.id && result.email) {
              //they need to set up their password
              this.$router.push({
                name: 'forgot-password',
                query: {
                  userId: result.id,
                  email: result.email,
                  code: result.code,
                  reset: true,
                  confirmed: true
                }
              });
            }
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                if (errArr[i][0] == 'Invalid token.') {
                  this.expiredToken = true;
                  continue;
                }

                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
      }
    }
  },
  methods: {
    ...mapActions(['limitedLogin']),

    handleSubmit: function () {
      this.isBusy = true;
      const credentials = {
        userName: this.userName.trim(),
        password: this.password
      };
      this.$store
        .dispatch('authRequest', credentials)
        .then((result) => {
          window.scrollTo(0, 0);
          if (result.route && result.route != 'default') {
            this.limitedLogin(result.route);
            this.$router.push({
              name: result.route,
              params: { userId: result.jwt.id }
            });
          } else {
            this.$router.push('/dashboard');
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = '';
          if (errors.response && errors.response.data) {
            const errobj = errors.response.data;

            if (typeof errobj == 'string') {
              this.errors = errobj;
              return;
            }
            let errArr = [];
            if (errobj.errors) {
              errArr = Object.values(errobj.errors);
            } else {
              errArr = Object.values(errobj);
            }
            for (let i = 0; i < errArr.length; i++) {
              if (errArr[i].errors) {
                errArr[i] = errArr[i].errors;
                if (typeof errArr[i] == 'object') {
                  //sometimes the modelstate will have a super nested error...
                  errArr[i] = errArr[i][0].errorMessage;
                }
              }
              this.errors = this.errors + errArr[i] + '\n';
            }
          } else this.errors = errors;
        });
    },
    showpassword: function () {
      if (this.passwordFieldType == 'password') this.passwordFieldType = 'text';
      else this.passwordFieldType = 'password';
    },
    hidepassword: function () {
      this.passwordFieldType = 'password';
    },
    sendconfemail: function () {
      const credentials = { userName: this.userName, password: this.password };
      this.$store
        .dispatch('sendConfEmailRequest', credentials)
        .then((result) => {
          this.$router.push({ name: 'login-form', query: { new: true } });
        })
        .catch((errors) => {
          this.errors = '';
          if (errors.response && errors.response.data) {
            const errobj = errors.response.data;

            let errArr = [];
            if (errobj.errors) {
              errArr = Object.values(errobj.errors);
            } else {
              errArr = Object.values(errobj);
            }

            for (let i = 0; i < errArr.length; i++) {
              this.errors = this.errors + errArr[i] + '\n';
            }
          } else this.errors = errors;
        });
    }
  }
};
</script>
