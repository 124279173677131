<template>
  <div :class="isBusy ? 'disabled-area ' : ''">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div>
      <div class="mb-4">
        <b-button
          variant="wfr"
          v-if="shouldShowRequestLink"
          :title="'Request Coaching ' + item.fullName"
          @click="requestButtonClick()"
          ><icon :icon="'people-arrows'" class="mr-2"></icon>Request Coaching {{ item.fullName }}</b-button
        >
        <b-button
          class="ml-3"
          variant="wfr"
          v-if="shouldShowWithdrawLink"
          :title="'Withdraw Coaching Request  ' + item.fullName"
          @click="withdrawButtonClick()"
          ><icon :icon="'user-times'" class="mr-2"></icon>Withdraw Coaching Request with {{ item.fullName }}</b-button
        >
        <b-button
          class="ml-3"
          variant="wfr"
          v-if="shouldShowCloseLink"
          :title="'End Coaching ' + item.fullName"
          @click="closeButtonClick()"
          ><icon :icon="'user-minus'" class="mr-2"></icon>End Coaching {{ item.fullName }}</b-button
        >
      </div>
      <div role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.requests>
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="requestsOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">History</span>
            </span>
          </b-card-header>
          <b-collapse id="requests" accordion="access-accordion" role="tabpanel" v-model="requestsOpen">
            <b-card-body>
              <grid
                :entityTypeId="coachRelationshipActionEntityTypeId"
                :formId="0"
                :entityTypeName="''"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :primaryEntity="getPrimaryEntity()"
                :showDisable="false"
                :showEdit="false"
                :showView="false"
                :showActionColumn="false"
                :allowDownload="false"
                ref="historyGrid"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"
              ></grid>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmploymentRoles',
  data() {
    return {
      userOrgRoleInfo: [],
      requestsOpen: true,
      userEntityTypeId: 1,
      coachRelationshipActionEntityTypeId: 22,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    requestCoach: {
      type: Function,
      default: () => {
        return true;
      }
    },
    withdrawCoachRequest: {
      type: Function,
      default: () => {
        return true;
      }
    },
    closeCoach: {
      type: Function,
      default: () => {
        return true;
      }
    },
    shouldShowRequestLink: {
      type: Boolean,
      default: false
    },
    shouldShowWithdrawLink: {
      type: Boolean,
      default: false
    },
    shouldShowCloseLink: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    async requestButtonClick() {
      await this.requestCoach(this.item);
      this.$nextTick().then(() => {
        this.$refs.historyGrid.loadSummary();
      });
    },
    async withdrawButtonClick() {
      await this.withdrawCoachRequest(this.item);
      this.$nextTick().then(() => {
        this.$refs.historyGrid.loadSummary();
      });
    },
    async closeButtonClick() {
      await this.closeCoach(this.item);
      this.$nextTick().then(() => {
        this.$refs.historyGrid.loadSummary();
      });
    },
    getPrimaryEntity() {
      return {
        Id: this.item.id,
        EntityTypeId: this.userEntityTypeId
      };
    }
  }
};
</script>
