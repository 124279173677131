import { render, staticRenderFns } from "./checkbox-prompt.vue?vue&type=template&id=376f4fb2"
import script from "./checkbox-prompt.vue?vue&type=script&lang=js"
export * from "./checkbox-prompt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports