<template>
  <section class="container-fluid">
    <div class="profile-form-container">
      <div class="has-text-centered">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">
            {{ currentPageName }}
          </h1>
          <h4 v-if="showLevelHeader">{{ levelText }}<icon class="ml-3 fa-sm" :icon="verificationIcon" v-if="verificationIcon.length" /></h4>
        </div>
        <div>
          <grid :entityTypeId="entityTypeId"
                :formId="formId"
                :entityTypeName="entityTypeName"
                :primaryEntity="primaryentity"
                :showFormOnEmpty="true"
                :showContinueButton="false"
                :additionalActions="additionalActions"
                :additionalActionsOnColumn="true"
                @dirty="$emit('dirty', $event)"
                @continueButton="$emit('continueButton')"
                @setCurrentLevel="setCurrentLevel"
                @setVerificationIcon="setVerificationIcon"
                ref="actionGrid"
                :key="gridKey">
          </grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { locale, getText } from '../../scripts/localized';
  import { mapGetters } from 'vuex';
  export default {
    name: 'CareerLattice',
    data() {
      return {
        locale: locale,
        isBusy: false,
        entityTypeId: 31,
        formId: 28,
        gridKey: 'level',
        entityTypeName: 'Career Lattice Level',
        currentPageName: ' My Career Lattice Level',
        instructionText: 'Please answer the following questions and request for verification of Career Lattice Level update',
        levelText: '',
        verificationIcon: '',
        verificationStatus: '',
        showLevelHeader: false,
        additionalActions: [
          {
            title: 'Request Verification',
            icon: 'plus-circle',
            click: this.makeVerificationRequest,
            check: this.shouldShowVerificationRequest
          },
          {
            title: 'Verification Not Available',
            icon: 'plus-circle',
            click: this.showDisabledVerificationMessage,
            check: this.shouldShowDisabledVerificationRequest
          }
        ],
      };
    },
    props: {
      primaryentity: {
        type: Object,
        default: () => { }
      },
    },
    computed: {
      ...mapGetters(['userOrganizationRoles']),

    },
    methods: {
      setCurrentLevel(val) {
        this.levelText = val !== '' ? "Current Level: " + val.slice(-1) : '';
        this.showLevelHeader = val.length > 0 ? true : false;
      },
      setVerificationIcon(status, item) {
        this.verificationStatus = status;
        this.verificationIcon = item;
      },
      async makeVerificationRequest(item) {
        return await new Promise((resolve, reject) => {
          const confirmText = `Once verification is requested, the record can not be edited unless it is returned by the ${getText('program')}'s director(s) or designee.  Please click Confirm to continue.`
          const confirmOptions = {
            title: "Request Verification",
            cancelLabel: 'Cancel',
            okLabel: 'Confirm'
          };
          this.$dialogs
            .confirm(confirmText, confirmOptions)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                return new Promise((resolve, reject) => {
                  const body = {
                    entityTypeId: this.entityTypeId,
                    entityId: item.id
                  };
                  this.isBusy = true;
                  this.$store
                    .dispatch('verificationRequestRequest', body)
                    .then(async (response) => {
                      this.isBusy = false;
                      const alertOptions = { title: 'Verification request submitted!' };
                      this.$dialogs.alert(`Your request for verification has been successfully submitted to your ${getText('program')}'s director(s) or designee for review.`, alertOptions);
                      this.gridKey += '-1';
                      resolve(true);
                    })
                    .catch((errors) => {
                      this.isBusy = false;
                      resolve(false);
                    });
                });
              }
            })
            .catch((error) => {
              resolve(false);
            });
        });
      },
      shouldShowVerificationRequest(item) {
        if (item.verified || (item.verificationStatus == 'Requested'))
          return false;
        else
          return true;
      },
      shouldShowDisabledVerificationRequest(item) {
        return false;
      },
      showDisabledVerificationMessage() {
        const options = { title: 'Verification Not Available' };
        this.$dialogs.alert(`This action is currently not available for ${getText('agency')} Director(s). Please reach out to ${getText('threeFiftyRole')} for verification of your records.`, options);
      },
    }
  };
</script>
