<template>
  <div>
    <h1 class="hidden-title">Request Details</h1>
    <div role="tablist">
      <div>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle="'accordion_0'">
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="historyOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">History</span>
            </span>
          </b-card-header>
          <b-collapse
            :id="'accordion_0'"
            accordion="access-accordion"
            role="tabpanel"
            :visible="true"
            v-model="historyOpen"
          >
            <b-card-body>
              <div>
                <b-table hover :items="requestTableRows" :busy.sync="isBusy">
                  <template v-slot:table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div v-if="canViewEmploymentRecord">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle="'accordion_1'">
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="recordOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">Employment Record</span>
            </span>
          </b-card-header>
          <b-collapse :id="'accordion_1'" accordion="access-accordion" role="tabpanel" v-model="recordOpen">
            <b-card-body style="min-height: 400px">
              <div>
                <wfrform
                  :formid="employmentFormId"
                  :entities="getFormEntity()"
                  :startinviewmode="true"
                  :primaryEntity="getPrimaryEntity()"
                  :showEditButton="false"
                >
                </wfrform>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'RequestDetails',
  data() {
    return {
      isBusy: false,
      requestInfo: [],
      historyOpen: true,
      recordOpen: false,
      employmentFormId: 6,
      employmentEntityTypeId: 8,
      userEntityTypeId: 1,
      actionTypes: {
        requested: {
          id: 1,
          display: 'Role Requested'
        },
        approved: {
          id: 2,
          display: 'Role Granted'
        },
        rejected: {
          id: 3,
          display: 'Role Request Rejected'
        },
        removed: {
          id: 4,
          display: 'Role Removed'
        },
        userAdded: {
          id: 5,
          display: 'User Added'
        },
        userDisabled: {
          id: 6,
          display: 'User Disabled'
        },
        userEnabled: {
          id: 7,
          display: 'User Enabled'
        },
        generalRoleAdded: {
          id: 8,
          display: 'User Role Granted'
        },
        generalRoleRemoved: {
          id: 9,
          display: 'User Role Removed'
        },
        withdrawn: {
          id: 10,
          display: 'Role Request Withdrawn'
        }
      },
      roleObject: {}
    };
  },
  props: {
    employment: {
      type: Object,
      default: () => {}
    },
    rolePromotion: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.loadInfo();
  },
  computed: {
    ...mapGetters(['activeRole']),
    requestTableRows: function () {
      const requests = [];
      this.requestInfo.forEach((action) => {
        requests.push({
          actionUser: action.userLastUpdated.name,
          actionTime: this.$formatUtcDate(action.lastChangeDateTime),
          actionType: this.getActionTypeDisplayTextById(action.actionType),
          role: action.roleRequested.roleName,
          comments: action.comments
        });
      });
      return requests;
    },
    canViewEmploymentRecord: function () {
      if (
        ((this.employment && this.employment.id) || (this.rolePromotion && this.rolePromotion.employmentRecordId)) &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.roleObject &&
        this.roleObject.active &&
        this.roleObject.active &&
        this.roleObject.active.roles
      ) {
        if (this.activeRole.role.roleName != '200') return true;
        return !this.roleObject.active.roles.some((role) => role.roleName == '200');
      }
      return false;
    }
  },
  methods: {
    loadInfo() {
      let bodySet = false;
      let body = {};
      if (this.employment && this.employment.id) {
        body = {
          employmentRecordId: this.employment.id
        };
        bodySet = true;
      } else if (this.rolePromotion && this.rolePromotion.employmentRecordId) {
        body = {
          employmentRecordId: this.rolePromotion.employmentRecordId
        };
        bodySet = true;
      } else if (this.rolePromotion && this.rolePromotion.id && this.rolePromotion.primaryEntityId) {
        body = {
          rolePromotionActionId: this.rolePromotion.id,
          userId: this.rolePromotion.primaryEntityId
        };
        bodySet = true;
      }
      if (bodySet) {
        this.isBusy = true;
        this.$store
          .dispatch('userOrganizationRolesRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.roleObject = response;
            if (response.requests) this.requestInfo = response.requests;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    getActionTypeDisplayTextById(id) {
      return this.actionTypes[Object.keys(this.actionTypes).find((key) => this.actionTypes[key].id == id)].display;
    },
    getFormEntity() {
      let employmentId = '';
      if (this.employment && this.employment.id) employmentId = this.employment.id;
      else if (this.rolePromotion && this.rolePromotion.employmentRecordId)
        employmentId = this.rolePromotion.employmentRecordId;
      return [{ Id: employmentId, EntityTypeId: this.employmentEntityTypeId }];
    },
    getPrimaryEntity() {
      let primaryId = '';
      if (this.employment && this.employment.primaryEntityId) primaryId = this.employment.primaryEntityId;
      else if (this.rolePromotion && this.rolePromotion.primaryEntityId) primaryId = this.rolePromotion.primaryEntityId;
      return {
        Id: primaryId,
        EntityTypeId: this.userEntityTypeId
      };
    }
  }
};
</script>
