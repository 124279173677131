<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div class="form-el">
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>

    <div
      class="custom-file form-el"
      v-if="
        !viewMode && ((['singleFileUpload','certificateUpload'].includes(subtype) && uploadedFiles.length == 0) || subtype == 'multipleFileUpload')
      "
    >
      <input
        type="file"
        :warningmessage="warningMessage"
        :required="required && uploadedFiles.length == 0 ? true : false"
        :disabled="readOnly ? true : false"
        @input="checkValid($event.target)"
        :formelementid="id"
        class="custom-file-input form-control"
        :id="'fileupload' + id"
        ref="fileInput"
      />
      <label
        :style="returnCss()"
        data-browse="Browse"
        :class="'custom-file-label text-truncate' + (isBusy ? ' input-loading-file' : '')"
        :for="'fileupload' + id"
        >{{ filename }}</label
      >
    </div>

    <div class="form-el" v-if="viewMode && uploadedFiles.length == 0">
      <input type="text" placeholder="No Files Uploaded" disabled :formelementid="id" class="form-control" />
    </div>

    <div>
      <div
        :class="(index == 0 && viewMode ? 'file-first ' : '') + 'download-link-container'"
        v-for="(file, index) in uploadedFiles"
        :key="file.fileId"
        :style="readOnly ? 'background: #e9ecef;' : ''"
      >
        <a
          v-if="subtype == 'certificateUpload'"
          class="download-link mr-2"
          :href="'/api/file/c/' + file.fileId"
          :aria-text="'Download ' + file.fileName"
          :title="'Download ' + file.fileName"
          target="_new"
          >{{ file.fileName }}</a
        >
        <a
          v-else
          class="download-link mr-2"
          v-on:click="fileDownload(file.fileId)"
          :aria-text="'Download ' + file.fileName"
          :title="'Download ' + file.fileName"
          >{{ file.fileName }}</a
        >
        <a
          class="download-link-delete"
          v-on:click="fileDelete(file.fileId, file.fileName)"
          :aria-text="'Delete ' + file.fileName"
          :title="'Delete ' + file.fileName"
          v-if="!viewMode"
        >
          <icon class="link-delete" :icon="'times'"></icon>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fileupload',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 12
    },
    warningMessage: {
      type: String,
      default: 'Please select a file.'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    viewMode: {
      type: Boolean,
      required: true
    },
    entityTypeId: {
      type: Number,
      required: true
    },
    subtype: {
      type: String,
      default: 'multipleFileUpload'
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      filename: '',
      uploadedFiles: [],
      fileSetId: '',
      errors: '',
      isBusy: false,
      supportedTypes: ['image/', 'text/', 'application/vnd.openxmlformats-officedocument', 'application/pdf']
    };
  },
  methods: {
    checkValid: function (control) {
      control.setCustomValidity('');
      if (control.files && control.files.length > 0) {
        //check if were allowed to upload more files
        if (['singleFileUpload','certificateUpload'].includes(this.subtype) && this.uploadedFiles && this.uploadedFiles.length > 0) {
          const options = { title: 'Error', size: 'md' };
          const errMsg = 'Only one file can be uploaded to this record';
          this.$dialogs.alert(errMsg, options);
          return;
        }
        const file = control.files[0];
        if (file && file.name && file.type) {
          if (this.isNewFile(file.name)) {
            if (this.isSupportedFileFormat(file.type)) {
              this.filename = file.name;
              this.fileUpload(file);
            } else {
              const options = { title: 'Error', size: 'md' };
              const errMsg = 'The file type "' + file.type + '" is not supported';
              this.$dialogs.alert(errMsg, options);
            }
          } else {
            const options = { title: 'Error', size: 'md' };
            const errMsg = 'A file with the name "' + file.name + '" has already been uploaded';
            this.$dialogs.alert(errMsg, options);
          }
        }
      } else if (this.required && this.uploadedFiles.length == 0) {
        control.setCustomValidity(this.warningMessage);
        control.reportValidity();
      }
    },
    isNewFile: function (fileName) {
      let isNew = true;
      if (this.uploadedFiles && this.uploadedFiles.length > 0) {
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          const existingFile = this.uploadedFiles[i];
          if (existingFile.fileName && existingFile.fileName == fileName) {
            isNew = false;
            break;
          }
        }
      }
      return isNew;
    },
    isSupportedFileFormat: function (fileType) {
      let isSupported = false;
      if (this.supportedTypes && this.supportedTypes.length > 0) {
        for (let i = 0; i < this.supportedTypes.length; i++) {
          const supportedType = this.supportedTypes[i];
          if (supportedType && fileType.substring(0, supportedType.length) === supportedType) {
            isSupported = true;
            break;
          }
        }
      }
      return isSupported;
    },
    fileUpload: function (file) {
      this.isBusy = true;
      this.$emit('busy', true);
      const payload = {
        file: file,
        fileSetId: this.fileSetId,
        entityTypeId: this.entityTypeId,
        formelementid: this.$vnode.key
      };
      this.$store
        .dispatch('fileUploadRequest', payload)
        .then((result) => {
          this.uploadedFiles.push(result[0]);
          if (!this.fileSetId) {
            this.fileSetId = result[0].fileSetId;
          }
          const options = { title: 'Success' };
          this.$dialogs.alert(`The file <strong>${result[0].fileName}</strong> uploaded successfully.`, options);
          this.$emit('input', this.fileSetId);
          this.$emit('dirty', true);
          this.reset();
          this.isBusy = false;
          this.$emit('busy', false);
        })
        .catch((errors) => {
          const options = { title: 'Error' };
          this.$dialogs.alert(`The file could not be uploaded.<br>Please refresh the page and try again.`, options);
          this.isBusy = false;
          this.$emit('busy', false);
          this.errors = errors;
        });
    },
    fileDownload: function (fileKey) {
      this.isBusy = true;
      this.$store
        .dispatch('fileDownloadRequest', fileKey)
        .then((result) => {
          this.isBusy = false;
          if (window.navigator.msSaveOrOpenBlob) {
            const url = window.navigator.msSaveOrOpenBlob(new Blob([result.data]), 'file');
            window.open(url);
          } else {
            const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            const filename = result.headers['content-disposition'].split('filename=')[1].split('; filename*=')[0];
            link.setAttribute('download', filename.replace(/"/g, ''));
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    fileDelete: function (fileKey, fileName) {
      const options = { title: 'Confirm Delete', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm('Are you sure you want to delete "' + fileName + '"? This cannot be undone.', options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.isBusy = true;
            const primaryEntityId = this.primaryEntity && this.primaryEntity.Id ? this.primaryEntity.Id : '';
            const body = { fileKey: fileKey, entityTypeId: this.entityTypeId, primaryEntityId: primaryEntityId };
            this.$store
              .dispatch('fileDeleteRequest', body)
              .then((result) => {
                this.uploadedFiles.forEach(function (item, index, obj) {
                  if (item.fileId == fileKey) {
                    obj.splice(index, 1);
                  }
                });
                if (this.uploadedFiles.length == 0) {
                  this.$emit('input', '');
                  this.fileSetId = '';
                }
                this.isBusy = false;
                this.$emit('dirty', true);
              })
              .catch((errors) => {
                this.isBusy = false;
                this.errors = errors;
              });
          }
        })
        .catch((error) => {
          this.errors = error;
        });
    },
    returnCss() {
      const color = this.filename != this.stimulus && !this.readOnly ? 'black' : 'grey';
      return 'color:' + color + ';';
    },
    reset() {
      const input = this.$refs.fileInput;
      input.type = 'text';
      input.type = 'file';
      this.filename = this.stimulus;
    }
  },
  created() {
    this.uploadedFiles = this.files;
    if (this.uploadedFiles && this.uploadedFiles[0]) {
      this.fileSetId = this.uploadedFiles[0].fileSetId;
    }
    this.filename = this.stimulus;
  }
};
</script>
