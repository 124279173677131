<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <textarea
      :warningmessage="warningMessage"
      :required="required ? true : false"
      :disabled="readOnly ? true : false"
      @input="
        checkValid($event.target);
        $emit('input', $event.target.value);
      "
      :formelementid="id"
      :placeholder="stimulus"
      :class="'form-control form-el preformatted' + (value == '' ? '' : ' has-value')"
      autocomplete="new-password"
      rows="5"
      :value="value"
    >
    </textarea>
  </div>
</template>
<script>
export default {
  name: 'textArea',
  props: {
    id: {
      type: Number,
      required: true
    },
    subtype: {
      type: String,
      required: false
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 180
    },
    width: {
      type: Number,
      default: 12
    },
    regex: {
      type: String,
      default: '(.*?)'
    },
    warningMessage: {
      type: String,
      default: 'Please fill out this field'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    checkValid: function (input) {
      input.setCustomValidity('');
      if (input.validity.valid) {
        return;
      } else {
        input.setCustomValidity(input.attributes.warningMessage.value);
      }
    }
  }
};
</script>
