const DateFormatter = {
  install(Vue, options) {
    Vue.prototype.$formatUtcDate = function (date) {
      if (!date.endsWith('Z')) {
        //the z tells js that we're parsing a utc date
        date = date + 'Z';
      }
      const now = new Date();
      const itemDate = new Date(date);
      if (itemDate.toLocaleDateString) {
        if (now.toLocaleDateString && itemDate.toLocaleDateString() == now.toLocaleDateString()) {
          //if it's today, return the time
          const options = { hour: 'numeric', minute: 'numeric', hour12: true };
          return itemDate.toLocaleTimeString('en-US', options);
        }
        const difference = Vue.timeSince(itemDate);
        if (difference && difference.years === 0 && difference.months < 6) {
          //if it's in the last half year, return the month/day + time
          const options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          };
          return itemDate.toLocaleDateString('en-US', options);
        }
        //otherwise return the formatted mm/dd/yy + time
        const options = { dateStyle: 'short', timeStyle: 'short' };
        return itemDate.toLocaleString('en-US', options);
      }
      return date;
    };

    Vue.prototype.$formatEmailDate = function (date) {
      if (!date.endsWith('Z')) {
        //the z tells js that we're parsing a utc date
        date = date + 'Z';
      }
      const now = new Date();
      const messageDate = new Date(date);
      if (messageDate.toLocaleDateString) {
        if (now.toLocaleDateString && messageDate.toLocaleDateString() == now.toLocaleDateString()) {
          //if it's today, return the time
          const options = { hour: 'numeric', minute: 'numeric', hour12: true };
          return messageDate.toLocaleTimeString('en-US', options);
        }
        const difference = Vue.timeSince(messageDate);
        if (difference && difference.years === 0) {
          //if it's this year, return the month/day
          const options = { month: 'short', day: 'numeric' };
          return messageDate.toLocaleDateString('en-US', options);
        }
        //otherwise return the formatted mm/dd/yy
        const options = { dateStyle: 'short' };
        return messageDate.toLocaleDateString('en-US', options);
      }
      return date;
    };

    Vue.prototype.$formatFullDate = function (date, includeDayName) {
      if (!date.endsWith('Z')) {
        //the z tells js that we're parsing a utc date
        date = date + 'Z';
      }
      const messageDate = new Date(date);
      if (messageDate.toLocaleDateString) {
        const options = { dateStyle: 'long', timeStyle: 'short' };
        let formatted = messageDate.toLocaleString('en-US', options);
        if (includeDayName) {
          const dayOptions = { weekday: 'long' };
          const dayOfWeek = messageDate.toLocaleString('en-US', dayOptions);
          formatted = dayOfWeek + ' ' + formatted;
        }
        return formatted;
      }
      return date;
    };

    Vue.prototype.$timestampedReportName = function (reportSubject, reportName, extension) {
      const d = new Date();
      const month = (d.getMonth() < 9 ? '0' : '') + (d.getMonth() + 1).toString();
      const day = (d.getDate() < 10 ? '0' : '') + d.getDate().toString();
      const ts = d
        .toLocaleTimeString()
        .replace(new RegExp(' ', 'g'), '')
        .replace(new RegExp('_', 'g'), '-')
        .replace(new RegExp(':', 'g'), '-');
      const displayTime = (ts.length < 10 ? '0' : '') + ts;
      const timestamp = `${d.getFullYear()}-${month}-${day}_${displayTime}`;
      let finalName = `${reportName}_${timestamp}.${extension}`;
      if (reportSubject) {
        finalName = `${reportSubject}_${finalName}`;
      }
      return finalName.replace(new RegExp(' ', 'g'), '');
    };

    Vue.prototype.$dateTimestampReport = function () {
      const d = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric'
      };
      return d.toLocaleDateString('en', options) + '-' + d.toLocaleTimeString('en-US');
    };

    Vue.prototype.$formatGridDate = function (date, timestamp, includeTime) {
      if (!date.endsWith('Z') && timestamp) {
        //the z tells js that we're parsing a utc date
        date = date + 'Z';
      }
      const itemDate = new Date(date);
      if (itemDate.toLocaleDateString) {
        if (!includeTime) {
          const options = { dateStyle: 'long' };
          return itemDate.toLocaleDateString('en-US', options);
        } else {
          const options = { dateStyle: 'short', timeStyle: 'short' };
          return itemDate.toLocaleString('en-US', options);
        }
      }
      return date;
    };

    Vue.prototype.$isDateInThePast = function (date) {
      if (!date.endsWith('Z')) {
        //the z tells js that we're parsing a utc date
        date = date + 'Z';
      }
      const now = new Date();
      const messageDate = new Date(date);
      return now >= messageDate;
    };

    Vue.timeSince = function (then) {
      const now = new Date(),
        obj = {};
      obj.milliseconds = now.getUTCMilliseconds() - then.getUTCMilliseconds();
      obj.seconds = now.getUTCSeconds() - then.getUTCSeconds();
      obj.minutes = now.getUTCMinutes() - then.getUTCMinutes();
      obj.hours = now.getUTCHours() - then.getUTCHours();
      obj.days = now.getUTCDate() - then.getUTCDate();
      obj.months = now.getUTCMonth() - then.getUTCMonth();
      obj.years = now.getUTCFullYear() - then.getUTCFullYear();
      // fix negatives
      if (obj.milliseconds < 0) --obj.seconds, (obj.milliseconds = (obj.milliseconds + 1000) % 1000);
      if (obj.seconds < 0) --obj.minutes, (obj.seconds = (obj.seconds + 60) % 60);
      if (obj.minutes < 0) --obj.hours, (obj.minutes = (obj.minutes + 60) % 60);
      if (obj.hours < 0) --obj.days, (obj.hours = (obj.hours + 24) % 24);
      if (obj.days < 0) {
        // months have different lengths
        --obj.months;
        now.setUTCMonth(now.getUTCMonth() + 1);
        now.setUTCDate(0);
        obj.days = (obj.days + now.getUTCDate()) % now.getUTCDate();
      }
      if (obj.months < 0) --obj.years, (obj.months = (obj.months + 12) % 12);
      return obj;
    };
  }
};
export default DateFormatter;
