const actionTypes = {
  show: 'show',
  hide: 'hide',
  require: 'require',
  unrequire: 'unrequire',
  makeReadOnly: 'makeReadOnly',
  makeNotReadOnly: 'makeNotReadOnly',
  populateFromParent: 'populateFromParent',
  filterListChoices: 'filterListChoices',
  setDefault: 'setDefault',
  reject: 'reject',
  verify: 'verify',
  unverify: 'unverify'
};

let filteredElementIds;

function loadParentRules(form, data, activeRole) {
  if (
    form &&
    form.parentProps &&
    form.elements &&
    form.elements.length > 0 &&
    form.arguments &&
    form.arguments.parentRules &&
    form.arguments.parentRules.length > 0
  ) {

    filteredElementIds = [];
    const parentRules = form.arguments.parentRules;
    const changedElementRules = [];
    parentRules.forEach(function (rule) {
      let formElement;
      if (rule && rule.conditions && rule.conditions.length > 0) {
        for (let i = 0; i < rule.conditions.length; i++) {
          const condition = rule.conditions[i];
          //check the parentElement condition evaluates to true
          formElement = form.parentProps.filter((f) => f.propertyId === condition.propertyId && f.computedValue === condition.value);
          if (formElement && formElement.length > 0) {  //only multiselect in use is checkbox; currently defined as distinct elements
            //remove them because we want to use only the action once the parent condition is met
            rule.conditions.pop(rule.conditions[i]);
          }
          else { //either formElement is undefined or has length=0 ...
            //condition is not met so remove it and associated actions
            rule.conditions.pop(rule.conditions[i]);
            rule.actions.length = 0;
          }
        }
        if (rule.conditions.length === 0) delete rule.conditions; //remove conditions if empty
        if (rule.actions.length === 0) delete rule.actions; //remove actions if empty
        //We need logic here to push only if the thing is still defined
        if (rule.actions) changedElementRules.push(rule);
      }
      else if (rule && rule.actions && rule.actions.length > 0) { //Need to eventually make this more generic to work in all use cases
        const parentActions = rule.actions;
        for (let i = 0; i < parentActions.length; i++) {
          const action = parentActions[i];
          const elementValues = action.value;
          if (elementValues && elementValues.length > 0) {
            const newValues = form.parentProps.filter((f) => elementValues.includes(f.valueInt));
            const allowedValues = [];
            newValues.forEach(function (item) {
              allowedValues.push(item.valueInt);
            });
            action.value = allowedValues.toString();
            changedElementRules.push(rule);
            break;
          }
        }
      }
    });
    applySelectedParentRules(changedElementRules, form, data);
  }
}

function applyRules(form, data, changedElementId, activeRole) {
  if (
    form &&
    form.elements &&
    form.elements.length > 0 &&
    form.arguments &&
    form.arguments.rules &&
    form.arguments.rules.length > 0
  ) {

    filteredElementIds = [];
    let rules = form.arguments.rules;
    if (changedElementId) {
      const changedElementRules = [];
      rules.forEach(function (rule, ruleIndex) {
        if (rule && rule.conditions && rule.conditions.length > 0) {
          for (let i = 0; i < rule.conditions.length; i++) {
            const condition = rule.conditions[i];
            if (condition.formElementId && condition.formElementId == changedElementId) {
              changedElementRules.push(rule);
              break;
            }
          }
        }
      });
      rules = changedElementRules;
    }
    applySelectedRules(rules, form, data, activeRole);
  }
}

function applySelectedParentRules(rules, form, data) {
  rules.forEach(function (rule) {
    if (rule && rule.actions && rule.actions.length > 0) {
      performRuleActions(rule.actions, true, form.elements, data);
    }
  });
}

function applySelectedRules(rules, form, data, activeRole) {
  rules.forEach(function (rule, ruleIndex) {
    if (rule && rule.conditions && rule.conditions.length > 0 && rule.actions && rule.actions.length > 0) {
      let meetsConditions = true;
      rule.conditions.forEach(function (condition, conditionIndex) {
        if (meetsConditions) meetsConditions = isConditionMet(condition, form.elements, data, activeRole);
      });
      performRuleActions(rule.actions, meetsConditions, form.elements, data);
    }
  });
}

function isConditionMet(condition, elements, data, activeRole) {
  if (condition && condition.formElementId) {
    const conditionElementMatches = elements.filter((element) => element.id == condition.formElementId);
    if (conditionElementMatches && conditionElementMatches.length > 0) {
      const conditionElement = conditionElementMatches[0];
      const val = data[conditionElement.id];
      if (!val && !condition.value)
        //null matches null
        return true;
      if (val && condition.value && Array.isArray(condition.value)) {
        for (let i = 0; i < condition.value.length; i++) {
          if (val == condition.value[i]) {
            return true;
          }
        }
      }
      if (val && condition.value && val == condition.value) return true;
    }
  }
  if (condition && condition.role) {
    let activeRoleName = '';
    if (activeRole && activeRole.role && activeRole.role.roleName) activeRoleName = activeRole.role.roleName;
    if (!activeRoleName) return false;
    if (Array.isArray(condition.role)) {
      for (let j = 0; j < condition.role.length; j++) {
        if (activeRoleName == condition.role[j]) {
          return true;
        }
      }
    }
    if (activeRoleName == condition.role) return true;
  }
  return false;
}

function performRuleActions(actions, meetsConditions, elements, data) {
  actions.forEach(function (action, actionIndex) {
    if (action.formElementId && action.actionType) {
      const actionElementMatches = elements.filter((element) => element.id == action.formElementId);
      if (actionElementMatches && actionElementMatches.length > 0) {
        const actionTargetElement = actionElementMatches[0];
        switch (action.actionType) {
          case actionTypes.show:
            if (meetsConditions) actionTargetElement.visible = true;
            else hideElement(actionTargetElement, data);
            break;
          case actionTypes.hide:
            if (meetsConditions) hideElement(actionTargetElement, data);
            else showElement(actionTargetElement);
            break;
          case actionTypes.require:
            toggleRequireElement(actionTargetElement, meetsConditions);
            break;
          case actionTypes.unrequire:
            toggleRequireElement(actionTargetElement, !meetsConditions);
            break;
          case actionTypes.makeReadOnly:
            if (meetsConditions) toggleReadOnlyElement(actionTargetElement, meetsConditions);
            break;
          case actionTypes.makeNotReadOnly:
            if (meetsConditions) toggleReadOnlyElement(actionTargetElement, !meetsConditions);
            break;
          case actionTypes.filterListChoices:
            filterListChoicesForElement(actionTargetElement, action.value, meetsConditions, data);
            break;
          case actionTypes.populateFromParent:
            filterListChoicesForElement(actionTargetElement, action.value, true, data);
            break;
          case actionTypes.setDefault:
            setDefaultValueForElement(actionTargetElement, action.value, meetsConditions, data);
            break;
          case actionTypes.reject:
            if (meetsConditions) markAsRejected(actionTargetElement);
            break;
          case actionTypes.verify:
            if (meetsConditions) markAsVerified(actionTargetElement);
            break;
          case actionTypes.unverify:
            if (meetsConditions) markAsUnverified(actionTargetElement);
            break;
          default:
            break;
        }
      }
    }
  });
}

function markAsRejected(targetElement) {
  targetElement.rejected = true;
}

function markAsVerified(targetElement) {
  targetElement.verified = true;
}

function markAsUnverified(targetElement) {
  targetElement.unverified = true;
}

function hideElement(targetElement, data) {
  //set value to empty when hiding
  data[targetElement.id] = '';
  targetElement.value = '';
  targetElement.visible = false;
}

function showElement(targetElement) {
  targetElement.visible = true;
}

function toggleRequireElement(targetElement, require) {
  targetElement.required = require;
}

function toggleReadOnlyElement(targetElement, ro) {
  targetElement.readOnly = ro;
}

function filterListChoicesForElement(targetElement, choiceIds, meetsConditions, data) {
  //if we have already filtered this element during this round of rule evaluations, skip it, or if there's no choice for some reason
  if (!filteredElementIds.includes(targetElement.id) && targetElement.choices && targetElement.choices.length > 0) {
    if (meetsConditions) {
      filteredElementIds.push(targetElement.id);
      if (targetElement.value && !choiceIds.includes(targetElement.value)) {
        data[targetElement.id] = '';
        targetElement.value = '';
      }
      targetElement.choices.forEach((choice) => (choice.visible = false));
      const filteredChoices = targetElement.choices.filter((choice) => choiceIds.includes(choice.id));
      if (filteredChoices && filteredChoices.length > 0) filteredChoices.forEach((choice) => (choice.visible = true));
    }
    //if we didn't meet the condition and it hasn't been filtered by another rule, show all the choices
    else targetElement.choices.forEach((choice) => (choice.visible = true));
  }
}

function setDefaultValueForElement(targetElement, defaultValue, meetsConditions, data) {
  if (meetsConditions) {
    targetElement.value = defaultValue;
    data[targetElement.id] = defaultValue;
  }
}
export { loadParentRules };
export { applyRules };
