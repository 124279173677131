import { library } from '@fortawesome/fontawesome-svg-core';
// Official documentation available at: https://github.com/FortAwesome/vue-fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faEnvelope,
  faAddressBook,
  faPoll,
  faListAlt,
  faHeart,
  faGraduationCap,
  faHome,
  faInfo,
  faList,
  faSpinner,
  faAddressCard,
  faAtlas,
  faEdit,
  faEye,
  faTrash,
  faPlus,
  faBan,
  faCheck,
  faCity,
  faSearch,
  faUser,
  faBookReader,
  faBuilding,
  faIdCardAlt,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faQuestionCircle,
  faAsterisk,
  faArrowRight,
  faArrowLeft,
  faTimes,
  faCheckCircle,
  faSort,
  faCaretUp,
  faCaretDown,
  faFilter,
  faStar,
  faHammer,
  faInbox,
  faFileCsv,
  faFilePdf,
  faEllipsisV,
  faUserLock,
  faFileWord,
  faUserShield,
  faCheckDouble,
  faCircle,
  faCaretRight,
  faUserCheck,
  faUsersCog,
  faUsers,
  faUserTimes,
  faUserEdit,
  faAward,
  faLightbulb,
  faFlag,
  faHistory,
  faClipboard,
  faComment,
  faCogs,
  faPortrait,
  faInfoCircle,
  faSuitcase,
  faHourglassHalf,
  faHouseUser,
  faWindowClose,
  faBell,
  faExclamationTriangle,
  faPeopleArrows,
  faUserMinus,
  faClipboardList,
  faHandshake,
  faHandshakeSlash,
  faReply,
  faReplyAll,
  faUnlock,
  faLock,
  faClock,
  faPaperclip,
  faLayerGroup,
  faMapMarkerAlt,
  faPhone,
  faPhoneSquareAlt,
  faMinus,
  faChalkboardTeacher,
  faCalendar,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarAlt,
  faFileSignature,
  faUserPlus,
  faUserSlash,
  faBook,
  faTasks,
  faPaperPlane,
  faGlasses,
  faExclamation,
  faFileExport,
  faUpload,
  faPenAlt,
  faNotesMedical,
  faPlusCircle,
  faPlusSquare,
  faFolderPlus,
  faSignature,
  faKey,
  faLaptopMedical,
  faSearchPlus,
  faNewspaper,
  faSitemap,
  faDoorClosed,
  faDoorOpen,
  faTable, 
  faArchive,
  faFileImport

} from '@fortawesome/free-solid-svg-icons';

import { faFontAwesome, faMicrosoft, faVuejs } from '@fortawesome/free-brands-svg-icons';

import {
  faStar as farStar,
  faCheckSquare as farCheckSquare,
  faMinusSquare as farMinusSquare,
  faSquare as farSquare,
  faTrashAlt as farTrashAlt,
  faEnvelope as farEnvelope,
  faEnvelopeOpen as farEnvelopeOpen,
  faBell as farBell
} from '@fortawesome/free-regular-svg-icons';

// If not present, it won't be visible within the application. Considering that you
// don't want all the icons for no reason. This is a good way to avoid importing too many
// unnecessary things.
library.add(
  faEnvelope,
  faAddressBook,
  faPoll,
  faListAlt,
  faHome,
  faInfo,
  faList,
  faSpinner,
  faAddressCard,
  faAtlas,
  faEdit,
  faEye,
  faTrash,
  faPlus,
  faBan,
  faCheck,
  faCity,
  faCogs,
  faUser,
  faGraduationCap,
  faBookReader,
  faHeart,
  faBuilding,
  faIdCardAlt,
  faSearch,
  faAsterisk,
  faArrowRight,
  faArrowLeft,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight,
  faQuestionCircle,
  faTimes,
  faCheckCircle,
  faSort,
  faCaretUp,
  faCaretDown,
  faFilter,
  faStar,
  faHammer,
  faInbox,
  faFileCsv,
  faFilePdf,
  faEllipsisV,
  faUserLock,
  faFileWord,
  faUserShield,
  faCheckDouble,
  faCircle,
  faCaretRight,
  faUpload,
  faUserCheck,
  faUsersCog,
  faUsers,
  faUserTimes,
  faLightbulb,
  faUserEdit,
  faAward,
  faFlag,
  faHistory,
  faClipboard,
  faComment,
  faPortrait,
  faInfoCircle,
  faSuitcase,
  faHourglassHalf,
  faHouseUser,
  faWindowClose,
  faBell,
  faExclamationTriangle,
  faPeopleArrows,
  faUserMinus,
  faClipboardList,
  faHandshake,
  faHandshakeSlash,
  faReply,
  faReplyAll,
  faUnlock,
  faLock,
  faClock,
  faPaperclip,
  faLayerGroup,
  faMapMarkerAlt,
  faPhone,
  faPhoneSquareAlt,
  faMinus,
  faChalkboardTeacher,
  faCalendar,
  faCalendarPlus,
  faCalendarTimes,
  faCalendarAlt,
  faFileSignature,
  faUserPlus,
  faUserSlash,
  faBook,
  faTasks,
  faPaperPlane,
  faGlasses,
  faExclamation,
  faFileExport,
  faUpload,
  faPenAlt,
  faNotesMedical,
  faPlusCircle,
  faPlusSquare,
  faFolderPlus,
  faSignature,
  faFileSignature,
  faKey,
  faLaptopMedical,
  faSearchPlus,
  faNewspaper,
  faSitemap,
  faDoorClosed,  
  faDoorOpen,
  faTable,
  faArchive,
  faFileImport,   

  //regular (non-solid) icons
  farStar,
  farCheckSquare,
  farMinusSquare,
  farSquare,
  farTrashAlt,
  farEnvelope,
  farEnvelopeOpen,
  farBell,

  // Brands
  faFontAwesome,
  faMicrosoft,
  faVuejs
);

export { FontAwesomeIcon };
