<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Archived Training Reports</h1>
        </div>
        <div>
          <grid            
            :entityTypeId="entityTypeId"
            :altGridId="altGridId"
            :formId="formId"
            :defaultFilters="defaultFilters"
            :entityTypeName="entityTypeName"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :additionalActionsOnColumn="true"
            :showFormOnEmpty="false"            
            :showAddButton="false"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            :primaryEntity="getPrimaryEntity()"
          ></grid>
        </div>
      </div>
    </div>
    <modal v-show="isTrainingReportModalVisible"
        @close="closeTrainingReportModal"
        :isModalVisible="isTrainingReportModalVisible"
        :modalBusy="!docViewerData.fileUrl"
        :skinName="'doc-viewer'">
      <h2 slot="header">Training Report: QRIS Period {{ formatDate(docViewerData.cycleStartDate) }} - {{ formatDate(docViewerData.cycleEndDate, -1) }}{{ docViewerData.amendedStatus}}</h2>
      <div class="text-left row" slot="body">
        <div class="col">
          <docViewer v-if="docViewerData.fileUrl" :downloadButtonText="'Download Training Report'" :fileUrl="docViewerData.fileUrl" :isStatic="false"></docViewer>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import docViewer from '@components/utility/doc-viewer';
export default {
  name: 'ArchivedTrainingReports',
  components: {
    docViewer
  },
  data() {
    return {
      entityTypeId: 33,
      altGridId: 1,
      employmentRecordEntityTypeId: 8,
      fileUrl: '',
      formId: 1,
      docViewerData: {
        fileUrl: '',
        cycleStartDate: '',
        cycleEndDate: '',
        amendedStatus: ''
      },
      entityTypeName: 'Compliance Report',
      gridPageSize: 5,
      gridCurrentPage: 1,
      defaultFilters: [],
      additionalActions: [
        {
          title: 'View Report',
          icon: 'eye',
          click: this.viewReport,
          check: () => true
        }
      ],
      isTrainingReportModalVisible: false
    };
  },
  props: {
    employmentRecordId: {
      type: String,
      required: true
    }
  },
  methods: {
    getPrimaryEntity() {
      return {
        Id: this.employmentRecordId,
        EntityTypeId: this.employmentRecordEntityTypeId
      };
    },
    closeTrainingReportModal() {
      this.isTrainingReportModalVisible = false;
      this.docViewerData = {
        fileUrl: '',
        cycleStartDate: '',
        cycleEndDate: '',
        amendedStatus: ''
      };
    },
    viewReport(item) {
      this.isTrainingReportModalVisible = true;

      this.docViewerData.cycleStartDate = item.cycleStartDate;
      this.docViewerData.cycleEndDate = item.cycleEndDate;
      this.docViewerData.amendedStatus = item.status == 'Amended' ? ' (Amended)' : '';

      const body = { reportId: item.id };
      this.$store
        .dispatch('cachedComplianceReportRequest', body)
        .then((response) => {
          if (response && response.file) {
            this.docViewerData.fileUrl = response.file;
          }
        })
        .catch((errors) => {
          this.errors = errors;
        });
    },
    shouldShowAdditionalActions(item) {
      return true;
    },
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    formatDate(dateStr, daysAdd) {
        const date = new Date(dateStr);
        
        if (daysAdd) date.setDate(date.getDate() + daysAdd);
        return date.toLocaleDateString('en-us');
    },
  }
};
</script>
