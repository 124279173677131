<template>
  <section class="container-fluid">
    <div id="pdp" class="profile-nav nav-tabs">
      <b-navbar toggleable="md" class="w-100 pb-md-0 mt-3 mt-md-1 py-2">
        <b-navbar-brand>          
          <div class="text-center">{{ currentPageName }}</div>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse">
          <icon :icon="'angle-down'" :size="'lg'" class="nav-toggle-down"></icon>
          <icon :icon="'angle-up'" :size="'lg'" class="nav-toggle-up"></icon>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="w-100">
            <router-link
              v-for="(route, index) in resumeRoutes"
              :key="index"
              :class="'nav-item nav-link col mb-0' + (index != resumeRoutes.length - 1 ? ' mr-1' : '')"
              :id="'nav-' + route.name"
              exact
              :to="route.path"
              data-toggle="tab"
              role="tab"
            >
              <div>
                <icon :icon="route.meta.icon" :size="'lg'" style="width: 40px;" class="ml-1 ml-md-0"></icon>{{ route.meta.display }}
              </div>
            </router-link>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!------- Top Navigation Area-------->
    <div class="profile-form-container mt-md-0" style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)">
      <router-view v-bind:primaryentity="this.primaryentity" @busy="$emit('busy', $event)"></router-view>
    </div>
  </section>
</template>

<script>
import { routes } from '../../router/routes';

export default {
  name: 'DashboardResume',
  data() {
    return {
      routes,
      currentPageName: '',
      currentPosition: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.sequence) this.currentPosition = this.$route.meta.sequence;
    }
  },
  watch: {
    $route(to) {
      if (to.meta.display) {
        this.currentPageName = to.meta.display;
      }
      if (to.meta.sequence) {
        this.currentPosition = to.meta.sequence;
      }
    }
  },
  computed: {
    resumeRoutes() {
      if (this.routes) {
        const dashboard = this.routes.filter(function (route) {
          return route.name === 'dashboard';
        });
        const res = dashboard[0].children.filter(function (childRoute) {
          return childRoute.name === 'dashboard-resume-menu';
        });

        if (res && res.length > 0 && res[0] && res[0].children) return res[0].children;
      }
      return [];
    }
  }
};
</script>
