<template>
  <div class="text-center container-fluid">
    <div v-if="total > 5">
      <div class="d-inline-block" v-if="pageSize <= total">
        <b-pagination
          :total-rows="total"
          :per-page="pageSize"
          v-model="currentPage"
          @change="loadPage($event)"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          limit="5"
        ></b-pagination>
      </div>
      <div>
        <span class="text-secondary mr-2">Page Size</span>
        <select
          class="custom-select w-auto text-secondary"
          @change="pageSizeChanged($event.target.value)"
          v-model="pageSize"
        >
          <option>5</option>
          <option>25</option>
          <option v-if="total > 25">50</option>
          <option v-if="total > 50">100</option>
        </select>
      </div>
    </div>
    <div class="text-secondary mb-3" v-if="total > 0">
      Displaying {{ firstOnPage }} through {{ lastOnPage }} of {{ total }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paging',
  props: ['firstOnPage', 'lastOnPage', 'total', 'pageSize', 'currentPage', 'pageSizeChanged', 'loadPage']
};
</script>
