<template>
  <section>
    <div class="container">
      <div class="training-layout">
        <div class="row">
          <div class="col-12">
            <h1>Training</h1>
          </div>
          <div class="col-lg-8">
            <hr />
            <div>
              <p>
                TNPAL helps educators, directors, and other staff of child care agencies licensed by the Tennessee
                Department of Human Services (TDHS) to meet their training requirements.
              </p>
              <p>
                Using the TNPAL professional development plan feature helps you better identify trainings you WANT and
                links you to training organizations.
              </p>
              <p class="about-list">Here’s one example of how to use TNPAL:</p>
              <ul>
                <li>Consult your professional development plan to identify a training you need or want.</li>
                <li>
                  Use the links below to find and enroll for a CCR&R, TECTA, TCCOTS, or other TDHS-recognized training.
                </li>
                <li>
                  Upon completion of the training, your TNPAL record will be updated to reflect details of the training,
                  including topic and training hours.
                </li>
              </ul>
              <p class="about-list">
                <icon icon="lightbulb" size="lg" style="color: #ba0101"></icon>
                Two important tips to remember:
              </p>
              <ul>
                <li>It is important to remember and save your TNPAL ID when enrolling and taking training.</li>
                <li>Only completed trainings will be reflected in your record.</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 d-none d-lg-block">
            <img
              src="../../../images/pages/group_walking.jpg"
              alt="Group of people walking on a white background"
              style="width: 100%; height: auto"
            />
          </div>
        </div>
        <h2 style="font-size: 2rem" class="mt-3 mb-4 text-center">Find Training</h2>
        <div id="trainingCTA" class="my-3">
          <div class="row">
            <div class="col"></div>
            <div class="col-lg-4 my-3">
              <div>
                <h2>
                  Available
                  <span class="sr-only">Tennessee Child Care Online Training System</span
                  ><span aria-hidden="true">TCCOTS</span> Trainings
                </h2>
                <p>
                  <a href="https://www.tccots.com" v-confirm-leave target="_blank" class="btn btn-primary"
                    >Access Now &raquo;</a
                  >
                </p>
              </div>
              <p class="my-2">TCCOTS = Tennessee Child Care Online Training System</p>
            </div>
            <div class="col"></div>
            <div class="col-lg-4 my-3">
              <div>
                <h2>
                  Available
                  <span class="sr-only">Tennessee Child Care Resource & Referral Agency</span
                  ><span aria-hidden="true">TN CCR&amp;R</span> Trainings
                </h2>
                <p>
                  <a href="https://tnccrr.org/#RegionMap" v-confirm-leave target="_blank" class="btn btn-primary"
                    >Access Now &raquo;</a
                  >
                </p>
              </div>
              <p class="my-2">TN CCR&amp;R = Tennessee Child Care Resource & Referral Agency</p>
            </div>
            <div class="col"></div>
          </div>
          <div class="row">
            <div class="col"></div>
            <div class="col-lg-4 my-3">
              <div>
                <h2>
                  Available
                  <span class="sr-only">Tennessee Early Childhood Training Alliance</span
                  ><span aria-hidden="true">TECTA</span> Trainings
                </h2>
                <p>
                  <a href="https://www.tecta.info" v-confirm-leave target="_blank" class="btn btn-primary"
                    >Access Now &raquo;</a
                  >
                </p>
              </div>
              <p class="my-2">TECTA = Tennessee Early Childhood Training Alliance</p>
            </div>
            <div class="col"></div>
            <div class="col-lg-4 my-3">
              <div>
                <h2>
                  Available
                  <span class="sr-only">TDHS recognized training and trainers</span
                  ><span aria-hidden="true">TrainTN Training</span>
                </h2>
                <p>
                  <a href="https://traintn-trainer.tnstate.edu/Trainings/Index" v-confirm-leave target="_blank" class="btn btn-primary"
                    >Access Now &raquo;</a
                  >
                </p>
              </div>
              <p class="my-2">TrainTN = TDHS recognized training and trainers</p>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Training',
  data() {
    return {};
  }
};
</script>
