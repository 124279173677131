<template>
  <div class="has-text-centered mb-4">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="profile-form-container">
      <h2 class="title has-text-grey">Updated {{$t('message.terms') }}</h2>
      <p class="subtitle has-text-grey">Please read and accept the updated {{$t('message.terms').toLowerCase() }}.</p>
      <div class="box">
        <form @submit.prevent="handleSubmit">
          <div v-if="!isBusy">
            <div class="container-fluid">
              <div class="row">
                <div class="card my-3 py-2 px-3">
                  {{ termsAndConditionsText }}
                </div>
                <div class="field m-2">
                  <div class="control">
                    <input
                      class="input is-large"
                      type="checkbox"
                      :title="tacAcceptMessage"
                      v-model="tacAccepted"
                      @click="termsAccepted"
                    />
                    <span> I Accept the {{$t('message.terms') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="my-3">
              <button class="btn btn-primary" type="submit">Submit</button>
            </div>
          </div>
        </form>
        <div class="errors-container" v-if="errors">
          {{ errors }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { getText } from  '@scripts/localized';
export default {
  props: {
    userId: {
      type: String
    }
  },
  data() {
    return {
      isBusy: false,
      errors: '',
      tacAccepted: false,
      termsAndConditionsText: '',
      id: '',
      tacAcceptMessage: `I accept the ${ getText('terms').toLowerCase()}`
    };
  },
  created() {
    if (!this.userId) {
      this.id = this.$store.getters['userId'];
    } else {
      this.id = this.userId;
    }

    const key = 'TermsAndConditionsText';
    this.isBusy = true;
    this.$store
      .dispatch('configRequest', key)
      .then((result) => {
        this.isBusy = false;
        this.termsAndConditionsText = result;
      })
      .catch((errors) => {
        this.isBusy = false;
        this.errors = errors.response.data;
      });
  },
  methods: {
    ...mapActions(['hasAcceptedTac']),
    handleSubmit: function (event) {
      if (this.tacAccepted) {
        this.isBusy = true;
        const attrs = { userId: this.id };
        this.$store
          .dispatch('termsAcceptRequest', attrs)
          .then((result) => {
            this.isBusy = false;
            this.hasAcceptedTac();
            window.scrollTo(0, 0);
            if (result.route && result.route != 'default') {
              this.$router.push({
                name: result.route,
                params: { displayWelcome: true }
              });
            } else {
              this.$router.push({ name: 'dashboard-main' });
            }
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
      } else {
        this.errors = `${ getText('terms') } must be read and accepted.`;
      }
    },
    termsAccepted() {
      if (this.errors === `${ getText('terms') } must be read and accepted.`) {
        this.errors = '';
      }
    }
  },
  computed: {
    ...mapGetters(['activeRole'])
  }
};
</script>
