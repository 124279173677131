<template>
  <div class="notifications">
    <Notification
      v-for="notification in notifications"
      :notification="notification"
      :starNotification="starNotification"
      :key="notification.notificationId"
      :openNotification="openNotification"
      :userColumnValue="userColumnValue"
      :formatFullDate="formatFullDate"
    >
    </Notification>
  </div>
</template>

<script>
import Notification from './notification-item';

export default {
  name: 'NotificationList',
  props: ['notifications', 'starNotification', 'openNotification', 'userColumnValue', 'formatFullDate'],
  components: {
    Notification
  }
};
</script>
