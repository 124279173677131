<template>
  <div class="row toolbar">
    <div class="col-md-12">
      <b-button class="btn btn-default" @click="bulkSelect" :title="checkTitle" id="check">
        <icon v-if="halfCheckbox" :icon="['far', 'minus-square']"></icon>
        <icon v-if="bulkCheckbox" :icon="['far', 'check-square']"></icon>
        <icon v-if="emptyCheckbox" :icon="['far', 'square']"></icon>
      </b-button>

      <b-button @click="plusFunction" class="btn btn-danger" title="Compose" id="compose">
        <icon icon="plus"></icon><span aria-hidden="true"> Compose</span>
      </b-button>

      <b-dropdown text="Folder" id="folder">
        <b-dropdown-item
          v-for="folder in folders"
          :active="activeFolder.folder == folder"
          :key="folder"
          @click="folderChange(folder)"
          >{{ folder }}</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown text="Filter" v-if="activeFolder.folder != folders.sent" id="filter">
        <b-dropdown-item
          v-for="filter in filters"
          :active="activeFilter.filter == filter"
          :key="filter"
          @click="filterChange(filter)"
          >{{ filter }}</b-dropdown-item
        >
      </b-dropdown>

      <b-button
        @click="markRead"
        class="btn btn-default"
        :disabled="!markReadAvailable"
        v-if="activeFolder.folder != folders.sent"
        title="Mark Selected Items as Read"
        id="markRead"
      >
        <icon :icon="['far', 'envelope-open']"></icon
        ><span class="d-none d-md-inline" aria-hidden="true"> Mark Read</span>
      </b-button>

      <b-button
        @click="markUnread"
        class="btn btn-default"
        :disabled="!markUnreadAvailable"
        v-if="activeFolder.folder != folders.sent"
        title="Mark Selected Items as Unread"
        id="markUnread"
      >
        <icon :icon="['far', 'envelope']"></icon><span class="d-none d-md-inline" aria-hidden="true"> Mark Unread</span>
      </b-button>

      <b-button
        @click="toggleArchive"
        class="btn btn-default"
        :disabled="emptyCheckbox"
        v-if="activeFolder.folder != folders.sent"
        :title="archiveTitle"
        id="archive"
      >
        <archive-icon class="icon-2x" v-if="activeFolder.folder == folders.inbox" :title="archiveTitle" /><span
          class="d-none d-md-inline"
          v-if="activeFolder.folder == folders.inbox"
          aria-hidden="true"
        >
          Archive</span
        >
        <icon icon="inbox" v-if="activeFolder.folder == folders.archive"></icon
        ><span class="d-none d-md-inline" v-if="activeFolder.folder == folders.archive"> Inbox</span>
      </b-button>

      <b-button
        @click="deleteEmail"
        class="btn btn-default"
        :disabled="emptyCheckbox"
        v-if="activeFolder.folder != folders.sent"
        title="Delete Selected Items"
        id="delete"
      >
        <icon :icon="['far', 'trash-alt']"></icon><span class="d-none d-md-inline" aria-hidden="true"> Delete</span>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageToolbar',
  props: [
    'emails',
    'unreadCount',
    'markRead',
    'markUnread',
    'bulkCheckbox',
    'halfCheckbox',
    'emptyCheckbox',
    'starMessage',
    'bulkSelect',
    'inputForm',
    'deleteEmail',
    'form',
    'markReadAvailable',
    'markUnreadAvailable',
    'toggleArchive',
    'activeFolder',
    'folders',
    'folderChange',
    'activeFilter',
    'filters',
    'filterChange',
    'exitForm'
  ],
  computed: {
    archiveTitle: function () {
      const folderWord = this.activeFolder.folder == this.folders.inbox ? this.folders.archive : this.folders.inbox;
      return 'Move Selected Items to ' + folderWord;
    },
    checkTitle: function () {
      if (this.halfCheckbox) return 'Uncheck Selected Items';
      if (this.bulkCheckbox) return 'Uncheck All Items';
      return 'Check All Items';
    },
    plusFunction: function () {
      if (this.form) {
        return this.exitForm;
      }
      return this.inputForm;
    }
  }
};
</script>
