<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="mt-2">
        <div class="my-3">
          <b-button
            size="sm"
            variant="success"
            v-if="selected.length > 0 && !has300Role"
            class="mr-2 mt-2"
            @click="performActionOnSelected(actions.approve)"
            title="Approve Selected Requests"
          >
            <icon class="mr-2" icon="check" size="sm"></icon>Approve Selected
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            v-if="selected.length > 0 && !has300Role"
            class="mr-2 mt-2"
            @click="performActionOnSelected(actions.reject)"
            title="Decline Selected Requests"
          >
            <icon class="mr-2" icon="ban" size="sm"></icon>Reject Selected
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            v-if="selected.length > 0 && has300Role"
            class="mr-2 mt-2"
            @click="performActionOnSelected(actions.withdraw)"
            title="Withdraw Selected Requests"
          >
            <icon class="mr-2" icon="user-times" size="sm"></icon>Withdraw Selected
          </b-button>
        </div>
        <div class="tblSelectable">
          <b-table
            :busy.sync="isBusy"
            :items="requestTableRows"
            :fields="fields"
            selectable
            stacked="lg"
            @row-selected="onRowSelected"
            ref="requestTable"
            hover
            tbody-tr-class="mb-4"
          >
            <template v-slot:cell(actions)="row">
              <button
                class="abtn btn action-link mouse-pointer px-1"
                v-if="!has300Role"
                :title="getActionTitle(row.item, actions.approve.verb)"
                @click="performActions([row.item.coachRelationshipActionId], actions.approve)"
              >
                <icon class="action-link" icon="check" size="sm" style="color: green !important"></icon>
              </button>
              <button
                class="abtn btn action-link mouse-pointer ml-1 px-1"
                v-if="!has300Role"
                :title="getActionTitle(row.item, actions.reject.verb)"
                @click="performActions([row.item.coachRelationshipActionId], actions.reject)"
              >
                <icon class="action-link" icon="ban" size="sm" style="color: red !important"></icon>
              </button>
              <button
                class="abtn btn action-link mouse-pointer ml-1 px-1"
                v-if="has300Role"
                :title="getActionTitle(row.item, actions.withdraw.verb)"
                @click="performActions([row.item.coachRelationshipActionId], actions.withdraw)"
              >
                <icon class="action-link" icon="user-times" size="md"></icon>
              </button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-button class="mb-3" v-if="selected.length > 0" size="sm" variant="wfr" @click="clearSelected"
            >Clear selected</b-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CheckboxPrompt from '../utility/dialog/checkbox-prompt';
import RequestPrompt from '../utility/dialog/request-prompt';
import RequestPromptRequired from '../utility/dialog/request-prompt-required';
import { mapGetters } from 'vuex';

export default {
  name: 'CoachRequests',
  data() {
    return {
      isBusy: false,
      requests: [],
      selected: [],
      actions: {
        approve: { status: 'Approved', verb: 'approve' },
        reject: { status: 'Rejected', verb: 'decline' },
        withdraw: { status: 'Withdrawn', verb: 'withdraw' }
      }
    };
  },
  created() {
    this.loadInfo();
  },
  props: {
    initialLoad: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['activeRole']),
    fields: function () {
      if (this.has300Role) {
        return [
          { key: 'primaryEntity.name', label: 'User' },
          { key: 'primaryEntity.email', label: 'Email' },
          { key: 'primaryEntity.phoneNumber', label: 'Phone Number' },
          { key: 'requestDate' },
          { key: 'expirationDate' },
          { key: 'comment' },
          { key: 'actions' }
        ];
      }
      return [
        { key: 'userRequesting.name', label: 'Requesting Coach' },
        { key: 'userRequesting.email', label: 'Email' },
        { key: 'userRequesting.phoneNumber', label: 'Phone Number' },
        { key: 'requestDate' },
        { key: 'expirationDate' },
        { key: 'comment' },
        { key: 'actions' }
      ];
    },
    requestTableRows: function () {
      const requestInfo = [];
      this.requests.forEach((action) => {
        requestInfo.push({
          coachRelationshipActionId: action.coachRelationshipActionId,
          userRequesting: action.userRequesting,
          primaryEntity: action.primaryEntity,
          requestDate: this.$formatGridDate(action.lastChangeDateTime, true, true),
          expirationDate: this.$formatGridDate(action.expirationDate, true, true),
          comment: action.comments
        });
      });
      return requestInfo;
    },
    has300Role() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '300'
      );
    }
  },
  methods: {
    loadInfo() {
      this.isBusy = true;
      this.$store
        .dispatch('requestedCoachesRequest')
        .then(async (response) => {
          this.isBusy = false;
          this.requests = response;
          if (this.initialLoad) this.$emit('loaded', response.length);
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.requestTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.requestTable.clearSelected();
    },
    getActionTitle(item, startingWord) {
      const withUpper = startingWord.charAt(0).toUpperCase() + startingWord.slice(1);
      let ending = withUpper + ' request';
      if (this.has300Role && item && item.primaryEntity && item.primaryEntity.name) {
        ending = ending + ' with ' + item.primaryEntity.name;
      } else if (item && item.userRequesting && item.userRequesting.name) {
        ending = ending + ' from ' + item.userRequesting.name;
      }
      return ending;
    },
    async performActionOnSelected(action) {
      //get the selected ids
      const ids = [];
      this.requests.forEach((request) => {
        const selected = this.selected.some((s) => s.coachRelationshipActionId == request.coachRelationshipActionId);
        if (selected) ids.push(request.coachRelationshipActionId);
      });
      this.performActions(ids, action);
    },
    async performActions(ids, action) {
      return await new Promise((resolve, reject) => {
        const promptComponent =
          action == this.actions.approve
            ? CheckboxPrompt
            : action == this.actions.reject
            ? RequestPromptRequired
            : RequestPrompt;

        let checkboxLabel = 'Allow coach PDP commenting';
        const part1 = 'Are you sure you want to ' + action.verb + ' ';
        let part2;
        if (ids.length == 1) {
          part2 = 'this request ';
          const request = this.requests.find((request) => request.coachRelationshipActionId == ids[0]);
          if (request && request.userRequesting && request.userRequesting.name && !this.has300Role) {
            part2 = part2 + 'from ' + request.userRequesting.name + '? ';
            checkboxLabel = 'I give ' + request.userRequesting.name + ' permission to provide comments on my PDP';
          } else if (request && request.primaryEntity && request.primaryEntity.name && this.has300Role) {
            part2 = part2 + 'with ' + request.primaryEntity.name + '? ';
          }
        } else {
          part2 = 'the selected requests? ';
        }
        const optional = action == this.actions.withdraw ? 'n optional' : '';
        let finalPart;
        if (action == this.actions.approve)
          finalPart =
            "If so, use the checkbox below to change your coach's ability to comment on your PDP, then click Continue";
        else finalPart = 'If so, please enter a' + optional + ' comment and click Continue';
        const promptText = part1 + part2 + finalPart;

        const promptTitle =
          action.verb.charAt(0).toUpperCase() + action.verb.slice(1) + ' Request' + (ids.length == 1 ? '' : 's');

        const defaultVal = action == this.actions.approve ? false : '';
        const options = {
          title: promptTitle,
          okLabel: 'Continue',
          cancelLabel: 'Cancel',
          prompt: {
            invalidMessage: '',
            component: promptComponent,
            value: defaultVal,
            checkboxLabel: checkboxLabel
          }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            //if we clicked continue
            if (res && res.ok && res.ok == true) {
              const body = {
                coachRelationshipActionIds: ids,
                action: action.status,
                comments: action != this.actions.approve ? res.value : '',
                pdpShared: action == this.actions.approve ? res.value : false
              };
              this.isBusy = true;
              this.$store
                .dispatch('coachRelationshipActionRequest', body)
                .then(async (response) => {
                  this.isBusy = false;
                  //remove the requests we acted on from the pending requests grid
                  this.requests = this.requests.filter((request) => {
                    const selected = ids.some((id) => id == request.coachRelationshipActionId);
                    return !selected;
                  });
                  this.$emit('saved');
                  this.$emit('loaded', this.requests.length);
                  resolve(true);
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    }
  }
};
</script>
