<template>
  <span>
    <input type="hidden" :required="required ? true : false" :formelementid="id" :value="value" />
  </span>
</template>
<script>
export default {
  name: 'hidden',
  props: {
    id: {
      type: Number,
      required: true
    },
    subtype: {
      type: String,
      required: false
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 180
    },
    width: {
      type: Number,
      default: 12
    },
    regex: {
      type: String,
      default: '(.*?)'
    },
    warningMessage: {
      type: String,
      default: 'Please fill out this field'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    checkValid: function (input) {
      input.setCustomValidity('');
      if (input.validity.valid) {
        return;
      } else {
        input.setCustomValidity(input.attributes.warningMessage.value);
      }
    },
    returnCss() {
      return '';
    }
  }
};
</script>
