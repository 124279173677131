<template>
  <!--TODO: This is Alabama specific and can be removed -->
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">DHR Reports</h1>
        </div>
        <div>
          <grid
            :key="gridKey"
            :entityTypeId="entityTypeId"
            :formId="formId"
            :entityTypeName="entityTypeName"
            :primaryEntity="getPrimaryEntity()"
            :showFormOnEmpty="is250User()"
            :showEdit="is250User()"
            :showDelete="is250User()"
            :showAddButton="is250User()"
            @dirty="$emit('dirty', $event)"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :additionalActionsOnColumn="true"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">Review Comments</h2>
      <div slot="body" v-if="activeEntity.Id">
        <grid
          :entityTypeId="commentEntityTypeId"
          :formId="commentFormId"
          :entityTypeName="'Comment'"
          :primaryEntity="activeEntity"
          :showFormOnEmpty="false"
          :withinModal="true"
          :showAddButton="false"
          :showEdit="false"
          :showNoDataWarning="true"
          :allowDownload="false"
          :showActionColumn="false"
          :skinName="'comment'"
          :basePageSize="commentGridPageSize"
          :baseCurrentPage="commentGridCurrentPage"
          @setPageSize="setCommentPageSize"
          @setCurrentPage="setCommentCurrentPage"
        >
        </grid>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestPrompt from '../../utility/dialog/request-prompt';
import RequiredPrompt from '../../utility/dialog/request-prompt-required';
export default {
  name: 'QEAReports',
  components: {},
  data() {
    return {
      entityTypeId: 27,
      formId: 25,
      entityTypeName: 'Report',
      isModalVisible: false,
      activeReportType: '',
      additionalActions: [
        {
          title: 'Approve',
          icon: 'check',
          click: this.approveReport,
          check: this.shouldShowApprove
        },
        {
          title: 'Decline',
          icon: 'times',
          click: this.declineReport,
          check: this.shouldShowDecline
        },
        {
          title: 'Submit Report',
          icon: 'file-export',
          click: this.submitReport,
          check: this.shouldShowSubmit
        },
        {
          title: 'View Comments',
          icon: 'comment',
          click: this.showCommentsForReport,
          check: this.shouldShowViewComments
        }
      ],
      gridKey: '',
      isDirty: false,
      activeId: '',
      activeReportRecord: {},
      approvedStatusCode: 455,
      declinedStatusCode: 456,
      commentEntityTypeId: 16,
      draftStatusString: 'Uploaded (Draft)',
      submittedStatusString: 'Submitted',
      declinedStatusString: 'Declined',
      approvedStatusString: 'Approved',
      commentFormId: 14,
      activeEntity: {},
      gridPageSize: 5,
      gridCurrentPage: 1,
      commentGridPageSize: 5,
      commentGridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['roles', 'activeRole', 'isAuthenticated'])
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    setCommentPageSize(size) {
      this.commentGridPageSize = parseInt(size);
    },
    setCommentCurrentPage(page) {
      this.commentGridCurrentPage = parseInt(page);
    },
    is250User() {
      if (this.roles && this.roles.length > 0 && this.roles.includes('250')) return true;
      else return false;
    },
    getPrimaryEntity() {
      if (this.is250User()) return this.primaryentity;
      else return {};
    },
    shouldShowApprove(item) {
      if (item && item.status && [this.submittedStatusString].includes(item.status) && !this.is250User()) return true;
      return false;
    },
    shouldShowDecline(item) {
      if (item && item.status && [this.submittedStatusString].includes(item.status) && !this.is250User()) return true;
      return false;
    },
    shouldShowSubmit(item) {
      if (
        item &&
        item.status &&
        [this.draftStatusString, this.declinedStatusString].includes(item.status) &&
        this.is250User()
      )
        return true;
      return false;
    },
    shouldShowEdit(item) {
      if (
        item &&
        item.status &&
        [this.draftStatusString, this.declinedStatusString].includes(item.status) &&
        this.is250User()
      )
        return true;
      return false;
    },
    shouldShowDelete(item) {
      if (item && item.status && [this.draftStatusString].includes(item.status) && this.is250User()) return true;
      return false;
    },
    shouldShowViewComments(item) {
      if (item && item.hasComments && item.hasComments == true) return true;
      return false;
    },
    shouldShowAdditionalActions(item) {
      return true;
    },
    resetGrid() {
      this.gridKey = Math.random().toString();
    },
    showCommentsForReport(item) {
      this.activeEntity = { Id: item.id, EntityTypeId: this.entityTypeId };
      this.isModalVisible = true;
    },
    async closeModal(type) {
      this.isModalVisible = false;
      this.activeEntity = {};
    },
    async submitReport(item) {
      const regText =
        'You are about to submit this report to the Alabama Pathway Registry Manager. Please enter an optional comment and click continue to proceed.';
      const regOptions = {
        title: 'Confirm Submission!',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then(async (res) => {
          if (res && res.ok && res.ok == true) {
            this.submitConfirmed(item, res.value);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    async submitConfirmed(item, comment) {
      const body = {
        reportId: item.id,
        comment: comment
      };
      this.isBusy = true;
      this.$store
        .dispatch('reportSubmissionRequest', body)
        .then(async (response) => {
          this.isBusy = false;
          this.$dialogs.alert(
            'Your report has been submitted successfully to the Alabama Pathways Registry Manager for review.',
            { title: 'Report Submitted!' }
          );
          this.resetGrid();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async approveReport(item) {
      const regText = `You are about to confirm this report from ${item.submitter}. Please enter an optional comment and click continue to proceed.`;
      const regOptions = {
        title: 'Confirm Approval!',
        okLabel: 'Continue',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then(async (res) => {
          if (res && res.ok && res.ok == true) {
            this.reportResponse(item, res.value, this.approvedStatusCode);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    async declineReport(item) {
      const regText = `You are about to decline this report from ${item.submitter}. Please enter a mandatory comment to provide the reason and click continue to proceed.`;
      const regOptions = {
        title: 'Confirm Decline!',
        okLabel: 'Continue',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequiredPrompt
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then(async (res) => {
          if (res && res.ok && res.ok == true) {
            this.reportResponse(item, res.value, this.declinedStatusCode);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    async reportResponse(item, comment, statusCode) {
      const body = {
        reportId: item.id,
        comment: comment,
        status: statusCode
      };
      this.isBusy = true;
      this.$store
        .dispatch('reportResponseRequest', body)
        .then(async (response) => {
          this.isBusy = false;
          if (statusCode == this.approvedStatusCode) {
            this.$dialogs.alert(
              `You have successfully approved the report. A notification has been sent to ${item.submitter}.`,
              { title: 'Report Approved!' }
            );
          } else if (statusCode == this.declinedStatusCode) {
            this.$dialogs.alert(
              `You have successfully declined the report. A notification has been sent to ${item.submitter}.`,
              { title: 'Report Declined!' }
            );
          }
          this.resetGrid();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    }
  }
};
</script>
