<template>
  <div>
    <div class="form-row">
      <div class="form-group col-12">
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <span style="font-size: 1rem">
                <span class="ml-4">General Information</span>
              </span>
            </b-card-header>
            <b-card-body>
              <div class="form-row">
                <div class="form-group col-12">
                  <strong>Description:</strong> <span v-html="section.description"></span>
                </div>
                <div class="form-group col-6">
                  <strong>Starts:</strong>
                  <div>{{ this.$formatGridDate(section.trainingStartDate, false, true) }}</div>
                </div>
                <div class="form-group col-6">
                  <strong>Ends:</strong>
                  <div>{{ this.$formatGridDate(section.trainingEndDate, false, true) }}</div>
                </div>
                <div class="form-group col-6">
                  <strong>Region:</strong>
                  <div>{{ section.region }}</div>
                </div>
                <div class="form-group col-6">
                  <strong>Delivery Method:</strong>
                  <div v-if="section.locationName != null">{{section.locationName}}</div>
                  <div v-if="section.locationPhone != null">{{phoneFormat(section.locationPhone)}} 
                    <span v-if="section.locationPhoneExt != null">ext {{section.locationPhoneExt}}</span>
                  </div>
                  <div v-if="section.locationAddress != null">{{section.locationAddress}}</div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="form-group col-12">
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <span style="font-size: 1rem">
                <span class="ml-4">Contact Information</span>
              </span>
            </b-card-header>
            <b-card-body>
              <div class="form-row pl-3">
                <div class="form-group col-6">
                  <strong> Contact Name: </strong>
                  <div v-html="section.contactName"></div>
                </div>
                <div class="form-group col-6">
                  <strong> Contact Phone: </strong>
                  <div v-html="section.contactPhone"></div>
                </div>
                <div class="form-group col-6">
                  <strong> Contact Phone Ext: </strong>
                  <div v-html="section.contactPhoneExt" v-if="section.contactPhoneExt !== null"></div>
                  <div v-else>N/A</div>
                </div>
                <div class="form-group col-6">
                  <strong> Contact Email: </strong>
                  <div v-html="section.contactEmail"></div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="form-group col-12">
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <span style="font-size: 1rem">
                <span class="ml-4">Session Information</span>
              </span>
            </b-card-header>
            <b-card-body>
              <div class="form-row pl-3">
                <div class="form-group col-6">
                  <strong> Core Knowledge Area: </strong>
                  <div v-html="section.coreKnowledgeArea"></div>
                </div>
                <div class="form-group col-6">
                  <strong> Target Age Group: </strong>
                  <div v-html="section.targetAgeGroup"></div>
                </div>
                <div class="form-group col-6">
                  <strong> DHR Minimum Standard: </strong>
                  <div v-html="section.dhrMinimumStandard"></div>
                </div>
                <div class="form-group col-6">
                  <strong> Child Development Associate Area (CDA): </strong>
                  <div v-html="section.cdaArea"></div>
                </div>
                <div class="form-group col-12">
                  <strong> CCDF - Health and Safety: </strong>
                  <div v-html="section.ccdfArea" v-if="section.ccdfArea !== '<ul></ul>'"></div>
                  <div v-else>N/A</div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="form-group col-12" 
          v-if="(section.otherInformation && section.otherInformation.length > 1)|| 
                (section.status == 'Registered' && section.sessionInformation && section.sessionInformation.length > 1)">
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle.otherInfo>
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="otherInfoOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">Other Information</span>
              </span>
            </b-card-header>
            <b-collapse id="otherInfo" accordion="other-info-accordion" role="tabpanel" v-model="otherInfoOpen">
              <b-card-body>
                <div v-html="section.otherInformation"></div>
                <div v-html="section.sessionInformation" v-if="section.status == 'Registered'"></div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="form-group col-6">
        <span style="color: red !important"> Registration Start Date: </span
        >{{ this.$formatGridDate(section.regStartDate, false, true) }}
      </div>
      <div class="form-group col-6">
        <span style="color: red !important"> Registration End Date: </span
        >{{ this.$formatGridDate(section.regDeadlineDate, false, true) }}
      </div>
      <div class="form-group col-4" v-if="!hideRegister">
        <button
          :title="
            registered
              ? 'Already Registered'
              : pastDeadline
              ? 'Past Registration Deadline'
              : beforeStart
              ? 'Registration Has Not Started'
              : ''
          "
          class="btn btn-primary btn-pointer-events-visible"
          @click="register"
          :disabled="disabled"
        >
          Register
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestPrompt from '../utility/dialog/request-prompt';
import SelectPromptRequired from '../utility/dialog/select-prompt-required';
export default {
  name: 'TrainingSearchDetails',
  data() {
    return {
      isBusy: false,
      registered: false,
      disabled: false,
      otherInfoOpen: false
    };
  },
  props: {
    section: {
      type: Object,
      default: () => {}
    },
    hideRegister: {
      type: Boolean,
      default: false
    },
    disableRegister: {
      type: Boolean,
      default: false
    },
    pastDeadline: {
      type: Boolean,
      default: false
    },
    beforeStart: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.disableRegister) {
      this.registered = true;
      this.disabled = true;
    }
    if (this.pastDeadline || this.beforeStart) this.disabled = true;
  },
  methods: {
    registerConfirmed(orgId) {
      const body = {
        courseSectionId: this.section.id,
        userId: this.$store.getters['userId'],
        organizationId: orgId
      };
      this.$store
        .dispatch('courseRegistrationRequest', body)
        .then(async (response) => {
          if (response == 1) {
            const options = { title: 'Registration Successful!' };
            this.$dialogs.alert(
              `You have successfully registered for  ${this.section.courseName}: ${this.section.sectionName}`,
              options
            );
            this.registered = true;
            this.disabled = true;
            this.section.status='Registered';
            this.$emit('reset');
          } else if (response == 2) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Unable to register. You are already registered for  ${this.section.courseName}: ${this.section.sectionName}.`,
              options
            );
          } else if (response == 3) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full. Your registration request has been declined.`,
              options
            );
          } else if (response == 4) {
            this.offerWaitlist(orgId);
          } else {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert('Unable to register. An error occurred.', options);
          }
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    register() {
      this.$store
      .dispatch('getOwnOrgsRequest')
      .then(async (response) => {
        if (response.length > 1) {
          this.requestRegisterMultiChoice(response);
        } else {
          this.requestRegisterOneChoice(response);
        }
      })
      .catch((errors) => {
        this.isBusy = false;
      })
    },
    requestRegisterMultiChoice(opts) {
      const regText = `Please select a Facility and click Confirm to register for ${this.section.courseName}: ${this.section.sectionName}`;
      const regOptions = {
        title: 'Select a Facility & Confirm Registration',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: SelectPromptRequired,
          stimulus: 'Select a Facility',
          selectOptions: opts
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const params = {
              region: this.section.region,
              userId: this.$store.getters['userId'],
              organizationId: res.value
            };
            this.$store
            .dispatch('regionCheckRequest', params)
            .then(async (response) => {
              if (response == true) {
                this.registerConfirmed(res.value);
 
              } else {
                this.offerRequestRegistration(res.value);
                this.$emit('dirty', false);
                this.isDirty = false;
              }
            })
            .catch((errors) => {
              this.isBusy = false;
            })           
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    requestRegisterOneChoice(opts) {
      const inRegion = false;
      if (opts.length == 1) {
          const orgId = opts[0].key;
          const regText = `Please click Confirm to register for ${this.section.courseName}: ${this.section.sectionName} `;
          const regOptions = {
            title: 'Confirm Registration',
            cancelLabel: 'Cancel',
            okLabel: 'Confirm'
          };
          this.isBusy = true;
          const params = {
            region: this.section.region,
            userId: this.$store.getters['userId'],
            organizationId: orgId
          };
          this.$store
          .dispatch('regionCheckRequest', params)
          .then(async (response) => {
            if (response == true) {
              this.$dialogs
                .confirm(regText, regOptions)
                .then((res) => {
                  if (res && res.ok && res.ok == true) {
                    this.registerConfirmed(orgId);
                  }
                  this.$emit('dirty', false);
                  this.isDirty = false;
                })
                .catch((error) => {
                  this.$emit('dirty', false);
                  this.isDirty = false;
                });
            } else {
              this.offerRequestRegistration(orgId);
              this.$emit('dirty', false);
              this.isDirty = false;
            }
          })
          .catch((errors) => {
            this.isBusy = false;
          })
      } else { // no recs
        this.offerRequestRegistration('');
        this.$emit('dirty', false);
        this.isDirty = false;     
      }
    },
    requestRegistrationConfirmed(comment, orgId) {
      const body = {
        courseSectionId: this.section.id,
        userId: this.$store.getters['userId'],
        comment: comment,
        organizationId: orgId
      };
      this.$store
        .dispatch('requestRegistrationRequest', body)
        .then(async (response) => {
          if (response == 1) {
            const options = { title: 'Registration Request Submitted!' };
            this.$dialogs.alert(
              `Your request to register out of region training has been submitted. You will receive a notification once the review is completed.`,
              options
            );
            this.registered = true;
            this.disabled = true;
            this.$emit('reset');
          } else if (response == 2) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Unable to register. You are already registered for  ${this.section.courseName}: ${this.section.sectionName}.`,
              options
            );
          } else if (response == 3) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full. Your registration request has been declined.`,
              options
            );
          } else {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert('Unable to register. An error occurred.', options);
          }
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    offerRequestRegistration(orgId) {
      const regText =
        'Your request to register out of region training requires approval by the QEA. Please enter an optional comment and click continue to proceed.';
      const regOptions = {
        title: 'Out of region Registration!',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.requestRegistrationConfirmed(res.value, orgId);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    offerWaitlist(orgId) {
      const regText = `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full. Please click Confirm to be waitlisted.  You will receive notifications for any changes in your waitlist status.`;
      const regOptions = {
        title: 'Registration Full -- Waiting List',
        cancelLabel: 'Cancel',
        okLabel: 'Confirm'
      };
      this.$dialogs
        .confirm(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.acceptWaitlist(orgId);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    acceptWaitlist(orgId) {
      const body = {
        courseSectionId: this.section.id,
        userId: this.$store.getters['userId'],
        organizationId: orgId
      };
      this.$store
        .dispatch('courseWaitlistRequest', body)
        .then(async (response) => {
          if (response >= 1) {
            const options = { title: 'Registration Status Waitlisted!' };
            this.$dialogs.alert(
              `Your waitlist position for  ${this.section.courseName}: ${this.section.sectionName} is <strong>${response}</strong>`,
              options
            );
            this.registered = true;
            this.disabled = true;
            this.$emit('reset');
          } else if (response == -2) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Unable to add to waitlist. You are already registered or waitlisted for  ${this.section.courseName}: ${this.section.sectionName}.`,
              options
            );
          } else if (response == -3) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full and there are no more spots on the waitlist. Your registration request has been declined.`,
              options
            );
          } else {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert('Unable to add to waitlist. An error occurred.', options);
          }
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    phoneFormat(num) {
      if (num.length == 10) {
        return `(${num.substring(0,3)}) ${num.substring(3,6)}-${num.substring(6)}`;
      } else if (num.length == 7) {
        return `(${num.substring(0,3)}-${num.substring(3)}`;
      } else {
        return num;
      }
    }
  }
};
</script>
