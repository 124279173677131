<template>
  <iframe :src="quickSightURL" style="height: 100vh; width: 100%"></iframe>
</template>

<script>
import { apiService } from '@api';

export default {
  name: 'QuickSight',
  data() {
    return {
      quickSightURL: ''
    };
  },
  mounted() {
    apiService
      .getQuickSightURL()
      .then((result) => {
        this.quickSightURL = result.url;
      })
      .catch((errors) => {});
  }
};
</script>
