import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach(async (to, from, next) => {
  //see if we're going to a page the required you to be logged in
  if (
    to.matched.some((record) => record.meta.requiresAuth) ||
    store.getters['isFirstTime'] ||
    store.getters['needsTac']
  ) {
    if (!store.getters['isAuthenticated']) {
      //the requested route requires you to be logged in and we have no token
      next({
        path: '/',
        query: { redirect: to.fullPath }
      });
    }
    //if they have limited login, keep them on that page
    //1: basic user info needs to be entered after account confirmation
    else if (to.name != 'profile-personal' && store.getters['isFirstTime']) {
      next({
        name: 'profile-personal',
        params: { userId: store.getters['userId'] }
      });
    }
    //2: terms and conditions need to be accepted
    else if (to.name != 'accept-terms' && store.getters['needsTac']) {
      next({
        name: 'accept-terms',
        params: { userId: store.getters['userId'] }
      });
    } else {
      //variable to control if we're going to allow them to get to the route
      let go = true;
      //some routes require specific user role claims
      if (to.matched.some((record) => record.meta.requiresClaims && record.meta.requiresClaims.length > 0)) {
        //we'll allow them to go when they've satisfied all the claims
        go = false;
        const allRequiredClaims = [];

        //to.matched includes the desired route as well as its parent routes
        for (let i = 0; i < to.matched.length; i++) {
          const match = to.matched[i];
          if (match && match.meta && match.meta.requiresClaims && match.meta.requiresClaims.length > 0) {
            for (let j = 0; j < match.meta.requiresClaims.length; j++) {
              const claim = match.meta.requiresClaims[j];
              if (!allRequiredClaims.includes(claim)) allRequiredClaims.push(claim);
            }
          }
        }
        const userClaims = store.getters['claims'];
        //compare the claims required for the route with the users granted claims
        for (let k = 0; k < allRequiredClaims.length; k++) {
          if (userClaims && userClaims.includes(allRequiredClaims[k])) {
            go = true;
          } else {
            go = false;
            break;
          }
        }
      }
      if (go) {
        next();
      } else {
        //they don't have permissions to access the route, send them home
        next({ name: 'home' });
      }
    }
  }
  //the route doesn't match a route we have that requires authorization
  else {
    //see if we can find the route at all, if we find it they're allowed there
    if (to.matched.some((record) => record)) {
      next();
    }
    //we can't find the route at all, send them home
    //if they're not authenticated they will be routed to login on the second pass through this method
    else {
      next({ name: 'home' });
    }
  }
});

export default router;
