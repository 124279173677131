<template>
  <section class="container-fluid">
    <div class="container has-text-centered">
      <div class="profile-form-container">
        <div class="my-4 text-center">
          <h1 class="title has-text-grey">Action History</h1>
        </div>
        <div>
          <grid
            :entityTypeId="rolePromotionEntityTypeId"
            :formId="0"
            :entityTypeName="''"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :primaryEntity="primaryEntity"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            ref="actionGrid"
            :additionalActionsOnColumn="true"
            :allowDownload="false"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">Action History Details for {{ activeRolePromotion.primaryEntityName }}</h2>
      <div class="text-left row" slot="body">
        <div v-if="activeRolePromotion.id" class="w-100 m-3">
          <actionDetails :key="activeId" :action="activeRolePromotion"></actionDetails>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import actionDetails from './action-details.vue';
export default {
  name: 'ActionHistory',
  components: { actionDetails },
  data() {
    return {
      rolePromotionEntityTypeId: 19,
      userEntityTypeId: 1,
      primaryEntity: {},
      isModalVisible: false,
      activeId: '',
      activeRolePromotion: {},
      isDirty: false,
      additionalActions: [
        {
          title: 'View Request Action History',
          icon: 'history',
          click: this.rowAction,
          check: this.shouldShowAction
        }
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    if (this.userId && this.userEntityTypeId) {
      this.primaryEntity = {
        Id: this.userId,
        EntityTypeId: this.userEntityTypeId
      };
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      return true;
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeRolePromotion = item;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeId = 'closed';
      this.activeRolePromotion = {};
    }
  }
};
</script>
