<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area ' : ''">
      <div class="has-text-centered">
        <div class="profile-form-container">
          <div v-if="canView" class="mb-4 text-center">
            <h1 class="title has-text-grey">Staff Management</h1>
            <p>
              Click on an individual associated with your program to view the EC Professional’s information, Education,
              Certification/Credentials, {{ $t('message.pdpText') }} Experience, Training or to disassociate them from
              your program.
            </p>
          </div>
          <div>
            <div v-if="canView" class="float-left mb-3">
              <b-dropdown
                :text="filterTitle"
                variant="wfr"
                class="mr-2"
                :disabled="activeRole.organizations && activeRole.organizations.length > 1 ? false : true"
              >
                <b-dropdown-item-button
                  v-for="organization in activeRole.organizations"
                  :key="organization.organizationId"
                  :active="filterOrganizationId == organization.organizationId"
                  @click="setFilterOrganization(organization)"
                >
                  {{ organization.organizationName }}
                </b-dropdown-item-button>
              </b-dropdown>
              <span v-if="showDetailButton">
                <button class="btn btn-wfr mr-2" @click="showOrgModal">Program Details</button>
              </span>
              <span v-if="showAgencyReportButton">
                <!--<button class="btn btn-wfr mr-2" @click="showSummary">Agency Summary</button>-->
              </span>
            </div>
            <div v-if="canView" class="float-right mb-3 mr-3">
              <button class="btn btn-wfr" @click="showReferralModal">Refer Staff to {{ $t('message.textID') }}</button>
            </div>
            <div v-if="organizationEntity.Id">
              <grid
                :entityTypeId="userEntityType"
                :formId="userFormId"
                :entityTypeName="entityTypeName"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :key="filterOrganizationId + keyAdd"
                :primaryEntity="organizationEntity"
                :showDisable="false"
                :showView="canView"
                ref="userGrid"
                :additionalShowColumns="additionalColumns"
                :additionalActionsOnColumn="false"
                :additionalActions="additionalActions"
                :checkForAdditionalActions="shouldShowAdditionalActions"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"
              ></grid>
            </div>
          </div>
        </div>
      </div>
      <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
        <h2 slot="header" v-if="isModalVerificationOnly">Verify User Records - {{ activeUser.fullName }}</h2>
        <h2 slot="header" v-else>User Records - {{ activeUser.fullName }}</h2>
        <div class="text-left row" slot="body">
          <div v-if="activeUser.id" class="w-100 m-3">
            <userRecordsView
              :key="activeId"
              :user="activeUser"
              :readOnly="activeUserIs200"
              :verificationOnly="isModalVerificationOnly"
              @dirty="formDirty($event)"
              :organizationId="filterOrganizationId"
            ></userRecordsView>
          </div>
        </div>
      </modal>
      <modal v-show="isReferralModalVisible" @close="closeReferralModal" :isModalVisible="isReferralModalVisible">
        <h2 slot="header">User Referral</h2>
        <div class="text-left row" slot="body">
          <div class="w-100 m-3">
            <wfrform
              :formid="referralFormId"
              :entities="defaultReferralEntity"
              :primaryEntity="getPrimaryEntity()"
              @dirty="formDirty($event)"
              :key="activeReferral"
              @saved="closeReferralModal"
            >
            </wfrform>
          </div>
        </div>
      </modal>
      <modal
        v-show="isProgramInfoModalVisible"
        @close="closeProgramInfoModal"
        :isModalVisible="isProgramInfoModalVisible"
      >
        <div slot="header" class="text-center" style="width: 100%">
          <h2>Program Details</h2>
          <p><i>If any of the information below needs to be updated, please contact your licensing consultant.</i></p>
        </div>
        <div class="form-row" slot="body">
          <div class="form-group col-12">
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-body>
                  <div class="form-row">
                    <div class="form-group col-6">
                      <strong>FEIN:</strong>
                      <div class="ml-2">xxxxxxx.xxx</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Street Address:</strong>
                      <div class="ml-2">
                        {{ activeOrg.addressLine1 }}
                        <br v-if="activeOrg.addressLine2" />
                        {{ activeOrg.addressLine2 }}
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Regulatory Agency:</strong>
                      <div class="ml-2">{{ activeOrg.regulatoryAgency }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>City:</strong>
                      <div class="ml-2">{{ activeOrg.city }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Child Care Type:</strong>
                      <div class="ml-2">{{ activeOrg.providerType }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>State:</strong>
                      <div class="ml-2">{{ activeOrg.state }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Regulatory Status:</strong>
                      <div class="ml-2">{{ activeOrg.regulatoryStatus }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Zip:</strong>
                      <div class="ml-2">{{ activeOrg.zipCode }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Provider Name:</strong>
                      <div class="ml-2">{{ activeOrg.organizationName }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Phone Number:</strong>
                      <div class="ml-2">{{ activeOrg.phoneNumber }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Provider Status:</strong>
                      <div class="ml-2">{{ activeOrg.agencyStatus }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Email Address:</strong>
                      <div class="ml-2">{{ activeOrg.email }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>County:</strong>
                      <div class="ml-2">{{ activeOrg.county }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Participates In Certificate:</strong>
                      <div class="ml-2">{{ activeOrg.participatesInCertificate ? 'TRUE' : 'FALSE' }}</div>
                    </div>
                    <div class="form-group col-6">
                      <strong>Licensed Capacity:</strong>
                      <div class="ml-2">{{ activeOrg.licensedCapacity > 0 ? activeOrg.licensedCapacity : 'n/a' }}</div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </div>
      </modal>
      <modal
        v-show="isSummaryModalVisible"
        @close="closeSummary"
        :isModalVisible="isSummaryModalVisible"
        :mustViewAll="false"
        :modalBusy="isBusy"
      >
        <h2 slot="header" class="text-center" style="width: 100%" v-if="isSummaryModalVisible">
          QRIS REPORT PERIOD<br />
          {{ getQrisStartDate() }}
          -
          {{ getQrisEndDate() }}
        </h2>
        <div slot="body" v-if="isSummaryModalVisible">
          <grid
            :entityTypeId="summaryEntityType"
            :formId="8"
            :entityTypeName="summaryEntityTypeName"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :allowDownload="false"
            :key="filterOrganizationId + '0' + summaryKeyAdd"
            :primaryEntity="organizationEntity"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            ref="summaryGrid"
            :additionalActions="summaryAdditionalActions"
            :additionalActionsOnColumn="true"
            :basePageSize="summaryGridPageSize"
            :baseCurrentPage="summaryGridCurrentPage"
            @setPageSize="setSummaryPageSize"
            @setCurrentPage="setSummaryCurrentPage"
          ></grid>
          <b-button
            variant="wfr"
            @click="getAgencyReport"
            class="btn btn-wfr mr-4 float-right"
            title="Download Agency Summary PDF"
            id="pdf"
            style="position: relative; bottom: 45px"
          >
            <icon icon="file-pdf" size="lg"></icon>
          </b-button>
        </div>
      </modal>
      <modal
        v-show="isTrainingReportModalVisible"
        @close="closeTrainingReportModal"
        :isModalVisible="isTrainingReportModalVisible"
        :modalBusy="!fileUrl"
        :skinName="'doc-viewer'"
      >
        <h2 slot="header">Training Report</h2>
        <div class="text-left row" slot="body">
          <div class="col">
            <docViewer
              v-if="fileUrl"
              :downloadButtonText="'Download Training Report'"
              :fileUrl="fileUrl"
              :isStatic="false"
            ></docViewer>
          </div>
        </div>
      </modal>
      <modal
        v-show="isArchivedReportsModalVisible"
        @close="closeArchivedReportsModal"
        :isModalVisible="isArchivedReportsModalVisible"
      >
        <h2 slot="header">Archived Training Reports</h2>
        <archivedTrainingReports
          v-if="activeUser.employmentRecordId"
          :employmentRecordId="activeUser.employmentRecordId"
          slot="body"
        ></archivedTrainingReports>
      </modal>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import userRecordsView from './user-records-view.vue';
import { getText, locale } from '@scripts/localized';
import docViewer from '@components/utility/doc-viewer';
import archivedTrainingReports from '@components/dashboard-items/management/archived-training-reports';

export default {
  name: 'OrganizationUsers',
  components: {
    userRecordsView,
    docViewer,
    archivedTrainingReports
  },
  data() {
    return {
      locale: locale,
      filterOrganizationId: '',
      currentOrganizationName: '',
      isBusy: false,
      userEntityType: 1,
      summaryEntityType: 33,
      userFormId: 1,
      entityTypeName: 'User',
      summaryEntityTypeName: 'Training Report',
      organizationEntity: {},
      isSummaryModalVisible: false,
      additionalActions: [
        {
          title: 'View User Records',
          icon: 'clipboard',
          click: this.viewUserRecords,
          check: this.shouldShowViewUserRecords
        },
        {
          title: 'Verify User Records',
          icon: 'user-shield',
          click: this.verifyUserRecords,
          check: this.shouldShowVerifyUserRecords
        },
        {
          title: 'Training Report',
          icon: 'book',
          click: this.getReport,
          check: this.shouldShowReportLink
        },
        {
          title: 'Archived Training Reports',
          icon: 'archive',
          click: this.getArchivedReports,
          check: this.shouldShowArchivedReportsLink
        },
        {
          title: 'Add TrainTN Access',
          icon: 'door-open',
          click: this.addTrainTNAccess,
          check: this.shouldShowAddTrainTN
        },
        {
          title: 'Remove TrainTN Access',
          icon: 'door-closed',
          click: this.removeTrainTNAccess,
          check: this.shouldShowRemoveTrainTN
        },
        {
          title: 'Remove Organization Association',
          icon: 'user-times',
          click: this.removeAccess,
          check: this.shouldShowPermissionsLink
        }
      ],
      summaryAdditionalActions: [
        {
          title: 'Training Report',
          icon: 'book',
          click: this.getReport,
          check: this.shouldShowReportLinkOnSummary
        }
      ],
      userInPilot: true,
      additionalColumns: ['titlePosition'],
      isModalVisible: false,
      isModalVerificationOnly: false,
      isReferralModalVisible: false,
      isProgramInfoModalVisible: false,
      isTrainingReportModalVisible: false,
      itemData: false,
      activeId: '',
      activeUser: {},
      activeUserIs200: false,
      isDirty: false,
      referralFormId: 16,
      defaultReferralEntity: [{ Id: '', EntityTypeId: 17 }],
      userEntityTypeId: 1,
      activeReferral: '',
      gridPageSize: 5,
      gridCurrentPage: 1,
      summaryGridPageSize: 5,
      summaryGridCurrentPage: 1,
      keyAdd: '',
      summaryKeyAdd: '',
      activeOrg: {},
      fileUrl: '',
      qrisStartDate: null,
      qrisEndDate: null,
      showAgencyReportButton: false,
      isArchivedReportsModalVisible: false
    };
  },
  props: {
    organizationId: {
      type: String,
      default: ''
    },
    organizationName: {
      type: String,
      default: ''
    }
  },
  watch: {
    filterOrganizationId: function (newVal) {
      this.organizationEntity = { Id: newVal, EntityTypeId: 2 };
      this.getOrgData();
    }
  },
  created() {
    if (this.organizationId) {
      this.filterOrganizationId = this.organizationId;
      this.currentOrganizationName = this._props.organizationName;
    } else if (
      this.activeRole &&
      this.activeRole.organizations &&
      this.activeRole.organizations[0] &&
      this.activeRole.organizations[0].organizationId
    ) {
      this.filterOrganizationId = this.activeRole.organizations[0].organizationId;
      this.currentOrganizationName = this.activeRole.organizations[0].organizationName;
    } else this.$router.push({ name: 'dashboard-main' });
  },
  computed: {
    ...mapGetters(['activeRole', 'userId', 'isAuthenticated']),
    canView() {
      return this.activeRole?.role?.roleName != '350' && this.activeRole?.role?.roleName != '375';
    },
    canGrantTrainTN() {
      return this.activeRole?.role?.roleName == '200';
    },
    filterTitle: function () {
      if (this.activeRole && this.activeRole.organizations && this.filterOrganizationId) {
        const filterOrg = this.activeRole.organizations.find((org) => org.organizationId == this.filterOrganizationId);
        if (filterOrg && filterOrg.organizationName) {
          return filterOrg.organizationName;
        }
      }
      return `${getText('facility')} Filter`;
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    setSummaryPageSize(size) {
      this.summaryGridPageSize = parseInt(size);
    },
    setSummaryCurrentPage(page) {
      this.summaryGridCurrentPage = parseInt(page);
    },
    getQrisStartDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.qrisStartDate.toLocaleDateString('en-US', options);
    },
    getQrisEndDate() {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return this.qrisEndDate.toLocaleDateString('en-US', options);
    },
    setFilterOrganization(organization) {
      if (organization && organization.organizationId) {
        this.filterOrganizationId = organization.organizationId;
        this.currentOrganizationName = organization.organizationName;
        this.gridCurrentPage = 1;
      } else {
        this.filterOrganizationId = '';
        this.currentOrganizationName = '';
        this.gridCurrentPage = 1;
      }
    },
    showDetailButton() {
      return (
        locale === 'tn' &&
        this.filterOrganizationId != '' &&
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '200'
      );
    },
    closeTrainingReportModal() {
      this.isTrainingReportModalVisible = false;
      this.fileUrl = '';
    },
    closeArchivedReportsModal() {
      this.isArchivedReportsModalVisible = false;
      this.activeUser = {};
    },
    shouldShowAdditionalActions(item) {
      return (
        this.shouldShowPermissionsLink(item) ||
        this.shouldShowArchivedReportsLink(item) ||
        this.shouldShowViewUserRecords(item) ||
        this.shouldShowVerifyUserRecords(item) ||
        this.shouldShowReportLink(item)
      );
    },
    shouldShowPermissionsLink(item) {
      return !item.has200Role && this.canView;
    },
    shouldShowArchivedReportsLink(item) {
      return item.hasArchivedComplianceReport;
    },
    shouldShowViewUserRecords(item) {
      return (!item.has200Role || this.locale == 'tn') && this.canView;
    },
    shouldShowVerifyUserRecords(item) {
      return !item.has200Role || !this.canView;
    },
    shouldShowReportLink(item) {
      return locale === 'tn' && !item.agencyIsClosed;
    },
    shouldShowReportLinkOnSummary(item) {
      return locale === 'tn' && !item.agencyIsClosed;
    },
    shouldShowAddTrainTN(item) {
      return locale === 'tn' && this.canGrantTrainTN && !item.has200Role && !item.hasTrainTNAccess;
    },
    shouldShowRemoveTrainTN(item) {
      return locale === 'tn' && this.canGrantTrainTN && !item.has200Role && item.hasTrainTNAccess;
    },
    async showSummary() {
      this.isBusy = true;
      this.$store
        .dispatch('qrisCycleRequest')
        .then(async (response) => {
          this.qrisStartDate = new Date(response.startDate);
          this.qrisEndDate = new Date(new Date(response.endDate).getTime() - 86400);
          this.isBusy = false;
          this.isSummaryModalVisible = true;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    closeSummary() {
      this.isSummaryModalVisible = false;
    },
    async addTrainTNAccess(item) {
      const options = { title: 'Confirm TrainTN Access', okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm(`Are you sure you want to give ${item.fullName} access to Train TN?`, options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = {
              employmentRecordId: item.employmentRecordId100Role,
              accessStatus: true
            };
            this.isBusy = true;
            this.$store
              .dispatch('trainTNAccessRequest', body)
              .then(async (response) => {
                this.keyAdd += '-1';
                this.isBusy = false;
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async removeTrainTNAccess(item) {
      const options = { title: 'Confirm TrainTN Access Removal', okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm(`Are you sure you want to remove ${item.fullName} access to Train TN?`, options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = {
              employmentRecordId: item.employmentRecordId100Role,
              accessStatus: false
            };
            this.isBusy = true;
            this.$store
              .dispatch('trainTNAccessRequest', body)
              .then(async (response) => {
                this.keyAdd += '-1';
                this.isBusy = false;
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async getArchivedReports(item) {
      this.activeUser = item;
      this.isArchivedReportsModalVisible = true;
    },
    async getReport(item) {
      this.isTrainingReportModalVisible = true;

      const body = { username: item.userName, organizationId: this.filterOrganizationId };
      this.$store
        .dispatch('complianceReportRequest', body)
        .then((response) => {
          if (response && response.file) {
            this.fileUrl = response.file;
            this.summaryKeyAdd += '-1';
          }
        })
        .catch((errors) => {
          this.errors = errors;
        });
    },
    async getAgencyReport() {
      this.isBusy = true;
      const body = { organizationId: this.filterOrganizationId };
      this.$store
        .dispatch('agencyReportRequest', body)
        .then((response) => {
          this.isBusy = false;
          if (response && response.report) {
            const orgName = this.strip(this.currentOrganizationName);
            let date = new Date().toLocaleString();
            date = date.replace(/\//g, '-');
            date = date.replace(/:/g, '-');
            date = date.replace(/,/g, '_');
            date = date.replace(/ /g, '');
            const fileName = `TNPAL_${orgName}_AgencySummaryReport_${date}.pdf`;
            this.savePdf(fileName, response.report);
            const options = { title: 'Download successful.' };
            this.$dialogs.alert(`Check your Downloads folder for a PDF of the report.`, options);
          }
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    strip(arr) {
      const arr2 = arr.split(' ');
      for (let item of arr2) {
        const index = arr2.indexOf(item);
        const ewc = item.endsWith(',');
        const ewp = item.endsWith('.');
        const e = item.length - 1;
        if (ewc || ewp) {
          item = item.slice(0, e);
          arr2[index] = item;
        }
        if (item.toLowerCase().includes('llc') || item.toLowerCase().includes('inc')) {
          arr2.splice(index, 1);
        }
      }
      return arr2.join(' ');
    },
    savePdf(strFileName, strData) {
      const pdfData = 'data:application/pdf;base64,' + escape(strData);

      const link = document.createElement('a');
      link.href = pdfData;
      link.download = strFileName;
      link.click();
    },
    async removeAccess(item) {
      if (item && item.id) {
        return await new Promise((resolve, reject) => {
          const options = {
            title: 'Confirm remove role',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm(
              'Are you sure you want to remove the Staff level role from ' +
                item.fullName +
                ' in ' +
                this.filterTitle +
                '?',
              options
            )
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.isBusy = true;
                const body = {
                  userId: item.id,
                  organizationId: this.filterOrganizationId,
                  roleName: '100',
                  active: false,
                  employmentRecordId: item.employmentRecordId100Role
                };
                this.$store
                  .dispatch('userOrganizationRolesUpdateRequest', body)
                  .then(async (response) => {
                    this.$refs.userGrid.loadSummary();
                    this.isBusy = false;
                    resolve(true);
                  })
                  .catch((errors) => {
                    this.isBusy = false;
                    reject(errors);
                  });
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        });
      }
    },
    viewUserRecords(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeUser = item;
        this.activeUserIs200 = item.has200Role;
        this.showModal();
      }
    },
    verifyUserRecords(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeUser = item;
        this.showModal(true);
      }
    },
    showModal(verificationOnly = false) {
      this.isModalVerificationOnly = verificationOnly;
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
      }
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    },
    getPrimaryEntity() {
      return { Id: this.userId, EntityTypeId: this.userEntityTypeId };
    },
    showReferralModal() {
      this.isReferralModalVisible = true;
      this.activeReferral = new Date().toString();
    },
    showOrgModal() {
      this.isProgramInfoModalVisible = true;
    },
    async getOrgData() {
      this.isBusy = true;
      const body = {
        orgId: this.filterOrganizationId
      };
      this.$store
        .dispatch('getOrgByIdRequest', body)
        .then(async (response) => {
          this.activeOrg = response;
          this.isBusy = false;
          if (
            locale === 'tn' &&
            this.filterOrganizationId != '' &&
            this.activeRole &&
            this.activeRole.role &&
            this.activeRole.role.roleName &&
            this.activeRole.role.roleName == '200' &&
            !this.activeOrg.disabled
          ) {
            this.showAgencyReportButton = true;
          } else {
            this.showAgencyReportButton = false;
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async closeReferralModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isReferralModalVisible = false;
        this.activeReferral = '';
      }
    },
    closeProgramInfoModal() {
      this.isProgramInfoModalVisible = false;
      this.activeOrg = {};
    }
  }
};
</script>
