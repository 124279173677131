<template>
  <section class="container-fluid">
    <div>
      <div class="mb-4 text-center">
        <h1>Self-Assessment</h1>
      </div>
      <div>
        <grid
          :entityTypeId="entityTypeId"
          :formId="formId"
          :entityTypeName="entityTypeName"
          :primaryEntity="primaryentity"
          :showAddButton="false"
          :showFormOnEmpty="true"
          :basePageSize="gridPageSize"
          :baseCurrentPage="gridCurrentPage"
          @setPageSize="setPageSize"
          @setCurrentPage="setCurrentPage"
          :showPagination="false"
        >
        </grid>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PDPSelfAssessment',
  data() {
    return {
      entityTypeId: 13,
      formId: 12,
      entityTypeName: 'PDP Self Assessment',
      gridPageSize: 25,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    }
  }
};
</script>
