<template>
  <focus-trap
    :active="true"
    :initial-focus="() => getInitialFocus()"
    :fallback-focus="() => $refs.body"
    :escapeDeactivates="false"
  >
    <div class="v-dialog" :class="{ active: show, [`v-dialog-${item.size}`]: item.size }">
      <div class="v-dialog-overlay" @click="onClose(false)"></div>
      <div class="v-dialog-container">
        <div class="v-dialog-header row">
          <div class="v-dialog-title col-10" v-if="item.title">
            <h2 class="h3">{{ item.title }}</h2>
          </div>
          <div class="col-2">
            <button class="v-dialog-btn v-dialog-btn-close btn" aria-label="Close" @click="onClose()"></button>
          </div>
        </div>
        <div class="v-dialog-body">
          <div class="content">
            <div v-html="item.message"></div>
            <form v-if="item.prompt" @submit.prevent="onOk">
              <div
                class="v-dialog-input"
                :is="item.prompt.component"
                v-model="item.prompt.value"
                ref="prompt"
                @input="onPrompt"
                :checkboxLabel="item.prompt.checkboxLabel"
                :selectOptions="item.prompt.selectOptions"
                :stimulus="item.prompt.stimulus"
              ></div>
              <div class="prompt-error-message" v-if="!valid">
                {{ item.prompt.invalidMessage }}
              </div>
            </form>
          </div>
        </div>
        <div class="v-dialog-footer row text-right" style="background-color: none">
          <div class="col-sm-7" v-if="item.notOkLabel === ''"></div>
          <div class="col-12 col-sm mt-2">
            <button class="btn col" @click="onClose(false)" v-if="item.cancelLabel" :ref="'body'">
              {{ item.cancelLabel }}
            </button>
          </div>
          <div class="col-12 col-sm mt-2" v-if="item.notOkLabel !== ''">
            <button
              :class="(!valid ? 'disabled ' : '') + 'tnpalBGRed white btn col'"
              @click="onNotOk"
              :ref="!item.cancelLabel ? 'body' : ''"
              :disabled="!valid"
            >
              {{ item.notOkLabel }}
            </button>
          </div>
          <div class="col-12 col-sm mt-2">
            <button
              :class="(!valid ? 'disabled ' : '') + (item.notOkLabel === '' ? 'tnpalBGRed' : 'tnpalBGGreen') + ' white btn col'"
              @click="onOk"
              :ref="!item.cancelLabel ? 'body' : ''"
              :disabled="!valid"
            >
              {{ item.okLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </focus-trap>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';
export default {
  components: {
    FocusTrap: FocusTrap
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      valid: this.isValid()
    };
  },

  methods: {
    isValid() {
      if (this.item.prompt) {
        return this.$refs.prompt && this.$refs.prompt.isValid();
      }
      return true;
    },

    onPrompt() {
      setTimeout(() => {
        this.valid = this.isValid();
      });
    },

    onClose(response) {
      this.$emit('close', this.item, response);
    },

    onOk() {
      if (this.valid) {
        this.onClose(true);
      }
    },

    onNotOk() {
      if (this.valid) {
        this.onClose(null);
      }
    },

    getInitialFocus() {
      let element;

      const prompt = this.$refs.prompt;
      if (prompt && prompt.$refs && prompt.$refs.inputbox) element = prompt.$refs.inputbox;

      if (!element) element = this.$refs.body;
      return element;
    }
  }
};
</script>

<style>
.v-dialog {
  font-weight: 300;
  align-items: center;
  bottom: 0;
  display: none;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: 8px;
  position: fixed;
  right: 0;
  top: 0;
}

.v-dialog.v-dialog-sm .v-dialog-container {
  max-width: 320px;
  padding: 0 8px;
}

.v-dialog.v-dialog-lg .v-dialog-container {
  box-shadow: none;
  max-width: 960px;
}

.v-dialog:target,
.v-dialog.active {
  display: flex;
  display: -ms-flexbox;
  opacity: 1;
  z-index: 400;
}

.v-dialog:target .v-dialog-overlay,
.v-dialog.active .v-dialog-overlay {
  background: rgba(248, 249, 250, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.v-dialog:target .v-dialog-container,
.v-dialog.active .v-dialog-container {
  animation: slide-down 0.2s ease 1;
  z-index: 1;
}

.v-dialog-container {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 10px rgba(69, 77, 93, 0.3);
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 75vh;
  max-width: 640px;
  padding: 0 16px;
  width: 100%;
  color: #50596c;
}

.v-dialog-container.v-dialog-fullheight {
  max-height: 100vh;
}

.v-dialog-container .v-dialog-header {
  color: #454d5d;
  padding: 16px;
}

.v-dialog-container .v-dialog-header .v-dialog-title {
  font-size: 110%;
  font-weight: 500;
}

.v-dialog-container .v-dialog-body {
  overflow-y: auto;
  padding: 16px;
  position: relative;
}

.v-dialog-container .v-dialog-body .v-dialog-input {
  display: block;
  width: 100%;
}

.v-dialog-container .v-dialog-body .prompt-error-message {
  color: #d9534f;
  font-size: 14px;
}

.v-dialog-container .v-dialog-footer {
  padding: 16px;
  text-align: right;
}

.v-dialog-container .v-dialog-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: #5cb85c;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  height: 36px;
  line-height: 20px;
  outline: none;
  padding: 7px 8px;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.v-dialog-container .v-dialog-btn.v-dialog-btn-danger {
  color: #d9534f;
}

.v-dialog-container .v-dialog-btn.v-dialog-btn-primary {
  color: rgb(80, 89, 108);
}

.v-dialog-container .v-dialog-btn.v-dialog-btn-close {
  height: 16px;
  line-height: 16px;
  margin-left: 8px;
  margin-right: -15px;
  padding: 0;
  width: 16px;
  float: right;
  color: #50596c;
}

.v-dialog-container .v-dialog-btn.v-dialog-btn-close::before {
  content: '\2715';
}

.v-dialog-container .v-dialog-btn.disabled,
.v-dialog-container .v-dialog-btn[disabled],
.v-dialog-container .v-dialog-btn:disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
</style>
