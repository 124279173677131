<template>
  <div>
    <img :src="image" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    image: {
      type: String
    }
  }
};
</script>
