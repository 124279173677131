<template>
  <div :class="isBusy ? 'disabled-area' : ''">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="mb-4">
      <p>{{ $t('message.uploadResumeInstructions') }}</p>
      <p>If you choose to have your resume generated, you can choose which records from your profile will appear.</p>
      <b-form-group label="Choose an option">
        <b-form-radio-group
          v-model="selectedOption"
          :options="radioOptions"
          buttons
          button-variant="outline-wfr"
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { getText, locale } from '@scripts/localized';
export default {
  name: 'ToggleResume',
  data() {
    return {
      isBusy: false,
      selectedOption: null,
      radioOptions: [
        { text: 'Generate Resume', value: 'generateResume' },
        { text: `${getText('uploadResumeBtn')}`, value: 'uploadResume' }
      ]
    };
  },
  methods: {
    async generateNewResume() {
      this.isBusy = true;
      this.$store
        .dispatch('createResumeRequest')
        .then((response) => {
          this.isBusy = false;
          this.$emit('showAltForm', response);
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    }
  },
  watch: {
    selectedOption: function (newVal) {
      if (newVal == 'uploadResume') {
        this.$emit('showForm', true);
      } else {
        this.$emit('showForm', false);
        this.generateNewResume();
      }
    }
  },
  created() {
    this.$emit('showForm', false);
  },
  props: {}
};
</script>
