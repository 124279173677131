<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="'has-text-centered ' + (isBusy ? ' disabled-area' : '')">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Reports</h1>
        </div>
        <div class="row align-items-center">
          <div class="col"></div>
          <div class="col-12 col-sm-11 col-md-10 col-lg-7">
            <div class="row">
              <div class="col-12 col-lg-7">
                <b-form-group :label="titleName">
                  <b-form-checkbox-group id="cbGrpStaffReports"
                                         v-model="reportsSelected"
                                         :options="filterOptions()"
                                         stacked>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-5 mt-3 mt-lg-0">
                <b-form-group label="Report Format:">
                  <b-form-radio-group id="rbGrpFormat" v-model="formatSelected" stacked>
                    <b-form-radio name="rbCSV" value="csv"><icon icon="file-csv" size="lg" class="mr-1"></icon>CSV</b-form-radio>
                    <b-form-radio disabled name="rbPDF" value="pdf"><icon icon="file-pdf" size="lg" class="mr-1"></icon>PDF</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-12 mt-3 mt-lg-0 text-center">
                <b-button variant="wfr"
                          @click="downloadReports"
                          :disabled="reportsSelected.length == 0 || formatSelected.length == 0">Run Report<span v-if="reportsSelected.length > 1">s</span></b-button>
              </div>
            </div>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getText, locale } from '@scripts/localized';

  export default {
    name: 'AdminReports',
    components: {},
    data() {
      return {
        isBusy: false,
        options: [
          { text: 'All Training Participants', value: '1', minRole: 250 }
        ],
        reportsSelected: [],
        formatSelected: 'csv'
      };
    },
    props: {},
    computed: {
      ...mapGetters(['activeRole']),
      titleName() {
        return 'QEA Reports';
      }
    },
    methods: {
      filterOptions() {
        return this.options;
      },
      async downloadReports() {
        for (let i = 0; i < this.reportsSelected.length; i++) {
          const report = this.options.find((report) => report.value == this.reportsSelected[i]);
          if (report) {
            await this.downloadReport(report);
          }
        }
        this.reportsSelected = [];
      },
      async downloadReport(report) {
        return await new Promise((resolve, reject) => {
          const type = report.text.replace(new RegExp(' ', 'g'), '');
          const body = {
            reportType: type,
            fileType: this.formatSelected
          };
          this.isBusy = true;
          this.$store
            .dispatch('qeaReportRequest', body)
            .then(async (response) => {
              this.isBusy = false;
              if (response && response.report) {
                const fileName = this.$timestampedReportName(getText('textID'), report.text, this.formatSelected);
                if (this.formatSelected == 'csv') await this.saveCsv(fileName, response.report);
                if (this.formatSelected == 'pdf') await this.savePdf(fileName, response.report);
                resolve(true);
              }
              resolve(false);
            })
            .catch((errors) => {
              this.isBusy = false;
              reject(errors);
            });
        });
      },
      async saveCsv(filename, data) {
        return await new Promise((resolve, reject) => {
          const blob = new Blob([data], { type: 'text/csv' });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
          } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
          }
          resolve(true);
        });
      },
      async savePdf(strFileName, strData) {
        return await new Promise((resolve, reject) => {
          const pdfData = 'data:application/pdf;base64,' + escape(strData);
          const link = document.createElement('a');
          link.href = pdfData;
          link.download = strFileName;
          link.click();
          resolve(true);
        });
      }
    }
  };
</script>
