<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <auto-complete
      v-model="selected"
      :items="items"
      :loading="isBusy"
      item-text="value"
      :placeholder="stimulus"
      disable-filtering-by-search
      @search="onSearch"
      :disabled="readOnly ? true : false"
      :value="value"
      :input-el-custom-attributes="{
        required: required,
        formelementid: id,
        autocomplete: 'new-password'
      }"
      :loadingIndicator="iconUrl"
    >
      <template slot="input-start">
        <icon class="ml-2" icon="search" size="xs"></icon>
      </template>
      <template slot="no-data">
        <span>{{ noData ? 'No matches found.' : warningMessage }}</span>
      </template>
      <template slot="item" slot-scope="{ item }">
        <span>{{ item.value }}</span>
      </template>
    </auto-complete>
  </div>
</template>
<script>
export default {
  name: 'autocomplete',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String,
      default: 'Two or more characters are required for search'
    },
    width: {
      type: Number,
      default: 12
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      selected: null,
      items: [],
      isBusy: false,
      timeoutId: null,
      noData: false,
      errorMessage: null,
      errors: '',
      currentSearchCount: 0
    };
  },
  created() {
    if (this.value) {
      const obj = JSON.parse(this.value);
      if (obj && obj.key && obj.value) {
        this.selected = obj;
        this.items = [obj];
      }
    }
  },
  methods: {
    async onSearch(search) {
      const lettersLimit = 2;
      this.noData = false;
      if (search.length < lettersLimit) {
        this.items = [];
        this.isBusy = false;
        return;
      }
      this.isBusy = true;
      this.currentSearchCount++;
      const localSearchCount = this.currentSearchCount;
      const body = { formElementId: this.id, searchString: search };

      this.$store
        .dispatch('autoCompleteSearchRequest', body)
        .then((result) => {
          this.isBusy = false;
          if (localSearchCount == this.currentSearchCount) {
            this.items = result;
            if (!this.items.length) this.noData = true;
          } 
        })
        .catch((errors) => {
          this.isBusy = false;
          if (localSearchCount == this.currentSearchCount) {
            this.selected = null;
            this.items = [];
            this.noData = true;
          }
        });
    }
  },
  watch: {
    selected: function (newVal) {
      if (newVal && newVal.key) {
        this.$emit('input', newVal.key.toString());
      } else {
        this.$emit('input', '');
      }
    }
  },
  computed: {
    iconUrl() {
      return require('../../images/loading.gif');
    }
  }
};
</script>
