<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div :class="isBusy ? 'disabled-area has-text-centered' : 'has-text-centered'">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Reports</h1>
          <p>Search by {{ $t('message.agency').toLowerCase() }} name to download reports.</p>
        </div>
        <div>
          <agencySearch @input="agencySelected"></agencySearch>
        </div>
        <div class="col-12 col-lg-7" v-if="filterOrganizationId">
          <b-form-group label="Staff Reports:">
            <b-form-checkbox-group
                id="cbGrpStaffReports"
                v-model="reportsSelected"
                :options="options"
                class="ml-5"
                stacked
              >
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <div style="min-height: 200px">
          <div v-if="filterOrganizationId" class="ml-3">
            <b-button variant="wfr" 
                  @click="downloadReports"
                  :disabled="reportsSelected.length == 0 || !formatSelected || !filterOrganizationId">
              <icon icon="file-csv" size="lg" class="mr-2"></icon>Run Report<span v-if="reportsSelected.length > 1">s</span> for
              {{ organizationEntity.value }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import agencySearch from './agency-autocomplete.vue';
import {getText } from '@scripts/localized';

export default {
  name: 'AgencyReports',
  components: {
    agencySearch
  },
  data() {
    return {
      filterOrganizationId: '',
      isBusy: false,
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      reportTypeName: 'EmployeeReport',
      organizationEntity: {},
      options: [
        { text: 'Staff Education Records', value: '1' },
        { text: 'Staff Certification/Credential Records', value: '2' },
        { text: 'Staff Training Records', value: '3' },
        { text: 'Staff Missing TN Rules and Regulations Training', value: '7' }
      ],
      reportsSelected: [],
      formatSelected: 'csv'
    };
  },
  methods: {
    agencySelected(organization) {
      if (organization && organization.key) {
        this.organizationEntity = organization;
        this.filterOrganizationId = organization.key;
      } else {
        this.organizationEntity = {};
        this.filterOrganizationId = '';
      }
    },
    async downloadReports() {
      for (let i = 0; i < this.reportsSelected.length; i++) {
        const report = this.options.find((report) => report.value == this.reportsSelected[i]);
        if (report) {
          await this.downloadReport(report);
        }
      }
      this.reportsSelected = [];
    },
    async downloadReport(report) {
      if (!this.filterOrganizationId) {
        const options = { title: 'Error' };
        this.$dialogs.alert(`Please select an ${getText('agency').toLowerCase()} from the dropdown menu`, options);
        return;
      }
      return await new Promise((resolve, reject) => {
        const type = report.text.replace(new RegExp(' ', 'g'), '').replace('/','');
        const body = {
          reportType: type,
          organizationId: this.filterOrganizationId,
          fileType: this.formatSelected
        };
        this.isBusy = true;
        this.$store
          .dispatch('organizationEmployeesReportRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            if (response && response.report) {
              const fileName = this.$timestampedReportName(
                this.organizationEntity.value,
                report.text,
                this.formatSelected
              );
              
              if (this.formatSelected == 'csv') await this.saveCsv(fileName, response.report);
              if (this.formatSelected == 'pdf') await this.savePdf(fileName, response.report);
              resolve(true);
            }
            resolve(false);
          })
          .catch((errors) => {
            this.isBusy = false;
            reject(errors);
          });
      });
    },
    saveCsv(filename, data) {
      const blob = new Blob([data], { type: 'text/csv' });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    }
  }
};
</script>
