<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <input
      type="text"
      :warningmessage="warningMessage"
      :required="required ? true : false"
      :value="selectedVal"
      :disabled="true"
      :formelementid="id"
      :placeholder="stimulus"
      :class="'form-control form-el' + (selectedVal == '' ? '' : ' has-value') + (isBusy ? ' input-loading' : '')"
      :style="returnCss()"
      autocomplete="new-password"
    />
  </div>
</template>
<script>
export default {
  name: 'entityPropertyCrossReference',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    warningMessage: {
      type: String,
      default: 'Please fill out this field'
    },
    width: {
      type: Number,
      default: 12
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object,
      required: true
    },
    selectedReference: {
      Object
    },
    formData: {
      type: Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {
      isBusy: false,
      errors: '',
      selectedVal: ''
    };
  },
  created() {
    if (this.value) {
      this.selectedVal = this.value.toString();
    }
  },
  watch: {
    selectedVal: function (val) {
      this.$emit('input', val);
    },
    selectedReference: function (val) {
      if (
        val &&
        val.formElementId &&
        val.entityTypeId &&
        this.arguments &&
        this.arguments.ecrElementId &&
        val.formElementId == this.arguments.ecrElementId &&
        this.arguments.ecrPropertyId
      ) {
        if (!val.entityId) {
          this.selectedVal = '';
          return;
        }
        this.isBusy = true;

        const body = {
          entityTypeId: val.entityTypeId,
          entityId: val.entityId,
          propertyId: this.arguments.ecrPropertyId
        };

        this.$store
          .dispatch('entityCrossReferencePropertySearchRequest', body)
          .then((result) => {
            this.isBusy = false;
            if (result) this.selectedVal = result.toString();
            else this.selectedVal = '';
          })
          .catch((errors) => {
            this.isBusy = false;
            this.selectedVal = '';
          });
      }
    }
  },
  methods: {
    returnCss() {
      const color = this.selectedVal && !this.readOnly ? 'black' : 'grey';
      return 'color:' + color + ';';
    }
  }
};
</script>
