<template>
  <footer class="footer text-center text-lg-left">
    <div class="row">
      <div class="col-md-12 col-lg">
        <img id="footer-logo" src="../../../images/logo/FINAL_TN_PAL_LOGO_WEB.png" alt="T.N. Pal Logo" />
        <p class="nav__title mt-4 mb-2">TNPAL HELP CENTER</p>
        <address>
          Monday through Saturday from 7:00 AM-9:00 PM Central 
          <br />
          <a href="tel:+18338347227" style="color: black">1-833-TDHS-CCP | 1-833-834-7227</a>
          <div class="mt-2">
            <a class="footer__btn m-auto m-lg-0" href="mailto:support@tnpal.org">Email Us</a>
          </div>
        </address>
      </div>
      <div class="col-md-4 col-lg">
        <p class="nav__title mt-4 mb-2">QUALITY CHILD CARE PARTNERS</p>
        <ul class="nav__ul">
          <li>
            <a target="_blank" v-confirm-leave href="https://www.aimhitn.org/"
              >Association of Infant Mental Health in Tennessee (AIMHiTN)</a
            >
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="https://www.tnccrr.org"
              >Tennessee Child Care Resource and Referral (CCR&R)</a
            >
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="https://tnwages.org/"
              >Child Care WAGE$ <span style="padding-left: 2px">&reg;</span> Tennessee</a
            >
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="https://www.childcaretennessee.com">ChildcareTennessee.com - Community Foundation of Middle Tennessee</a>
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="https://www.tnstate.edu/learningsciences/tsutecta.aspx"
              >Tennessee State University — Tennessee Early Childhood Training Alliance (TECTA)</a
            >
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="https://www.sworps.tennessee.edu/"
              >University of Tennessee College of Social Work Office of Research & Public Service (SWORPS)</a
            >
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-2">
        <p class="nav__title mt-4 mb-2">COMPANION RESOURCES</p>
        <ul class="nav__ul">
          <li>
            <a target="_blank" v-confirm-leave href="https://www.prosolutionstraining.com/tn/">TCCOTS</a>
          </li>
          <li>
            <a target="_blank" v-confirm-leave href="http://traintn.org/catalog">TrainTN</a>
          </li>
        </ul>

        <p class="nav__title mt-4 mb-2">TERMS & FAQ</p>
        <ul class="nav__ul">
          <li> 
            <button class="abtn btn m-0 p-0 text-md-left" @click="showModal()">TNPAL Terms and Conditions</button>
          </li>
          <li>
            <button class="abtn btn my-2 p-0 text-md-left" @click="showGuidanceModal()">
              TDHS TNPAL Guidance<icon icon="file-pdf" class="ml-2 fa-1_5x"></icon>
            </button>
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-3">
        <p class="nav__title mt-4 mb-2">PROVIDED BY</p>
        <ul class="nav__ul">
          <li>
            <a target="_blank" v-confirm-leave href="https://www.tn.gov/humanservices.html"
              ><img
                style="padding: 5px; background-color: white"
                src="../../../images/logo/TNHumanServices-Logo.png"
                alt="Tennessee Department of Human Services Logo"
            /></a>
          </li>
          <li class="mt-2 font-italic">Under Contract with:</li>
          <li>
            <div>
              <a target="_blank" v-confirm-leave href="https://sworps.org/">University of Tennessee College of Social Work Office of Research & Public Service (SWORPS)</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 text-center">
        <div style="font-size: 0.7em; color: #666; line-height: 1.5">
          &copy; {{ year }} Tennessee Department of Human Services all rights reserved. This project is funded through an agreement
          with the Tennessee Department of Human Services and and the University of Tennessee Social Work Office of Research and Public Service.
        </div>
      </div>
    </div>
    <modal
      v-show="isGuidanceModalVisible"
      @close="closeGuidanceModal"
      :isModalVisible="isGuidanceModalVisible"
      :mustViewAll="false"
      :modalBusy="isBusy"
      :skinName="'doc-viewer'">
    <h2 slot="header">TDHS TNPAL Guidance</h2>
      <div class="text-left row" slot="body">
        <div v-if="isGuidanceModalVisible" class="col">
          <docViewer :downloadButtonText="'Download TDHS TNPAL Guidance'" :fileCode="'TDHS_TNPAL_Guidance'"></docViewer>
        </div>
      </div>
    </modal>
    <modal
      v-show="isModalVisible"
      @close="closeModal"
      :isModalVisible="isModalVisible"
      :mustViewAll="false"
      :showCopyright="true"
      :modalBusy="isBusy"
    >
      <h2 slot="header">Terms and Conditions</h2>
      <div class="text-left row" slot="body">
        <div class="col">
          <h3>Acceptable Use and Linking Statement</h3>
          <p>
            The Tennessee Professional Archive of Learning (&#34;TNPAL&#34;) is maintained by the University of Tennessee Social Work Office of Research and Public Service
             (&#34;UT SWORPS&#34;) under a contract with the Tennessee Department of Human Services (&#34;TDHS&#34;).
          </p>
          <p>
            Individual state agency websites are separately maintained by the respective agency.
          </p>
          <p>
            TNPAL links to publicly available websites that are government-owned or government-sponsored websites ending in domains such as &#34;.gov, .us, .mil, and .fed.&#34; 
            TNPAL may link to quasi-government agencies and websites created by public sector&#47;private sector partnerships and some government-sponsored websites that 
            end in &#34;.com, .org, or .net.&#34;
          </p>
          <p>
            TNPAL may, at its discretion, provide links to websites of nonprofit organizations and private or commercial entities if the sites offer information or services 
            related to topics covered on the TNPAL website.
          </p>
          <p>  
            TDHS (including all TNPAL associated partner institutions and agencies) neither endorses nor guarantees in any way the external organizations, 
            services, advice, or products included in these website links. Furthermore, TDHS (including all TNPAL associated partner institutions and agencies) 
            makes no guarantee regarding the accuracy, relevance, timeliness, or completeness of the information contained in non-government website links.
          </p>
          <h3>TNPAL Privacy Statement</h3>
          <p>
            The Tennessee Department of Human Services Privacy Statement may be found on the Department's website 
            at 
            <a
              class="light-blue-link"
              target="_blank"
              v-confirm-leave
              href="https://www.tn.gov/web-policies/privacy-statement.html"
              >https://www.tn.gov/web-policies/privacy-statement.html</a
            >.
          </p>
          <h3>TNPAL Accessibility Statement</h3>
          <p>
            The Tennessee Department of Human Service Accessibility Statement may be found on the Department's website at
            <a
              class="light-blue-link"
              target="_blank"
              v-confirm-leave
              href="https://www.tn.gov/web-policies/accessibility.html"
              > https://www.tn.gov/web-policies/accessibility.html</a
            >.
          </p>
          <a
            class="light-blue-link d-inline-block"
            style="margin-top: 30px"
            @click="downloadAcceptableUse"
            title="Download Terms and Conditions"
          >
            TNPAL Terms and Conditions<icon icon="file-pdf" class="ml-1 fa-1_5x vaMiddle"></icon>
          </a>
        </div>
      </div>
    </modal>
    <span id="commithash" style="display: none">{{ githash }}</span>
  </footer>
</template>

<script>
import docViewer from '@components/utility/doc-viewer';
export default {
  data() {
    return {
      isModalVisible: false,
      isFAQModalVisible: false,
      isGuidanceModalVisible: false,
      acceptableUseText: '',
      year: new Date().getFullYear().toString(),
      isBusy: false,
      // This is injected from webpack.config.js line below tells eslint that
      /*global GIT_HASH*/ 
      githash: process.env.GIT_HASH
    };
  },
  components: {
    docViewer
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showFAQModal() {
      this.isFAQModalVisible = true;
    },
    closeFAQModal() {
      this.isFAQModalVisible = false;
    },
    showGuidanceModal() {
      this.isGuidanceModalVisible = true;
    },
    closeGuidanceModal() {
      this.isGuidanceModalVisible = false;
    },
    async downloadAcceptableUse() {
      this.$emit('busy', true);
      this.isBusy = true;
      const fileCode = 'acceptable-use';
      await this.$downloadStaticFile(fileCode);
      this.$emit('busy', false);
      this.isBusy = false;
    }
  }
};
</script>
