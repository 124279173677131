<template>
  <div>
    <div>
      <wfrform
        :key="formKey"
        :formid="1"
        v-bind:entities="this.primaryEntityArray"
        @saved="formSaved($event)"
        :saveButtonName="isFirstTime ? 'Save and Continue' : 'Save'"
        @continueButton="$emit('continueButton')"
        :showContinueButton="!isFirstTime"
        @dirty="$emit('dirty', $event)"
      ></wfrform>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">Congratulations {{ profile.firstName }}!</h2>
      <slot slot="body">
        <div class="row align-text-top">
          <!---First Column----->
          <div class="col-lg-6">
            <div class="personal-content-top">
              You have successfully created an account for {{ $t('message.textID') }}.
              <br /><br />Your Username is: {{ profile.userName }}, and your ID is: {{ profile.registryId }}.
              <br /><br />Please save this information. Your {{ $t('message.textID') }} can also be found in your profile.
              <br /><br />This information has also been emailed to you.
              <div class="complete-btn">
                <button class="btn abtn" @click="closeModal">Complete Your Profile!</button>
                <br /><br />
              </div>
            </div>
          </div>

          <!--Second Column-->
          <div class="col-lg-6">
            <div class="personal-content-bottom">
              <img src="../../images/pages/Congratulations.png" alt="Congratulations Image" style="width: 90%" />
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';

export default {
  name: 'ProfilePersonal',
  data() {
    return {
      isModalVisible: false,
      formKey: '1'
    };
  },
  mounted() {
    if (this.isFirstTime || this.displayWelcome) this.isModalVisible = true;
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    },
    displayWelcome: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['profile', 'isFirstTime']),
    primaryEntityArray: function () {
      if (this.primaryentity) return [this.primaryentity];
      return [];
    }
  },
  methods: {
    ...mapActions(['notifyRequest', 'seedRequest']),
    closeModal() {
      this.isModalVisible = false;
    },
    formSaved(data) {
      //need to store state as it will be unset during the notify request
      const firstTime = this.isFirstTime;
      this.notifyRequest(firstTime);
      this.seedRequest();
      if (firstTime) {
        this.$router.push({ name: 'profile-employment' });
      } else {
        this.formKey += '-1';
      }
    }
  }
};
</script>
