<template>
  <div class="form-group col-md-6">
    <auto-complete v-model="selected"
                   :items="items"
                   :loading="isBusy"
                   item-text="value"
                   placeholder="Search for a County"
                   disable-filtering-by-search
                   @search="onSearch"
                   :value="value"
                   :input-el-custom-attributes="{ autocomplete: 'new-password' }"
                   :loadingIndicator="iconUrl"
                   :ref="'search'"
                   :class="selected ? 'mt-county' : ''"
                   >
      <template slot="input-start">
        <icon class="ml-2" icon="search" size="xs"></icon>
      </template>
      <template slot="no-data">
        <span>{{ noData ? 'No matches found.' : warningMessage }}</span>
      </template>
      <template slot="item" slot-scope="{ item }">
        <span>{{ item.value }}</span>
      </template>
    </auto-complete>
  </div>
</template>
<style>
  @media only screen and (min-width: 768px) {
    .mt-county {
      margin-top: 34px;
    }
  }
</style>
<script>
export default {
  name: 'CountyAutocomplete',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: null,
      items: [],
      isBusy: false,
      timeoutId: null,
      noData: false,
      errorMessage: null,
      errors: '',
      warningMessage: 'Two or more characters are required for search'
    };
  },
  created() {
    if (this.value) {
      const obj = JSON.parse(this.value);
      if (obj && obj.key && obj.value) {
        this.selected = obj;
        this.items = [obj];
      }
    }
  },
  methods: {
    async onSearch(search) {
      const lettersLimit = 2;
      this.noData = false;
      if (search.length < lettersLimit) {
        this.items = [];
        this.isBusy = false;
        return;
      }
      this.isBusy = true;
      const body = { searchString: search };

      this.$store
        .dispatch('countySearchRequest', body)
        .then((result) => {
          this.isBusy = false;
          this.items = result;
          if (!this.items.length) this.noData = true;
        })
        .catch((errors) => {
          this.isBusy = false;
          this.selected = null;
          this.items = [];
          this.noData = true;
        });
    },
    focusOnInput() {
      if (this.$refs.search && this.$refs.search.$refs) {
        let focused = false;
        for (const ref in this.$refs.search.$refs) {
          const childRef = this.$refs.search.$refs[ref];
          if (childRef && childRef.focus && !focused) {
            childRef.focus();
            focused = true;
          }
        }
      }
    }
  },
  watch: {
    selected: function (newVal) {
      if (newVal && newVal.key) {
        this.$emit('input', newVal);
      } else {
        this.$emit('input', {});
      }
    }
  },
  computed: {
    iconUrl() {
      return require('../../../images/loading.gif');
    }
  }
};
</script>
