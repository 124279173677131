<template>
  <div class="w-100" style="text-align: center">
    <div style="margin: auto; display: block" class="mt-4">
      <b-form-checkbox size="md" v-model="model" ref="inputbox" id="check">
        {{ checkboxLabel }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [Boolean, String],
    required: Boolean,
    checkboxLabel: String
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods: {
    isValid() {
      return true;
    }
  },
  mounted() {
    this.$parent.$parent.valid = true;
  }
};
</script>
