<template>
  <section class="container-fluid">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-3 text-center">
          <h1 class="title has-text-grey">Requests</h1>
          <p id="gridInstructions">
            Click to view and approve <span v-if="!admin && !hasStaffRole">EC Professional/Staff</span> requests for association to the
            {{ $t('message.facility').toLowerCase() }}.
            <span class="sr-only">
              Only once you have selected one or more of the rows of the table using enter or the space bar, will the accept and reject selected buttons appear.
              They will be located after the agency dropdown and before the table.
              You may also use the select and clear all buttons that are located after the table.
            </span>
          </p>
        </div>
        <div>
          <div class="mb-2" style="min-height: 35px;float:left;">
            <b-dropdown :text="filterTitle"
                        variant="wfr"
                        class="mr-2"
                        v-if="!admin && !hasStaffRole"
                        :disabled="(activeRole.organizations && activeRole.organizations.length > 1) || !filterOrganizationId ? false : true ">
              <b-dropdown-item-button v-if="filterOrganizationId" @click="setFilterOrganization(null)">
                Clear Filter
              </b-dropdown-item-button>
              <b-dropdown-item-button v-for="organization in activeRole.organizations"
                                      :key="organization.organizationId"
                                      :active="filterOrganizationId == organization.organizationId"
                                      @click="setFilterOrganization(organization)">
                {{ organization.organizationName }}
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div class="has-text-centered">
            <grid :entityTypeId="entityTypeId"
                  :entityTypeName="entityTypeName"
                  :primaryEntity="primaryentity"
                  :altGridId="altGridId"
                  :formId="0"
                  :key="gridKey"
                  :showFormOnEmpty="false"
                  :showAddButton="false"
                  :showDisable="false"
                  :showEdit="false"
                  :showView="false"
                  :multiRowSelect="multiRowSelect"
                  :allowSelectAll="allowSelectAll"
                  :allowClearSelection="allowClearSelection"
                  ref="actionGrid"
                  :additionalActionsOnColumn="true"
                  :additionalActions="additionalActions"
                  :tableActions="tableActions"
                  @tableAction="tableAction"
                  :defaultFilters="gridDefaultFilters"
                  :basePageSize="gridPageSize"
                  :baseCurrentPage="gridCurrentPage"
                  @setPageSize="setPageSize"
                  @setCurrentPage="setCurrentPage"
                >
            </grid>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex';
  import RequestPrompt from '../../utility/dialog/request-prompt';
  import RequestPromptRequired from '../../utility/dialog/request-prompt-required';
  import { getText, locale } from '@scripts/localized';

  export default {
    name: 'OrganizationRequests',
    data() {
      return {
        filterOrganizationId: '',
        isBusy: false,
        locale: locale,
        entityTypeId: 19,
        entityTypeName: "RolePromotionAction",
        altGridId: 1,
        isDirty: false,
        isFiltered: false,
        multiRowSelect: true,
        allowSelectAll: true,
        allowClearSelection: true,
        gridDefaultFilters: [],
        searchDefaultFilters: [
          {
            operation: 'Contains',
            filterValue: '',
            filterColumn: { propertyName: 'organizationId' }
          }
        ],
        gridKey: 'grid',
        tableActions: [
          {
            status: 'Approved',
            verb: 'approve',
            button: {
              classes: 'btn mr-2 tnpalBGGreen px-2',
              title: 'Approve Selected',
              click: 'tableAction("approve")',
              icon: 'check',
            },
          },
          {
            status: 'Rejected',
            verb: 'reject',
            button: {
              classes: 'btn tnpalBGRed px-2',
              title: 'Reject Selected',
              click: 'tableAction("reject")',
              icon: 'ban'
            },
          }
        ],
        additionalActions: [
          {
            title: 'Approve',
            icon: 'check',
            click: this.approveRequest, 
            check: () => { return true; }
          },
          {
            title: 'Reject',
            icon: 'ban',
            click: this.rejectRequest,
            check: () => { return true; }
          },
        ],
        gridPageSize: 5,
        gridCurrentPage: 1
      };
    },
    props: {
      admin: {
        type: Boolean,
        default: false
      },
      primaryentity: {
        type: Object,
        default: () => { }
      }
    },
    computed: {
      ...mapGetters(['activeRole']),
      hasStaffRole() {
        return this.activeRole && this.activeRole.role && (this.activeRole.role.roleName == '350' || this.activeRole.role.roleName == '375'); //Only ALP has this feature for 350 at present
      },
      filterTitle: function () {
        if (this.activeRole && this.activeRole.organizations && this.filterOrganizationId) {
          const filterOrg = this.activeRole.organizations.find((org) => org.organizationId == this.filterOrganizationId);
          if (filterOrg && filterOrg.organizationName)
            return filterOrg.organizationName;
        }
        return `${getText('facility')} Filter`;
      },
    },
    created() {
      //if we only have one org, set it as the active value in the dropdown, which will be disabled (:disabled)
      if (
        this.activeRole &&
        this.activeRole.organizations &&
        this.activeRole.organizations.length == 1 &&
        this.activeRole.organizations[0] &&
        this.activeRole.organizations[0].organizationId
      ) {
        this.filterOrganizationId = this.activeRole.organizations[0].organizationId;
      }
    },
    methods: {
      setPageSize(size) {
        this.gridPageSize = parseInt(size);
      },
      setCurrentPage(page) {
        this.gridCurrentPage = parseInt(page);
      },
      setFilterOrganization(organization) {
        if (organization && organization.organizationName) {
          this.filterOrganizationId = organization.organizationId;
          this.isFiltered = true;
          this.searchDefaultFilters[0].filterValue = organization.organizationId
          this.gridDefaultFilters = this.searchDefaultFilters;
          this.gridKey = this.filterOrganizationId;
          this.gridCurrentPage = 1;
        }
        else {
          this.filterOrganizationId = '';
          this.isFiltered = false;
          this.gridDefaultFilters = [];
          this.searchDefaultFilters[0].filterValue = '';
          this.gridKey = 'grid';
          this.gridCurrentPage = 1;
        }
      },
      shouldShowAdditionalActions(item) {
        return this.shouldShowActions(item);
      },
      shouldShowActions(item) {
        return !item.has200Role;
      },
      async approveRequest(item) {
        this.performAction('approve', item);
      },
      async rejectRequest(item) {
        this.performAction('reject', item);
      },
      async tableAction(eventVal, items) {
        if (eventVal) {
          this.performBulkAction(items, eventVal);
        }
      },
      async performAction(action, item) {
        return await new Promise((resolve, reject) => {
          const promptComponent = action == 'approve' ? RequestPrompt : RequestPromptRequired;
          let promptAction = action;
          const part1 = 'Are you sure you want to ' + promptAction + ' the selected request?';
          const addition = action == 'approve' ? 'n optional' : '';
          const finalPart = 'If so, please enter a' + addition + ' comment and click Continue.';
          const promptText = part1 + finalPart;
          promptAction = promptAction.charAt(0).toUpperCase() + promptAction.slice(1);
          const promptTitle = promptAction + ' Request';
          promptAction = promptAction == 'Approve' ? 'Approved' : 'Rejected';
          const options = {
            title: promptTitle,
            okLabel: 'Continue',
            cancelLabel: 'Cancel',
            prompt: { invalidMessage: '', component: promptComponent }
          };
          this.$dialogs
            .prompt(promptText, options)
            .then((res) => {
              //if we clicked Continue
              if (res && res.ok && res.ok == true) {
                const body = {
                  rolePromotionActionIds: [item.id],
                  action: promptAction,
                  comments: res.value
                };
                this.isBusy = true;
                this.$store
                  .dispatch('requestAction', body)
                  .then(async (response) => {
                    this.isBusy = false;
                    this.gridKey += '-1'
                    resolve(true);
                    //Need to update dropdown menu after each transaction in case an org is no longer in grid?
                    //NO! Prod doesn't (yet) alter the dropdown based on the grid contents.
                  })
                  .catch((errors) => {
                    this.isBusy = false;
                    reject(errors);
                  });
              } else { //we clicked Cancel
                resolve(false);
              }
              //we closed the window
              resolve(false);
            })
            .catch((error) => reject(error));
        });
      },
      async performBulkAction(ids, action) {
        return await new Promise((resolve, reject) => {
          const promptComponent = action == 'approve' ? RequestPrompt : RequestPromptRequired;
          let promptAction = action;
          const part1 = 'Are you sure you want to ' + promptAction + ' the selected requests?';
          const addition = action == 'approve' ? 'n optional' : ' required';
          const finalPart = ' If so, please enter a' + addition + ' comment and click Continue.';
          const promptText = part1 + finalPart;
          promptAction = promptAction.charAt(0).toUpperCase() + promptAction.slice(1);
          const promptTitle = promptAction + ' Requests';
          promptAction = promptAction == 'Approve' ? 'Approved' : 'Rejected';
          const options = {
            title: promptTitle,
            okLabel: 'Continue',
            cancelLabel: 'Cancel',
            prompt: { invalidMessage: '', component: promptComponent }
          };
          this.$dialogs
            .prompt(promptText, options)
            .then((res) => {
              //if we clicked Continue
              if (res && res.ok && res.ok == true) {
                const body = {
                  rolePromotionActionIds: ids,
                  action: promptAction,
                  comments: res.value
                };
                this.isBusy = true;
                this.$store
                  .dispatch('requestAction', body)
                  .then(async (response) => {
                    this.isBusy = false;
                    this.gridKey += '-1';
                    resolve(true);
                    //Need to update dropdown menu after each transaction in case an org is no longer in grid?
                    //NO! Prod doesn't (yet) alter the dropdown based on the grid contents.
                  })
                  .catch((errors) => {
                    this.isBusy = false;
                    reject(errors);
                  });
              } else { //we clicked Cancel
                resolve(false);
              }
              //we closed the window
              resolve(false);
            })
            .catch((error) => reject(error));
        });
      },
    }
  };
</script>
