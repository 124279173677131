<template>
  <div :class="isBusy ? 'disabled-area' : ''">

    <div class="text-left" style="color: #333">
      <p class="text-left" style="color: #333">Current Email Address: <b>{{ user.userName }}</b></p>
      <div class="form-row">
        <label class="col-md-4">Please enter the new email address: </label>
        <input class="col-md-8 input form-control" type="email" placeholder="myname@example.com" v-model="newEmail" />
      </div>
      <p />
      <p class="text-left" style="color: #333">Current Secondary Email Address: <b>{{ currentSecondaryEmail ? currentSecondaryEmail : 'None' }}</b></p>
      <div class="form-row">
        <label class="col-md-4">Please enter the new secondary email address: </label>
        <input class="col-md-8 input form-control" type="email" placeholder="myname@example.com" v-model="newSecondaryEmail" />
      </div>
      <p />
      <button aria-label="Cancel" class="btn v-dialog-btn-close mr-3" style="border-color: #999" @click="closeModal">Cancel</button>
      <button aria-label="Continue" class="btn btn-primary" @click="launchDialog">Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeEmail',
  props: {
    user: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      isBusy: false,
      isDirty: false,
      newEmail: '',
      currentEmail: '',
      newSecondaryEmail: '',
      currentSecondaryEmail: '',
      userName: '',
    };
  },
  watch: {
    isBusy: function (newValue, oldValue) {
      this.$emit('busy', newValue);
    },
  },
  created() {
    if (this.user) {
      this.userName = this.user.fullName;
      this.currentEmail = this.user.userName;
      this.isBusy = true;
      this.$store
        .dispatch('secondaryEmailRequest', this.user.id)
        .then(async (response) => {
          this.currentSecondaryEmail = response;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    }
  },
  methods: {
    closeModal() {
      if (this.isDirty) {
        this.$parent.$parent.$parent.gridKey += '-1';
        this.isDirty = false;
      }
      this.$parent.$parent.$emit('close');
      
    },
    async launchDialog() {
      this.isBusy = true;
      if ((this.newEmail.trim() === '' || this.newEmail.trim() === this.currentEmail) && (this.newSecondaryEmail.trim() === '' || this.newSecondaryEmail.trim() === this.currentSecondaryEmail)) {
        const alertOptions = { title:'No changes made.' };
        this.$dialogs.alert(`Changes to email address will not be submitted because no changes have been made.`, alertOptions);
        this.isBusy = false;
        return;
      }
      const emailRe= /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/;
      if (this.newEmail.trim() != '' && !this.newEmail.trim().match(emailRe)) {
        const alertOptions = { title: 'Invalid primary email address.' };
        this.$dialogs.alert(`New primary email addresss ${this.newEmail.trim()} is not a valid email address.`, alertOptions);
        this.isBusy = false;
        return;       
      }
      if (this.newSecondaryEmail.trim() != '' && !this.newSecondaryEmail.trim().match(emailRe)) {
        const alertOptions = { title: 'Invalid secondary email address.' };
        this.$dialogs.alert(`New secondary email addresss ${this.newSecondaryEmail.trim()} is not a valid email address.`, alertOptions);
        this.isBusy = false;
        return;       
      }
      return await new Promise((resolve, reject) => {
        // build confirm message
        let regText = '';
        if (this.newEmail.trim() !== '' && this.newSecondaryEmail.trim() !== '') {
          regText = `Are you sure to change ${this.userName}'s primary email address from ${this.currentEmail} to ${this.newEmail} and secondary email address from ${this.currentSecondaryEmail ? this.currentSecondaryEmail : 'None'} to ${this.newSecondaryEmail}?`;
        } else if (this.newEmail.trim() !== '') {
          regText = `Are you sure to change ${this.userName}'s primary email address from ${this.currentEmail} to ${this.newEmail}?`;
        } else if (this.newSecondaryEmail.trim() !== '') {
          regText = `Are you sure to change ${this.userName}'s secondary email address from ${this.currentSecondaryEmail ? this.currentSecondaryEmail : 'None'} to ${this.newSecondaryEmail}?`;
        }
        regText += ' If so, please click Confirm. User will be notified of this change.';
        const regOptions = {
          title: 'Confirm Email Change',
          okLabel: 'Confirm',
          cancelLabel: 'Cancel',
        };
        this.$dialogs
          .confirm(regText, regOptions)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              this.isDirty = true;
              return new Promise((resolve, reject) => {
                const body = {
                  email: this.newEmail.trim(),
                  secondaryEmail: this.newSecondaryEmail.trim(),
                  userName: this.currentEmail.trim(),
                  fullName: this.userName.trim(),
                };
                this.$store
                  .dispatch('emailChangeRequest', body)
                  .then(async (response) => {
                    if (response && response.error) {
                      const alertOptions = { title:'Email Change Failed!' };
                      this.$dialogs.alert(response.error, alertOptions);                    
                    } else {
                      const alertOptions = { title:'Email Address Change is Complete!' };
                      let repText = '';
                      if (this.newEmail.trim() !== '' && this.newSecondaryEmail.trim() !== '') {
                        repText = `${this.userName}'s primary email address has been changed from ${this.currentEmail} to ${this.newEmail} and secondary email address from ${this.currentSecondaryEmail ? this.currentSecondaryEmail : 'None' } to ${this.newSecondaryEmail}.`;
                      } else if (this.newEmail.trim() !== '') {
                        repText = `${this.userName}'s primary email address has been changed from ${this.currentEmail} to ${this.newEmail}.`;
                      } else if (this.newSecondaryEmail.trim() !== '') {
                        repText = `${this.userName}'s secondary email address has been changed from ${this.currentSecondaryEmail ? this.currentSecondaryEmail : 'None' } to ${this.newSecondaryEmail}.`;
                      }
                      repText += ' User will be notified.';
                      this.$dialogs.alert(repText, alertOptions);
                      this.isBusy = false;
                      this.closeModal();
                    } 
                    resolve(true);
                  })
                  .catch((errors) => {
                    resolve(false);
                  });
                this.isBusy = false;
             });
            }
         })
        .catch((error) => {
          resolve(false);
        });
        this.isBusy = false;
      });
    },
  }
};
</script>
