<template>
  <div>
    <input ref="inputbox" type="text" v-model="model" class="form-control mt-4" />
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String],
    required: Boolean
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods: {
    isValid() {
      return Boolean(String(this.model).trim());
    }
  },
  mounted() {
    if (!this.required) this.$parent.$parent.valid = true;
  }
};
</script>
