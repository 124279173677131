<template>
  <focus-trap :active="form" :escapeDeactivates="false" :initialFocus="() => getInitialFocus()">
    <b-form class="form-horizontal well" v-show="form">
      <div class="form-group" v-if="showTitle">
        <div class="col-sm-8 col-sm-offset-2">
          <h2 style="font-size: 2rem">Compose Message</h2>
        </div>
      </div>
      <div class="form-group" v-if="bulkUserMode">
        <div class="col-sm-8">To: <b>{{ toUserString }}</b></div>
      </div>
      <div v-else>
        <label for="recipient" class="col-sm-2 control-label">To</label>
        <div>
          <userSearch
            :limitText="limitText"
            :userSearch="userSearch"
            :users="users"
            :isLoading="isLoading"
            :twoCharacterMessage="twoCharacterMessage"
            :emptySetMessage="emptySetMessage"
            :noResultMessage="noResultMessage"
            :usersSelected="usersSelected"
            :defaultSelectedUsers="defaultSelectedUsers"
          >
          </userSearch>
        </div>
      </div>
      <div class="form-group">
        <label for="subject" class="col-sm-2 control-label">Subject</label>
        <div class="col-md-8">
          <b-form-input
            type="text"
            class="form-control message-subject"
            id="subject"
            v-model="composeEmail.subject"
            name="subject"
          ></b-form-input>
        </div>
      </div>
      <div class="form-group">
        <label for="body" class="col-sm-2 control-label">Body</label>
        <div class="col-md-8">
          <quill-editor
            name="body"
            id="body"
            ref="messageBody"
            v-model="content"
            :options="editorOption"
            @change="onEditorChange($event)"
            class="wfr-quill"
          ></quill-editor>
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-8 col-sm-offset-2">
          <b-button @click="compose" variant="primary" id="send">Send</b-button>
          <b-button @click="exitForm" variant="primary" id="cancel" class="ml-3">Cancel</b-button>
        </div>
      </div>
    </b-form>
  </focus-trap>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import userSearch from '../messaging/message-user-search.vue';
import { FocusTrap } from 'focus-trap-vue';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'MessageCompose',
  data() {
    return {
      content: this.composeEmail.message,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link']
          ]
        },
        placeholder: 'Type here...',
        theme: 'snow'
      }
    };
  },
  components: {
    FocusTrap: FocusTrap,
    userSearch,
    quillEditor
  },
  methods: {
    onEditorChange(editor) {
      this.$emit('input', editor.html);
      this.composeEmail.message = this.content;
    },
    getInitialFocus() {
      let element;
      if (
        this.$parent.form == true &&
        this.$children &&
        this.$children[0] &&
        this.$children[0].$children &&
        this.$children[0].$children[0] &&
        this.$children[0].$children[0].$refs &&
        this.$children[0].$children[0].$refs.recipient &&
        this.$children[0].$children[0].$refs.recipient.$el
      ) {
        element = this.$children[0].$children[0].$refs.recipient.$el;
      } else if (
        this.replyForm &&
        this.$refs.messageBody &&
        this.$refs.messageBody.$el.children &&
        this.$refs.messageBody.$el.children[1] &&
        this.$refs.messageBody.$el.children[1].firstChild
      ) {
        element = this.$refs.messageBody.$el.children[1].firstChild;
      } else element = this.$refs.messageBody.$el;
      return element;
    }
  },
  props: [
    'form',
    'inputForm',
    'exitForm',
    'composeEmail',
    'compose',
    'users',
    'isLoading',
    'twoCharacterMessage',
    'emptySetMessage',
    'noResultMessage',
    'limitText',
    'userSearch',
    'usersSelected',
    'defaultSelectedUsers',
    'replyForm',
    'showTitle',
    'bulkUserMode',
    'toUserString'
  ]
};
</script>
<style>
.wfr-quill {
  min-height: 200px;
}
.ql-container {
  min-height: 200px;
}
</style>
