<template>
  <div :class="isBusy ? 'disabled-area ' : 'w-100'">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="w-100">
      <div role="tablist">
        <div v-for="(ent, entIndex) in entityTypeInfo" :key="entIndex">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle="'accordion_' + entIndex"
              @click="setExpanded(entIndex)"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="expanded == entIndex ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">{{ ent.entityTypeName }}</span>
              </span>
            </b-card-header>
            <b-collapse
              :id="'accordion_' + entIndex"
              accordion="access-accordion"
              role="tabpanel"
              :visible="entIndex == 0 ? true : false"
            >
              <b-card-body>
                <div v-if="!ent.children">
                  <div v-if="expanded == entIndex">
                    <grid
                      :entityTypeId="ent.entityTypeId"
                      :formId="ent.formId"
                      :entityTypeName="ent.entityTypeName"
                      :primaryEntity="primaryEntity"
                      :showAddButton="false"
                      :showFormOnEmpty="false"
                      :showEdit="false"
                      :showDisable="!readOnly"
                      :additionalActions="ent.additionalActions"
                      :withinModal="withinModal"
                      :additionalActionsOnColumn="true"
                      :additionalShowColumns="ent.additionalColumns"
                      :key="ent.gridKey"
                      :basePageSize="ent.gridPageSize"
                      :baseCurrentPage="ent.gridCurrentPage"
                      @setPageSize="ent.setPageSize"
                      @setCurrentPage="ent.setCurrentPage"
                    ></grid>
                  </div>
                </div>
                <div v-else role="tablist">
                  <div v-for="(childEnt, childEntIndex) in ent.children" :key="entIndex + '_' + childEntIndex">
                    <b-card no-body class="mb-1">
                      <b-card-header
                        header-tag="header"
                        class="p-1 mouse-pointer"
                        role="tab"
                        v-b-toggle="'accordion_' + entIndex + '_' + childEntIndex"
                        @click="setChildExpanded(childEntIndex)"
                      >
                        <span style="font-size: 1rem">
                          <button class="btn accordion-caret p-0">
                            <icon
                              :icon="expandedChild == childEntIndex ? 'caret-down' : 'caret-right'"
                              class="ml-2"
                              style="color: dimgray"
                            ></icon>
                          </button>
                          <span class="ml-4">{{ childEnt.entityTypeName }}</span>
                        </span>
                      </b-card-header>
                      <b-collapse
                        :id="'accordion_' + entIndex + '_' + childEntIndex"
                        accordion="access-accordion2"
                        role="tabpanel"
                        :visible="childEntIndex == 0 ? true : false"
                      >
                        <b-card-body>
                          <div v-if="expanded == entIndex && expandedChild == childEntIndex">
                            <grid
                              :entityTypeId="childEnt.entityTypeId"
                              :formId="childEnt.formId"
                              :entityTypeName="childEnt.entityTypeName"
                              :primaryEntity="primaryEntity"
                              :showAddButton="false"
                              :showFormOnEmpty="false"
                              :showEdit="false"
                              :showDisable="!readOnly"
                              :additionalActions="childEnt.additionalActions"
                              :withinModal="withinModal"
                              :additionalActionsOnColumn="true"
                              :showComments="childEnt.showComments"
                              :canAddComments="childEnt.addComments ? childEnt.addComments : undefined"
                              :ref="childEnt.refName"
                              :key="childEnt.gridKey"
                              :basePageSize="childEnt.gridPageSize"
                              :baseCurrentPage="childEnt.gridCurrentPage"
                              @setPageSize="childEnt.setPageSize"
                              @setCurrentPage="childEnt.setCurrentPage"
                            ></grid>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div :key="entityTypeInfo.length" v-if="!has300Role()">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle="'accordion_' + entityTypeInfo.length"
              @click="setExpanded(entityTypeInfo.length)"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="expanded == entityTypeInfo.length ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">Attestations</span>
              </span>
            </b-card-header>
            <b-collapse
              :id="'accordion_' + entityTypeInfo.length"
              accordion="access-accordion"
              role="tabpanel"
              :visible="false"
            >
              <b-card-body>
                <div>
                  <div v-if="expanded == entityTypeInfo.length">
                    <AttestationList :primaryEntity="primaryEntity" :parentEntIndex="entityTypeInfo.length"></AttestationList>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="this.isModalVisible" :withinModal="withinModal">
      <h2 slot="header">Reflection</h2>
      <wfrform
        slot="body"
        v-bind:formid="commentFormId"
        v-bind:entities="this.reflectionEntity"
        @saved="formSaved($event)"
        :key="this.reflectionParentEntity.Id"
        :primaryEntity="this.reflectionParentEntity"
        @dirty="formDirty($event)"
        :showEditButton="false"
        :startinviewmode="reflectionViewMode"
      >
      </wfrform>
    </modal>
    <modal 
      v-show="isEndorsementModalVisible" 
      @close="closeEndorsementModal" 
      :isModalVisible="this.isEndorsementModalVisible" 
      :withinModal="withinModal">
      <h2 slot="header">Endorsement Details</h2>
      <div slot="body">
         <EndorsementDetail :id="endorsementId" :key="endorsementId" v-if="endorsementId !== ''" />
      </div>
    </modal>
  </div>
</template>

<script>
import RequestPrompt from '../../utility/dialog/request-prompt';
import AttestationList from './attestation-list';
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';
import EndorsementDetail  from '@components/dashboard-items/endorsement-detail';

export default {
  name: 'UserRecordsView',
  components: {
    AttestationList,
    EndorsementDetail
  },
  computed: {
    ...mapGetters(['activeRole', 'isAuthenticated'])
  },
  data() {
    return {
      isBusy: false,
      isDirty: false,
      isModalVisible: false,
      isEndorsementModalVisible: false,
      endorsementEntityTypeId: 7,
      endorsementId: '',
      locale: locale,
      expanded: 0,
      expandedChild: 0,
      userentitytypeid: 1,
      primaryEntity: {},
      commentEntityTypeId: 16,
      commentFormId: 15,
      entityTypeInfo: [
        {
          entityTypeName: 'Education',
          entityTypeId: 4,
          formId: 2,
          additionalActions: [
            {
              title: 'Verify',
              icon: 'user-shield',
              click: this.setEducationVerification,
              check: this.isNotVerified
            },
            {
              title: 'Remove Verification',
              icon: 'user-times',
              click: this.setEducationVerification,
              check: this.isVerified
            }
          ],
          additionalColumns: [],
          gridKey: 'edu',
          gridPageSize: this.eduGridPageSize,
          gridCurrentPage: this.eduGridCurrentPage,
          setPageSize: this.setEduPageSize,
          setCurrentPage: this.setEduCurrentPage
        },
        {
          entityTypeName: 'Certification/Credential',
          entityTypeId: 7,
          formId: 5,
          additionalActions: [
            {
              title: 'View',
              icon: 'eye',
              click: this.showEndorsement,
              check: this.shouldShowEndorsement
            },
            {
              title: 'Disable Endorsement',
              icon: 'ban',
              click: this.disableEndorsement,
              check: this.showDisableEndorsement
            },
            {
              title: 'Enable Endorsement',
              icon: 'check',
              click: this.enableEndorsement,
              check: this.showEnableEndorsement
            },
            {
              title: 'Verify',
              icon: 'user-shield',
              click: this.setCertificationVerification,
              check: this.isNotVerifiedCert
            },
            {
              title: 'Remove Verification',
              icon: 'user-times',
              click: this.setCertificationVerification,
              check: this.isVerifiedCert
            }
          ],
          additionalColumns: [],
          gridKey: 'cert',
          gridPageSize: this.certGridPageSize,
          gridCurrentPage: this.certGridCurrentPage,
          setPageSize: this.setCertPageSize,
          setCurrentPage: this.setCertCurrentPage
        },
        {
          entityTypeName: 'Professional Development Plan',
          children: [
            {
              entityTypeName: 'PDP - Required Training',
              refName: 'requiredTrainingGrid',
              entityTypeId: 11,
              formId: 10,
              additionalActions: [
                {
                  title: 'Verify',
                  icon: 'user-shield',
                  click: this.setRequiredTrainingVerification,
                  check: this.canPdpVerify
                },
                {
                  title: 'Remove Verification',
                  icon: 'user-times',
                  click: this.setRequiredTrainingVerification,
                  check: this.canPdpUnverify
                }
              ],
              showComments: true,
              addComments: this.canAddComments,
              gridKey: 'pdptrain',
              gridPageSize: this.reqTrainGridPageSize,
              gridCurrentPage: this.reqTrainGridCurrentPage,
              setPageSize: this.setReqTrainPageSize,
              setCurrentPage: this.setReqTrainCurrentPage
            },
            {
              entityTypeName: 'PDP - Goals',
              refName: 'goalsGrid',
              entityTypeId: 12,
              formId: 11,
              additionalActions: [
                {
                  title: 'Verify',
                  icon: 'user-shield',
                  click: this.setGoalsVerification,
                  check: this.canPdpVerify
                },
                {
                  title: 'Remove Verification',
                  icon: 'user-times',
                  click: this.setGoalsVerification,
                  check: this.canPdpUnverify
                },
                {
                  title: 'Add/View Reflection',
                  icon: 'portrait',
                  click: this.showGoalsReflection,
                  check: this.canPdpAddReflection
                }
              ],
              showComments: true,
              addComments: this.canAddComments,
              gridKey: 'pdpGoals',
              gridPageSize: this.goalsGridPageSize,
              gridCurrentPage: this.goalsGridCurrentPage,
              setPageSize: this.setGoalsPageSize,
              setCurrentPage: this.setGoalsCurrentPage
            },
            {
              entityTypeName: 'PDP - Self Assessment',
              refName: 'selfAssessmentGrid',
              entityTypeId: 13,
              formId: 12,
              additionalActions: [],
              showComments: false,
              gridKey: 'pdpAssess',
              gridPageSize: this.saGridPageSize,
              gridCurrentPage: this.saGridCurrentPage,
              setPageSize: this.setSAPageSize,
              setCurrentPage: this.setSACurrentPage
            }
          ]
        },
        {
          entityTypeName: 'Experience',
          entityTypeId: 8,
          formId: 6,
          additionalActions: [
            {
              title: 'Verify or Reject Start Date',
              icon: 'user-shield',
              click: this.setFirstExperienceVerification,
              check: this.canExperienceVerify
            },
            {
              title: 'Verify Start Date',
              icon: 'user-shield',
              click: this.setExperienceVerification,
              check: this.canExperienceReverify
            },
            {
              title: 'Remove Start Date Verification',
              icon: 'user-times',
              click: this.setExperienceVerification,
              check: this.canExperienceUnverify
            }
          ],
          additionalColumns: ['startDate'],
          gridKey: 'exp',
          gridPageSize: this.expGridPageSize,
          gridCurrentPage: this.expGridCurrentPage,
          setPageSize: this.setExpPageSize,
          setCurrentPage: this.setExpCurrentPage
        },
        {
          entityTypeName: 'Training',
          entityTypeId: 3,
          formId: 7,
          additionalActions: [
            {
              title: 'Verify',
              icon: 'user-shield',
              click: this.setTrainingVerification,
              check: this.canTrainingVerify
            },
            {
              title: 'Remove Verification',
              icon: 'user-times',
              click: this.setTrainingVerification,
              check: this.canTrainingUnverify
            }
          ],
          additionalColumns: [],
          gridKey: 'train',
          gridPageSize: this.trainGridPageSize,
          gridCurrentPage: this.trainGridCurrentPage,
          setPageSize: this.setTrainPageSize,
          setCurrentPage: this.setTrainCurrentPage
        }
      ],
      reflectionViewMode: false,
      eduGridPageSize: 5,
      eduGridCurrentPage: 1,
      certGridPageSize: 5,
      certGridCurrentPage: 1,
      reqTrainGridPageSize: 5,
      reqTrainGridCurrentPage: 1,
      goalsGridPageSize: 5,
      goalsGridCurrentPage: 1,
      saGridPageSize: 5,
      saGridCurrentPage: 1,
      expGridPageSize: 5,
      expGridCurrentPage: 1,
      trainGridPageSize: 5,
      trainGridCurrentPage: 1
    };
  },
  created() {
    if (['al'].includes(this.locale)) {
      this.entityTypeInfo.splice(2, 1);
      if (this.verificationOnly && this.has200Role()) {
        this.entityTypeInfo.splice(0, 3);
      }
    }
    if (['tn'].includes(this.locale)) {
      if (this.verificationOnly && this.has200Role()) {
        this.entityTypeInfo.splice(4, 1);
        this.entityTypeInfo.splice(2, 1);
      }
    }
    if (this.user && this.user.id) {
      this.primaryEntity = {
        Id: this.user.id,
        EntityTypeId: this.userentitytypeid
      };
      this.reflectionParentEntity = {
        Id: '',
        EntityTypeId: 0
      };
      this.reflectionEntity = [
        {
          Id: '',
          EntityTypeId: this.commentEntityTypeId
        }
      ];

      if (this.hasElevatedRole()) {
        this.entityTypeInfo.push(
          {
            entityTypeName: 'Community Service',
            entityTypeId: 5,
            formId: 3,
            additionalActions: [],
            additionalColumns: []
          },
          {
            entityTypeName: 'Membership',
            entityTypeId: 6,
            formId: 4,
            additionalActions: [],
            additionalColumns: []
          }
        );
        if (['al'].includes(this.locale)) {
          this.entityTypeInfo = this.entityTypeInfo.filter((item) => item.entityTypeName != 'Community Service');
        }
      }
    }
  },

  methods: {
    setEduPageSize(size) {
      this.eduGridPageSize = parseInt(size);
    },
    setEduCurrentPage(page) {
      this.eduGridCurrentPage = parseInt(page);
    },
    setCertPageSize(size) {
      this.certGridPageSize = parseInt(size);
    },
    setCertCurrentPage(page) {
      this.certGridCurrentPage = parseInt(page);
    },
    setReqTrainPageSize(size) {
      this.reqTrainGridPageSize = parseInt(size);
    },
    setReqTrainCurrentPage(page) {
      this.reqTrainGridCurrentPage = parseInt(page);
    },
    setGoalsPageSize(size) {
      this.goalsGridPageSize = parseInt(size);
    },
    setGoalsCurrentPage(page) {
      this.goalsGridCurrentPage = parseInt(page);
    },
    setSAPageSize(size) {
      this.saGridPageSize = parseInt(size);
    },
    setSACurrentPage(page) {
      this.saGridCurrentPage = parseInt(page);
    },
    setExpPageSize(size) {
      this.expGridPageSize = parseInt(size);
    },
    setExpCurrentPage(page) {
      this.expGridCurrentPage = parseInt(page);
    },
    setTrainPageSize(size) {
      this.trainGridPageSize = parseInt(size);
    },
    setTrainCurrentPage(page) {
      this.trainGridCurrentPage = parseInt(page);
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.reflectionEntity = [
          {
            Id: '',
            EntityTypeId: 0
          }
        ];
        this.reflectionViewMode = false;
      }
    },
    formSaved(data) {
      this.closeModal();
      if (this.$refs.goalsGrid && this.$refs.goalsGrid[0] && this.$refs.goalsGrid[0].loadSummary)
        this.$refs.goalsGrid[0].loadSummary();
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    isVerified(item) {
      return item.verified && (this.hasStaffRole() || (this.has200Role() && this.locale === 'tn')) && !this.readOnly;
    },
    isNotVerified(item) {
      return !item.verified && (this.hasStaffRole() || (this.has200Role() && this.locale === 'tn')) && !this.readOnly;
    },
    isVerifiedCert(item) {
      return item.verified && (this.hasStaffRole() || (this.has200Role() && this.locale === 'tn')) && !this.readOnly && !item.isEndorsement;
    },
    isNotVerifiedCert(item) {
      return !item.verified && (this.hasStaffRole() || (this.has200Role() && this.locale === 'tn')) && !this.readOnly && !item.isEndorsement;
    },
    canTrainingUnverify(item) {
      return item.verified && item.sentViaApi !== 'Yes' && this.has200Role() && !this.readOnly;
    },
    canTrainingVerify(item) {
      return !item.verified && item.sentViaApi !== 'Yes' && this.has200Role() && !this.readOnly;
    },
    canExperienceVerify(item) {
      return (
        (!item.verified &&
        !item.rejected &&
        this.has200Role() &&
        this.locale === 'tn' &&
        item.viewerIsDirector &&
        item.has100Role &&
        !item.has200Role && 
        !this.readOnly)
        ||
        (!item.verified &&
        !item.rejected &&
        this.hasStaffRole() &&
        this.locale === 'tn' &&
        item.has100Role &&
        item.has200Role && 
        !this.readOnly)      
      );
    },
    canExperienceUnverify(item) {
      return (
        (item.verified &&
        !item.rejected &&
        this.has200Role() &&
        this.locale === 'tn' &&
        item.viewerIsDirector &&
        item.has100Role &&
        !item.has200Role &&
        !this.readOnly)
        ||
        (item.verified &&
        !item.rejected &&
        this.hasStaffRole() &&
        this.locale === 'tn' &&
        item.has100Role &&
        item.has200Role &&
        !this.readOnly)       
      );
    },
    canExperienceReverify(item) {
      return (
        (!item.verified &&
        item.rejected &&
        this.has200Role() &&
        this.locale === 'tn' &&
        item.viewerIsDirector &&
        item.has100Role &&
        !item.has200Role &&
        !this.readOnly)
        ||
        (!item.verified &&
        item.rejected &&
        this.hasStaffRole() &&
        this.locale === 'tn' &&
        item.has100Role &&
        item.has200Role &&
        !this.readOnly)      
      );
    },
    canPdpAddReflection(item) {
      return (
        item.status == 'Completed' &&
        item.verified &&
        !this.readOnly &&
        ((this.has200Role() && !item.reflectionId) ||
          (this.has300Role && this.relationshipPdpCommenting && !item.coachReflectionId))
      );
    },
    canPdpUnverify(item) {
      return item.verified && item.status == 'Completed' && this.has200Role() && !this.readOnly;
    },
    canPdpVerify(item) {
      return !item.verified && item.status == 'Completed' && this.has200Role() && !this.readOnly;
    },
    hasStaffRole() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        (this.activeRole.role.roleName == '350' || this.activeRole.role.roleName == '375') 
      );
    },
    has200Role() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '200'
      );
    },
    has300Role() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '300'
      );
    },
    hasElevatedRole() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        (this.activeRole.role.roleName == '400' ||
          this.activeRole.role.roleName == '500' ||
          this.activeRole.role.roleName == 'AdminUser')
      );
    },
    showRequiredTrainingReflection(item) {
      const requiredTrainingRecordId = 11;
      this.showReflection(item, requiredTrainingRecordId);
    },
    showGoalsReflection(item) {
      const goalsRecordId = 12;
      this.showReflection(item, goalsRecordId);
    },
    showReflection(item, entityTypeId) {
      this.reflectionParentEntity = {
        Id: item.id,
        EntityTypeId: entityTypeId
      };
      let reflectionId;
      if (this.has200Role()) {
        reflectionId = item.reflectionId;
      } else if (this.has300Role()) {
        reflectionId = item.coachReflectionId;
      } else {
        return;
      }
      this.reflectionEntity = [
        {
          Id: reflectionId,
          EntityTypeId: this.commentEntityTypeId
        }
      ];
      this.reflectionViewMode = reflectionId ? true : false;
      this.showModal();
    },
    showModal() {
      this.isModalVisible = true;
    },
    showEndorsement(item) {
      this.endorsementId = item.id;
      this.isEndorsementModalVisible = true;
    },
    shouldShowEndorsement (item) {
      return item.isEndorsement;
    },
    closeEndorsementModal() {
      this.isEndorsementModalVisible =false;
      this.endorsementId = '';
    },
    async setEducationVerification(item) {
      const educationRecordId = 4;
      return await this.setVerification(item, educationRecordId);
    },
    async setCertificationVerification(item) {
      const certificationRecordId = 7;
      return await this.setVerification(item, certificationRecordId);
    },
    async setRequiredTrainingVerification(item) {
      const requiredTrainingRecordId = 11;
      return await this.setVerification(item, requiredTrainingRecordId);
    },
    async setTrainingVerification(item) {
      const trainingRecordId = 3;
      return await this.setVerification(item, trainingRecordId);
    },
    async setGoalsVerification(item) {
      const goalsRecordId = 12;
      return await this.setVerification(item, goalsRecordId);
    },
    async setFirstExperienceVerification(item) {
      const employmentRecordId = 8;
      return await this.setVerification(item, employmentRecordId, true, true);
    },
    async setExperienceVerification(item) {
      const employmentRecordId = 8;
      return await this.setVerification(item, employmentRecordId, true);
    },
    async setVerification(item, entityTypeId, isStartDateVerification = false, isFirstStartDateVerification = false) {
      return await new Promise((resolve, reject) => {
        if (!this.primaryEntity || !this.organizationId) {
          resolve(false);
        }
        const promptComponent = RequestPrompt;
        const actionText = item.verified ? 'remove verification from' : 'verify';
        const actionTitleText = item.verified ? 'Remove Verification From' : 'Verify';
        const startDateDisplay = isStartDateVerification
          ? `<b>Start Date: ${this.$formatGridDate(item.startDate, false, false)}</b><br/>`
          : '';
        const promptText = `${startDateDisplay}Are you sure you want to ${actionText} this ${
          isStartDateVerification ? ' start date' : ' record'
        }? If so please enter an optional comment and click Continue.`;
        const promptTitle = `${actionTitleText} ${isStartDateVerification ? ' Start Date' : ' Record'}`;
        const options = {
          title: promptTitle,
          okLabel: isFirstStartDateVerification ? 'Verify' : 'Continue',
          cancelLabel: 'Cancel',
          notOkLabel: isFirstStartDateVerification ? 'Reject' : '',
          prompt: { invalidMessage: '', component: promptComponent }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            if (res && (res.ok || (isFirstStartDateVerification && res.ok === null))) {
              const body = {
                entityTypeId: entityTypeId,
                entityId: item.id,
                primaryEntityId: this.primaryEntity.Id,
                isVerified: res.ok ? !item.verified : false,
                comments: res.value,
                agencyId: this.organizationId
              };
              this.isBusy = true;
              this.$store
                .dispatch('verificationAction', body)
                .then(async (response) => {
                  this.isBusy = false;
                  if (res.ok) {
                    item.verified = !item.verified;
                    item.verificationStatus = 'Approved';
                    if (isStartDateVerification) item.rejected = isFirstStartDateVerification ? false : !item.rejected;
                  } else {
                    if (isFirstStartDateVerification) item.rejected = true;
                  }
                  resolve(true);
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    },
    setExpanded(index) {
      if (this.expanded == index) this.expanded = -1;
      else this.expanded = index;
    },
    setChildExpanded(index) {
      if (this.expandedChild == index) this.expandedChild = -1;
      else this.expandedChild = index;
    },
    canAddComments(item) {
      return (this.has200Role() || (this.has300Role() && this.relationshipPdpCommenting)) && !item.verified && !this.readOnly;
    },
    showDisableEndorsement(item) {
      return this.hasElevatedRole() && item.isEndorsement && !item.disabled;
    },
    showEnableEndorsement(item) {
      return this.hasElevatedRole() && item.isEndorsement && item.disabled;
    },
    async disableEndorsement(item) {
      await this.setEndorsementDisableStatus(item, true);
    },
    async enableEndorsement(item) {
      await this.setEndorsementDisableStatus(item, false);
    },
    async setEndorsementDisableStatus(item, disable) {
      let confirmed = true;
      if (disable)
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Warning', cancelLabel: 'Cancel' };
          this.$dialogs
            .confirm('Are you sure you want to disable?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      if (confirmed) {
        const body = {
          disable: disable,
          entityTypeId: this.endorsementEntityTypeId,
          entityId: item.id,
          primaryEntityId: item.primaryEntityId
        };
        this.isBusy = true;
        this.$store
          .dispatch('gridDisableRequest', body)
          .then((response) => {
            const cGrid = this.entityTypeInfo.find((eti) => eti.entityTypeId == this.endorsementEntityTypeId);
            cGrid.gridKey += '-1';
            this.isBusy = false;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    organizationId: {
      type: String,
      default: ''
    },
    withinModal: {
      type: Boolean,
      default: true
    },
    relationshipPdpCommenting: {
      type: Boolean,
      default: false
    },
    verificationOnly: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
};
</script>
