import axios from 'axios';
import store from '../store';

export default function setup() {
  axios.interceptors.request.use(
    (config) => {
      const authToken = sessionStorage.getItem('auth-token');
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if ('x-new-token' in response.headers) {
        store.dispatch('localAuthRequest', response.headers['x-new-token']);
      }
      return response;
    },
    (error) => {
      if (error.response.status !== 401) {
        let errobj;
        let errors = '';
        if (error.response.status === 403) errobj = 'Insufficient access privileges';
        else errobj = error.response.data;
        if (typeof errobj == 'string') {
          errors = errobj;
        } else {
          let errArr = [];
          if (errobj.errors) {
            errArr = Object.values(errobj.errors);
          } else {
            errArr = Object.values(errobj);
          }
          for (let i = 0; i < errArr.length; i++) {
            if (typeof errArr[i] == 'string') {
              errors = errors + errArr[i] + '\n';
            } else {
              errors = errors + errArr[i][0] + '\n';
            }
          }
        }
        store.dispatch('showToastError', errors);
      }
      return Promise.reject({ ...error });
    }
  );
}
