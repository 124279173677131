<script>
import RequestPrompt from './request-prompt';

export default {
  extends: RequestPrompt,
  props: {
    required: {
      type: Boolean,
      default: true
    }
  }
};
</script>
