<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle="'accordion_1'">
        <span style="font-size: 1rem">
          <button class="btn accordion-caret p-0">
            <icon :icon="recordOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
          </button>
          <span class="ml-4"><span v-if="!recordOpen">View</span><span v-else>Hide</span> Master Training Record</span>
        </span>
      </b-card-header>
      <b-collapse :id="'accordion_1'" accordion="access-accordion" role="tabpanel" v-model="recordOpen">
        <b-card-body style="min-height: 400px">
          <div>
            <wfrform
              :formid="formId"
              :entities="[currentEntity]"
              :startinviewmode="true"
              :primaryEntity="getCurrentUserEntity()"
              :showEditButton="false"
            >
            </wfrform>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'TrainingPropertyDisplay',
  props: {
    currentEntity: {
      type: Object,
      default: () => {}
    },
    formId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      recordOpen: false
    };
  },
  methods: {
    getCurrentUserEntity() {
      return { Id: this.$store.getters['userId'], EntityTypeId: 1 };
    }
  }
};
</script>
