<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <input
      type="text"
      :warningmessage="warningMessage"
      :required="required ? true : false"
      :value="value"
      :disabled="readOnly ? true : false"
      @input="
        checkValid($event.target);
        $emit('input', $event.target.value);
      "
      :formelementid="id"
      :placeholder="stimulus"
      class="form-control form-el"
      v-mask="'(###) ###-####'"
      pattern="^\(\d{3}\)\s\d{3}-\d{4}"
      autocomplete="new-password"
    />
  </div>
</template>
<script>
export default {
  name: 'phone',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 12
    },
    warningMessage: {
      type: String,
      default: 'Phone number must contain 10 digits'
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    checkValid: function (input) {
      input.setCustomValidity('');
      let val = '';
      if (input && input.value) {
        val = val.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
      }
      if (input.validity.valid && (val.length == 0 || val.length == 10)) {
        return;
      } else {
        input.setCustomValidity(input.attributes.warningMessage.value);
      }
    }
  }
};
</script>
