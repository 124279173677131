<template>
  <section class="container-fluid">
    <modal v-if="isModalVisible" @close="closeModal">
      <h2 slot="header">View Record</h2>
      <wfrform
        slot="body"
        v-bind:formid="activeFormId"
        v-bind:entities="activeEntities"
        :key="activePrimaryEntity.Id"
        :primaryEntity="activePrimaryEntity"
        :showEditButton="false"
        :startinviewmode="true"
      >
      </wfrform>
    </modal>
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="row">
          <div class="mb-4 text-center col-12">
            <h1>Verification Requests</h1>
          </div>
        </div>
        <grid :entityTypeId="entityTypeId"
              :entityTypeName="entityTypeName"
              :additionalActions="additionalActions"
              :showFormOnEmpty="false"
              :showAddButton="false"
              :showDisable="false"
              :showEdit="false"
              :showView="false"
              ref="actionGrid"
              :additionalActionsOnColumn="true"
              :allowDownload="false"
              :key="gridKey"
              :formId="0"
              :basePageSize="gridPageSize"
              :baseCurrentPage="gridCurrentPage"
              @setPageSize="setPageSize"
              @setCurrentPage="setCurrentPage"></grid>
      </div>
    </div>
  </section>
</template>

<script>
  import RequestPrompt from '../../utility/dialog/request-prompt';
  import RequestPromptRequired from '../../utility/dialog/request-prompt-required';
export default {
  name: 'VerificationRequests',
  data() {
    return { 
      entityTypeId: 29,
      entityTypeName: "Verification Actions",
      gridKey: 'grid',
      activeFormId: 0,
      eduFormId: 2,
      certFormId: 5,
      careerLatticeFormId: 28,
      userEntityTypeId: 1,
      eduEntityTypeId: 4,
      certEntityTypeId: 7,
      careerLatticeEntityTypeId: 31,
      activeEntities: [],
      activePrimaryEntity: {},
      isBusy: false,
      isModalVisible: false,
      additionalActions: [
        {
          title: 'Approve',
          icon: 'check',
          click: this.acceptVerification,
          check: this.recordViewed
        },
        {
          title: 'Decline',
          icon: 'ban',
          click: this.declineVerification,
          check: this.recordViewed
        },
        {
          title: 'View and download to enable approval',
          icon: 'check',
          click: () => { return true;},
          check: this.recordNotViewed
        },
        {
          title: 'View and download to enable rejection',
          icon: 'ban',
          click: () => { return true;},
          check: this.recordNotViewed
        },
        {
          title: 'View',
          icon: 'eye',
          click: this.viewRecord,
          check: () => { return true;}
        }     
      ],
      viewedRecords: [],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    recordViewed(item) {
      return this.viewedRecords.includes(item.id);
    },
    recordNotViewed (item) {
      return !this.viewedRecords.includes(item.id);
    },
    viewRecord(item) {
      this.activeFormId = item.entityTypeId == this.eduEntityTypeId ? this.eduFormId :
                          item.entityTypeId == this.certEntityTypeId ? this.certFormId :
                          item.entityTypeId == this.careerLatticeEntityTypeId ? this.careerLatticeFormId : 0;
      if (this.activeFormId == 0)
        return false;
      this.activeEntities = [
        {
          id: item.entityId,
          entityTypeId: item.entityTypeId
        }
      ];
      this.activePrimaryEntity = {
        id: item.primaryEntityId,
        entityTypeId: this.userEntityTypeId
      };
      this.isModalVisible = true;
      this.viewedRecords.push(item.id);
      return true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.gridKey += '-1';
    },
    async acceptVerification(item) {
      await this.setVerification(item, true);
    },
    async declineVerification(item) {
      await this.setVerification(item, false);
    },
    async setVerification(item, accept) {
      return await new Promise((resolve, reject) => {
        const promptComponent = accept ? RequestPrompt : RequestPromptRequired;
        const promptTitle = accept ? 'Confirm Verification!' : 'Confirm Decline!';
        const acceptText=`You are about to successfully verify the ${item.identifier} document of ${item.primaryEntityName}. Please enter an optional comment and click on "Approve".`;
        const declineText=`You are about to decline the ${item.identifier} document of ${item.primaryEntityName}. Please enter a <b>mandatory</b> comment and click Decline to proceed.`;
        const promptText = accept ? acceptText : declineText;
        const options = {
          title: promptTitle,
          okLabel: accept ? 'Approve' : 'Decline',
          cancelLabel: 'Cancel',
          prompt: { invalidMessage: '', component: promptComponent }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            if (res && res.ok) {
              const body = {
                entityTypeId: item.entityTypeId,
                entityId: item.entityId,
                primaryEntityId: item.primaryEntityId,
                isVerified: accept,
                comments: res.value
              };
              this.isBusy = true;
              this.$store
                .dispatch('verificationAction', body)
                .then(async (response) => {
                  this.isBusy = false;
                  if (res.ok) {

                    const options = { title: accept ? 'Record Verified!' : 'Verification Declined!' };
                    const acceptAlertText  = `You have successfully verified ${item.identifier} document of ${item.primaryEntityName}.`;
                    const declineAlertText = `Verification is declined for ${item.identifier} document of ${item.primaryEntityName}.`;
                    const alertText = accept ? acceptAlertText : declineAlertText;
                    this.$dialogs.alert(alertText, options);
                    this.gridKey += '-1';
                    resolve(true);
                  }
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            //we closed the window
            resolve(false);
          })
          .catch((error) => reject(error));
      });
    }    
  }
};
</script>
