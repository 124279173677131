<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <div v-if="choiceGroups.length == 0">
      <select v-if = "visibleChoices.length >1"
        :required="required ? true : false"
        :disabled="readOnly ? true : false"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :formelementid="id"
        class="custom-select form-el"
      >
        <option value="" selected disabled hidden>{{ stimulus }}</option>
        <option v-for="choice in visibleChoices" v-bind:key="choice.id" :value="choice.id" style="color: black;">
          {{ choice.text }}
        </option>
      </select>
      <Span v-else-if="visibleChoices.length == 1">
        {{visibleChoices[0].text }}       
      </Span>
    </div>
    <div v-if="choiceGroups.length > 0">
      <select
        :required="required ? true : false"
        :disabled="readOnly ? true : false"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :formelementid="id"
        class="custom-select form-el"
      >
        <option value="" selected disabled hidden>{{ stimulus }}</option>
        <optgroup v-for="group in groupedChoices" :label="group.groupName" :key="group.groupName">
          <option v-for="choice in group.groupChoices" v-bind:key="choice.id" :value="choice.id" style="color: black">
            {{ choice.text }}
          </option>
        </optgroup>
      </select>
    </div>
  </div>
</template>
<script>

export default {
  name: 'dropdown',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 12
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    choiceGroups: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {};
    },
    mounted() {
      if ((this.choiceGroups.length == 0) && (this.visibleChoices.length == 1)) {
        this.$emit('input', this.visibleChoices[0].id.toString());
      }
    },
  methods: {
  },
  computed: {
    groupedChoices() {
      const grouped = [];
      const used = [];
      if (this.choiceGroups.length > 0 && this.choices.length > 0) {
        for (let i = 0; i < this.choiceGroups.length; i++) {
          const group = this.choiceGroups[i];
          const firstInGroup = group.firstChoiceSequence;
          let lastInGroup = this.choices.length;
          if (i < this.choiceGroups.length - 1) {
            const nextGroup = this.choiceGroups[i + 1];
            if (nextGroup && nextGroup.firstChoiceSequence) lastInGroup = nextGroup.firstChoiceSequence - 1;
          }
          const groupChoices = [];
          this.choices.forEach((choice) => {
            if (
              choice.id &&
              !used.includes(choice.id) &&
              choice.sequence &&
              choice.sequence >= firstInGroup &&
              choice.sequence <= lastInGroup
            ) {
              groupChoices.push(choice);
              used.push(choice.id);
            }
          });
          grouped.push({ groupName: group.groupName, groupChoices });
        }
        const ungroupedChoices = [];
        this.choices.forEach((choice) => {
          if (choice.id && !used.includes(choice.id)) {
            ungroupedChoices.push(choice);
            used.push(choice.id);
          }
        });
        if (ungroupedChoices.length > 0) {
          grouped.push({
            groupName: 'Ungrouped',
            groupChoices: ungroupedChoices
          });
        }
      }
      return grouped;
    },
    visibleChoices() {
      // resolves issue (vue/no-use-v-if-with-v-for)
      return this.choices.filter((x) => x.visible === true);
    }
  }
};
</script>
