<template>
  <div>
    <div v-for="(att, attIndex) in attestations" :key="attIndex">
        
        <div v-if="att.approved" class="row">
            <div class="col-1">
                <icon icon="check" style= "font-size: 1.125rem; color:var(--green);"></icon>
                
            </div>
            <div class="col-11">
                <div v-html="att.text"></div>
                <i>Attested on {{ formatDate(att.attestationDate) }} by {{ att.attestorName}} <span v-if="att.comment">with the following comment:</span></i><br/>
                <span class="ml-4" v-if="att.comment"><i>"{{ att.comment}}"</i></span>
            </div>
        </div>
        <div v-else class="row">
            <div class="col-1">
                <input type="checkbox" :id="'cb_' + attIndex" @click="attest(attIndex)" :disabled="!has200Role()"/>
            </div>
            <div class="col-11" v-html="att.text"></div>
        </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestPrompt from '../../utility/dialog/request-prompt';
export default {
  name: 'Attestation',
  data() {
    return {
    };
  },
  props: {
    attestations: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapGetters(['activeRole'])
  },
  methods: {
    attest(attIndex) {
      const regText =
        'Please enter an optional comment and click "Confirm".';
      const regOptions = {
        title: 'Confirm Attestation',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = this.attestations[attIndex];
            body.comment = res.value;
            this.isBusy = true;
            this.$store
            .dispatch('setAttestationRequest', body)
            .then(async (response) => {
                this.isBusy = false;
                this.attestations[attIndex].attestorId = response.attestorId;
                this.attestations[attIndex].attestorName = response.attestorName;
                this.attestations[attIndex].attestationDate = response.attestationDate;
                this.attestations[attIndex].approved = response.approved;
            })
            .catch((errors) => {
                this.isBusy = false;
                const cb = document.getElementById('cb_' + attIndex);
                cb.checked = false;
            });
          } else {
            const cb = document.getElementById('cb_' + attIndex);
            cb.checked = false;
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
      },
      formatDate(dateStr) {
          const date = new Date(dateStr);
          return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
      },
    has200Role() {
      return (
        this.activeRole &&
        this.activeRole.role &&
        this.activeRole.role.roleName &&
        this.activeRole.role.roleName == '200'
      );
    }
  }
};
</script>
