<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">
            {{ currentPageName }}
          </h1>
          <p v-if="instructionText">{{ instructionText }}</p>
        </div>
        <div>
          <grid
            :key="gridKey"
            :entityTypeId="entityTypeId"
            :formId="formId"
            :entityTypeName="entityTypeName"
            :primaryEntity="primaryentity"
            :showFormOnEmpty="true"
            :showDelete="true"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DashboardResume',
  data() {
    return {
      entityTypeId: 10,
      formId: 9,
      entityTypeName: 'Resume',
      currentPageName: '',
      instructionText: '',
      additionalActions: [
        {
          title: 'Publish',
          icon: 'user-shield',
          click: this.publishResume,
          check: this.shouldShowPublish
        }
      ],
      gridKey: 'resume',
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    async publishResume(item) {
      return await new Promise((resolve, reject) => {
        const body = {
          resumeId: item.id
        };
        this.isBusy = true;
        this.$store
          .dispatch('publishResumeRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.resetGrid();
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    shouldShowPublish(item) {
      return item.isGenerated && !item.isPublished;
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowPublish(item);
    },
    resetGrid() {
      this.gridKey = Math.random().toString();
    }
  }
};
</script>
