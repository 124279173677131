<template>
  <div>
    <div class="form-row">
      <div class="form-group col-12">
        <div role="tablist">
          <b-card no-body class="mb-1">
            <b-card-body>
              <div class="form-row">
                <div class="form-group col-12">
                  <strong>Register for Course Session:</strong>
                  <div>{{ section.courseName }} - {{ section.sectionName }}</div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <strong>Name:</strong>
                  <div>{{ user.fullName }}</div>
                </div>
                <div class="form-group col-6">
                  <strong>Email:</strong>
                  <div>{{ user.userName }}</div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>
      <div class="form-group col-4" v-if="!registered">
        <button class="btn btn-primary" @click="register">Register</button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestPromptRequired from '../../utility/dialog/request-prompt-required';
import SelectPromptRequired from '../../utility/dialog/select-prompt-required';
import { mapGetters } from 'vuex';
export default {
  name: 'EnrollUser',
  components: {},
  computed: {
    ...mapGetters(['profile', 'roles', 'activeRole', 'isAuthenticated'])
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      trainerId: '',
      isBusy: false,
      isDirty: false,
      isModalVisible: false,
      registered: false,
      inRegion: false,
      beforeRegPeriod: false,
      afterRegPeriod: false
    };
  },
  created() {
    this.trainerId = this.$store.getters['userId'];
    this.userId = this.user.id;
    this.courseSectionId = this.section.id;
    if (new Date(this.section.registrationStartDate) > new Date()) this.beforeRegPeriod = true;
    if (new Date(this.section.registrationEndDate) < new Date()) this.afterRegPeriod = true;
  },
  methods: {
    registerConfirmed(comments, orgId) {
      const body = {
        courseSectionId: this.section.id,
        userId: this.user.id,
        comment: comments,
        organizationId: orgId
      };
      this.$store
        .dispatch('courseRegistrationRequest', body)
        .then(async (response) => {
          if (response == 1) {
            const options = { title: 'Registration Successful!' };
            this.$dialogs.alert(`You have successfully registered ${this.user.fullName} for ${this.section.courseName}: ${this.section.sectionName}`, options);
            this.registered = true;
          }
          else if (response == 2) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(`Unable to register. ${this.user.fullName} is already registered for ${this.section.courseName}: ${this.section.sectionName}.`, options);
          }
          else if (response == 3) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(`Registration for ${this.section.courseName}: ${this.section.sectionName} is full. Registration request has been declined.`, options);
          }
          else if (response == 4) {
            this.offerWaitlist(orgId);
          }
          else {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert('Unable to register. An error occurred.', options);
          }
          this.isBusy = false;
          if (response == 1) 
            this.$parent.$parent.$parent.closeModal();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    offerWaitlist(orgId) {
      const regText = `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full. Please click Confirm to add ${this.user.fullName} to the waitlist.  They will receive notifications for any changes in waitlist status.`;
      const regOptions = {
        title: 'Registration Full -- Waiting List',
        cancelLabel: 'Cancel',
        okLabel: 'Confirm'
      };
      this.$dialogs
        .confirm(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.acceptWaitlist(orgId);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    acceptWaitlist(orgId) {
      const body = {
        courseSectionId: this.section.id,
        userId: this.user.id,
        organizationId: orgId
      };
      this.$store
        .dispatch('courseWaitlistRequest', body)
        .then(async (response) => {
          if (response >= 1) {
            const options = { title: 'Registration Status Waitlisted!' };
            this.$dialogs.alert(
              `User ${this.user.fullName} has been added to the waitlist for  ${this.section.courseName}: ${this.section.sectionName}. Their waitlist position is <strong>${response}</strong>`,
              options
            );
            this.registered = true;
            this.disabled = true;
          } else if (response == -2) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Unable to add to waitlist. User ${this.user.fullName} is already registered or waitlisted for  ${this.section.courseName}: ${this.section.sectionName}.`,
              options
            );
          } else if (response == -3) {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert(
              `Registration for  ${this.section.courseName}: ${this.section.sectionName} is full and there are no more spots on the waitlist. Registration request for ${this.user.fullName} has been declined.`,
              options
            );
          } else {
            const options = { title: 'Registration Request' };
            this.$dialogs.alert('Unable to add to waitlist. An error occurred.', options);
          }
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    getRegText() {
      let regText = `Are you sure you want to add ECE professional ${this.user.fullName} to ${this.section.courseName}: ${this.section.sectionName} ? If so, please enter a comment and click Confirm.`;
      if (!this.inRegion) {
        if (this.afterRegPeriod)
          regText = `The registration window for this training has expired and this is an out of region training for ${this.user.fullName}. ${regText}`;
        else if (this.beforeRegPeriod)
          regText = `Registration for this course has not yet opened and this is an out of region training for ${this.user.fullName}. ${regText}`;
        else
          regText = `This is an out of region training for ${this.user.fullName}. ${regText}`;
      } else {
        if (this.afterRegPeriod)
          regText = `The registration window for this training has expired. ${regText}`;
        else if (this.beforeRegPeriod)
          regText = `Registration for this course has not yet opened. ${regText}`;
      }


      return regText;
    },
    getRegOptions() {
      const regOptions = {
        title: 'Register an ECE professional',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPromptRequired
        }
      };
      if (!this.inRegion) {
        if (this.afterRegPeriod) regOptions.title = 'Out of region registration and registration window has expired!';
        else if (this.beforeRegPeriod) regOptions.title = 'Out of region registration and course registration not yet open.';
        else regOptions.title = 'Out of region registration!';
      } else {
        if (this.afterRegPeriod) regOptions.title = 'Registration window has expired!';
        else if (this.beforeRegPeriod) regOptions.title = 'Course registration not yet open.';
      }
      return regOptions;
    },
    register() {
      this.isBusy = true;
      const params = {
        userId: this.user.id
      }
      this.$store
      .dispatch('getUserOrgsRequest', params)
      .then(async (response) => {
        if (response.length > 1) {
          this.registerMultiChoice(response);
        } else {
          this.registerOneChoice(response);
        }
      })
      .catch((error) => {
        this.$emit('dirty', false);
        this.isDirty = false;
      });
    },
    registerOneChoice(opts) {
      if (opts.length == 1) {
        const orgId = opts[0].key;
        const params = {
          region: this.section.region,
          userId: this.user.id,
          organizationId: orgId
        };
        this.isBusy = true;
        this.$store
          .dispatch('regionCheckRequest', params)
          .then(async (response) => {
            this.inRegion = response;
            const regText = this.getRegText();
            const regOptions = this.getRegOptions();
            this.$dialogs
              .prompt(regText, regOptions)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  this.registerConfirmed(res.value, orgId);
                }
                this.$emit('dirty', false);
                this.isDirty = false;
              })
              .catch((error) => {
                this.$emit('dirty', false);
                this.isDirty = false;
              });
          })
          .catch((error) => {
            this.$emit('dirty', false);
            this.isDirty = false;
          });
      } else {
        this.inRegion = false;
        const regText = this.getRegText();
        const regOptions = this.getRegOptions();
        this.$dialogs
          .prompt(regText, regOptions)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              this.registerConfirmed(res.value, '');
            }
            this.$emit('dirty', false);
            this.isDirty = false;
          })
          .catch((error) => {
            this.$emit('dirty', false);
            this.isDirty = false;
          });
      }
    },
    registerMultiChoice(opts) {
      const regText = 'This user is associated with multiple facilities. Please select a facility to proceed with registration';
      const regOptions = {
        title: 'Select a Facility',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: SelectPromptRequired,
          stimulus: 'Select a Facility',
          selectOptions: opts
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const orgId = res.value;
            const params = {
              region: this.section.region,
              userId: this.user.id,
              organizationId: orgId
            };
            this.$store
            .dispatch('regionCheckRequest', params)
            .then(async (response) => {
              this.inRegion = response;
              const innerRegText = this.getRegText();
              const innerRegOptions = this.getRegOptions();
              this.$dialogs
                .prompt(innerRegText, innerRegOptions)
                .then((res) => {
                  if (res && res.ok && res.ok == true) {
                    this.registerConfirmed(res.value, orgId);
                  }
                  this.$emit('dirty', false);
                  this.isDirty = false;
                })
                .catch((error) => {
                  this.$emit('dirty', false);
                  this.isDirty = false;
                });
            })
            .catch((errors) => {
              this.isBusy = false;
            })           
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
      }
    },
    formSaved(data) {
      this.closeModal();
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    }
  }
};
</script>
