<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <div :class="subtype == 'horizontalCheckbox' ? 'form-radio form-radio-inline' : 'form-radio'">
      <div :class="subtype == 'horizontalCheckbox' ? 'radio-inline' : 'radio'" v-for="choice in choices" :key="choice.id">
        <label>
          <input
            type="checkbox"
            :checked=" checkActive(choice.id) ? true : false"
            :disabled="readOnly ? true : false"
            :name="'checkbox_' + id"
            v-bind:key="choice.id"
            :value="choice.id"
            ref="checkbox"
            @input="updateVal($event.target.value)"
          />&nbsp;{{ choice.text }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'checkbox',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 12
    },
    subtype: {
      type: String,
      default: 'verticalCheckbox'
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: Array
    }
  },
  data() {
    return {
        vals: this.startingValue
    };
  },
  methods: {
      updateVal(testValue) {
          const firstOpt = this.$refs.checkbox[0];
          if (this.vals.includes(testValue)) {
              this.vals = this.vals.filter(function(value, index,arr) { return value !== testValue});
          } else {
              this.vals.push(testValue);
          }
          if (this.required && this.vals.length === 0)
              firstOpt.setCustomValidity('One or more selections must be made for this field');
          else
              firstOpt.setCustomValidity('');
          this.$emit('input', this.vals);
      },
      checkIfSelectionMade() {
          const firstOpt = this.$refs.checkbox[0];
          firstOpt.setCustomValidity('');
          if (this.required && this.vals.length === 0) {
              firstOpt.setCustomValidity('One or more selections must be made for this field');
              firstOpt.reportValidity();
              return false;
          }
              
          return true;
      },
      checkActive(id) {
          return this.vals.includes(id.toString());
      }

  }
};
</script>
