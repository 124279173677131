<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="content-container">
        <div class="mb-4 text-center">
          <h1>My Calendar</h1>
        </div>
        <div class="mb-4">
          <b-form-group>
            <b-form-radio-group v-model="selectedOption"
                                :options="radioOptions"
                                buttons
                                button-variant="outline-wfr"></b-form-radio-group>
          </b-form-group>
        </div>
        <div v-if="selectedOption == 'calendarView'">
          <div class="row" aria-hidden="true">
            <div class="col-12 d-flex flex-wrap justify-content-center justify-content-md-end align-items-center">
              <div class="mr-md-3">Training Status: </div>
              <div class="d-block d-md-none" style="flex-basis: 100%; height: 0;"></div>
              <div class="active-training mr-1" style="height:20px;width:20px;"></div>
              <div v-if="!is250User" class="mr-md-2">
                Registered
              </div>
              <div v-if="is250User" class="mr-md-2">
                Upcoming
              </div>
              <div class="d-block d-md-none" style="flex-basis: 100%; height: 0;"></div>
              <div v-if="!is250User" class="pending-training mr-1" style="height:20px;width:20px;"></div>
              <div v-if="!is250User" class="mr-md-2">
                Pending Approval
              </div>
              <div class="d-block d-md-none" style="flex-basis: 100%; height: 0;"></div>
              <div v-if="!is250User" class="waitlist-training mr-1" style="height:20px;width:20px;"></div>
              <div v-if="!is250User" class="mr-md-2">
                Waitlist
              </div>
              <div class="d-block d-md-none" style="flex-basis: 100%; height: 0;"></div>
              <div class="past mr-1" style="height:20px;width:20px;"></div>
              <div>
                Past
              </div>
            </div>
          </div>
          <CalendarView />
        </div>
        <div v-if="selectedOption == 'trainingGrid'">
          <grid 
            :entityTypeId="courseSessionEntityType"
            :formId="courseSessionFormId"
            :entityTypeName="entityTypeName"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :key="gridKey"
            :primaryEntity="primaryEntity"
            ref="sessionGrid"
            :allowDownload="false"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            :additionalActions="additionalActions"
            :additionalActionsOnColumn="true"
            :defaultFilters="defaultFilters"
            :altGridId="altGridId"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
        <div v-if="selectedOption == 'trainingSearch'">
          <TrainingSearch :key="searchKey" @reset="resetSearch" />
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header" v-if="activeSession">{{activeSession.courseName}}: {{ activeSession.sectionName }}</h2>
      <div class="text-left row" slot="body" v-if="activeKey != 'closed'">
        <div class="w-100 m-3">
            <trainingSearchDetails 
              :key="activeKey" 
              :section="activeSession" 
              :disableRegister="disableRegister" 
              :hideRegister="hideRegister"
              @reset="setDirty"></trainingSearchDetails>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import CalendarView from './calendar-view.vue';
import TrainingSearch from './training-search.vue';
import trainingSearchDetails from './training-search-details.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'Calendar',
  data() {
    return {
      selectedOption: 'calendarView',
      searchKey: 'searchKey' + Math.random(),
      radioOptions: [
        { text: 'View Calendar', value: 'calendarView' },
        { text: 'Upcoming Trainings', value: 'trainingGrid' },
        { text: 'Search Training', value: 'trainingSearch' }
      ],
      primaryEntity: {
        type: Object,
        default() {
          return {
            Id: '',
            EntityTypeId: 1
          };
        }
      },
      additionalActions: [
        {
          title: 'View Details',
          icon: 'file-signature',
          click: this.rowAction,
          check: () => true
        },
        {
          title: 'Cancel Training',
          icon: 'calendar-times',
          click: this.cancelTraining,
          check: this.isNot250
        },
      ],
      gridKey: 'open',
      courseSessionEntityType: 24,
      courseSessionFormId: 22,
      entityTypeName: 'Training',
      altGridId: 1,
      defaultFilters: [],
      isModalVisible: false,
      is250User: false,
      activeSession: {},
      activeKey: 'closed',
      disableRegister: false,
      hideRegister: false,
      dirtyFlag: false,
      gridPageSize: 5,
      gridCurrentPage: 1
    }
  },
  components: {
    CalendarView,
    TrainingSearch,
    trainingSearchDetails
  },
  computed: {
    ...mapGetters(['userId','activeRole'])
  },
  created() {
    if (this.userId) {
      this.primaryEntity = {
        Id: this.userId,
        EntityTypeId: 1
      };
      this.is250User = !this.isNot250();
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    resetSearch () {
      this.searchKey = 'searchKey' + Math.random();
    },
    setDirty () {
      this.dirtyFlag = true;
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeKey = item.id;
        this.activeSession = item;
        if (this.is250User)
          this.hideRegister = true;
        else
          this.disableRegister = true;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeKey="closed"
      this.activeSession = {};
      if (this.dirtyFlag) {
        this.gridKey += '-1';
        this.dirtyFlag=false;
      }
    },
    isNot250() {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName === '250')
        return false;
      return true;
    },
    async cancelTraining(item) {
      const confirmTitle = "Confirm Cancellation";
      const confirmText = "Your cancellation request will be processed immediately and can not be reversed. Please click Confirm to cancel your registration.";
      const options = { title: confirmTitle, okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm(confirmText, options)
        .then((res) => {
          //if we clicked continue
          if (res && res.ok && res.ok == true) {
            const body = {
              courseSectionId: item.id,
              userIds: [this.$store.getters['userId']],
              comment: ''
            };
            this.isBusy = true;
            this.$store
              .dispatch('cancelRegistrationRequest', body)
              .then(async (response) => {
                const options = { title: 'Cancellation Request Complete!' };
                this.$dialogs.alert(`You have successfully unregistered for  ${item.courseName}: ${item.sectionName}`, options);
                this.gridKey += "-1";
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        });
    }
  }
};
</script>
