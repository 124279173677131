<template>
  <div>
    <select
        :required="required ? true : false"
        v-model="value"
        @input="$emit('input', $event.target.value)"
        class="custom-select form-el"
    >
        <option value="" selected disabled hidden>{{ stimulus }}</option>
        <option v-for="option in selectOptions" v-bind:key="option.key" :value="option.key" style="color: black;">
          {{ option.value }}
        </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    stimulus: {
        type: String,
        default: "Select a value"
    },
    selectOptions: {
        type: Array,
        default: () => []
    },
    required: {
        type: Boolean,
        default: false
    }
  },
  data() {
      return {
          value: ''
      }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods: {
    isValid() {
      return this.value !== '';
    }
  },
  mounted() {
    if (!this.required) this.$parent.$parent.valid = true;
  }
};
</script>