<template>
  <div :class="isBusy ? 'disabled-area' : ''">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="mb-4">
      <div class="row">
        <div class="form-group col-md-6">
          <div>
            <label>Resume description</label>
          </div>
          <input
            class="form-control form-el"
            type="text"
            placeholder="Enter a description"
            autofocus=""
            @change="setDirty()"
            :disabled="viewmode ? true : false"
            v-model="localDescription"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p>Select records you want displayed on your resume from the sections below.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.education
              @click="toggleEducation()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="educationOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">Education</span>
              </span>
            </b-card-header>
            <b-collapse id="education" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="educationItems" v-if="educationItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.value"
                      @change="toggleItem('edu', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No Education Records</span>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.employment
              @click="toggleEmployment()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="employmentOpen ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">Work Experience</span>
              </span>
            </b-card-header>
            <b-collapse id="employment" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="employmentItems" v-if="employmentItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.value"
                      @change="toggleItem('emp', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No Employment Records</span>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.certification
              @click="toggleCertification()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="certificationOpen ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">Certification / Credentials</span>
              </span>
            </b-card-header>
            <b-collapse id="certification" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="certificationItems" v-if="certificationItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.value"
                      @change="toggleItem('cert', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No Certification/Credential Records</span>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1" v-if="locale !== 'al'">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.service
              @click="toggleService()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="serviceOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">Community Service</span>
              </span>
            </b-card-header>
            <b-collapse id="service" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="serviceItems" v-if="serviceItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.item.checked"
                      @change="toggleItem('svc', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No Community Service Records</span>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.membership
              @click="toggleMembership()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon
                    :icon="membershipOpen ? 'caret-down' : 'caret-right'"
                    class="ml-2"
                    style="color: dimgray"
                  ></icon>
                </button>
                <span class="ml-4">Membership</span>
              </span>
            </b-card-header>
            <b-collapse id="membership" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="membershipItems" v-if="membershipItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.value"
                      @change="toggleItem('mem', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No Membership Records</span>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-1" v-if="locale !== 'al'">
            <b-card-header
              header-tag="header"
              class="p-1 mouse-pointer"
              role="tab"
              v-b-toggle.reference
              @click="toggleReferences()"
            >
              <span style="font-size: 1rem">
                <button class="btn accordion-caret p-0">
                  <icon :icon="referencesOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
                </button>
                <span class="ml-4">References{{ referenceItems.length > 3 ? ' (Maximum of 3 allowed)' : '' }}</span>
              </span>
            </b-card-header>
            <b-collapse id="reference" accordion="access-accordion" role="tabpanel">
              <b-card-body>
                <b-table :fields="dataFields" :items="referenceItems" v-if="referenceItems.length > 0">
                  <template v-slot:cell(checked)="data">
                    <b-form-checkbox
                      v-if="!viewmode && !localPublished"
                      switch
                      size="md"
                      :checked="data.item.checked"
                      :disabled="activeIdsList.referenceIds.length >= 3 && !activeIdsList.referenceIds.includes(data.item.id)"
                      @change="toggleItem('ref', data.item)"
                    ></b-form-checkbox>
                    <span v-else>{{ data.item.checked ? 'Yes' : 'No' }}</span>
                  </template>
                  <template v-slot:cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
                <span v-else>No References</span>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-primary"
            style="margin-right: 10px"
            @click="fileDownload()"
            v-if="localFileKey && !dirty"
          >
            Download
          </button>
          <button
            class="btn btn-primary"
            style="margin-right: 10px"
            @click="showEdit()"
            v-if="viewmode && localShowEditButton"
          >
            Edit
          </button>
          <button class="btn btn-primary" style="margin-right: 10px" @click="saveAndPreview()" v-if="!viewmode">
            <span v-if="dirty || !localFileKey || firstTime">Save and Preview</span>
            <span v-else>Refresh</span>
          </button>
          <button
            class="btn btn-primary"
            v-if="!viewmode && !localPublished && localFileKey && showPublish"
            @click="publishResume()"
          >
            Publish
          </button>
          <button class="btn btn-primary" style="float: right" v-if="firstTime" @click="closeForm()">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { locale } from '@scripts/localized';
export default {
  name: 'ResumeProfileEditor',
  data() {
    return {
      isBusy: false,
      dirty: false,
      viewmode: false,
      employmentOpen: false,
      educationOpen: false,
      certificationOpen: false,
      serviceOpen: false,
      membershipOpen: false,
      referencesOpen: false,
      showPublish: false,
      dataFields: [
        { key: 'content', label: 'Record' },
        { key: 'checked', label: 'Include?' }
      ],
      educationItems: [],
      employmentItems: [],
      certificationItems: [],
      serviceItems: [],
      membershipItems: [],
      referenceItems: [],
      activeIdsList: {
        educationIds: [],
        employmentIds: [],
        certificationIds: [],
        communityIds: [],
        membershipIds: [],
        referenceIds: []
      },
      localFileKey: '',
      localShowEditButton: false,
      localDescription: '',
      localPublished: false,
      locale: locale
    };
  },
  methods: {
    showEdit() {
      this.viewmode = false;
    },
    addOrRemove(list, id) {
      const idx = list.indexOf(id);
      if (idx >= 0) list.splice(idx, 1);
      else list.push(id);
    },
    showWarningMessage() {
      window.alert('hi');
    },
    toggleItem(type, item) {
      switch (type) {
        case 'edu':
          this.addOrRemove(this.activeIdsList.educationIds, item.id);
          break;
        case 'emp':
          this.addOrRemove(this.activeIdsList.employmentIds, item.id);
          break;
        case 'cert':
          this.addOrRemove(this.activeIdsList.certificationIds, item.id);
          break;
        case 'svc':
          this.addOrRemove(this.activeIdsList.communityIds, item.id);
          break;
        case 'mem':
          this.addOrRemove(this.activeIdsList.membershipIds, item.id);
          break;
        case 'ref':
          this.addOrRemove(this.activeIdsList.referenceIds, item.id);
          break;
      }
      this.setDirty();
      this.hidePublishButton();
    },
    setDirty() {
      this.dirty = true;
    },
    closeForm() {
      this.$emit('closed');
    },
    async publishResume() {
      this.isBusy = true;
      const body = {
        resumeId: this.resumeId
      };
      this.$store
        .dispatch('publishResumeRequest', body)
        .then((response) => {
          this.$emit('saved');
          this.localPublished = true;
          const options = { title: 'Resume published.' };
          this.$dialogs.alert(`Click the Dowload button to get a copy of the resume.`, options);

          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async saveAndPreview() {
      this.isBusy = true;
      const body = {
        resumeId: this.resumeId,
        description: this.localDescription,
        idsList: this.activeIdsList
      };
      this.$store
        .dispatch('editResumeRequest', body)
        .then((response) => {
          this.localFileKey = response.fileKey;
          this.fileDownload();
          this.$emit('saved');
          this.dirty = false;
          this.showPublishButton();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async fileDownload() {
      this.isBusy = true;
      const body = { fileKey: this.localFileKey };
      this.$store
        .dispatch('resumeDownloadRequest', body)
        .then((result) => {
          this.isBusy = false;
          if (window.navigator.msSaveOrOpenBlob) {
            const url = window.navigator.msSaveOrOpenBlob(new Blob([result.data]), 'file');
            window.open(url);
          } else {
            const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            const filename = result.headers['content-disposition'].split('filename=')[1].split('; filename*=')[0];
            link.setAttribute('download', filename.replace(/"/g, ''));
            document.body.appendChild(link);
            link.click();
          }
          const options = { title: 'Download successful.' };
          this.$dialogs.alert(`Check your Downloads folder for a PDF of the resume.`, options);
        })
        .catch((errors) => {
          this.isBusy = false;
          this.errors = errors;
        });
    },
    showPublishButton() {
      if (!this.viewmode && !this.locaalPublished) this.showPublish = true;
    },
    hidePublishButton() {
      this.showPublish = false;
    },
    toggleEducation() {
      const curval = this.educationOpen;
      this.closeAll();
      this.educationOpen = !curval;
    },
    toggleEmployment() {
      const curval = this.employmentOpen;
      this.closeAll();
      this.employmentOpen = !curval;
    },
    toggleCertification() {
      const curval = this.certificationOpen;
      this.closeAll();
      this.certificationOpen = !curval;
    },
    toggleService() {
      const curval = this.serviceOpen;
      this.closeAll();
      this.serviceOpen = !curval;
    },
    toggleMembership() {
      const curval = this.membershipOpen;
      this.closeAll();
      this.membershipOpen = !curval;
    },
    toggleReferences() {
      const curval = this.referencesOpen;
      this.closeAll();
      this.referencesOpen = !curval;
    },
    closeAll() {
      this.educationOpen = false;
      this.employmentOpen = false;
      this.certificationOpen = false;
      this.serviceOpen = false;
      this.membershipOpen = false;
      this.referencesOpen = false;
    },
    setSections(data) {
      if (this.sections == null) return;
      this.educationItems = [];
      this.activeIdsList.educationIds = [];
      this.educationOpen = false;
      for (const idx in this.sections.education) {
        const edItem = this.sections.education[idx];
        this.educationItems.push({
          content: edItem.description,
          checked: edItem.isSelected,
          id: edItem.id
        });
        if (edItem.isSelected) this.activeIdsList.educationIds.push(edItem.id);
      }
      this.employmentItems = [];
      this.activeIdsList.employmentIds = [];
      this.employmentOpen = false;
      for (const idx in this.sections.employment) {
        const empItem = this.sections.employment[idx];
        this.employmentItems.push({
          content: empItem.description,
          checked: empItem.isSelected,
          id: empItem.id
        });
        if (empItem.isSelected) this.activeIdsList.employmentIds.push(empItem.id);
      }
      this.certificationItems = [];
      this.activeIdsList.certificationIds = [];
      this.certificationOpen = false;
      for (const idx in this.sections.certifications) {
        const certItem = this.sections.certifications[idx];
        this.certificationItems.push({
          content: certItem.description,
          checked: certItem.isSelected,
          id: certItem.id
        });
        if (certItem.isSelected) this.activeIdsList.certificationIds.push(certItem.id);
      }
      this.serviceItems = [];
      this.activeIdsList.communityIds = [];
      this.serviceOpen = false;
      for (const idx in this.sections.communityService) {
        const svcItem = this.sections.communityService[idx];
        this.serviceItems.push({
          content: svcItem.description,
          checked: svcItem.isSelected,
          id: svcItem.id
        });
        if (svcItem.isSelected) this.activeIdsList.communityIds.push(svcItem.id);
      }
      this.membershipItems = [];
      this.activeIdsList.membershipIds = [];
      this.membershipOpen = false;
      for (const idx in this.sections.memberships) {
        const memberItem = this.sections.memberships[idx];
        this.membershipItems.push({
          content: memberItem.description,
          checked: memberItem.isSelected,
          id: memberItem.id
        });
        if (memberItem.isSelected) this.activeIdsList.membershipIds.push(memberItem.id);
      }
      this.referenceItems = [];
      this.activeIdsList.referenceIds = [];
      this.referencesOpen = false;
      for (const idx in this.sections.references) {
        const referenceItem = this.sections.references[idx];
        this.referenceItems.push({
          content: referenceItem.description,
          checked: referenceItem.isSelected,
          id: referenceItem.id
        });
        if (referenceItem.isSelected) this.activeIdsList.referenceIds.push(referenceItem.id);
      }
    }
  },
  created() {
    this.setSections(this.sections);
    this.viewmode = this.startinviewmode;
    this.localFileKey = this.fileKey;
    this.localShowEditButton = this.showEditButton;
    this.localDescription = this.description;
    this.localPublished = this.published;
    if (this.resumeId == 'closed' || this.resumeId == '') {
      this.hidePublishButton();
    } else {
      this.showPublishButton();
    }
  },
  watch: {
    resumeId: function (val) {
      if (val == 'closed' || val == '') {
        this.hidePublishButton();
      } else {
        this.showPublishButton();
      }
      this.dirty = false;
      this.viewmode = this.startinviewmode;
      this.localShowEditButton = this.showEditButton;
      this.localDescription = this.description;
    },
    sections: function (val) {
      this.setSections(val);
    },
    fileKey: function (val) {
      this.localFileKey = val;
    },
    showEditButton: function (val) {
      this.localShowEditButton = val;
    },
    description: function (val) {
      this.localDescription = val;
    },
    published: function (val) {
      this.localPublished = val;
    }
  },

  props: {
    resumeId: {
      type: String,
      default: ''
    },
    sections: {
      type: Object,
      default: null
    },
    startinviewmode: {
      type: Boolean,
      default: false
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    fileKey: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    published: {
      type: Boolean,
      default: false
    },
    firstTime: {
      type: Boolean,
      default: false
    }
  }
};
</script>
