<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Manage Sessions</h1>
        </div>
        <div class="search-area">
          <div>
            <trainingDropdown v-if="courseSectionChoices.length > 0"
                              @input="trainingSelected"
                              ref="trainingSearch"
                              :choices="courseSectionChoices">
            </trainingDropdown>
            <span v-if="courseSectionChoices.length == 0">No sessions are available for student attendance management. You must create a section and student record must exist in the section before attendance can be recorded.</span>
          </div>
        </div>
        <div v-if="gridKey.startsWith('open')">
          <grid :entityTypeId="enrollmentEntityType"
                :formId="enrollmentFormId"
                :entityTypeName="entityTypeName"
                :additionalActions="additionalActions"
                :checkForAdditionalActions="shouldShowAdditionalActions"
                :showFormOnEmpty="false"
                :showEdit="false"
                :showDisable="false"
                :showAddButton="true"
                :key="gridKey"
                :section="gridSection"
                :primaryEntity="gridPrimaryEntity"
                ref="enrollmentGrid"
                :additionalShowColumns="additionalColumns"
                :additionalActionsOnColumn="true"
                :allowDownload="false"
                :defaultFilters="gridDefaultFilters"
                :parentFormId="courseSectionFormId"
                :parentProps="gridParentProps"
                :showParentPropsInForm="true"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"
              >
          </grid>
        </div>
        <div v-if="gridKey == 'closed'" style="min-height: 200px"></div>
      </div>
    </div>
        <modal v-show="isMessageModalVisible" @close="closeMessageModal" :isModalVisible="isMessageModalVisible">
          <h2 slot="header">
            Send a Message
          </h2>
          <div slot="body">
            <div v-if="composeKey != ''">
              <MessageCompose
              :inputForm="inputForm"
              :exitForm="confirmExitForm"
              :form="composeForm"
              :composeEmail="composeEmail"
              :compose="compose"
              :key="composeKey"
              :limitText="limitText"
              :userSearch="userSearch"
              :users="users"
              :isLoading="isLoading"
              :twoCharacterMessage="twoCharacterMessage"
              :emptySetMessage="emptySetMessage"
              :noResultMessage="noResultMessage"
              :usersSelected="usersSelected"
              :defaultSelectedUsers="defaultSelectedUsers"
              :showTitle="true"
              >
              </MessageCompose>
            </div>
          </div>
        </modal>
  </section>
</template>

<script>
import trainingDropdown from './training-dropdown.vue';
import { mapGetters } from 'vuex';
import MessageCompose from '../../messaging/message-compose.vue';
import RequestPrompt from '../../utility/dialog/request-prompt';
import RequiredPrompt from '../../utility/dialog/request-prompt-required';
export default {
    name: 'ManageAttendance',
    components: {
      trainingDropdown,
      MessageCompose
    },
    data() {
      return {
        isBusy: false,
        courseSectionEntityType: 24,
        courseSectionFormId: 22,
        enrollmentEntityType: 26,
        enrollmentFormId: 24,
        entityTypeName: 'Student Details',
        courseSectionEntity: {},
        enrollmentPrimaryEntity: {},
        gridPrimaryEntity: {},
        additionalColumns: [],
        gridParentProps: [],
        gridEnrollmentProps: [],
        isModalVisible: false,
        activeId: '',
        activeUser: {},
        isDirty: false,
        searchedName: '',
        gridKey: 'closed',
        searchDefaultFilters: [
        {
          operation: 'Contains',
          filterValue: '',
          filterColumn: { propertyName: 'SearchableName' }
        },
        {
          operation: 'Equals',
          filterValue: 'true',
          filterColumn: { propertyName: 'HasECProfessionalRoleOnly' }
        }
      ],
      gridDefaultFilters: [],
      gridSection: {},
      courseSectionChoices: [],
      currentCourseName: '',
      currentStudentName: '',
      additionalActions: [
        {
          title: 'Send a Message',
          icon: 'paper-plane',
          click: this.sendMessage,
          check: this.shouldShowSendMessage
        },
        {
          title: 'Cancel Registration',
          icon: 'calendar-times',
          click: this.cancelRegistration,
          check: this.shouldShowCancel
        },
        {
          title: 'Review Out of Region Enrollment Request',
          icon: 'glasses',
          click: this.setVerification,
          check: this.canVerify
        }
      ],
      isMessageModalVisible: false,
      composeForm:false,
      composeKey: '',
      composeEmail: {
        subject: '',
        message: '',
        to: null
      },
      selectedUsers: [],
      defaultSelectedUsers: [],
      users: [],
      isLoading: false,
      twoCharacterMessage: 'At least two characters are required for search',
      emptySetMessage: 'No results found',
      noResultMessage: 'At least two characters are required for search',
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['profile', 'roles', 'isAuthenticated'])
  },
  created() {
      this.isBusy = true;
      const body = { };
      this.$store
        .dispatch('courseSectionSearchRequest', body)
        .then(async (result) => {
          this.isBusy = false;
          this.courseSectionChoices = result;
        })
        .catch((errors) => {
          this.isBusy = false;
          this.courseSectionChoices = [];
        });
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    async trainingSelected(courseSection) {
      this.isBusy = true;
      if (courseSection) {
        const body = { trainingId: courseSection }
        this.$store
          .dispatch('courseSectionPropsRequest', body)
          .then((result) => {
            this.gridParentProps = result;
            this.courseSectionEntity = courseSection;
            this.gridPrimaryEntity = { Id: courseSection, EntityTypeId: this.courseSectionEntityType }; //parent entity for enrollment/attendance entity
            const props = { courseRecordId: courseSection};
            this.$store.dispatch('courseSectionDetailRequest', body)
              .then ((result) => {
                this.gridSection = result;
                this.gridKey = 'open' + courseSection;
              });          
          })
      } else {
        this.gridKey = 'closed';
        this.gridPrimaryEntity = {};
        this.courseSectionEntity = {};
        this.courseSectionId = '';
        this.isBusy = false;
      }
    },
    reloadEnrollGrid (resetSection) {
      if (resetSection === true)
        this.gridSection.recordIsLocked = true;
      this.gridKey += "-1";
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      return true;
    },
    alertsDisplay(response) {
      const alertTitle = 'Review Out of Region Request';
      if (response == -2) {
        const options = { title: alertTitle };
        this.$dialogs.alert(`Unable to register. ${this.userName} is already registered for ${this.section.courseName}: ${this.section.sectionName}.`, options);
      }
      else if (response == -3 || response == -4) {
        const options = { title: alertTitle };
        this.$dialogs.alert(`Registration for ${this.section.courseName}: ${this.section.sectionName} is full. Registration is unavailable at this time.`, options);
      }
      else {
        const options = { title: alertTitle };
        this.$dialogs.alert('Unable to complete registration review process. An error occurred.', options);
      }
    },
    shouldShowCancel(item) {
      if (!this.gridSection || this.gridSection.recordIsLocked) return false;
      if (item && ['Unregistered', 'System Cancellation', 'Pending Approval', 'Denied'].includes(item.status)) return false;
      return true;
    },
    shouldShowSendMessage(item) {
      if (item && item.status === 'Unregistered') return false;
      return true;
    },
    cancelRegistration(item) {
      const regText = `Are you sure you want to cancel this registration? If so, please enter an optional comment and click Confirm.`;
      const regOptions = {
        title: 'Cancel Registration',
        okLabel: 'Continue',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.isBusy = true;
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.cancelRegistrationConfirmed(item, res.value);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    cancelRegistrationConfirmed(item, comment) {
      const body = {
        courseSectionId: item.courseSectionId,
        userId: item.userId,
        comment: comment
      };
      this.isBusy = true;
      this.$store
        .dispatch('cancelRegistrationRequest', body)
        .then(async (response) => {
            const dispDate = this.$formatGridDate(item.courseStartDate, false, true);
            this.composeEmail.subject = `${item.courseSectionName} ${dispDate} - Registration Cancelled`;
            this.sendMessage(item);
            this.gridKey += "-1";
          })
        .catch((errors) => {
          this.isBusy = false;
        });     
    },
    async setVerification(item) {
      return await new Promise((resolve, reject) => {
        if (!this.primaryEntity) { //What's this for?
          //if (!this.primaryEntity || !this.organizationId) {
          resolve(false);
        }
        const promptComponent = RequiredPrompt;
        const promptText = `To approve/decline out of region registration request for ${item.userName}, please enter a required comment and click Approve or Decline.`;
        const promptTitle = `Request Review for Out of Region Training`;
        const options = {
          title: promptTitle,
          okLabel: 'Approve',
          cancelLabel: 'Cancel',
          notOkLabel: 'Decline',
          prompt: { invalidMessage: '', component: promptComponent }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            if (res && res.ok != false) { //res.ok=null when declined
              const body = {
                accepted: res.ok ? true : false,
                courseSectionId: item.courseSectionId,
                userId: item.userId,
                comment: res.value,
              };
              this.isBusy = true;
              this.$store
                .dispatch('acceptRejectOverrideRequest', body)
                .then((response) => {
                  if (response > 0) {
                    this.reloadEnrollGrid(false);
                    resolve(true);
                  }
                  else {
                    this.alertsDisplay(response);
                    resolve(false);
                  }
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            }
            resolve(false); //dialog closed or cancelled
          })
          .catch((error) => reject(error));
      });
    },
    canVerify(item) {
      if (item && ['Pending Approval'].includes(item.status))
        return true;
      return false;
    },
    rowAction(item) {
      return true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    sendMessage(item) {
      this.composeForm = true;
      const toUser = { name: item.userName, email: item.name, id: item.userId };
      this.defaultSelectedUsers = [toUser];
      this.composeKey = `open${item.userId}`;
      this.showMessageModal();
    },    showMessageModal() {
      this.isMessageModalVisible = true;
    },
    async closeMessageModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isMessageModalVisible = false;
        this.composeKey = '';
        this.users = [];
        this.defaultSelectedUsers = [];
        this.selectedUsers = [];
        this.composeForm=false;
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    },
    async closeModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.enrollmentPrimaryEntity = {};
        this.gridKey = 'closed'
      }
    },
    inputForm() {
      this.composeForm = true;
      this.composeKey = '';
    },
    async confirmExitForm() {
      let confirmed = true;
      if (
        this.composeEmail &&
        (this.composeEmail.subject || this.composeEmail.message || this.selectedUsers.length > 0)
      ) {
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Warning', cancelLabel: 'Cancel' };
          this.$dialogs
            .confirm('Are you sure you want to close without sending?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      }
      if (confirmed) {
        this.exitForm();
      }
      return confirmed;
    },
    exitForm() {
      this.composeForm = false;
      this.composeKey = '';
      this.selectedUsers = [];
      this.defaultSelectedUsers = [];
      this.users = [];
      this.composeEmail = {
        subject: '',
        message: '',
        to: null
      };
    },
    async compose() {
      //make sure they have selected a recipient
      if (this.selectedUsers.length > 0) {
        let confirmed1 = true;
        if (!this.composeEmail.subject) {
          //ask if they want to send with no subject
          confirmed1 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty subject?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        let confirmed2 = true;
        if (!this.composeEmail.message) {
          //ask if they want to send with no body
          confirmed2 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty body?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        if (confirmed1 && confirmed2) {
          return await new Promise((resolve, reject) => {
            const body = {
              body: this.composeEmail.message,
              subject: this.composeEmail.subject,
              recipients: this.selectedUsers
            };
            this.isBusy = true;
            this.$store
              .dispatch('sendMessageRequest', body)
              .then((response) => {
                //Close message modal after reply sent.
                this.isMessageModalVisible = false;
                this.isBusy = false;
                this.exitForm();
                this.$dialogs.alert("Your message was sent successfully.", { title: 'Success'});
                this.composeEmail.subject='';
                resolve(true);
              })
              .catch((errors) => {
                this.isMessageModalVisible = false;
                this.isBusy = false;
                this.exitForm();
                this.$dialogs.alert("Your message could not be sent at this time. Please try again later.", {title: 'Error'});
                this.composeEmail.subject='';
                resolve(false);
              });
          });
        }
      } else {
        this.$dialogs.alert('No recipient selected', { title: 'Error' });
      }
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    userSearch(query) {
      const lettersLimit = 2;
      if (query.length < lettersLimit) {
        this.noResultMessage = this.twoCharacterMessage;
        this.users = [];
        return;
      }

      this.isLoading = true;
      const body = { searchString: query };

      this.$store
        .dispatch('messageUserSearchRequest', body)
        .then((result) => {
          this.isLoading = false;
          this.users = result;
          if (!this.users.length) {
            this.noResultMessage = this.emptySetMessage;
          }
        })
        .catch((errors) => {
          this.isLoading = false;
        });
    },
    usersSelected(users) {
      this.selectedUsers = users;
    },
  }
};
</script>
