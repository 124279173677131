<template>
  <section>
    <div class="container">
      <div id="maintenance" style="margin-top: 150px">
        <div class="row align-items-center">
          <div class="col text-center">
            <img src="../../../images/logo/FINAL_TN_PAL_LOGO_WEB.png" alt="T.N. Pal Logo" style="width: 345px" />
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-9 col-lg-6">
            <p>
              <b>TNPAL.org is currently down for maintenance. Please check back soon. Thanks for your patience.</b>
            </p>
            <p>
              Please reach out to us at
              <a href="mailto:support@tnpal.org">support@tnpal.org</a> if you have questions.
            </p>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  }
};
</script>
