<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">
            {{ currentPageName }}
          </h1>
          <p v-if="instructionText">{{ instructionText }}</p>
        </div>
        <div>
          <grid
            :key="gridKey"
            :entityTypeId="entityTypeId"
            :formId="formId"
            :altFormId="altFormId"
            :entityTypeName="entityTypeName"
            :primaryEntity="primaryentity"
            :showFormOnEmpty="true"
            :showDelete="true"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'References',
  data() {
    return {
      entityTypeId: 30,
      formId: 26,
      altFormId: 27,
      entityTypeName: 'Reference',
      currentPageName: '',
      instructionText: '',
      additionalActions: [],
      gridKey: 'references',
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    shouldShowAdditionalActions(item) {
      return false;
    },
    resetGrid() {
      this.gridKey = Math.random().toString();
    },
  }
};
</script>
