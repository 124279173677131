<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Search Users</h1>
          <p>Use search feature to find an EC professional and view his/her records.</p>
        </div>
        <div>
          <toggleSearches @agencyInput="agencySelected"
                          @nameInput="nameSearched"
                          @countyInput="countySelected"
                          :agencySearchHelpText="agencySearchHelpText"
                          :userSearchHelpText="userSearchHelpText"
                          :countySearchHelpText="countySearchHelpText">
          </toggleSearches>
        </div>

        <div v-if="gridKey.startsWith('open')">
          <grid :entityTypeId="userEntityType"
                :formId="userFormId"
                :entityTypeName="entityTypeName"
                :additionalActions="additionalActions"
                :checkForAdditionalActions="shouldShowAdditionalActions"
                :showFormOnEmpty="false"
                :showAddButton="false"
                :key="gridKey"
                :primaryEntity="gridPrimaryEntity"
                :showDisable="false"
                :showEdit="false"
                :showView="false"
                ref="userGrid"
                :additionalShowColumns="additionalColumns"
                :additionalActionsOnColumn="true"
                :allowDownload="false"
                :defaultFilters="gridDefaultFilters"
                :basePageSize="gridPageSize"
                :baseCurrentPage="gridCurrentPage"
                @setPageSize="setPageSize"
                @setCurrentPage="setCurrentPage"
              >
          </grid>
        </div>
        <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
          <h2 slot="header">Student Details - {{ activeUser.fullName }}</h2>
          <div class="text-left row" slot="body">
            <div v-if="activeUser.id && isModalVisible" class="w-100 m-3">
              <userRecordsView
                :key="gridKey"
                :user="activeUser"
                :section="section" 
                @dirty="formDirty($event)">
              </userRecordsView>
            </div>
          </div>
        </modal>
        <div v-if="gridKey == 'closed'" style="min-height: 200px"></div>
      </div>
    </div>

    <!--TODO: For each calling component, we need logic and to call the modal data as needed-->
    <!--      verification-search-users and manage-connections would each need to show a different modal-->
  </section>
</template>

<script>
import toggleSearches from './toggle-searches.vue';
import userRecordsView from './enroll-user.vue';
import { getText } from '@scripts/localized';
import { mapGetters } from 'vuex';
export default {
  name: 'SearchUsers',
  components: {
    toggleSearches,
    userRecordsView
  },
  props: {
    section: {
      type: Object,
      default: () => {}
    },
    enrolledUsers: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      filterOrganizationId: '',
      filterCountyId: '',
      isBusy: false,
      userEntityType: 1,
      userFormId: 1,
      entityTypeName: 'User',
      organizationEntity: {},
      countyEntity: {},
      gridPrimaryEntity: {},
      additionalActions: [
        {
          title: 'Enroll Student',
          icon: 'user-plus',
          click: this.rowAction,
          check: this.showAsEnabled
        },
        {
          title: 'Already Registered',
          icon: 'user-slash',
          check: this.showAsDisabled
        }
      ],
      additionalColumns: [],
      agencyAdditionalColumns: ['titlePosition'],
      isModalVisible: false,
      activeId: '',
      activeUser: {},
      isDirty: false,
      searchedName: '',
      gridKey: 'search',
      searchDefaultFilters: [
        {
          operation: 'Contains',
          filterValue: '',
          filterColumn: { propertyName: 'SearchableName' }
        },
        {
          operation: 'Equals',
          filterValue: 'true',
          filterColumn: { propertyName: 'HasECProfessionalRoleOnly' }
        }
      ],
      gridDefaultFilters: [], 
      agencySearchHelpText: `Search by child care <u>${getText('agency').toLowerCase()}
        name</u> to see staff associated with that specific ${getText('program')}.
        Click on the ${getText('program')} name. Once displayed, click on action icon to view staff records.`,
      userSearchHelpText:
        `Search by <u>EC professional's name.</u> Once displayed, click on action icon to view staff records.`,
      countySearchHelpText:
        `Search by <u>${getText('agency').toLowerCase()} county</u>. Once displayed, click on action icon to view ${getText('agency').toLowerCase()} records.`,
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  watch: {
    filterOrganizationId: function (newVal) {
      if (newVal) this.gridPrimaryEntity = { Id: newVal, EntityTypeId: 2 };
      else this.gridPrimaryEntity = {};
    }
  },
  computed: {
    ...mapGetters(['userId', 'isAuthenticated']),
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    isAlreadyRegistered(user) {
      if (this.enrolledUsers.includes(user.id)) return true;
      return false;
    },
    agencySelected(organization) {
      if (organization && organization.key) {
        this.gridKey = 'open' + organization.key;
        this.additionalColumns = this.agencyAdditionalColumns;
        this.organizationEntity = organization;
        this.filterOrganizationId = organization.key;
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.organizationEntity = {};
        this.filterOrganizationId = '';
      }
    },
    countySelected(county) {
      if (county && county.key) {
        this.additionalColumns = [];
        this.countyEntity = county;
        this.filterCountyId = county.key;
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.countyEntity = {};
        this.filterCountyId = '';
      }
    },
    nameSearched(name) {
      if (name) {
        this.gridKey = 'open';
        this.additionalColumns = [];
        this.searchedName = name;
        this.searchDefaultFilters[0].filterValue = name;
        this.gridDefaultFilters = this.searchDefaultFilters;
        this.filterOrganizationId = '';
        if (this.$refs.userGrid && this.$refs.userGrid.loadSummary) this.$refs.userGrid.loadSummary();
      } else {
        this.gridKey = 'closed';
        this.additionalColumns = [];
        this.searchedName = '';
        this.gridDefaultFilters = [];
        this.filterOrganizationId = '';
      }
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      //item is the user
      return true;
    },
    showAsEnabled(item) {
      if (this.isAlreadyRegistered(item)) return false;
      return true;
    },
    showAsDisabled(item) {
      if (this.isAlreadyRegistered(item)) return true;
      return false;
    },
    reload(resetSection) {
      if (this.$parent.$parent.$parent.reload) //this is the reload grid event for enrollment user search
        this.$parent.$parent.$parent.reload(resetSection);
      else if (this.$parent.reloadEnrollGrid)
        this.$parent.reloadEnrollGrid(resetSection);
      else if (this.$parent.$parent.reloadEnrollGrid)
        this.$parent.$parent.reloadEnrollGrid(resetSection);
      else if (this.$parent.$parent.$parent.reloadEnrollGrid)
        this.$parent.$parent.$parent.reloadEnrollGrid(resetSection);
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch((error) => reject(error));
        } else resolve(true);
      });
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeId = item.id; //this is the guid for the user
        this.activeUser = item;  //this is the user object
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.activeId = 'closed';
        this.activeUser = {};
      }
      this.reload(false);
    },
    formDirty(eventVal) {
      this.isDirty = eventVal;
      this.$emit('dirty', eventVal);
    }
  }
};
</script>
