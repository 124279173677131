<template>
  <div class="row toolbar">
    <div class="col-md-12">
      <b-button class="btn btn-default" @click="bulkSelect" :title="checkTitle" id="check">
        <icon v-if="halfCheckbox" :icon="['far', 'minus-square']"></icon>
        <icon v-if="bulkCheckbox" :icon="['far', 'check-square']"></icon>
        <icon v-if="emptyCheckbox" :icon="['far', 'square']"></icon>
      </b-button>

      <b-dropdown text="Folder" id="folder">
        <b-dropdown-item
          v-for="folder in folders"
          :active="activeFolder.folder == folder"
          :key="folder"
          @click="folderChange(folder)"
          >{{ folder }}</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown text="Filter" id="filter">
        <b-dropdown-item
          v-for="filter in filters"
          :active="activeFilter.filter == filter"
          :key="filter"
          @click="filterChange(filter)"
          >{{ filter }}</b-dropdown-item
        >
      </b-dropdown>

      <b-button
        @click="markRead"
        class="btn btn-default"
        :disabled="!markReadAvailable"
        title="Mark Selected Items as Read"
        id="markRead"
      >
        <icon :icon="['far', 'envelope-open']"></icon>
      </b-button>

      <b-button
        @click="markUnread"
        class="btn btn-default"
        :disabled="!markUnreadAvailable"
        title="Mark Selected Items as Unread"
        id="markUnread"
      >
        <icon :icon="['far', 'envelope']"></icon>
      </b-button>

      <b-button
        @click="toggleArchive"
        class="btn btn-default"
        :disabled="emptyCheckbox"
        v-if="activeFolder.folder != folders.sent"
        :title="archiveTitle"
        id="archive"
      >
        <archive-icon class="icon-2x" v-if="activeFolder.folder == folders.inbox" :title="archiveTitle" />
        <icon icon="inbox" v-if="activeFolder.folder == folders.archive"></icon>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationToolbar',
  props: [
    'unreadCount',
    'markRead',
    'markUnread',
    'bulkCheckbox',
    'halfCheckbox',
    'emptyCheckbox',
    'starNotification',
    'bulkSelect',
    'deleteNotification',
    'markReadAvailable',
    'markUnreadAvailable',
    'toggleArchive',
    'activeFolder',
    'folders',
    'folderChange',
    'activeFilter',
    'filters',
    'filterChange'
  ],
  computed: {
    archiveTitle: function () {
      const folderWord = this.activeFolder.folder == this.folders.inbox ? this.folders.archive : this.folders.inbox;
      return 'Move Selected Items to ' + folderWord;
    },
    checkTitle: function () {
      if (this.halfCheckbox) return 'Uncheck Selected Items';
      if (this.bulkCheckbox) return 'Uncheck All Items';
      return 'Check All Items';
    }
  }
};
</script>
