const locale = process.env.TENANT_NAME;

const localized = {
  tn: {
    message: {
      id: 'TNPAL ID',
      textID: 'TNPAL',
      pdpInfo: 'TDHS',
      threeFiftyRole: 'DHS Staff',
      agencyArticle: 'an',
      agency: 'Agency',
      agencyPlural: 'Agencies',
      facility: 'Agency',
      program: 'program',
      terms: 'Terms and Conditions',
      additionalInstructions:
        '<br>Upload only Early Childhood Education related current credentials/certifications; all credential/certification information must match the uploaded document. Record must include all items with a red asterisk<br>Completed trainings recorded under the Training tab are used in the evaluation of preservice and ongoing professional development requirements.<br>Training certifications recorded in this tab are not included in your record of completed trainings.',
      pdpText: 'PDP,',
      rolePromotionWarning:
        'Are you sure you want to submit this request? Your request for a staff role at this organization, if approved, will allow program Directors to access relevant information like your training records, awards, certifications, experience, education, and Professional Development Plan.',
      uploadResumeBtn: 'Upload Resume',
      uploadResumeInstructions:
        'You can upload a resume document directly or have a resume generated from your profile.',
      myResumeInstructions: 'Please upload your resume here or generate a new resume.',
      certificationCredentialsTab: "<div class ='tenant-certificate'> Please upload the supporting documents for your Early Childhood-related Certifications and/or Credentials here (Ex. First Aid/CPR card). All information you enter <b> <i> must match </i> </b> the uploaded document. A red asterisk <span style='color:red'>(*)</span> indicates a required item. <br> <br> Only completed TrainTN-approved trainings will appear under your My Training tab for use with the evaluation of your preservice and ongoing professional development requirements. Training certificates <b> <i> should not </i> </b> be recorded under this tab and <b> <i> will not </i> </b> be included in your record of completed trainings. </div>",
      uploadReferenceBtn: 'Upload Reference',
      uploadReferenceInstructions:
        'You can upload a reference document directly or enter a reference record. If you choose to enter a reference, it will be available to you to include in auto generated resume(s). A maximum of three references can be included in a generated resume.',
      myReferenceInstructions: 'Please add and manage your references here. These references will be available to you to include during auto resume generation.',
    }
  },
  al: {
    message: {
      id: 'Alabama Pathways ID',
      textID: 'Alabama Pathways',
      pdpInfo: 'Alabama',
      threeFiftyRole: 'Child Care Consultant',
      agencyArticle: 'a',
      agency: 'Facility',
      agencyPlural: 'Facilities',
      facility: 'Facility',
      program: 'facility',
      terms: 'User Agreement for Account Creation',
      additionalInstructions: '',
      pdpText: '',
      rolePromotionWarning:
        'Are you sure you want to submit this request? Your request for a staff role at this organization, if approved, will allow program Directors to access relevant information like your training records, awards, certifications, experience, and education.',
      uploadResumeBtn: 'Upload Resume',
      uploadResumeInstructions: 'You can upload a resume directly or have it generated from your profile.',
      myResumeInstructions: 'Please upload or generate your resume here.',
      certificationCredentialsTab: '<div class ="tenant-certificate"> Please upload the supporting documents for your Early Childhood-related Certifications and/or Credentials here (Ex. First Aid/CPR card). All information you enter <strong>must match</strong> the uploaded document. A red asterisk <span style="color:red">(*)</span> indicates a required item. <br> <br> Training certificates <strong><i>should not</i></strong> be recorded under this tab and <strong><i>will not</i></strong> be included in your record of completed trainings. Certificates to be used for evaluation of your preservice and ongoing professional development requirements should be listed in the <u>My Training</u> area.</div>'
    }
  },
  jh: {
    message: {
      id: 'Registry360 ID',
      textID: 'Registry360',
      pdpInfo: 'State',
      threeFiftyRole: 'Staff',
      agencyArticle: 'an',
      agency: 'Agency',
      agencyPlural: 'Agencies',
      facility: 'Agency',
      program: 'program',
      terms: 'Terms and Conditions',
      additionalInstructions: '',
      pdpText: '',
      rolePromotionWarning:
        'Are you sure you want to submit this request? Your request for a staff role at this organization, if approved, will allow program Directors to access relevant information like your training records, awards, certifications, experience, and education.',
      uploadResumeBtn: 'Upload Resume',
      uploadResumeInstructions: 'You can upload a resume directly or have it generated from your profile.',
      myResumeInstructions: 'Please upload or generate your resume here.',
      certificationCredentialsTab: '<div class ="tenant-certificate"> Please upload the supporting documents for your Early Childhood-related Certifications and/or Credentials here (Ex. First Aid/CPR card). All information you enter <strong>must match</strong> the uploaded document. A red asterisk <span style="color:red">(*)</span> indicates a required item.Training certificates <strong>should not</strong> be recorded under this tab and <strong>will not</strong> be included in your record of completed trainings. Certificates to be used for evaluation of your preservice and ongoing professional development requirements should be listed in the <u>My Training</u> area.</div>'
    }
  }
};

function getText(key) {
  return localized[locale]['message'][key];
}

export { localized, locale, getText };
