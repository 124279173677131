<template>
  <div data-app>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat class="cal-toolbar-padding">
            <v-btn outlined
                   class="mr-sm-4 text-capitalize"
                   @click="setToday">
              Today
            </v-btn>
            <v-btn fab
                   text
                   small
                   @click="prev">
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn fab
                   text
                   small
                   @click="next"
                   class="mr-2">
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" class="text-center d-none d-sm-block">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom
                    right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined
                       color="#000"
                       v-bind="attrs"
                       v-on="on">
                  <span class="text-capitalize">{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'month'" v-if="type != 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'" v-if="type != 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'day'" v-if="type != 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-toolbar-title v-if="$refs.calendar" class="text-center d-block d-sm-none">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-sheet>
        <v-sheet height="600" class="shift-cal-down">
          <v-calendar ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-color="getEventColor"
                      :event-height="22"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="getEvents">
            <template v-slot:event="{ event, eventParsed }">
              <div tabindex="0" :class="getEventStatus(event, true) + ' v-event-summary fill-height pl-2'">
                <span class="font-weight-bold" v-if="event.timed">
                  {{getEventStartTime(eventParsed.start)}}
                </span>
                <span :aria-label="getEventStatus(event, false) + event.name">{{ event.name }}</span>
              </div>
            </template>
          </v-calendar>
          <v-menu v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x>
            <v-card max-width="450px"
                    flat
                    id="eventPopup">
              <v-toolbar :class="getEventStatus(selectedEvent, true)">
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text
                       @click="selectedOpen = false"
                       :class="selectedEvent.status == 8 ? 'btn-event-close-light' : 'btn-event-close-dark'">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <div v-html="selectedEvent.description"></div>
                <div v-if="!selectedEvent.timed"><strong>All day event</strong></div>
                <div v-if="!selectedEvent.timed && selectedEvent.start"><strong>Start:</strong> {{new Date(selectedEvent.start).toLocaleDateString()}}</div>
                <div v-if="!selectedEvent.timed && selectedEvent.end"><strong>End:</strong> {{new Date(selectedEvent.end).toLocaleDateString()}}</div>
                <div v-if="selectedEvent.timed && selectedEvent.start"><strong>Start:</strong> {{getTimeDisplay(selectedEvent.start)}}</div>
                <div v-if="selectedEvent.timed && selectedEvent.end"><strong>End:</strong> {{getTimeDisplay(selectedEvent.end)}}</div>
                <div class="row mt-2" v-if="!is250User() && !trainingInPast(selectedEvent)">
                  <div class="col-3"></div>
                  <button @click="cancelTraining(selectedEvent)" class="btn btn-primary btn-pointer-events-visible col-6">Cancel Registration</button>
                  <div class="col-3"></div>
                </div>
              </v-card-text>
              <v-card-actions>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<style>
  /* Circles around dates */
  .theme--light.v-btn.v-btn--has-bg {
    background-color: inherit !important;
    color: inherit !important;
  }

  /* All Day event popup, shorten width */
  .v-menu__content {
    min-width: auto !important;
  }

  /* Today circle */
  .v-btn--fab.v-size--small {
    height: 25px !important;
    width: 25px !important;
  }

  .v-calendar-daily_head-day-label .v-btn--fab.v-size--default {
    height: 35px !important;
    width: 35px !important;
  }

  /* Today circle space between circle and event */
  .v-calendar-weekly__day-label {
    margin-bottom: 3px !important;
  }

  /* Event colors */
  .active-training {
    background: #21BA45 !important;
    color: #000 !important;
    border: 1px solid #dabdd6 !important;
    border-radius: 4px !important;
  }

  .waitlist-training {
    background:  #E03997 !important;
    color: #000 !important;
    border: 1px solid #8b4481 !important;
    border-radius: 4px;
  }

  .pending-training {
    background: #FBBD08 !important;
    color: #000 !important;
    border: 1px solid #8b4481 !important;
    border-radius: 4px;
  }

  .cancelled-training {
    background: #dda0a0 !important;
    color: #820f0d !important;
    border: 1px solid #e0bcbb !important;
    border-radius: 4px !important;
  }

  .all-day {
    background: #454372 !important;
    color: #fff !important;
    border: 1px solid #2d2b60 !important;
    border-radius: 4px !important;
  }

  .past {
    background: #e5e5e5 !important;
    color: #666 !important;
    border: 1px solid #666 !important;
    border-radius: 4px !important;
  }

  /* Add space to left of bullet in Event Popup */
  #eventPopup li {
    margin-left: 15px;
  }

  /* Calendar Daily Text Modal */
  .v-card__text {
    color: #444444 !important;
  }

    .v-card__text a {
      color: blue;
    }

      .v-card__text a:hover,
      .v-card__text a:focus {
        color: #833a78;
      }

  /* Popup Close (x) button */
  .btn-event-close-light,
  .btn-event-close-light {
    color: #444444 !important;
  }
  .btn-event-close-dark,
  .btn-event-close-dark {
    color: #fff !important;
  }

  .btn-event-close-light:hover,
  .btn-event-close-light:focus {
    color: #444444 !important;
    outline: 1px solid #444444;
  }

  .btn-event-close-dark:hover,
  .btn-event-close-dark:focus {
    color: #444444 !important;
    outline: 1px solid #444444;
  }

  /* List item */
  .v-list-item__title:hover,
  .v-list-item__title:focus {
    text-decoration: underline;
  }

  /*Extra border around parent div of event*/
  .theme--light.v-calendar-events .v-event-timed {
    border: none !important;
  }

  /*Margin around toolbar content*/
  .cal-toolbar-padding .v-toolbar__content, .v-toolbar__extension {
    padding: 4px 0px !important;
  }

  @media only screen and (max-width: 576px) {
    .shift-cal-down {
      top: 34px;
      position: relative;
    }
  }
</style>
<script>
import { mapGetters } from 'vuex';
export default {
  isBusy: false,
  name: 'CalendarView',
  data: () => ({
    focus: '',
    type: 'month',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: []
  }),
  computed: {
    ...mapGetters(['activeRole'])
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.getEvents();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    is250User () {
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName=='250')
        return true;
      else
        return false;
    },
    trainingInPast(training) {
      if (!training) return false;
      if (new Date(training.end) < new Date())
        return true;
      else 
        return false;
    },
    getEvents() {
      let storeEvent;
      if (this.is250User())
        storeEvent = 'trainerCalendarEventRequest';
      else
        storeEvent = 'calendarEventRequest';
      this.isBusy = true;
      this.$store
        .dispatch(storeEvent)
        .then(async (response) => {
          response.forEach( (item) => {
            item.start = item.start.substring(0,16);
            item.end = item.end.substring(0,16);
          });
          this.events = response;
          this.isBusy = false;
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    getEventColor(event) {
      return event.color
    },
    getEventStatus(event, css) {
      let cssClass = '';
      let statusName = '';
      if (this.trainingInPast(event)) {
        cssClass = 'past';
        statusName = 'Past event: ';
      } else if (!event.timed) {
        cssClass = 'all-day';
        statusName = 'All day event: ';
     } else if (event.status === 1) {
        cssClass = 'active-training';
        statusName = 'Active event: ';
      } else if (event.status === 4) {
        cssClass = 'waitlist-training';
        statusName = 'Waitlist event: ';
      } else if (event.status === 8) {
        cssClass = 'pending-training';
        statusName = 'Pending event: ';
      }
      if (css == true) {
        return cssClass;
      } else {
        return statusName;
      }
    },
    getEventStartTime(start) {
      let hour = '';
      let minutes = '';
      let dn = '';

      if (start.hour > 12) {
        hour = start.hour - 12;
        dn = 'PM';
      } else {
        hour = start.hour;
        dn = 'AM';
      }

      if (start.minute < 10) {
        minutes = '';
      } else {
        minutes = ':' + start.minute;
      }

      const time = hour + minutes + ' ' + dn;
      return time;
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    getTimeDisplay(dtStr) {
      const dt = new Date(dtStr);
      const ts = dt.toLocaleTimeString();
      return `${ts.substring(0,ts.lastIndexOf(':'))}${ts.substring(ts.lastIndexOf(' '))}`;
    },
    async cancelTraining(item) {
        const confirmTitle = "Confirm Cancellation";
        const confirmText = "Your cancellation request will be processed immediately and can not be reversed. Please click Confirm to cancel your registration.";
        const options = { title: confirmTitle, okLabel: 'Confirm', cancelLabel: 'Cancel' };
        this.$dialogs
          .confirm(confirmText, options)
          .then((res) => {
            //if we clicked continue
            if (res && res.ok && res.ok == true) {
              const body = {
                courseSectionId: item.id,
                userIds: [this.$store.getters['userId']],
                comment: ''
              };
              this.isBusy = true;
              this.$store
                .dispatch('cancelRegistrationRequest', body)
                .then(async (response) => {
                    const options = { title: 'Cancellation Request Complete!' };
                    this.$dialogs.alert(`You have successfully unregistered for  ${item.name}`, options);
                    this.getEvents();
                  })
                .catch((errors) => {
                  this.isBusy = false;
                });
            }
          });
    }
  }
};
</script>
