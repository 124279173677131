<template>
  <div>
    <div>
      <grid
        :entityTypeId="entityTypeId"
        :formId="formId"
        :entityTypeName="entityTypeName"
        :primaryEntity="primaryentity"
        :showFormOnEmpty="true"
        :showContinueButton="true"
        :additionalActions="additionalActions"
        :additionalActionsOnColumn="true"
        @continueButton="$emit('continueButton')"
        @dirty="$emit('dirty', $event)"
        :key="  gridKey"
        :basePageSize="gridPageSize"
        :baseCurrentPage="gridCurrentPage"
        @setPageSize="setPageSize"
        @setCurrentPage="setCurrentPage"
      >
      </grid>
    </div>
  </div>
</template>

<script>
import { locale, getText } from '../../scripts/localized';
import { mapGetters } from 'vuex';
export default {
  name: 'ProfileEducation',
  data() {
    return {
      locale: locale,
      isBusy: false,
      entityTypeId: 4,
      formId: 2,
      gridKey: 'education',
      entityTypeName: 'Education',
      additionalActions: [
        {
          title: 'Request Verification',
          icon: 'plus-circle',
          click: this.makeVerificationRequest,
          check: this.shouldShowVerificationRequest
        }
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['userOrganizationRoles'])
  },
  methods: {
    async makeVerificationRequest(item) {
      return await new Promise((resolve, reject) => {
        const confirmText = `Once verification is requested, the record can not be edited unless it is returned by the ${getText('program')}'s director(s) or designee.  Please click Confirm to continue.`
        const confirmOptions = {
          title: "Request Verification",
          cancelLabel: 'Cancel',
          okLabel: 'Confirm'
        };
        this.$dialogs
          .confirm(confirmText, confirmOptions)
          .then((res) => {
            if (res && res.ok && res.ok == true) {
              return new Promise((resolve, reject) => {
                const body = {
                  entityTypeId: this.entityTypeId, 
                  entityId: item.id
                };
                this.isBusy = true;
                this.$store
                  .dispatch('verificationRequestRequest', body)
                  .then(async (response) => {
                    this.isBusy = false;
                    const alertOptions = { title: 'Verification request submitted!' };
                    this.$dialogs.alert(`Your request for verification has been successfully submitted to your ${getText('program')}'s director(s) or designee for review.`, alertOptions);
                    this.gridKey += '-1';
                    resolve(true);
                  })
                   .catch((errors) => {
                    this.isBusy = false;
                    resolve(false);
                  });
                });
                }
              })
              .catch((error) => {
                resolve(false);
        });
    });
    },
     shouldShowVerificationRequest(item) {
      if (item.verified || (item.verificationStatus == 'Requested'))
        return false;
      else
        return true;
    },
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    }  
  }
};
</script>
