<template>
  <div>
    <div id="myCarousel" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div id="homepageBanner"></div>
          <div class="container">
            <div class="carousel-caption text-center">
              <h1>Welcome to the Tennessee <br />Professional Archive of Learning</h1>
              <div v-if="!isAuthenticated">
                <p><br />Create Your Free Account Today!</p>
                <p>
                  <router-link exact :to="'/login/register'" class="btn btn-lg btn-main" role="button"
                    >Start Now</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Messaging and featurettes
    ================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->
    <div class="container marketing">
      <!-- Columns of text below the carousel -->
      <div class="row learn-more">
        <div class="col-lg-6">
          <p class="my-1">About TNPAL</p>
          <p>Learn how TNPAL can benefit you</p>
          <p>
            <router-link exact :to="'/about'"
              ><a class="btn btn-primary" role="button">Learn More &raquo;</a></router-link
            >
          </p>
        </div>
        <div class="col-lg-6">
          <p class="my-1">About Training</p>
          <p>Learn what training is included</p>
          <p>
            <router-link exact :to="'/training'"><a class="btn btn-primary">Learn More &raquo;</a></router-link>
          </p>
        </div>
        <!--<div class="col-lg-4">
          <p class="my-1">Career Opportunities</p>
          <p>Benefits Advance in your profession <br />with Opportunities provided</p>
          <p><router-link exact :to="'/opportunities'"><a class="btn btn-primary">Learn More &raquo;</a></router-link></p>
       </div>-->
      </div>
      <hr class="featurette-divider" />
      <div class="row featurette">
        <div class="col-md-12 text-center">
          <div><h2>TNPAL offers the following features!</h2></div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-lg"></div>
        <div class="col-lg-8 pl-lg-5">
          <div style="font-size: 1.1rem">
            <ul>
              <li class="mb-3">Creating your own early childhood professional and training profile.</li>
              <li class="mb-3">Documenting your professional accomplishments and professional certificates.</li>
              <li class="mb-3">Developing your own professional development plan.</li>
              <li class="mb-3">
                Collaborating with state-designated coaches to help you identify and reach your goals.
              </li>
              <li class="mb-3">
                Linking to organizations recognized by TDHS that offer early childhood education training, including
                CCR&R, TECTA, TCCOTS, and more!
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="single-top-det-right">
            <img src="../../../images/home/Students_standing.jpg" alt="group of diverse student standing" />
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      imagename: 'idealslogo.png'
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>

<style></style>
