<template>
  <div :class="isBusy ? 'disabled-area' : ''">
    <div v-if="!formInfo.elements" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div>
      <trainingPropertyDisplay 
        v-if="showParentForm"
        :currentEntity="primaryEntity"
        :formId="parentFormId"
      >
      </trainingPropertyDisplay>

    </div>
    <div class="container-fluid">
      <form @submit.prevent="handleSubmit">
        <div class="form-row">
          <component
            v-for="control in visibleFormElements"
            v-bind:key="control.id"
            :is="control.type"
            v-bind="control"
            :warningMessage="control.warningMessage ? control.warningMessage : undefined"
            :regex="control.regex ? control.regex : undefined"
            :maxLength="control.maxLength > 0 ? control.maxLength : undefined"
            :width="control.width > 0 ? control.width : undefined"
            v-model="formData[control.id]"
            @input="updateForm(control.id, $event)"
            :readOnly="isControlReadonly(control.readOnly)"
            :subtype="control.subtype ? control.subtype : undefined"
            :primaryEntity="primaryEntity"
            :arguments="control.arguments ? JSON.parse(control.arguments) : undefined"
            @crossRef="crossRefSelected(control.id, $event, control.arguments)"
            @peerRef="peerRefSelected(control.id, $event)"
            :selectedReference="selectedReference"
            :peerReference="peerReference"
            :viewMode="viewmode"
            :entityTypeId="entityTypeId"
            :ref="'formElement' + control.id"
            :formData="formData"
            @busy="formBusy($event)"
          >
          </component>
        </div>
        <br />
        <div>
          <div style="display: inline">
            <input
              type="submit"
              v-if="formInfo.elements && !viewmode"
              class="btn btn-primary"
              :value="saveButtonName"
            />
            <input
              type="button"
              class="btn btn-primary"
              @click="goEditMode()"
              v-if="formInfo.elements && viewmode && showEditButton"
              value="Edit"
            />
          </div>
          <div style="display: inline; float: right">
            <input
              type="button"
              v-if="formInfo.elements && showContinueButton"
              class="btn btn-primary"
              :value="continueButtonName"
              @click="continueButtonClick()"
              id="btnContinue"
            />
            <input
              type="button"
              v-if="firstTime && (entityTypeId == 10 || entityTypeId == 30)"
              class="btn btn-primary"
              value="Close"
              @click="closeFirstTimeForm()"
            />
          </div>
        </div>
      </form>

      <div v-if="showComments && activeEntity.Id" :class="showEditButton ? 'mt-5 ' : ''">
        <grid
          :entityTypeId="commentEntityTypeId"
          :formId="commentFormId"
          :entityTypeName="'Comment'"
          :primaryEntity="activeEntity"
          :showFormOnEmpty="addComments"
          :withinModal="true"
          :showAddButton="addComments"
          :showEdit="false"
          :showNoDataWarning="true"
          :allowDownload="false"
          :showActionColumn="false"
          :skinName="'comment'"
          :basePageSize="gridPageSize"
          :baseCurrentPage="gridCurrentPage"
          @setPageSize="setPageSize"
          @setCurrentPage="setCurrentPage"
        >
        </grid>
      </div>

      <div class="errors-container" v-if="errors">
        {{ errors }}
      </div>
    </div>
  </div>
</template>

<script>
import arbText from '@components/form/arb-text.vue';
import checkbox from '@components/form/checkbox.vue';
import dropdown from '@components/form/dropdown.vue';
import phone from '@components/form/phone.vue';
import autocomplete from '@components/form/autocomplete.vue';
import fileupload from '@components/form/fileupload.vue';
import radio from '@components/form/radio.vue';
import textArea from '@components/form/text-area.vue';
import hidden from '@components/form/hidden.vue';
import richText from '@components/form/rich-text.vue';
import entityCrossReference from '@components/form/entity-cross-reference.vue';
import entityPropertyCrossReference from '@components/form/entity-property-cross-reference.vue';
import { applyRules } from '@scripts/form-rules.js';
import { loadParentRules } from '@scripts/form-rules.js';
import trainingPropertyDisplay from '@components/dashboard-items/management/training-property-display.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    arbText,
    checkbox,
    dropdown,
    phone,
    autocomplete,
    fileupload,
    radio,
    textArea,
    hidden,
    richText,
    entityCrossReference,
    entityPropertyCrossReference,
    trainingPropertyDisplay
  },
  name: 'form-base',
  props: {
    parentFormId: {
      type: Number
    },
    formid: {
      type: Number,
      required: true
    },
    entities: {
      type: Array,
      default: () => []
    },
    startinviewmode: {
      type: Boolean,
      default: false
    },
    primaryEntity: {
      type: Object,
      default: function () {
        return { Id: '', EntityTypeId: 0 };
      }
    },
    saveButtonName: {
      type: String,
      default: 'Save'
    },
    showContinueButton: {
      type: Boolean,
      default: false
    },
    continueButtonName: {
      type: String,
      default: 'Continue'
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showComments: {
      type: Boolean,
      default: false
    },
    addComments: {
      type: Boolean,
      default: false
    },
    firstTime: {
      type: Boolean,
      default: false
    },
    showParentForm: {
      type: Boolean,
      default: false
    },
    parentProps: { 
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['activeRole', 'isAuthenticated']),
    addingNew: function () {
      if (this.viewmode) return false;
      for (let i = 0; i < this.entities.length; i++) {
        if (this.entities[i].id) {
          return false;
        }
      }
      return true;
    },
    visibleFormElements: function () {
      // resolves (vue/no-use-v-if-with-v-for)
      if (this.formInfo && this.formInfo.elements) return this.formInfo.elements.filter((x) => x.visible === true);
      return [];
    }
  },
  created() {
    this.viewmode = this.startinviewmode;
    if (this.entities && this.entities.length > 0 && this.entities[0].EntityTypeId) {
      this.entityTypeId = this.entities[0].EntityTypeId;
      this.activeEntity = this.entities[0];
    }
    this.isBusy = true;
    const params = {
      formid: this.formid,
      entities: this.entities,
      primaryEntity: this.primaryEntity
    };
    this.$store
      .dispatch('formRequest', params)
      .then((result) => {
        this.isBusy = false;
        if (result && result.arguments) {
          const parsed = JSON.parse(result.arguments);
          if (parsed) result.arguments = parsed;
        }
        this.formInfo = result;
        for (let i = 0; i < result.elements.length; i++) {
          const control = result.elements[i];
          if (control.type === 'checkbox'){
            control.startingValue= [];  
            if (control.id && control.multiValue) {
              this.$set(this.formData, control.id, control.multiValue);
              this.$emit('input', this.formData);
              control.startingValue = control.multiValue;
            }
          } 
          else {
            control.startingValue = '';
            if (control.id && control.value) {
              this.$set(this.formData, control.id, control.value);
              this.$emit('input', this.formData);
              control.startingValue = control.value;
            }
          }       

          //if the control is readonly by default but we're adding a new record, make it not readonly
          //otherwise they would never be able to enter data in it
          if (!control.value && control.readOnly && this.addingNew) control.readOnly = false;
        }
        this.formInfo.parentProps = this.parentProps; //this is where they get put into the form initially
        loadParentRules(this.formInfo, this.formData, this.activeRole);
        applyRules(this.formInfo, this.formData, null, this.activeRole);
        this.$emit('dirty', false);
      })
      .catch((errors) => {
        this.isBusy = false;
        this.errors = errors.response.data;
      });
  },
  data() {
    return {
      errors: '',
      formInfo: {},
      formData: this.value || {},
      isBusy: false,
      viewmode: false,
      selectedReference: {},
      peerReference: {},
      isDirty: false,
      entityTypeId: 0,
      commentEntityTypeId: 16,
      commentFormId: 14,
      activeEntity: {},
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    getVModel () {

    },
    updateForm(fieldName, value) {
      let existingValue = '';
      let compareValue = value;
      const element = this.getFormElement(fieldName);
      if (element.type === 'checkbox') return;
      if (element && element.value) {
        existingValue = element.value;
        if (element.type === 'checkbox') {
          if (existingValue.includes(compareValue))
            existingValue = existingValue.filter(function(value, index,arr) { return value !== compareValue});
          else
            existingValue.push(compareValue);
          value = existingValue;
        }
        if (element.type == 'phone') {
          //ignore auto formatting for comparison
          existingValue = existingValue.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
          compareValue = compareValue.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
        }
        if (element.type == 'autocomplete' || element.type == 'entityCrossReference') {
          //just get the key value
          if (this.isJsonString(existingValue)) {
            const kvp = JSON.parse(existingValue);
            if (kvp && kvp.key) existingValue = kvp.key;
          }
          if (this.isJsonString(compareValue)) {
            const kvp = JSON.parse(compareValue);
            if (kvp && kvp.key) compareValue = kvp.key;
          }
        }
      }
      if (existingValue != compareValue) {
        this.$emit('dirty', true);
        this.isDirty = true;
      }
      this.$set(this.formData, fieldName, value);
      if (element) element.value = value;

      this.formInfo.parentProps = this.parentProps; //this is where they get put back into the form on update
      loadParentRules(this.formInfo, this.formData, this.activeRole);
      applyRules(this.formInfo, this.formData, fieldName, this.activeRole);
      this.$emit('input', this.formData);
    },
    getFormElement(elementId) {
      if (this.formInfo && this.formInfo.elements && this.formInfo.elements.length > 0) {
        const elementMatches = this.formInfo.elements.filter((element) => element.id == elementId);
        if (elementMatches && elementMatches.length > 0) {
          return elementMatches[0];
        }
      }
      return undefined;
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    crossRefSelected(fieldName, value, elArgs) {
      const args = JSON.parse(elArgs);
      let valueToUse = '';
      if (value && value.key) valueToUse = value.key.toString();
      if (args && args.ecrEntityTypeId) {
        this.selectedReference = {
          formElementId: fieldName,
          entityId: valueToUse,
          entityTypeId: args.ecrEntityTypeId
        };
      }
    },
    peerRefSelected(fieldName, value) {
      let valueToUse = '';
      if (value && value.key) valueToUse = value.key.toString();
      this.peerReference = {
        peerElementId: fieldName,
        peerValue: valueToUse
      };
    },
    closeFirstTimeForm() {
      this.$emit('closed');
    },
    async handleSubmit(event) {
      for (const key of Object.keys(this.$refs)) {
        const ref = this.$refs[key][0];
        //for date elements we need to do custom comparison validations
        if (
          ref &&
          ref.subtype &&
          ['date','datetime'].includes(ref.subtype) &&
          ref.arguments &&
          ref.arguments.comparisons &&
          ref.checkDateComparisons
        ) {
          const result = ref.checkDateComparisons();
          if (!result) return;
        }
        //for checkboxes we need to check if required elements have at least one option checked
        if (
          ref &&
          ref.$attrs &&
          ref.$attrs.type &&
          ref.$attrs.type === 'checkbox' 
        ) {
          const result = ref.checkIfSelectionMade();
          if (!result) return;
        }
        //for rich text elements we need custom validation since there is no html5 element
        if (
          ref &&
          ref.$attrs &&
          ref.$attrs.type &&
          ref.$attrs.type === 'richText' 
        ) {
          const result = ref.checkIfFieldIsPopulated();
          if (!result) return;
        }
      }

      //get the responses formatted for upload
      const eleVals = [];
      for (const key in this.formData) {
        if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
          let elementResponse;
          if (Array.isArray(this.formData[key]))
            elementResponse = {
              formElementId: key,
              multiValue: this.formData[key]
            };
          else
            elementResponse = {
              formElementId: key,
              value: this.formData[key].trim()
            };
          eleVals.push(elementResponse);
        }
      }
      const response = {
        formId: this.formid,
        elementResponses: eleVals
      };

      //api call to check if override is needed or save should be blocked
      const userAccept = await this.preSubmissionValidation();

      if (userAccept) {
        //make api call to save data then emit that we saved so the parent component can route to the appropriate place
        const body = {
          response: response,
          entities: this.entities,
          primaryEntity: this.primaryEntity
        };
        this.isBusy = true;
        this.$store
          .dispatch('formResponseRequest', body)
          .then((result) => {
            this.isBusy = false;
            this.isDirty = false;
            this.$emit('dirty', false);
            this.$emit('saved', true);
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = errors.response.data;
          });
      }
    },
    goEditMode() {
      this.$emit('editing', true);
      this.viewmode = false;
    },
    async continueButtonClick() {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isDirty = false;
        this.$emit('dirty', false);
        this.$emit('continueButton');
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to leave without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else resolve(true);
      });
    },
    isControlReadonly(roSetting) {
      return this.viewmode || roSetting;
    },
    async preSubmissionValidation() {
      return await new Promise((resolve, reject) => {
        const validations = [];
        if (
          this.formInfo &&
          this.formInfo.arguments &&
          this.formInfo.arguments.preSubmissionValidation &&
          this.formInfo.arguments.preSubmissionValidation.formElementIds &&
          this.formInfo.arguments.preSubmissionValidation.formElementIds.length > 0
        ) {
          this.formInfo.arguments.preSubmissionValidation.formElementIds.forEach((formElementId) => {
            const elementValue = this.formData[formElementId];
            validations.push({
              entityTypeId: this.entityTypeId,
              formElementId: formElementId,
              value: elementValue
            });
          });
        }
        if (validations.length > 0) {
          const params = {
            validations: validations,
            entities: this.entities,
            primaryEntity: this.primaryEntity
          };
          this.isBusy = true;
          this.$store
            .dispatch('preSubmissionValidationRequest', params)
            .then(async (response) => {
              this.isBusy = false;
              if (response.messages.length == 0) resolve(true);
              else {
                let message = '';
                if (response.messages.length == 1) {
                  message = response.overridable
                    ? '<p>Are you sure you want to submit this form?'
                    : '<p>This request could not be submitted.';
                  message += ` ${response.messages[0]} `;
                  message += response.overridable ? 'If so, click Save to continue.</p>' : '';
                } else {
                  message = response.overridable
                    ? '<p>Are you sure you want to submit this form?</p>'
                    : '<p>This request could not be submitted.</p>';
                  message += '<ul>';
                  response.messages.forEach((m) => (message += `<li>${m}</li>`));
                  message += '</ul>';
                  message += response.overridable ? '<p>If so, click Save to continue.</p>' : '';
                }
                if (response.overridable) {
                  const options = {
                    title: 'Confirm Submission',
                    cancelLabel: 'Cancel',
                    okLabel: 'Save'
                  };
                  this.$dialogs
                    .confirm(message, options)
                    .then((res) => {
                      if (res && res.ok && res.ok == true) {
                        resolve(true);
                      } else {
                        resolve(false);
                      }
                    })
                    .catch((error) => reject(error));
                } else {
                  const alertOptions = { title: 'Submission Error' };
                  this.$dialogs.alert(message, alertOptions);
                  resolve(false);
                }
              }
            })
            .catch((errors) => {
              this.isBusy = false;
              reject(errors);
            });
        } else resolve(true);
      });
    },
    formBusy(eventVal) {
      this.isBusy = eventVal;
    }
  }
};
</script>
