<template>
  <div class="messagebox">
    <div
      class="row message fa-close align-items-center"
      :class="{
        read: notification.read,
        unread: !notification.read,
        selected: notification.selected
      }"
    >
      <div class="col-2 text-center check-star">
        <div class="row">
          <div class="check-row-item">
            <b-form-checkbox
              :class="'checkbox' + notification.selected"
              v-model="notification.selected"
            ></b-form-checkbox>
          </div>
          <button @click="starNotification(notification)" class="check-row-item abtn btn ml-1 ml-lg-3 p-0">
            <icon v-if="notification.starred" class="notification-starred" icon="star"></icon>
            <icon v-if="!notification.starred" :icon="['far', 'star']" class="star"></icon>
          </button>
        </div>
      </div>
      <div class="col-5 col-md-8" style="display: grid">
        <button class="message-data message-content abtn btn" @click="openNotification(notification)">
          {{ notification.subject
          }}<span v-if="notification.body" class="message-content-body"> - {{ stripHtml(notification.body) }}</span>
        </button>
      </div>
      <div
        class="col-4 col-md-2 text-center message-date"
        @click="openNotification(notification)"
        :title="formatFullDate(notification.creationDateTime)"
      >
        {{ formatFullDate(notification.creationDateTime) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationItem',
  props: ['notification', 'starNotification', 'bulkSelect', 'openNotification', 'userColumnValue', 'formatFullDate'],
  methods: {
    stripHtml(html) {
      const tmp = document.implementation.createHTMLDocument('New').body;
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    }
  }
};
</script>
