import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import '@plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueMask from 'v-mask';
import VueIziToast from 'vue-izitoast';
import PackageDownIcon from 'vue-material-design-icons/PackageDown.vue';
import Multiselect from 'vue-multiselect';
import Vue2TouchEvents from 'vue2-touch-events';
import IdleVue from 'idle-vue';
import { sync } from 'vuex-router-sync';
import { CoolSelectPlugin } from 'vue-cool-select';
import { CoolSelect } from 'vue-cool-select';
import interceptorSetup from '@api/interceptor';
import ImageComponent from '@components/utility/image-component.vue';
import WfrForm from '@components/form/form-base.vue';
import Grid from '@components/utility/grid.vue';
import Modal from '@components/utility/modal.vue';
import SlimDialog from '@components/utility/dialog/index';
import DateFormatter from '@plugins/date-formatter';
import FileDownloader from '@plugins/file-downloader';
import vuetify from '@plugins/vuetify';

import MessageHub from '@scripts/message-hub';
import { FontAwesomeIcon } from '@scripts/icons';

import { localized, locale } from '@scripts/localized';

import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'v-slim-dialog/dist/v-slim-dialog.css';
import 'izitoast/dist/css/iziToast.min.css';
import 'vue-material-design-icons/styles.css';
import 'vue-cool-select/dist/themes/bootstrap.css';

Vue.use(CoolSelectPlugin);
Vue.use(VueMask);
Vue.use(SlimDialog);
Vue.use(VueIziToast);
Vue.use(MessageHub);
Vue.use(Vue2TouchEvents);
Vue.use(DateFormatter);
Vue.use(FileDownloader);

Vue.use(VueI18n);

const eventsHub = new Vue();
Vue.use(IdleVue, { idleTime: 900000, eventEmitter: eventsHub });

// Registration of global components
Vue.component('icon', FontAwesomeIcon);
Vue.component('imagecomponent', ImageComponent);
Vue.component('wfrform', WfrForm);
Vue.component('grid', Grid);
Vue.component('modal', Modal);
Vue.component('auto-complete', CoolSelect);
Vue.component('archive-icon', PackageDownIcon);
Vue.component('multiselect', Multiselect);

Vue.directive('confirm-leave', {
  // When the bound element is inserted into the DOM...
  bind: function (el, binding, vnode) {
    el.addEventListener('mousedown', async (event) => {
      //event.which tells us which button they clicked, 1 is left click
      if (event && 'which' in event && event.which == 1) {
        //ask the user to confirm that they want to leave the site
        const confirmed = await new Promise((resolve, reject) => {
          const options = {
            title: 'Confirmation Message',
            cancelLabel: 'Cancel'
          };
          vnode.context.$dialogs
            .confirm('Are you sure you want to leave the registry?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
        if (confirmed) event.target.click();
      }
    });
  }
});

Vue.prototype.$http = axios;

sync(store, router);

store.dispatch('configRequest', 'QuickSightEnabled').then((result) => {
  if (result === 'True') store.commit('enableQuickSight');
});
interceptorSetup();

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: locale, // set locale
  messages: localized, // set locale messages
});

new Vue({
    router,
    store,
    vuetify,
    onIdle() {
      store.dispatch('appIdleStatusRequest', true);
    },
    onActive() {
      store.dispatch('appIdleStatusRequest', false);
    },
    render: (h) => h(App),
    i18n
}).$mount('#app');

export default i18n;