<template>
  <div :class="isBusy ? 'disabled-area' : ''">
    <div v-if="isBusy" class="loader">
      <icon icon="spinner" pulse />
      <p><em>Loading...</em></p>
    </div>
    <div class="mb-4">
      <p>{{ $t('message.uploadReferenceInstructions') }}</p>
      <b-form-group label="Choose an option">
        <b-form-radio-group
          v-model="selectedOption"
          :options="radioOptions"
          buttons
          button-variant="outline-wfr"
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { getText, locale } from '@scripts/localized';
export default {
  name: 'ToggleReference',
  data() {
    return {
      isBusy: false,
      selectedOption: null,
      radioOptions: [
        { text: 'Add a new Reference', value: 'enterReference' },
        { text: `${getText('uploadReferenceBtn')}`, value: 'uploadReference' }
      ]
    };
  },
  watch: {
    selectedOption: function (newVal) {
      if (newVal == 'uploadReference') {
        this.$emit('showForm', true);
      } else {
        this.$emit('showAltForm', true);
      }
    }
  },
  created() {
    this.$emit('showForm', false);
  },
  props: {}
};
</script>
