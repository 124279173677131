<template>
  <header>
    <div class="header-blue-area"></div>
  </header>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
