<template>
  <section class="container-fluid">
    <div class="profile-form-container">
      <div class="has-text-centered">
        <div class="mb-4 text-center">
            <h1>Career Lattice Info</h1>
        </div>
        <div style="display: flex; align-items: center; justify-content: center">
          <div class="col-sm-12 row cl-info" style="max-width: 1200px">
            <h6>Alabama Pathways Professional Development Lattice</h6>
            <p>
              The Alabama Pathways Professional Development Lattice is a system that provides early care and education
              professionals with a tool to track their professional development. Each user can identify her/his current
              place on the lattice, as well as the progressive path to enhanced training and education within the field of
              early care and education.
            </p>
            <p>
              The Alabama Pathways Professional Development Lattice reflects eight levels of training and education to guide
              caregivers and teachers of children from birth through 12 years on the path that promotes high-quality care
              and education.
            </p>
            <p>
              The levels include training, credentials, certificates, and degrees in child development, early childhood
              education, and elementary education, with a focus on knowledge and skills specific to early care and
              education. The training and education resources include agencies, organizations, and institutions that provide
              training and education specific to early childhood and school-age care.
            </p>
            <table class="cl-info-table">
              <th>LEVEL</th>
              <th>TRAINING AND EDUCATION</th>
              <th>RESOURCES</th>
              <tbody style="border-top: solid purple">
                <tr>
                  <th>Level 1</th>
                  <td>
                    Performance Standards training requirements and/or Alabama Early Learning Guidelines (AELG) credential
                    <br />
                    <br />
                    Alabama Infant/Toddler credential
                  </td>
                  <td style="line-height: normal">
                    Alabama Department of Human Resources (DHR), Child Care Services Division <br />
                    <br />
                    DHR quality enhancement contractors <br />
                    <br />
                    Local, statewide, regional, and national professional child care organizations
                  </td>
                </tr>
                <tr>
                  <th>Level 2</th>
                  <td>
                    Child Development Associate (CDA) credential or other national credential in child/care/early care and
                    education
                  </td>
                  <td>
                    Council for Professional Recognition <br />
                    <br />
                    DHR regional quality enhancement contractors <br />
                    <br />
                    Alabama Community College System (ACCS) institutions
                  </td>
                </tr>
                <tr>
                  <th>Level 3</th>
                  <td>Short certificate and/or certificate in child development/early care and education</td>
                  <td>
                    Alabama Community College System (ACCS) <br />
                    <br />
                    Two-year institutions
                  </td>
                </tr>
                <tr>
                  <th>Level 4</th>
                  <td>
                    Associate in Applied Science/Technology (AAS or AAT) <br />
                    <br />
                    Degree in child development/early care and education and/or Associate in Arts/ Science (AA or AS) <br />
                    <br />
                    Degree in child development/early childhood education, including 18 child development/early childhood
                    specific credits
                  </td>
                  <td>
                    Alabama Community College System (ACCS) <br />
                    <br />
                    Two-year institutions
                  </td>
                </tr>
                <tr>
                  <th>Level 5</th>
                  <td>
                    Bachelor’s degree in child development, early childhood education, or elementary education, including 30
                    child development/ early childhood specific credits
                  </td>
                  <td>
                    Athens State University <br />
                    <br />
                    Public and private four-year colleges and universities
                  </td>
                </tr>
                <tr>
                  <th>Level 6</th>
                  <td>
                    Master’s degree (MS) in child development, early childhood education, or elementary education, including
                    15 child development/ early childhood specific credits
                  </td>
                  <td>
                    Public and private four-year <br />
                    colleges and universities
                  </td>
                </tr>
                <tr>
                  <th>Level 7</th>
                  <td>
                    Education Specialist (Ed.S.) degree in child development, early childhood education or elementary
                    education, including 15 child development/early childhood specific credits
                  </td>
                  <td>Public and private four-year colleges and universities</td>
                </tr>
                <tr>
                  <th>Level 8</th>
                  <td>
                    Doctor of Philosophy (Ph.D.) degree or Doctor of Education (Ed.D.) degree in child development, early
                    childhood education, or elementary education, including 15 child development/early childhood specific
                    credits
                  </td>
                  <td>Public and private four-year colleges and universities</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'CareerInfo',
  props: {
    primaryentity: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
