<template>
  <section>
    <div>
      <div class="container">
        <div class="about-us-layout">
          <div class="row">
            <div class="col-12">
              <h1>Welcome To TNPAL!</h1>
            </div>
            <div class="col-lg-8">
              <hr />
              <div class="about-us-contents">
                <p>
                  <b>Tennessee Professional Archive of Learning (TNPAL)</b> is the official system of record for qualifications, training, and professional development for child care providers licensed by the Tennessee Department of Human Services (TDHS) effective January 01, 2022. This free resource is designed to better support professional development and empower early childhood professionals who work in TDHS-licensed child care agencies by collecting and preserving completed trainings, accomplishments, credentials, education, certifications, and awards. TNPAL also directly supports verification of these records by directors and TDHS for compliance and regulatory purposes.
                </p>
                <p class="about-list">
                  TNPAL is a resource that empowers and supports you in your professional development through such
                  benefits as:
                </p>
                <ul>
                  <li>Creating your own early childhood professional and training profile.</li>
                  <li>Documenting your professional accomplishments and professional certificates.</li>
                  <li>Developing your own professional development plan.</li>
                  <li>
                    Linking to TrainTN and organizations recognized by TDHS that offer early childhood education training, including
                    CCR&R, TECTA, TCCOTS, and more!
                  </li>
                </ul>
                <p>
                  Your profile is yours and goes with you wherever your professional journey takes you! All you have to
                  do is
                  <router-link :to="isAuthenticated ? '/dashboard' : '/login'">log in</router-link>!                  
                </p>        
            
              </div>                  
            </div>
            <div class="col-lg-4">
              <div class="about-us-image">
                <img src="../../../images/pages/hands.png" alt="Group of people holding hands together" />
              </div>
            </div>            
          </div>
        </div>        
      </div>      
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>
