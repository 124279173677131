<script>
import SelectPrompt from './select-prompt';

export default {
  extends: SelectPrompt,
  props: {
    required: {
      type: Boolean,
      default: true
    }
  }
};
</script>