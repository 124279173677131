const FileDownloader = {
  install(Vue, options) {
    Vue.prototype.$downloadStaticFile = async function (fileCode) {
      return await new Promise((resolve, reject) => {
        this.$store
          .dispatch('staticFileDownloadRequest', fileCode)
          .then((result) => {
            if (window.navigator.msSaveOrOpenBlob) {
              const url = window.navigator.msSaveOrOpenBlob(new Blob([result.data]), 'file');
              window.open(url);
              resolve(true);
            } else {
              const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type'] }));
              const link = document.createElement('a');
              link.href = url;
              const filename = result.headers['content-disposition'].split('filename=')[1].split('; filename*=')[0];
              link.setAttribute('download', filename.replace(/"/g, ''));
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              resolve(true);
            }
          })
          .catch((errors) => {
            resolve(false);
          });
      });
    };
  }
};
export default FileDownloader;
