<template>
  <section class="container-fluid">
    <div class="container has-text-centered">
      <div class="profile-form-container">
        <div class="my-4 text-center">
          <h1 class="title has-text-grey">Request History</h1>
          <p>
            Click on each EC Professional to view their request history for association to your
            {{ $t('message.facility').toLowerCase() }}.
          </p>
        </div>
        <div>
          <grid
            :entityTypeId="employmentEntityTypeId"
            :formId="0"
            :entityTypeName="''"
            :additionalActions="additionalActions"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            :showFormOnEmpty="false"
            :showAddButton="false"
            :primaryEntity="primaryEntity"
            :showDisable="false"
            :showEdit="false"
            :showView="false"
            ref="actionGrid"
            :additionalActionsOnColumn="true"
            :additionalShowColumns="additionalColumns"
            :allowDownload="false"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          ></grid>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">
        Request History Details for {{ activeEmployment.primaryEntityName }} -
        {{ activeEmployment.employerName }}
      </h2>
      <div class="text-left row" slot="body">
        <div v-if="activeEmployment.id" class="w-100 m-3">
          <requestDetails :key="activeId" :employment="activeEmployment"></requestDetails>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import requestDetails from './request-details.vue';
export default {
  name: 'RequestHistory',
  components: { requestDetails },
  data() {
    return {
      employmentEntityTypeId: 8,
      userEntityTypeId: 1,
      primaryEntity: {},
      isModalVisible: false,
      activeId: '',
      activeEmployment: {},
      isDirty: false,
      additionalActions: [
        {
          title: 'View Request Action History',
          icon: 'history',
          click: this.rowAction,
          check: this.shouldShowAction
        }
      ],
      additionalColumns: [
        'primaryEntityName',
        'primaryEntityEmail',
        'requestLastUpdatedDate',
        'requestLastUpdatedUserName'
      ],
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    if (this.userId && this.userEntityTypeId) {
      this.primaryEntity = {
        Id: this.userId,
        EntityTypeId: this.userEntityTypeId
      };
    }
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAction(item);
    },
    shouldShowAction(item) {
      return true;
    },
    rowAction(item) {
      if (item && item.id) {
        this.activeId = item.id;
        this.activeEmployment = item;
        this.showModal();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.activeId = 'closed';
      this.activeEmployment = {};
    }
  }
};
</script>
