<template>
  <div :class="'form-group col-md-' + width + (offset ? ' col-md-offset-right-' + offset : '')">
    <div>
      <label>{{ stimulus }}</label>
      <span class="required-red" v-if="required">*</span>
      <a class="mouse-pointer mx-1 form-tooltip" v-if="tooltip">
        <icon :icon="'question-circle'" class="form-tooltip-icon" :size="'sm'"></icon>
        <span class="form-tooltiptext">{{ tooltip }}</span>
      </a>
    </div>
    <div :class="subtype == 'horizontalRadio' ? 'form-radio form-radio-inline' : 'form-radio'">
      <div :class="subtype == 'horizontalRadio' ? 'radio-inline' : 'radio'" v-for="choice in choices" :key="choice.id">
        <label>
          <input
            type="radio"
            :checked="value == choice.id ? true : false"
            :disabled="readOnly ? true : false"
            :required="required ? true : false"
            :name="'radio_' + id"
            v-bind:key="choice.id"
            :value="choice.id"
            @input="$emit('input', $event.target.value)"
          />&nbsp;{{ choice.text }}
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'radio',
  props: {
    id: {
      type: Number,
      required: true
    },
    stimulus: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    choices: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 12
    },
    subtype: {
      type: String,
      default: 'verticalRadio'
    },
    offset: {
      type: Number
    },
    tooltip: {
      type: String
    },
    arguments: {
      type: Object
    },
    formData: {
      type: Object
    },
    selectedReference: {
      Object
    },
    files: {
      type: Array,
      default: () => []
    },
    formId: {
      type: Number,
      required: true
    },
    primaryEntity: {
      type: Object
    },
    startingValue: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {

  }
};
</script>
