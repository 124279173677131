<template>
  <section class="section">
    <div class="login-form-container">
      <div class="register-button-row text-center">
        <h1>Create Account</h1>
        <p>
          Create your <span style="text-decoration: underline">personal</span> user account and receive your
          {{ $t('message.id') }}
        </p>
      </div>
      <div :class="isBusy ? 'disabled-area' : ''">
        <form @submit.prevent="handleSubmit">
          <div class="input-group my-3">
            <input class="input form-control" type="text" placeholder="First Name" v-model.trim="firstName" pattern="^ *([a-zA-Z0-9]+(?:[a-zA-Z0-9 '-]+)*){1,}$" required/>
          </div>
          <div class="input-group my-3">
            <input class="input form-control" type="text" placeholder="Last Name" v-model.trim="lastName" pattern="^ *([a-zA-Z0-9]+(?:[a-zA-Z0-9 '-]+)*){2,}$" required/>
          </div>
          <div class="input-group my-3">
            <input class="input form-control" type="email" placeholder="Email" v-model="email" />
            <div class="font-italic" style="line-height: 1.5; color: #666">
              We recommend that you enter a
              <strong><span style="text-decoration: underline">personal</span></strong> email address that you will have
              continuous access to and use often.
            </div>
          </div>
          <div v-if="locale === 'al'">
            <div class="divider"></div>
            <h3>Passwords must:</h3>
            <div class="content_block">
              <ul class="password_requirements">
                <li id="pw0">
                  <icon 
                    :icon="validPwdElements.pw0 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw0 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Be at least 8 characters long.</li>
                <li id="pw1">
                  <icon 
                    :icon="validPwdElements.pw1 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw1 ? 'checked' : ''"
                    :size="'sm'"></icon>  
                  Contain at least 1 uppercase letter.</li>
                <li id="pw2">                  <icon 
                    :icon="validPwdElements.pw2 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw2 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Contain at least 1 lowercase letter.</li>
                <li id="pw3">                  
                  <icon 
                    :icon="validPwdElements.pw3 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw3 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                  Contain at least 1 number.</li>
                <li id="pw4">      
                  <icon 
                    :icon="validPwdElements.pw4 ? 'check' : 'minus'" 
                    :class="validPwdElements.pw4 ? 'checked' : ''"
                    :size="'sm'"></icon> 
                   Password and confirmation must match.</li>
              </ul>
            </div>
          </div>
          <div class="input-group addon password-wrapper my-3">
            <input
              class="form-control"
              :type="passwordFieldType"
              placeholder="Password"
              v-model="password"
              autocomplete="new-password"
              @keyup="validatePassword"
            />
            <div class="input-group-addon">
              <icon :icon="'eye'" class="password-view" v-touch:start="showpassword" v-touch:end="hidepassword"></icon>
            </div>
          </div>
          <div class="input-group addon password-wrapper my-3">
            <input
              class="form-control"
              :type="confirmPasswordFieldType"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              autocomplete="new-password"
              @keyup="validatePassword"
            />
            <div class="input-group-addon">
              <icon
                :icon="'eye'"
                class="password-view"
                v-touch:start="showconfirmpassword"
                v-touch:end="hideconfirmpassword"
              ></icon>
            </div>
          </div>
          <div class="text-left my-4">
            <div class="">
              <input
                class="input is-large"
                type="checkbox"
                ref="tcCheckbox"
                :title="tacAcceptMessage"
                v-model="tacAccepted"
                @click="checkTerms"
              />
              <span> I Accept the </span>
              <a class="modalLink" @click="showModal">{{ $t('message.terms') }}</a>
            </div>
          </div>
          <div class="text-left">
            <button class="btn btn-primary" type="submit">Register</button>
          </div>
          <div class="errors-container" v-if="pwErrors">
            {{ pwErrors }}
          </div>
          <div class="errors-container" v-if="errors">
            {{ errors }}
          </div>
          <modal
            v-show="isModalVisible"
            @close="closeModal"
            :isModalVisible="isModalVisible"
            :mustViewAll="false"
            :showCopyright="true"
            :showTopClose="false"
            :showBottomClose="false"
          >
            <h2 slot="header">{{ $t('message.terms') }}</h2>
            <div class="text-left" slot="body">
              <p>{{ termsAndConditionsText }}</p>
              <div class="row justify-content-center">
                <button @click="closeModal()" type="button" class="btn btn-primary col-11 col-md-3 mt-2">Close</button>
              </div>
            </div>
          </modal>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { locale, getText } from '@scripts/localized';
export default {
  name: 'registration',
  data() {
    return {
      isBusy: false,
      errors: '',
      pwErrors: '',
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      tacAccepted: false,
      passwordValidated: locale === 'al' ? false: true,
      //add nameFieldValidated: false, maybe I can add regex expression logic here to change something???
      isModalVisible: false,
      acceptTacDisabled: true,
      termsAndConditionsText: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      validPwdElements: {
        pw0: false,
        pw1: false,
        pw2: false,
        pw3: false,
        pw4: false,
      },
      tacAcceptMessage: `I accept the ${ getText('terms') }`,
      locale: locale
    };
  },
  watch: {
    isBusy: function (newValue, oldValue) {
      this.$emit('busy', newValue);
    }
  },
  mounted () {
    if (window.location.hostname === 'uat.alabamapathways.org') {
      const options = { title: 'Warning!'};
      this.$dialogs.alert(
        `This is a testing/training environment. It is for training purposes only. If you are attempting to create an account in Alabama Pathways, the live site web address is <a href="https://www.alabamapathways.org">www.alabamapathways.org</a>.`,
        options
      );
    }
  },
  created() {
    if (this.$route.query.email) {
      this.email = decodeURIComponent(this.$route.query.email);
    }
    if (this.$route.query.fname) {
      this.firstName = decodeURIComponent(this.$route.query.fname);
    }
    if (this.$route.query.lname) {
      this.lastName = decodeURIComponent(this.$route.query.lname);
    }
    //this.validatePassword();
    const key = 'TermsAndConditionsText';
    this.isBusy = true;
    this.$store
      .dispatch('configRequest', key)
      .then((result) => {
        this.isBusy = false;
        this.termsAndConditionsText = result;
      })
      .catch((errors) => {
        this.isBusy = false;
        this.errors = '';
        if (errors.response && errors.response.data) {
          const errobj = errors.response.data;
          if (typeof errobj == 'string') {
            this.errors = errobj;
            return;
          }
          let errArr = [];
          if (errobj.errors) {
            errArr = Object.values(errobj.errors);
          } else {
            errArr = Object.values(errobj);
          }
          for (let i = 0; i < errArr.length; i++) {
            this.errors = this.errors + errArr[i] + '\n';
          }
        } else this.errors = errors;
      });
  },
  methods: {
    handleSubmit: function (event) {
      if (this.tacAccepted && this.passwordValidated) {
        this.isBusy = true;
        const attrs = {
          email: this.email.trim(),
          password: this.password,
          confirmPassword: this.confirmPassword,
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim()
        };
        this.$store
          .dispatch('registrationRequest', attrs)
          .then(() => {
            this.$emit('busy', false);
            this.isBusy = false;
            this.$router.push({
              name: 'login-form',
              query: { new: 'true', email: this.email }
            });
          })
          .catch((errors) => {
            this.isBusy = false;
            this.errors = '';
            if (errors.response.data) {
              const errobj = errors.response.data;
              if (typeof errobj == 'string') {
                this.errors = errobj;
                return;
              }
              let errArr = [];
              if (errobj.errors) {
                errArr = Object.values(errobj.errors);
              } else {
                errArr = Object.values(errobj);
              }
              for (let i = 0; i < errArr.length; i++) {
                this.errors = this.errors + errArr[i] + '\n';
              }
            }
          });
      } else if (!this.passwordValidated) {
        this.pwErrors = 'Password is not valid. See rules above.';
      } else {
        this.errors = `${ getText('terms')} must be viewed and accepted.`;
      }
    },
    showModal() {
      this.acceptTacDisabled = false;
      this.isModalVisible = true;
    },
    checkTerms() {
      if (this.acceptTacDisabled == true) {
        this.$refs['tcCheckbox'].checked = false;
        this.showModal();
      } else {
        this.termsAccepted();
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
    termsAccepted() {
      if (this.errors === `${ getText('terms')} must be viewed and accepted.`) {
        this.errors = '';
      }
    },
    showpassword: function () {
      if (this.passwordFieldType == 'password') this.passwordFieldType = 'text';
      else this.passwordFieldType = 'password';
    },
    hidepassword: function () {
      this.passwordFieldType = 'password';
    },
    showconfirmpassword: function () {
      if (this.confirmPasswordFieldType == 'password') this.confirmPasswordFieldType = 'text';
      else this.confirmPasswordFieldType = 'password';
    },
    hideconfirmpassword: function () {
      this.confirmPasswordFieldType = 'password';
    },
    validatePassword: function () {
      if (locale !== 'al') return;
      let validCount = 0;
      if (this.password.length >= 8) {
        validCount++;
        this.setPwRequirementStatus(0, true);
      } else {
        this.setPwRequirementStatus(0, false);
      }
      if (/[A-Z]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(1, true);
      } else {
        this.setPwRequirementStatus(1, false);
      }
      if (/[a-z]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(2, true);
      } else {
        this.setPwRequirementStatus(2, false);
      }
      if (/[0-9]/.test(this.password)) {
        validCount++;
        this.setPwRequirementStatus(3, true);
      } else {
        this.setPwRequirementStatus(3, false);
      }
      if (this.password === this.confirmPassword &&
          this.password.length > 0) {
        validCount++;
        this.setPwRequirementStatus(4, true);
      } else {
        this.setPwRequirementStatus(4, false);
      }
      if (validCount === 5) {
        this.passwordValidated=true;
        this.pwErrors='';
      } else
        this.passwordValidated=false;
    },
    setPwRequirementStatus: function (itemIndex, isValid) {
      const itemId='pw' + itemIndex;
      const target_item=document.getElementById(itemId);
      //const firstChild = target_item.querySelector('icon');
      if (isValid===true){ //requirement met
        target_item.classList.add('checked');
        this.validPwdElements[itemId] = true;
      } else { //requirement not met
        target_item.classList.remove('checked');
        this.validPwdElements[itemId] = false;
      }
    }
  }
};
</script>





