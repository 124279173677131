<template>
  <div>
    <h1 class="hidden-title">Verification Details</h1>
    <div role="tablist">
      <div>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle="'accordion_0'">
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="historyOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">History</span>
            </span>
          </b-card-header>
          <b-collapse
            :id="'accordion_0'"
            accordion="access-accordion"
            role="tabpanel"
            :visible="true"
            v-model="historyOpen"
          >
            <b-card-body>
              <div>
                <b-table hover :items="verificationTableRows" :busy.sync="isBusy">
                  <template v-slot:table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
      <div>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1 mouse-pointer" role="tab" v-b-toggle="'accordion_1'">
            <span style="font-size: 1rem">
              <button class="btn accordion-caret p-0">
                <icon :icon="recordOpen ? 'caret-down' : 'caret-right'" class="ml-2" style="color: dimgray"></icon>
              </button>
              <span class="ml-4">Record</span>
            </span>
          </b-card-header>
          <b-collapse :id="'accordion_1'" accordion="access-accordion" role="tabpanel" v-model="recordOpen">
            <b-card-body style="min-height: 400px">
              <div>
                <wfrform
                  :formid="getFormIdFromEntityType()"
                  :entities="getFormEntity()"
                  :startinviewmode="true"
                  :primaryEntity="getPrimaryEntity()"
                  :showEditButton="false"
                >
                </wfrform>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerificationDetails',
  data() {
    return {
      isBusy: false,
      verificationInfo: [],
      historyOpen: true,
      recordOpen: false
    };
  },
  props: {
    verification: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    this.loadInfo();
  },
  computed: {
    verificationTableRows: function () {
      const verifications = [];
      this.verificationInfo.forEach((action) => {
        verifications.push({
          actionUser: action.actionUser.name,
          actionTime: this.$formatUtcDate(action.lastChangeDateTime),
          actionType: this.getActionTypeDisplayTextByInt(action.actionType),
          agency: action.agencyName,
          comments: action.comments
        });
      });
      return verifications;
    }
  },
  methods: {
    loadInfo() {
      if (this.verification && this.verification.id) {
        const body = {
          verificationId: this.verification.id
        };
        this.isBusy = true;
        this.$store
          .dispatch('verificationHistoryRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.verificationInfo = response;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
      }
    },
    getActionTypeDisplayTextByInt(val) {
      return val == 1 ? "Requested" : val == 3 ? "Approved" : val == 4 ? "Declined" : "";
    },
    getFormIdFromEntityType() {
      if (this.verification && this.verification.entityTypeId) {
        switch (this.verification.entityTypeId) {
          case 4:
            // education
            return 2;
          case 7:
            // certification
            return 5;
          case 3:
            // training
            return 7;
          case 8:
            // experience
            return 6;
          case 31:
            // career lattice
            return 28;
          default:
            return 0;
        }
      }
    },
    getFormEntity() {
      return [
        {
          Id: this.verification.entityId,
          EntityTypeId: this.verification.entityTypeId
        }
      ];
    },
    getPrimaryEntity() {
      return { Id: this.verification.primaryEntityId, EntityTypeId: 1 };
    }
  }
};
</script>
