<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1>Manage Training</h1>
        </div>
        <div>
          <grid
            :entityTypeId="entityTypeId"
            :formId="formId"
            :entityTypeName="entityTypeName"
            :primaryEntity="primaryentity"
            :showFormOnEmpty="true"
            :key="courseGridKey"
            :showContinueButton="false"
            :additionalActionsOnColumn="true"
            :additionalActions="additionalActions"
            :showDisable="false"
            :checkForAdditionalActions="shouldShowAdditionalActions"
            @dirty="$emit('dirty', $event)"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
      </div>
    </div>
    <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="isModalVisible">
      <h2 slot="header">
        Manage Schedule<span v-if="currentCourseName"> - {{ currentCourseName }}</span>
      </h2>
      <div slot="body">
        <div v-if="gridKey.startsWith('open')">
          <grid
            :entityTypeId="sectionEntityTypeId"
            :formId="sectionFormId"
            :key="gridKey"
            :entityTypeName="sectionEntityTypeName"
            :showFormOnEmpty="true"
            :showAddButton="true"
            :showDisable="false"
            :primaryEntity="sectionPrimaryEntity"
            ref="sectionGrid"
            :allowDownload="false"
            :parentProps="gridParentProps"
            :parentFormId="formId"
            :showParentPropsInForm="true"
            :additionalActionsOnColumn="true"
            :additionalActions="childAdditionalActions"
            @dirty="$emit('dirty', $event)"
            :basePageSize="sectionGridPageSize"
            :baseCurrentPage="sectionGridCurrentPage"
            @setPageSize="setSectionPageSize"
            @setCurrentPage="setSectionCurrentPage"
          >
          </grid>
        </div>
      </div>
    </modal>
    <modal v-show="isChildModalVisible" @close="closeChildModal" :isModalVisible="isChildModalVisible">
      <h2 slot="header">
        <span v-if="currentSectionName">{{ currentSectionName }}</span> Registration and Attendance Details
      </h2>
      <div slot="body">
        <div v-if="childGridKey.startsWith('open')">
          <grid
            :entityTypeId="enrollmentEntityTypeId"
            :formId="enrollmentFormId"
            :key="childGridKey"
            :entityTypeName="enrollmentEntityTypeName"
            :showFormOnEmpty="false"
            :showAddButton="true"
            :showEdit="false"
            :showDisable="false"
            :primaryEntity="enrollmentPrimaryEntity"
            :section="section"
            ref="enrollmentGrid"
            :allowDownload="true"
            :parentProps="childGridParentProps"
            :parentFormId="sectionFormId"
            :showParentPropsInForm="true"
            :additionalActionsOnColumn="true"
            :additionalActions="grandKidAdditionalActions"
            :multiRowSelect="multiRowSelect"
            :allowSelectAll="allowSelectAll"
            :allowClearSelection="allowClearSelection"
            :tableActions="getTableActions()"
            @tableAction="tableAction"
            @dirty="$emit('dirty', $event)"
            :basePageSize="enrollmentGridPageSize"
            :baseCurrentPage="enrollmentGridCurrentPage"
            @setPageSize="setEnrollmentPageSize"
            @setCurrentPage="setEnrollmentCurrentPage"
          >
          </grid>
        </div>
      </div>
    </modal>
    <modal v-show="isMessageModalVisible" @close="closeMessageModal" :isModalVisible="isMessageModalVisible">
      <h2 slot="header">Send a Message</h2>
      <div slot="body">
        <div v-if="composeKey != ''">
          <MessageCompose
            :inputForm="inputForm"
            :exitForm="confirmExitForm"
            :form="composeForm"
            :composeEmail="composeEmail"
            :compose="compose"
            :key="composeKey"
            :limitText="limitText"
            :userSearch="userSearch"
            :users="users"
            :isLoading="isLoading"
            :twoCharacterMessage="twoCharacterMessage"
            :emptySetMessage="emptySetMessage"
            :noResultMessage="noResultMessage"
            :usersSelected="usersSelected"
            :defaultSelectedUsers="defaultSelectedUsers"
            :showTitle="true"
            :bulkUserMode="bulkUserMode"
            :toUserString="toUserString"
          >
          </MessageCompose>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageCompose from '../../messaging/message-compose.vue';
import RequestPrompt from '../../utility/dialog/request-prompt';
import RequiredPrompt from '../../utility/dialog/request-prompt-required';
import SelectPromptRequired from '../../utility/dialog/select-prompt-required';
export default {
  name: 'ManageTraining',
  data() {
    return {
      entityTypeId: 23,
      formId: 21,
      entityTypeName: 'Training',
      isModalVisible: false,
      isChildModalVisible: false,
      isMessageModalVisible: false,
      sectionPrimaryEntity: {},
      gridParentProps: [],
      childGridParentProps: [],
      sectionEntityTypeId: 24,
      sectionFormId: 22,
      sectionEntityTypeName: 'Training Session',
      sectionDateTime: null,
      enrollmentEntityTypeId: 26,
      enrollmentFormId: 24,
      enrollmentEntityTypeName: 'Student Details',
      enrollmentPrimaryEntity: {},
      section: {},
      gridKey: 'closed',
      courseGridKey: 'open',
      childGridKey: 'closed',
      isDirty: false,
      currentCourseName: '',
      currentSectionName: '',
      multiRowSelect: true,
      allowSelectAll: true,
      allowClearSelection: true,
      bulkUserMode: false,
      toUserString: '',
      additionalActions: [
        {
          title: 'Manage Schedule',
          icon: 'calendar-plus',
          click: this.scheduleTraining,
          check: this.shouldShowSchedule
        },
        {
          title: 'Delete Training',
          icon: 'trash',
          click: this.deleteCourse,
          check: this.shouldShowDeleteCourse
        }
      ],
      childAdditionalActions: [
        {
          title: 'Manage Session',
          icon: 'user-check',
          click: this.manageAttendance,
          check: this.shouldShowAttendance
        },
        {
          title: 'Delete Session',
          icon: 'trash',
          click: this.deleteSection,
          check: this.shouldShowDeleteSection
        }
      ],
      grandKidAdditionalActions: [
        {
          title: 'Send a Message',
          icon: 'paper-plane',
          click: this.sendMessage,
          check: this.shouldShowSendMessage
        },
        {
          title: 'Cancel Registration',
          icon: 'calendar-times',
          click: this.cancelRegistration,
          check: this.shouldShowCancel
        },
        {
          title: 'Review Out of Region Enrollment Request',
          icon: 'glasses',
          click: this.setVerification,
          check: this.canVerify
        },
        {
          title: 'View Certificate',
          icon: 'paperclip',
          click: this.showCertificate,
          check: this.shouldShowCertificate
        }
      ],
      composeForm: false,
      composeKey: '',
      composeEmail: {
        subject: '',
        message: '',
        to: null
      },
      selectedUsers: [],
      defaultSelectedUsers: [],
      users: [],
      isLoading: false,
      twoCharacterMessage: 'At least two characters are required for search',
      emptySetMessage: 'No results found',
      noResultMessage: 'At least two characters are required for search',
      gridPageSize: 5,
      gridCurrentPage: 1,
      sectionGridPageSize: 5,
      sectionGridCurrentPage: 1,
      enrollmentGridPageSize: 5,
      enrollmentGridCurrentPage: 1
    };
  },
  props: {
    //This is the user (owner) for the course
    primaryentity: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    MessageCompose
  },
  computed: {
    ...mapGetters(['profile', 'roles', 'isAuthenticated']),
    hasAdminRole: function () { 
      if (this.roles && this.roles.length > 0 && this.roles.includes('AdminUser')) return true;
      return false;
    },
    hasTrainerRole: function () {
      if (this.roles && this.roles.length > 0 && this.roles.includes('250')) return true;
      return false;
    },
  },
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
    setSectionPageSize(size) {
      this.sectionGridPageSize = parseInt(size);
    },
    setSectionCurrentPage(page) {
      this.sectionGridCurrentPage = parseInt(page);
    },
    setEnrollmentPageSize(size) {
      this.enrollmentGridPageSize = parseInt(size);
    },
    setEnrollmentCurrentPage(page) {
      this.enrollmentGridCurrentPage = parseInt(page);
    },
    reloadEnrollGrid(resetSection) {
      if (resetSection == true) this.section.recordIsLocked = true;
      this.childGridKey += '-1';
    },
    shouldShowAdditionalActions(item) {
      return this.shouldShowAttendance(item);
    },
    shouldShowAttendance(item) {
      return true;
    },
    shouldShowSchedule(item) {
      return true;
    },
    async tableAction(eventVal, items, data) {
        if (eventVal == 'send') {
          this.bulkSendMessage(items, data);
        } else if (eventVal == 'cancel') {
          this.bulkCancelRegistration(items, data);
        } else if (eventVal == 'record') {
          this.bulkManageAttendance(items, data);
        }
    },
    getTableActions() {
      const actions = [
          {
            status: 'Message Sent',
            verb: 'send',
            button: {
              classes: 'btn mr-2 tnpalBGGreen px-2',
              title: 'Send Message',
              click: 'tableAction("send")',
              icon: 'paper-plane',
            },
          },
          {
            status: 'Canceled',
            verb: 'cancel',
            button: {
              classes: 'btn mr-2 tnpalBGRed px-2',
              title: 'Cancel',
              click: 'tableAction("cancel")',
              icon: 'calendar-times'
            },
            check: this.showBulkCancel
          }
        ];
      const now = new Date();
      if (this.sectionDateTime < now)
        actions.push(
          {
            status: 'Manage Attendance',
            verb: 'record',
            button: {
              classes: 'btn mr-2 tnpalBGGreen px-2',
              title: 'Manage Attendance',
              click: 'tableAction("record")',
              icon: 'tasks',
            },
            check: this.showBulkAttendance
          }
        );
      return actions;
    },
    alertsDisplay(response) {
      const alertTitle = 'Review Out of Region Request';
      if (response == -2) {
        const options = { title: alertTitle };
        this.$dialogs.alert(
          `Unable to register. ${this.userName} is already registered for ${this.section.courseName}: ${this.section.sectionName}.`,
          options
        );
      } else if (response == -3 || response == -4) {
        const options = { title: alertTitle };
        this.$dialogs.alert(
          `Registration for ${this.section.courseName}: ${this.section.sectionName} is full. Registration is unavailable at this time.`,
          options
        );
      } else {
        const options = { title: alertTitle };
        this.$dialogs.alert('Unable to complete registration review process. An error occurred.', options);
      }
    },
    shouldShowDisableCourse(item) {
      //if (this.hasAdminRole) return true;
      return false;
    },
    shouldShowDeleteCourse(item) {
      if (this.hasTrainerRole && item.numberOfSessions==0) return true;
      return false;
    },
    shouldShowDisableSection(item) {
      //if (this.hasAdminRole) return true;
      return false;
    },
    shouldShowDeleteSection(item) {
      if (this.hasTrainerRole && !item.hasHadEnrollments) return true;
      return false
    },
    shouldShowCancel(item) { //registrations
      if (!this.section || this.section.recordIsLocked) return false;
      if (item && ['Unregistered', 'System Cancellation', 'Pending Approval', 'Denied'].includes(item.status))
        return false;
      return true;
    },
    shouldShowCertificate(item) {
      if (!this.section) return false;
      if (item && item.certificate)
        return true;
      return false;
    },
    shouldShowSendMessage(item) {
      if (item && item.status === 'Unregistered') return false;
      return true;
    },
    async showCertificate(item) {
      const url ='/api/file/c/' + item.certificate;
      window.open(url);
    },
    deleteCourse(item) {
      const options = { title: 'Delete Training Course', okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm('Are you sure you want to delete this course?', options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = {
              courseId: item.id,
              userId: this._props.primaryentity.Id
            };
            this.isBusy = true;
            this.$store
              .dispatch('deleteCourseRequest', body)
              .then(async (response) => {
                this.courseGridKey += '-1';
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    deleteSection(item) {
      const options = { title: 'Delete Course Session', okLabel: 'Confirm', cancelLabel: 'Cancel' };
      this.$dialogs
        .confirm('Are you sure you want to delete this session?', options)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            const body = {
              courseSectionId: item.id,
              userId: this._props.primaryentity.Id
            };
            this.isBusy = true;
            this.$store
              .dispatch('deleteSectionRequest', body)
              .then(async (response) => {
                this.gridKey += '-1';
              })
              .catch((errors) => {
                this.isBusy = false;
              });
          }
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    cancelRegistration(item) {
      const regText = `Are you sure you want to cancel this registration? If so, please enter an optional comment and click Confirm.`;
      const regOptions = {
        title: 'Cancel Registration',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.isBusy = true;
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.cancelRegistrationConfirmed(item, res.value);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    cancelRegistrationConfirmed(item, comment) {
      const body = {
        courseSectionId: item.courseSectionId,
        userIds: [item.userId],
        comment: comment
      };
      this.isBusy = true;
      this.$store
        .dispatch('cancelRegistrationRequest', body)
        .then(async (response) => {
          const dispDate = this.$formatGridDate(item.courseStartDate, false, true);
          this.composeEmail.subject = `${item.courseSectionName} ${dispDate} - Registration Cancelled`;
          this.sendMessage(item);
          this.childGridKey += '-1';
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async setVerification(item) {
      return await new Promise((resolve, reject) => {
        if (!this.primaryEntity) {
          //What's this for?
          //if (!this.primaryEntity || !this.organizationId) {
          resolve(false);
        }
        const promptComponent = RequiredPrompt;
        const promptText = `To approve/decline out of region registration request for ${item.userName}, please enter a required comment and click Approve or Decline.`;
        const promptTitle = `Request Review for Out of Region Training`;
        const options = {
          title: promptTitle,
          okLabel: 'Approve',
          cancelLabel: 'Cancel',
          notOkLabel: 'Decline',
          prompt: { invalidMessage: '', component: promptComponent }
        };
        this.$dialogs
          .prompt(promptText, options)
          .then((res) => {
            if (res && res.ok != false) {
              //res.ok=null when declined
              const body = {
                accepted: res.ok ? true : false,
                courseSectionId: item.courseSectionId,
                userId: item.userId,
                comment: res.value
              };
              this.isBusy = true;
              this.$store
                .dispatch('acceptRejectOverrideRequest', body)
                .then((response) => {
                  if (response > 0) {
                    this.reloadEnrollGrid(false);
                    resolve(true);
                  } else {
                    this.alertsDisplay(response);
                    this.reloadEnrollGrid(false);
                    resolve(false);
                  }
                })
                .catch((errors) => {
                  this.isBusy = false;
                  reject(errors);
                });
            } else resolve(false); //dialog closed or cancelled
          })
          .catch((error) => reject(error));
      });
    },
    canVerify(item) {
      if (item && ['Pending Approval'].includes(item.status)) return true;
      return false;
    },
    sendMessage(item) {
      this.composeForm = true;
      const toUser = { name: item.userName, email: item.name, id: item.userId };
      this.defaultSelectedUsers = [toUser];
      this.composeKey = `open${item.userId}`;
      this.showMessageModal();
    },
    bulkSendMessage(items, data) {
      this.selectedUsers = data.filter((d) => items.includes(d.id)).map((i) => {return {'name': i.userName, 'email': i.name, 'id': i.userId}});
      this.composeForm = true;
      //const toUser = { name: item.userName, email: item.name, id: item.userId };
      this.defaultSelectedUsers = [];
      this.bulkUserMode = true;
      this.toUserString = this.selectedUsers.map((u) => u.name ).join(', ');
      this.composeKey = `open${this.selectedUsers[0].userId}`;
      this.showMessageModal();
    },
    showBulkAttendance(gridData) {
      if (!this.section || this.section.recordIsLocked) return false;
      const unsetItems = gridData.filter((g) => !['Completed','Absent'].includes(g.attendance));
      return unsetItems.length;
    },
    showBulkCancel(gridData) { 
      if (!this.section || this.section.recordIsLocked) return false;
      const okItems = gridData.filter((g) => !['Unregistered', 'System Cancellation', 'Pending Approval', 'Denied'].includes(g.status));
      return okItems.length;
    },

    bulkCancelRegistration(items, data) {
      const selected = data.filter((d) => items.includes(d.id) && !['Unregistered', 'System Cancellation', 'Pending Approval', 'Denied'].includes(d.status));
      if (selected.length == 0) {
        this.$dialogs.alert('No chosen users are eligible for cancellation.', { title: 'Unable to cancel registration.' });
        return;
      }
      let userStr = selected.map((u) =>  u.userName ).join(', ');
      const i = userStr.lastIndexOf(', ');
      if (i >= 0) userStr = (userStr.substring(0, i) + ' and ' + userStr.substring(i+2));
      const regText = `Are you sure you want to cancel registration for the user${selected.length > 1 ? 's' : ''} <b>${userStr}</b>? If so, please enter an optional comment and click Confirm.`;
      const regOptions = {
        title: 'Cancel Registration',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: RequestPrompt
        }
      };
      this.isBusy = true;
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok && res.ok == true) {
            this.bulkCancelRegistrationConfirmed(items, data, res.value);
          }
          this.$emit('dirty', false);
          this.isDirty = false;
        })
        .catch((error) => {
          this.$emit('dirty', false);
          this.isDirty = false;
        });
    },
    async bulkCancelRegistrationConfirmed(items, data, comment) {
      const selected = data.filter((d) => items.includes(d.id) && !['Unregistered', 'System Cancellation', 'Pending Approval', 'Denied'].includes(d.status));
      if (selected.length == 0) return;
      const userIds = selected.map((u) => u.userId);
      const body = {
        courseSectionId: selected[0].courseSectionId,
        userIds: userIds,
        comment: comment
      };
      this.isBusy = true;
      this.$store
        .dispatch('cancelRegistrationRequest', body)
        .then((response) => {
          const dispDate = this.$formatGridDate(selected[0].courseStartDate, false, true);
          this.composeEmail.subject = `${selected[0].courseSectionName} ${dispDate} - Registration Cancelled`;
          this.bulkSendMessage(items, data);
          this.childGridKey += '-1';
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async bulkManageAttendance(items, data) {
      const selected = data.filter((d) => items.includes(d.id) && d.status == 'Registered' && !['Absent','Completed'].includes(d.attendance));
      const enrollmentIds = selected.map((u) => u.id);
      if (enrollmentIds.length == 0) {
        this.$dialogs.alert('All chosen users have attendance set already.', { title: 'Unable to set attendance.' });
        return;
      }
      let userStr = selected.map((u) =>  u.userName ).join(', ');
      const i = userStr.lastIndexOf(', ');
      if (i >= 0) userStr = (userStr.substring(0, i) + ' and ' + userStr.substring(i+2));
      const opts = [{'key':'1','value':'Completed'},{'key':'2','value':'Partially Completed'},{'key':'3','value':'Absent'}];
      const regText = 'Please select an attendance status for the users <b>' + userStr + '</b>.';
      const regOptions = {
        title: 'Select Attendance Status',
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        prompt: {
          invalidMessage: '',
          component: SelectPromptRequired,
          stimulus: 'Select status',
          selectOptions: opts
        }
      };
      this.$dialogs
        .prompt(regText, regOptions)
        .then((res) => {
          if (res && res.ok != false) {
            this.isBusy = true;
            const status = res.value;
            const body = {
              enrollmentRecordIds: enrollmentIds,
              status: status
            };
            if (status === '1' || status === '3') {
              const options = { title: 'Confirm Attendance Status', okLabel: 'Confirm', cancelLabel: 'Cancel' };
              const msg =
                  '<p>Are you sure you want to mark the user' + (selected.length > 1 ? 's' : '') + ' ' +
                  userStr +
                  ' as "' +
                  (status == 1 ? 'Completed' : 'Absent') +
                  '" ?</p><p>* You will not be able to make changes once attendance status has been submitted.</p><p>Thanks,</p><p>Alabama Pathways Team';
              this.$dialogs
                .confirm(msg, options)
                .then((res) => {
                  if (res && res.ok && res.ok == true) {
                    this.$store.dispatch('setAttendanceRequest', body).then((result) => {
                      this.childGridKey += "-1";
                    });
                  }
                });
            this.isBusy = false;
          } else {
              this.$store.dispatch('setAttendanceRequest', body).then((result) => {
                this.childGridKey += "-1";
                this.isBusy = false;
              });       
          }
        }
      });

    },
    scheduleTraining(item) {
      if (item && item.id) {
        this.isBusy = true;
        const body = { trainingId: item.id };
        this.$store.dispatch('coursePropsRequest', body).then((result) => {
          this.gridParentProps = result;
          this.currentCourseName = item.courseName;
          this.sectionPrimaryEntity = { Id: item.id, EntityTypeId: this.entityTypeId }; //primary entity is the course
          this.gridKey = 'open' + item.id;
          this.showModal();
        });
      } else {
        this.isBusy = false;
      }
    },
    manageAttendance(item) {
      if (item && item.id) {
        this.isBusy = true;
        const body = { trainingId: item.id };
        this.$store.dispatch('courseSectionPropsRequest', body).then((result) => {
          this.childGridParentProps = result;
          this.currentSectionName = item.sectionName;
          this.enrollmentPrimaryEntity = { Id: item.id, EntityTypeId: this.sectionEntityTypeId };
          this.section = item;
          this.sectionDateTime = new Date(item.trainingEndDate);
          this.childGridKey = 'open' + item.id;
          this.showChildModal();
        });
      } else {
        this.isBusy = false;
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    async closeModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isModalVisible = false;
        this.sectionPrimaryEntity = {};
        this.courseGridKey += '-1';
        this.gridKey = 'closed';
      }
    },
    showChildModal() {
      this.isChildModalVisible = true;
    },
    async closeChildModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isChildModalVisible = false;
        this.enrollmentPrimaryEntity = {};
        this.sectionDateTime = null;
        this.section = {};
        this.gridKey += '-1';
        this.childGridKey = 'closed';
      }
    },
    showMessageModal() {
      this.isMessageModalVisible = true;
    },
    async closeMessageModal(type) {
      const userAccept = await this.dirtyCheck();
      if (userAccept) {
        this.isMessageModalVisible = false;
        this.composeKey = '';
        this.users = [];
        this.defaultSelectedUsers = [];
        this.bulkUserMode = false;
        this.toUserString='';
        this.selectedUsers = [];
        this.composeForm = false;
      }
    },
    async dirtyCheck() {
      return await new Promise((resolve, reject) => {
        if (this.isDirty && this.isAuthenticated) {
          const options = {
            title: 'Unsaved Data Warning',
            cancelLabel: 'Cancel'
          };
          this.$dialogs
            .confirm('Are you sure you want to close without saving?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                this.$emit('dirty', false);
                this.isDirty = false;
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        } else {
          resolve(true);
        }
      });
    },
    inputForm() {
      this.composeForm = true;
      this.composeKey = '';
    },
    async confirmExitForm() {
      let confirmed = true;
      if (
        this.composeEmail &&
        (this.composeEmail.subject || this.composeEmail.message || this.selectedUsers.length > 0)
      ) {
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Warning', cancelLabel: 'Cancel' };
          this.$dialogs
            .confirm('Are you sure you want to close without sending?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      }
      if (confirmed) {
        this.exitForm();
        this.closeMessageModal();
      }
      return confirmed;
    },
    exitForm() {
      this.composeForm = false;
      this.composeKey = '';
      this.selectedUsers = [];
      this.defaultSelectedUsers = [];
      this.bulkUserMode = false;
      this.toUserString='';
      this.users = [];
      this.composeEmail = {
        subject: '',
        message: '',
        to: null
      };
    },
    async compose() {
      //make sure they have selected a recipient
      if (this.selectedUsers.length > 0) {
        let confirmed1 = true;
        if (!this.composeEmail.subject) {
          //ask if they want to send with no subject
          confirmed1 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty subject?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        let confirmed2 = true;
        if (!this.composeEmail.message) {
          //ask if they want to send with no body
          confirmed2 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty body?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        if (confirmed1 && confirmed2) {
          return await new Promise((resolve, reject) => {
            const body = {
              body: this.composeEmail.message,
              subject: this.composeEmail.subject,
              recipients: this.selectedUsers
            };
            this.isBusy = true;
            this.$store
              .dispatch('sendMessageRequest', body)
              .then((response) => {
                //Close message modal after reply sent.
                this.isMessageModalVisible = false;
                this.isBusy = false;
                this.exitForm();
                this.$dialogs.alert('Your message was sent successfully.', { title: 'Success.' });
                this.composeEmail.subject = '';
                resolve(true);
              })
              .catch((errors) => {
                this.isMessageModalVisible = false;
                this.isBusy = false;
                this.exitForm();
                this.$dialogs.alert('Your message could not be sent at this time. Please try again later.', {
                  title: 'Error'
                });
                this.composeEmail.subject = '';
                resolve(false);
              });
          });
        }
      } else {
        this.$dialogs.alert('No recipient selected', { title: 'Error' });
      }
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    userSearch(query) {
      const lettersLimit = 2;
      if (query.length < lettersLimit) {
        this.noResultMessage = this.twoCharacterMessage;
        this.users = [];
        return;
      }

      this.isLoading = true;
      const body = { searchString: query };

      this.$store
        .dispatch('messageUserSearchRequest', body)
        .then((result) => {
          this.isLoading = false;
          this.users = result;
          if (!this.users.length) {
            this.noResultMessage = this.emptySetMessage;
          }
        })
        .catch((errors) => {
          this.isLoading = false;
        });
    },
    usersSelected(users) {
      this.selectedUsers = users;
    }
  }
};
</script>
