<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">Create Alert</h1>
        </div>
        <div>
          <grid 
            :entityTypeId="20" 
            :formId="18" 
            :entityTypeName="'Alert'"
            :basePageSize="gridPageSize"
            :baseCurrentPage="gridCurrentPage"
            @setPageSize="setPageSize"
            @setCurrentPage="setCurrentPage"
          >
          </grid>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AlertCreate',
  data() {
    return {
      gridPageSize: 5,
      gridCurrentPage: 1
    };
  },
  props: {},
  methods: {
    setPageSize(size) {
      this.gridPageSize = parseInt(size);
    },
    setCurrentPage(page) {
      this.gridCurrentPage = parseInt(page);
    },
  }
};
</script>
