<template>
  <section class="container-fluid">
    <div class="has-text-centered">
      <div class="profile-form-container">
        <div v-if="isBusy" class="loader">
          <icon icon="spinner" pulse />
          <p><em>Loading...</em></p>
        </div>
        <div class="mb-4 text-center">
          <h1 class="title has-text-grey">
            <icon :icon="['far', 'envelope']" :size="'sm'" class="mr-2"></icon>{{ currentPageName }}
          </h1>
          <p v-if="instructionText">{{ instructionText }}</p>
        </div>
        <div :class="isBusy ? ' disabled-area' : ''">
          <Toolbar
            :emails="emails"
            :bulkCheckbox="bulkCheckbox"
            :halfCheckbox="halfCheckbox"
            :emptyCheckbox="emptyCheckbox"
            :inputForm="inputForm"
            :markRead="markSelectedRead"
            :markUnread="markUnread"
            :bulkSelect="bulkSelect"
            :unreadCount="unreadCount"
            :starMessage="starMessage"
            :deleteEmail="deleteEmail"
            :markReadAvailable="markReadAvailable"
            :markUnreadAvailable="markUnreadAvailable"
            :toggleArchive="toggleArchive"
            :activeFolder="activeFolder"
            :folders="folders"
            :folderChange="folderChange"
            :activeFilter="activeFilter"
            :filters="filters"
            :filterChange="filterChange"
            :exitForm="confirmExitForm"
            :form="form"
          >
          </Toolbar>

          <Compose
            :inputForm="inputForm"
            :exitForm="confirmExitForm"
            :form="form"
            :composeEmail="composeEmail"
            :compose="compose"
            :key="composeKey"
            :limitText="limitText"
            :userSearch="userSearch"
            :users="users"
            :isLoading="isLoading"
            :twoCharacterMessage="twoCharacterMessage"
            :emptySetMessage="emptySetMessage"
            :noResultMessage="noResultMessage"
            :usersSelected="usersSelected"
            :replyForm="replyForm"
            :showTitle="true"
          >
          </Compose>

          <MessageList
            v-if="!switchingFolders"
            :emails="emails"
            :starMessage="starMessage"
            :formatEmailDate="$formatEmailDate"
            :activeFolder="activeFolder"
            :folders="folders"
            :openMessage="openMessage"
            :userColumnValue="userColumnValue"
            :formatFullDate="$formatFullDate"
          >
          </MessageList>
          <div class="mt-4" v-if="!switchingFolders">
            <Paging
              :firstOnPage="firstOnPage"
              :lastOnPage="lastOnPage"
              :total="total"
              :pageSize="pageSize"
              :currentPage="currentPage"
              :pageSizeChanged="pageSizeChanged"
              :loadPage="loadPage"
            ></Paging>
          </div>
          <div class="text-center m-5" v-if="noData && !isBusy">No Messages</div>

          <modal v-show="isModalVisible" @close="closeModal" :isModalVisible="this.isModalVisible" :showHeader="false">
            <div slot="body" :key="activeMessageId">
              <div class="message-border message-spacing pb-4" v-if="withinInbox">
                <b-button :variant="'wfr'" class="btn mr-3" @click="replyFunction" :disabled="activeMessageIncludesDistro">
                  <icon :icon="'reply'" class="mr-2"></icon>Reply
                </b-button>
                <b-button :variant="'wfr'" class="btn mr-3" @click="replyAllFunction" :disabled="activeMessageIncludesDistro">
                  <icon :icon="'reply-all'" class="mr-2"></icon>Reply All
                </b-button>
                <b-button :variant="'wfr'" class="btn" @click="markUnreadAndClose">
                  <icon :icon="['far', 'envelope']" class="mr-2"></icon>Mark Unread
                </b-button>
              </div>
              <div class="message-border" style="font-size: 0.85em; color: grey">
                <span>{{ activeMessageSentDate }}</span>
              </div>
              <div>
                <div class="message-border message-spacing" style="padding-bottom: 15px">
                  <h2>{{ activeMessage.subject }}</h2>
                </div>
                <div v-if="activeMessage.fromUser" class="message-border message-spacing">
                  <b class="mr-1">From:</b>
                  <span>{{ activeMessage.fromUser.name }} &lt;{{ activeMessage.fromUser.email }}&gt;</span>
                </div>
                <div
                  v-if="activeMessage.recipients && activeMessage.recipients.length > 0"
                  class="message-border message-spacing"
                >
                  <b>To: </b>
                  <span v-for="(recipient, index) in activeMessage.recipients" :key="recipient.id">
                    <span v-if="recipient.id.startsWith('ALL-')">{{ mapDistroName(recipient.id) }}</span>
                    <span v-else>{{ recipient.name }} &lt;{{ recipient.email }}&gt;</span>
                    <span
                      v-if="index != activeMessage.recipients.length - 1"
                    >,
                    </span>
                  </span>
                </div>
                <div v-html="activeMessage.body" id="messageBody" class="message-spacing"></div>
              </div>
            </div>
          </modal>
          <modal
            v-show="isReplyModalVisible"
            class="reply"
            @close="closeModal"
            :isModalVisible="this.isReplyModalVisible"
            :withinModal="true"
          >
            <div slot="header">
              <h2>Compose Reply</h2>
            </div>
            <div slot="body" :key="activeMessageId">
              <div v-if="isReplyModalVisible && replyReady">
                <Compose
                  :inputForm="inputForm"
                  :exitForm="confirmExitForm"
                  :form="replyForm"
                  :composeEmail="composeEmail"
                  :compose="compose"
                  :key="composeKey"
                  :limitText="limitText"
                  :userSearch="userSearch"
                  :users="users"
                  :isLoading="isLoading"
                  :twoCharacterMessage="twoCharacterMessage"
                  :emptySetMessage="emptySetMessage"
                  :noResultMessage="noResultMessage"
                  :usersSelected="usersSelected"
                  :defaultSelectedUsers="defaultSelectedUsers"
                  :replyForm="replyForm"
                >
                </Compose>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Toolbar from './message-toolbar';
import Compose from './message-compose';
import MessageList from './message-list';
import Paging from '@components/utility/paging.vue';
import { mapGetters } from 'vuex';
import { locale } from '@scripts/localized';

export default {
  name: 'MessageInbox',
  components: {
    Toolbar,
    Compose,
    MessageList,
    Paging
  },
  created() {
    // Listen to message changes coming from SignalR events
    this.$messageHub.$on('message-received', this.messageReceived);
    if (this.$route && this.$route.meta) {
      if (this.$route.meta.display) this.currentPageName = this.$route.meta.display;
      if (this.$route.meta.instructionText) this.instructionText = this.$route.meta.instructionText;
    }
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$messageHub.$off('message-received', this.messageReceived);
  },
  data() {
    return {
      emails: [],
      form: false,
      replyForm: false,
      currentPageName: '',
      instructionText: '',
      composeEmail: {
        subject: '',
        message: '',
        to: null
      },
      folders: {
        inbox: 'Inbox',
        archive: 'Archive',
        sent: 'Sent'
      },
      actions: {
        star: 'Star',
        unstar: 'Unstar',
        read: 'Read',
        unread: 'Unread',
        archive: 'Archive',
        unarchive: 'Unarchive',
        delete: 'Delete'
      },
      filters: {
        none: 'None',
        starred: 'Starred',
        unread: 'Unread'
      },
      activeFolder: { folder: 'Inbox' },
      activeFilter: { filter: 'None' },
      isBusy: false,
      composeKey: '',
      total: 0,
      pageSize: 5,
      currentPage: 1,
      noData: true,
      activeMessage: {},
      activeMessageId: '',
      isModalVisible: false,
      isReplyModalVisible: false,
      switchingFolders: false,
      selectedUsers: [],
      defaultSelectedUsers: [],
      users: [],
      isLoading: false,
      twoCharacterMessage: 'At least two characters are required for search',
      emptySetMessage: 'No results found',
      noResultMessage: 'At least two characters are required for search',
      replyReady: false,
      locale: locale
    };
  },
  async mounted() {
    await this.loadTotal();
  },
  computed: {
    ...mapGetters(['profile','activeRole']),
    unreadCount() {
      return this.emails.reduce((acc, email) => {
        if (email.read == false) {
          acc++;
        }
        return acc;
      }, 0);
    },
    bulkCheckbox() {
      return this.emails.every((email) => email.selected) && this.emails.length > 0;
    },
    halfCheckbox() {
      return this.emails.some((email) => email.selected) && !this.bulkCheckbox;
    },
    emptyCheckbox() {
      return this.emails.every((email) => !email.selected);
    },
    markReadAvailable() {
      return this.emails.some((email) => email.selected && !email.read);
    },
    markUnreadAvailable() {
      return this.emails.some((email) => email.selected && email.read);
    },
    firstOnPage: function () {
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    lastOnPage: function () {
      const max = this.currentPage * this.pageSize;
      return max > this.total ? this.total : max;
    },
    activeMessageSentDate: function () {
      if (this.activeMessage && this.activeMessage.sentDate)
        return this.$formatFullDate(this.activeMessage.sentDate, true);
      return '';
    },
    activeMessageIncludesDistro: function () {
      if (this.activeMessage && this.activeMessage.recipients) {
        if (this.activeMessage.recipients.find((r) => r.id.startsWith('ALL-')))
          return true;
      }
      return false;
    },
    withinInbox: function () {
      return this.activeFolder && this.activeFolder.folder && this.activeFolder.folder == this.folders.inbox;
    }
  },
  methods: {
    async loadMessages(f, t) {
      return await new Promise((resolve, reject) => {
        this.isBusy = true;
        const body = {
          folder: this.activeFolder.folder,
          filter: this.activeFilter.filter,
          from: f,
          to: t
        };
        this.$store
          .dispatch('getMessagesRequest', body)
          .then((response) => {
            this.isBusy = false;
            this.switchingFolders = false;
            this.emails = response.map((message) => {
              message.selected = false;
              return message;
            });
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            this.switchingFolders = false;
            resolve(false);
          });
      });
    },
    messageReceived(data) {
      if (data && data.message && this.activeFolder.folder == this.folders.inbox) {
        this.noData = false;
        this.emails.unshift(data.message);
        this.total++;
        if (this.emails.length > this.pageSize) this.emails.pop();
      }
    },
    starMessage(message) {
      message.starred = !message.starred;
      const action = message.starred ? this.actions.star : this.actions.unstar;
      const body = { action: action, messageIds: [message.messageId] };
      this.$store
        .dispatch('messageActionRequest', body)
        .then((response) => {})
        .catch((errors) => {});
    },
    bulkSelect() {
      if (this.emptyCheckbox) {
        this.emails.forEach((email) => this.$set(email, 'selected', true));
      } else {
        this.emails.forEach((email) => this.$set(email, 'selected', false));
      }
    },
    inputForm() {
      this.form = true;
      this.composeKey = '';
    },
    async confirmExitForm() {
      let confirmed = true;
      if (
        this.composeEmail &&
        (this.composeEmail.subject || this.composeEmail.message || this.selectedUsers.length > 0)
      ) {
        confirmed = await new Promise((resolve, reject) => {
          const options = { title: 'Warning', cancelLabel: 'Cancel' };
          this.$dialogs
            .confirm('Are you sure you want to close without sending?', options)
            .then((res) => {
              if (res && res.ok && res.ok == true) {
                resolve(true);
              }
              resolve(false);
            })
            .catch((error) => {
              resolve(false);
            });
        });
      }
      if (confirmed) {
        this.exitForm();
      }
      return confirmed;
    },
    exitForm() {
      this.form = false;
      this.replyForm = false;
      this.isReplyModalVisible = false;
      this.replyReady = false;
      this.composeKey = 'empty';
      this.selectedUsers = [];
      this.defaultSelectedUsers = [];
      this.users = [];
      this.composeEmail = {
        subject: '',
        message: '',
        to: null
      };
    },
    markSelectedRead() {
      const id = [];
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i].selected && !this.emails[i].read) {
          this.emails[i].read = true;
          id.push(this.emails[i].messageId);
        }
      }
      this.markRead(id);
    },
    markRead(ids) {
      if (ids && ids.length > 0) {
        const body = { action: this.actions.read, messageIds: ids };
        this.$store
          .dispatch('messageActionRequest', body)
          .then((response) => {
            this.emails = this.emails.map((message) => {
              message.selected = false;
              return message;
            });
          })
          .catch((errors) => {});
      }
    },
    markUnread() {
      const id = [];
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i].selected && this.emails[i].read) {
          this.emails[i].read = false;
          id.push(this.emails[i].messageId);
        }
      }
      const body = { action: this.actions.unread, messageIds: id };
      this.$store
        .dispatch('messageActionRequest', body)
        .then((response) => {
          this.emails = this.emails.map((message) => {
            message.selected = false;
            return message;
          });
        })
        .catch((errors) => {});
    },
    markUnreadAndClose() {
      let body;
      if (this.activeMessage && this.activeMessage.messageId)
        body = { action: this.actions.unread, messageIds: [this.activeMessage.messageId] };
      this.$store
        .dispatch('messageActionRequest', body)
        .then((response) => {
          for (let i = 0; i < this.emails.length; i++) {
            if (this.emails[i].messageId == this.activeMessage.messageId) {
              this.emails[i].read = false;
            }
          }
          this.closeModal();
        })
        .catch((errors) => {});
    },
    deleteEmail() {
      const id = [];
      this.emails = this.emails.filter((email) => {
        if (email.selected) {
          id.push(email.messageId);
        }
        return !email.selected;
      });
      const body = { action: this.actions.delete, messageIds: id };
      this.isBusy = true;
      this.$store
        .dispatch('messageActionRequest', body)
        .then((response) => {
          this.isBusy = false;
          this.loadTotal();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    async compose() {
      //make sure they have selected a recipient
      if (this.selectedUsers.length > 0) {
        let confirmed1 = true;
        if (!this.composeEmail.subject) {
          //ask if they want to send with no subject
          confirmed1 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty subject?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        let confirmed2 = true;
        if (!this.composeEmail.message) {
          //ask if they want to send with no body
          confirmed2 = await new Promise((resolve, reject) => {
            const options = { title: 'Warning', cancelLabel: 'Cancel' };
            this.$dialogs
              .confirm('Are you sure you send a message with an empty body?', options)
              .then((res) => {
                if (res && res.ok && res.ok == true) {
                  resolve(true);
                }
                resolve(false);
              })
              .catch((error) => {
                resolve(false);
              });
          });
        }

        if (confirmed1 && confirmed2) {
          return await new Promise((resolve, reject) => {
            const body = {
              body: this.composeEmail.message,
              subject: this.composeEmail.subject,
              recipients: this.selectedUsers
            };
            this.isBusy = true;
            this.$store
              .dispatch('sendMessageRequest', body)
              .then((response) => {
                //Close message modal after reply sent.
                this.isModalVisible = false;
                this.isBusy = false;
                this.exitForm();
                resolve(true);
              })
              .catch((errors) => {
                this.isBusy = false;
                resolve(false);
              });
          });
        }
      } else {
        this.$dialogs.alert('No recipient selected', { title: 'Error' });
      }
    },
    toggleArchive() {
      const id = [];
      this.emails = this.emails.filter((email) => {
        if (email.selected) {
          id.push(email.messageId);
        }
        return !email.selected;
      });
      const action = this.activeFolder.folder == this.folders.inbox ? this.actions.archive : this.actions.unarchive;
      const body = { action: action, messageIds: id };
      this.isBusy = true;
      this.$store
        .dispatch('messageActionRequest', body)
        .then((response) => {
          this.isBusy = false;
          this.loadTotal();
        })
        .catch((errors) => {
          this.isBusy = false;
        });
    },
    folderChange(val) {
      this.switchingFolders = true;
      this.activeFilter.filter = this.filters.none;
      this.activeFolder.folder = val;
      this.loadTotal();
    },
    filterChange(val) {
      this.activeFilter.filter = val;
      this.loadTotal();
    },
    pageSizeChanged(size) {
      if (size && !isNaN(size)) {
        this.pageSize = parseInt(size);
      }
      this.loadPage(1);
    },
    async loadPage(page) {
      return await new Promise((resolve, reject) => {
        this.currentPage = page;
        const from = (page - 1) * this.pageSize;
        const to = from + this.pageSize;
        this.loadMessages(from, to);
        resolve(true);
      });
    },
    async loadTotal() {
      return await new Promise((resolve, reject) => {
        const body = {
          folder: this.activeFolder.folder,
          filter: this.activeFilter.filter
        };
        this.isBusy = true;
        this.$store
          .dispatch('getMessageCountRequest', body)
          .then(async (response) => {
            this.total = response;
            this.noData = this.total == 0;
            if (!this.noData) {
              await this.loadPage(1);
            } else {
              this.emails = [];
              this.isBusy = false;
            }
            resolve(true);
          })
          .catch((errors) => {
            this.isBusy = false;
            resolve(false);
          });
      });
    },
    async closeModal() {
      const confirmed = await this.confirmExitForm();
      if (confirmed) {
        this.isModalVisible = false;
        this.activeMessage = {};
        this.activeMessageId = 'closed';
        this.replyReady = false;
      }
    },
    openMessage(message) {
      if (message && message.messageId) {
        if (!message.read && this.activeFolder.folder != this.folders.sent) {
          this.emails.map((email) => {
            if (email.messageId == message.messageId) message.read = true;
          });
          this.markRead([message.messageId]);
        }
        this.activeMessage = message;
        this.activeMessageId = message.messageId;
        this.isModalVisible = true;
      }
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    getGroups(query) {
      const baseEntries = [{"id":"ALL-100", "name": "All ECE Professionals"},{"id":"ALL-200", "name": "All Directors"}];
      const selectedEntries = [];
      if (this.activeRole && this.activeRole.role && this.activeRole.role.roleName) {
        if (!['250','350','400','500'].includes(this.activeRole.role.roleName)) {
          return selectedEntries;
        }
        if (['350','400','500'].includes(this.activeRole.role.roleName)) {
          if (this.locale === 'al') baseEntries.push({"id":"ALL-250", "name": "All QEAs"});
        }
        if (['400','500'].includes(this.activeRole.role.roleName)) {
          if (this.locale === 'al') baseEntries.push({"id":"ALL-350", "name": "All Child Care Consultants"});
        }
        if (this.activeRole.role.roleName === '500') {
          baseEntries.push({"id":"ALL-400", "name": "All Managers"})
        }
      }
      for (const baseEntry of baseEntries) {
        if (baseEntry.name.toLowerCase().startsWith(query.toLowerCase()))
          selectedEntries.push(baseEntry);
      }
      return selectedEntries;
    },
    userSearch(query) {
      const lettersLimit = 2;
      if (query.length < lettersLimit) {
        this.noResultMessage = this.twoCharacterMessage;
        this.users = [];
        return;
      }

      const groups = this.getGroups(query);
      
      this.isLoading = true;
      const body = { searchString: query };

      this.$store
        .dispatch('messageUserSearchRequest', body)
        .then((result) => {
          this.isLoading = false;
          this.users = groups.concat(result);
          if (!this.users.length) {
            this.noResultMessage = this.emptySetMessage;
          }
        })
        .catch((errors) => {
          this.isLoading = false;
        });
    },
    usersSelected(users) {
      this.selectedUsers = users;
    },
    userColumnValue(email) {
      if (email) {
        if (this.activeFolder.folder != this.folders.sent && email.fromUser && email.fromUser.name)
          return email.fromUser.name;
        if (this.activeFolder.folder == this.folders.sent && email.recipients && email.recipients.length > 0) {
          const names = email.recipients.map((r) => r.id.startsWith('ALL-') ? this.mapDistroName(r.id) : r.name);
          if (names && names.length > 0) return names.join(', ');
        }
      }
      return 'Error';
    },
    mapDistroName(distroId) {
      switch (distroId) {
        case 'ALL-100':
          return 'All ECE Professionals';
        case 'ALL-200':
          return 'All Directors';
        case 'ALL-250':
          return 'All QEAs';
        case 'ALL-350':
          return (locale == 'al' ? 'All Child Care Consultants' : '');
        case 'ALL-400':
          return 'All  Managers';
        default:
          return '';
      }
    },
    replyFunction() {
      this.isReplyModalVisible = true;
      this.replyForm = true;
      let defaultSelectedUsers = [];
      this.defaultSelectedUsers = [];
      defaultSelectedUsers = [...[this.activeMessage.fromUser]];
      this.defaultSelectedUsers = defaultSelectedUsers;
      this.composeEmail.subject = 'RE: ' + this.activeMessage.subject;
      const formattedSentDate = this.$formatFullDate(this.activeMessage.sentDate);
      this.composeEmail.message =
        '<br /><br />' +
        this.activeMessage.fromUser.name +
        ' - ' +
        formattedSentDate +
        ' -------------------------------------------------------------------------<br /> ' +
        this.activeMessage.body;
      this.$nextTick().then(() => {
        this.replyReady = true;
      });
    },
    replyAllFunction() {
      this.isReplyModalVisible = true;
      this.replyForm = true;
      let defaultSelectedUsers = [];
      this.defaultSelectedUsers = [];
      defaultSelectedUsers = [...this.activeMessage.recipients];
      let addFromUser = true;
      for (let i = 0; i < defaultSelectedUsers.length; i++) {
        if (defaultSelectedUsers[i].id == this.activeMessage.fromUser.id) {
          addFromUser = false;
        }
        if (defaultSelectedUsers[i].id == this.profile.id) {
          defaultSelectedUsers.splice(i, 1);
        }
      }
      if (addFromUser) {
        defaultSelectedUsers.push(this.activeMessage.fromUser);
      }
      this.defaultSelectedUsers = defaultSelectedUsers;
      this.composeEmail.subject = 'RE: ' + this.activeMessage.subject;
      const formattedSentDate = this.$formatFullDate(this.activeMessage.sentDate);
      this.composeEmail.message =
        '<br /><br />' +
        this.activeMessage.fromUser.name +
        ' - ' +
        formattedSentDate +
        ' -------------------------------------------------------------------------<br /> ' +
        this.activeMessage.body;
      this.$nextTick().then(() => {
        this.replyReady = true;
      });
    }
  }
};
</script>
