<template>
  <div class="messagebox">
    <div
      class="row message fa-close align-items-center"
      :class="{
        read: email.read,
        unread: !email.read,
        selected: email.selected
      }"
    >
      <div class="col-4 col-md-2 text-center check-star">
        <div class="row">
          <div class="check-row-item">
            <b-form-checkbox :class="'checkbox' + email.selected" v-model="email.selected"></b-form-checkbox>
          </div>
          <button
            @click="starMessage(email)"
            class="check-row-item abtn btn ml-1 ml-lg-3 p-0"
            v-if="activeFolder.folder != folders.sent"
          >
            <icon v-if="email.starred" class="message-starred" icon="star"></icon>
            <icon v-if="!email.starred" :icon="['far', 'star']" class="star"></icon>
          </button>
        </div>
      </div>
      <div class="col-8 col-md-3 message-data message-from" @click="openMessage(email)">
        {{ userColumnValue(email) }}
      </div>
      <button class="col-9 col-md-5 col-lg-6 abtn btn" @click="openMessage(email)">
        <div class="message-data">
          <div>{{ email.subject }}</div>
          <div v-if="email.body" class="message-content-body text-truncate mt-1">
            {{ strippedBody }}
          </div>
        </div>
      </button>
      <div
        class="col-3 col-md-2 col-lg-1 text-right message-date"
        @click="openMessage(email)"
        :title="formatFullDate(email.sentDate)"
      >
        {{ formatEmailDate(email.sentDate) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageItem',
  props: [
    'email',
    'starMessage',
    'bulkSelect',
    'formatEmailDate',
    'activeFolder',
    'folders',
    'openMessage',
    'userColumnValue',
    'formatFullDate'
  ],
  data() {
    return {
      strippedBody: ''
    };
  },
  created() {
    window.addEventListener('resize', this.stripHtml);
    this.stripHtml();
  },
  destroyed() {
    window.removeEventListener('resize', this.stripHtml);
  },
  methods: {
    stripHtml() {
      this.strippedBody = '';
      let html = this.email.body;
      const tmp = document.implementation.createHTMLDocument('New').body;
      html = html.replace('<br>', ' ');
      if (window.innerWidth <= 992) {
        tmp.innerHTML = html.substring(0, 100);
      } else if (window.innerWidth > 992 && window.innerWidth <= 1400) {
        tmp.innerHTML = html.substring(0, 180);
      } else {
        tmp.innerHTML = html.substring(0, 250);
      }
      this.strippedBody = tmp.textContent || tmp.innerText || '';
    }
  }
};
</script>
