<template>
  <div class="form-group col-md-6">
    <label for="ddAgency">List of {{ $t('message.agencyPlural') }}</label>
    <br />
    <select :value="value"
            @input="$emit('input', $event.target.value)"
            class="custom-select form-el"
            name="ddAgency"
            id="ddAgency">
      <option value="" selected disabled hidden>Select {{ $t('message.agencyArticle') }} {{ $t('message.agency') }} in {{ county }} county</option>
      <option v-for="choice in choices" v-bind:key="choice.key" :value="choice.key" style="color: black;">
        {{ choice.value }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'AgencyDropdown',
  props: {
    value: {
      type: String,
      default: ''
    },
    county: {
      type: String,
      default: ''
    },
    choices: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: null,
      isBusy: false,
      timeoutId: null,
      noData: false,
      errorMessage: null,
      errors: ''
    };
  },
  created() {
    if (this.value) {
      const obj = JSON.parse(this.value);
      if (obj && obj.key && obj.value) {
        this.selected = obj;
        this.choices = [obj];
      }
    }
  },
  watch: {
    selected: function (newVal) {
      if (newVal && newVal.key) {
        this.$emit('input', newVal);
      } else {
        this.$emit('input', {});
      }
    }
  }
}
</script>
