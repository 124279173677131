<template>
    <div>
      <div class="form-row">
        <div class="form-group col-12">
          <div role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <span style="font-size: 1rem">
                  <span class="ml-4">Trainee Information</span>
                </span>
              </b-card-header>
              <b-card-body>
                <div class="form-row">
                  <div class="form-group col-12">
                    <strong>Name:</strong> {{ endorsement.FullName }}
                  </div>
                  <div class="form-group col-12">
                    <strong>Email:</strong> {{ endorsement.Email }}
                  </div>
                  <div class="form-group col-12">
                    <strong>TNPAL ID:</strong> {{ endorsement.RegistryId }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>

        <div class="form-group col-12">
          <div role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <span style="font-size: 1rem">
                  <span class="ml-4">Endorsement Details</span>
                </span>
              </b-card-header>
              <b-card-body>
                <div class="form-row">
                  <div class="form-group col-12">
                    <strong>Endorsement Type:</strong> {{ endorsement.EndorsementName && endorsement.EndorsementName.includes('IMH-E') ? 'IMH-E®' : 'ECMH-E®'}}
                  </div>
                  <div class="form-group col-12">
                    <strong>Endorsement Name:</strong> {{ endorsement.EndorsementName }}
                  </div>
                  <div class="form-group col-12" v-if="endorsement.EndorsementSubcategory">
                    <strong>Endorsement Subcategory:</strong> {{ endorsement.EndorsementSubcategory }}
                  </div>
                  <div class="form-group col-12">
                    <strong>Original Endorsement Date:</strong> {{ endorsement.EndorsementStartDate }}
                  </div>
                  <div class="form-group col-12">
                    <strong>Endorsement Expiration Date:</strong> {{ endorsement.EndorsementEndDate }}
                  </div>
                  <div class="form-group col-12" v-if="endorsement.EndorsementRenewDate">
                    <strong>Prior Endorsement Renewal Date:</strong> {{ endorsement.EndorsementRenewDate }}
                  </div>
                  <div class="form-group col-12">
                    <strong>Awarding Institution/Organization:</strong> {{ endorsement.EndorsementAwardingInstitution }}
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>   

      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EndorsementDetail',
    data() {
      return {
        endorsement: {}
      };
    },
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    created() {
      this.loadInfo()
    },
    methods: {
      loadInfo() {
        if (this.id) {
          const body = {
            id: this.id
          };
          this.isBusy = true;
          this.$store
          .dispatch('endorsementDetailRequest', body)
          .then(async (response) => {
            this.isBusy = false;
            this.endorsement = response;
          })
          .catch((errors) => {
            this.isBusy = false;
          });
        }
      }
    }
  };
  </script>
  