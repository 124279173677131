import Vue from 'vue';
import Vuex from 'vuex';
import { apiService } from '@api';
import router from '../router/index';
import { getText } from '@scripts/localized';

Vue.use(Vuex);

// TYPES
const MAIN_SET_COUNTER = 'MAIN_SET_COUNTER';

// STATE

const getDefaultState = function () {
  return {
    counter: 1,
    authStatus: '',
    regStatus: '',
    profile: {},
    profileStatus: '',
    authenticated: !!sessionStorage.getItem('auth-token'),
    notifyStatus: '',
    resendNotifyStatus: '',
    seedStatus: '',
    confirmationStatus: '',
    confirmSecondaryStatus: '',
    secondaryEmailStatus: '',
    configStatus: '',
    forgotPasswordStatus: '',
    resetPasswordStatus: '',
    claims: [],
    formStatus: '',
    formResponseStatus: '',
    firstTime: !!sessionStorage.getItem('first-time'),
    tacFlag: !!sessionStorage.getItem('tac-flag'),
    token: sessionStorage.getItem('auth-token'),
    userId: '',
    gridDataStatus: '',
    gridSummaryStatus: '',
    gridDisableStatus: '',
    gridDeleteStatus: '',
    termsAcceptStatus: '',
    emailChangeStatus: '',
    autoCompleteSearchStatus: '',
    entityCrossReferenceSearchStatus: '',
    entityCrossReferencePropertySearchStatus: '',
    roles: [],
    sendMessageStatus: '',
    getMessagesStatus: '',
    getMessageCountStatus: '',
    messageActionStatus: '',
    messageUserSearchStatus: '',
    emailConfStatus: '',
    formFlatFileStatus: '',
    userOrganizationRolesStatus: '',
    userOrganizationRolesUpdateStatus: '',
    addAccountRoleStatus: '',
    removeAccountRoleStatus: '',
    tokenReceivedTime: 0,
    expiresIn: 0,
    refreshAt: 0,
    expirationCheckTimeout: null,
    isIdle: false,
    rolePromotionStatus: '',
    rolePromotionWarning: `${getText('rolePromotionWarning')}`,
    userOrganizationRoles: [],
    rolesSuccess: false,
    hasActiveRole: !!sessionStorage.getItem('active-role'),
    activeRole: {},
    activeRoleId: sessionStorage.getItem('active-role'),
    organizationRequestsStatus: '',
    requestActionStatus: '',
    verificationActionStatus: '',
    agencySearchStatus: '',
    countySearchStatus: '',
    agencySearchByCountyStatus: '',
    courseSearchStatus: '',
    deleteCourseStatus: '',
    courseSectionSearchStatus: '',
    courseSectionDetailStatus: '',
    deleteSectionStatus: '',
    coursePropsStatus: '',
    courseRegistrationStatus: '',
    courseWaitlistStatus: '',
    requestRegistrationStatus: '',
    cancelRegistrationStatus: '',
    acceptRejectOverrideRequestStatus: '',
    setAttendanceStatus: '',
    regionCheckStatus: '',
    getOwnOrgsStatus: '',
    getUserOrgsStatus: '',
    pilotCheckStatus: '',
    emailConfirmStatus: '',
    calendarEventStatus: '',
    trainerCalendarEventStatus: '',
    courseSectionPropsStatus: '',
    enrollmentPropsStatus: '',
    organizationEmployeesReportStatus: '',
    complianceReportStatus: '',
    cachedComplianceReportStatus: '',
    agencyReportStatus: '',
    verificationHistoryRequestStatus: '',
    verificationRequestRequestStatus: '',
    roleSwitchRequestStatus: '',
    directorReportRequestStatus: '',
    adminReportRequestStatus: '',
    supervisorReportRequestStatus: '',
    coachReportRequestStatus: '',
    qeaReportRequestStatus: '',
    preSubmissionValidationRequestStatus: '',
    actionHistoryRequestStatus: '',
    getNotificationsStatus: '',
    getNotificationCountStatus: '',
    notificationActionStatus: '',
    getAlertsStatus: '',
    dismissAlertStatus: '',
    coachRelationshipActionStatus: '',
    requestedCoachesStatus: '',
    unlockUserStatus: '',
    fileDownloadStatus: '',
    fileDeleteStatus: '',
    staticFileDownloadStatus: '',
    reportDownloadStatus: '',
    staticFileUrlStatus: '',
    createResumeStatus: '',
    viewResumeFieldsStatus: '',
    editResumeStatus: '',
    publishResumeStatus: '',
    downloadResumeStatus: '',
    submitReportStatus: '',
    reportResponseStatus: '',
    qrisCycleStatus: '',
    getArchivedCyclesStatus: '',
    getArchivedMembersByOrgCycleStatus: '',
    createReferenceStatus: '', //TODO: Reference - not sure which will be needed, so keep for now
    viewReferenceFieldsStatus: '',
    editReferenceStatus: '',
    trainTNAccessStatus: '',
    getOrgByIdStatus: '',
    attestationListStatus: '',
    setAttestationStatus: '',
    endorsementDetailStatus: '',
    quickSightEnabled: false
  };
};
const state = getDefaultState();

// GETTERS
const getters = {
  isAuthenticated: () => state.authenticated,
  authStatus: (state) => state.authStatus,
  regStatus: (state) => state.regStatus,
  profile: (state) => state.profile,
  profileStatus: (state) => state.profileStatus,
  notifyStatus: (state) => state.notifyStatus,
  resendNotifyStatus: (state) => state.resendNotifyStatus,
  seedStatus: (state) => state.seedStatus,
  confirmationStatus: (state) => state.confirmationStatus,
  confirmSecondaryStatus: (state) => state.confirmSecondaryStatus,
  secondaryEmailStatus: (state) => state.secondaryEmailStatus,
  configStatus: (state) => state.configStatus,
  forgotPasswordStatus: (state) => state.forgotPasswordStatus,
  resetPasswordStatus: (state) => state.resetPasswordStatus,
  formStatus: (state) => state.formStatus,
  formResponseStatus: (state) => state.formResponseStatus,
  isFirstTime: (state) => state.firstTime,
  needsTac: (state) => state.tacFlag,
  token: (state) => state.token,
  userId: (state) => {
    if (state.userId) return state.userId;
    if (state.token) {
      const decodedToken = decodeToken(state.token);
      if (decodedToken && decodedToken.id) {
        state.userId = decodedToken.id;
        return decodedToken.id;
      }
    }
    return '';
  },
  gridDataStatus: (state) => state.gridDataStatus,
  gridSummaryStatus: (state) => state.gridSummaryStatus,
  gridDisableStatus: (state) => state.gridDisableStatus,
  gridDeleteStatus: (state) => state.gridDeleteStatus,
  termsAcceptStatus: (state) => state.termsAcceptStatus,
  emailChangeStatus: (state) => state.emailChangeStatus,
  autoCompleteSearchStatus: (state) => state.autoCompleteSearchStatus,
  entityCrossReferenceSearchStatus: (state) => state.entityCrossReferenceSearchStatus,
  entityCrossReferencePropertySearchStatus: (state) => state.entityCrossReferencePropertySearchStatus,
  roles: (state) => {
    if (state.roles && state.roles.length > 0) return state.roles;
    if (state.token) {
      const decodedToken = decodeToken(state.token);
      if (decodedToken) {
        const roles = extractRolesFromDecodedToken(decodedToken);
        if (roles && roles.length > 0) {
          state.roles = roles;
          return roles;
        }
      }
    }
    return [];
  },
  claims: (state) => {
    if (state.claims && state.claims.length > 0) return state.claims;
    if (state.token) {
      const decodedToken = decodeToken(state.token);
      if (decodedToken) {
        const claims = extractClaimsFromDecodedToken(decodedToken);
        if (claims && claims.length > 0) {
          state.claims = claims;
          return claims;
        }
      }
    }
    return [];
  },
  sendMessageStatus: (state) => state.sendMessageStatus,
  getMessagesStatus: (state) => state.getMessagesStatus,
  getMessageCountStatus: (state) => state.getMessageCountStatus,
  messageActionStatus: (state) => state.messageActionStatus,
  messageUserSearchStatus: (state) => state.messageUserSearchStatus,
  emailConfStatus: (state) => state.emailConfStatus,
  formFlatFileStatus: (state) => state.formFlatFileStatus,
  userOrganizationRolesStatus: (state) => state.userOrganizationRolesStatus,
  userOrganizationRolesUpdateStatus: (state) => state.userOrganizationRolesUpdateStatus,
  addAccountRoleStatus: (state) => state.addAccountRoleStatus,
  removeAccountRoleStatus: (state) => state.removeAccountRoleStatus,
  tokenReceivedTime: (state) => state.tokenReceivedTime,
  expiresIn: (state) => state.expiresIn,
  refreshAt: (state) => state.refreshAt,
  expirationCheckTimeout: (state) => state.expirationCheckTimeout,
  isIdle: (state) => state.isIdle,
  rolePromotionStatus: (state) => state.rolePromotionStatus,
  rolePromotionWarning: (state) => state.rolePromotionWarning,
  userOrganizationRoles: (state) => state.userOrganizationRoles,
  rolesSuccess: (state) => state.rolesSuccess,
  hasActiveRole: (state) => state.hasActiveRole,
  activeRole: (state) => state.activeRole,
  activeRoleId: (state) => state.activeRoleId,
  organizationRequestsStatus: (state) => state.organizationRequestsStatus,
  requestActionStatus: (state) => state.requestActionStatus,
  agencySearchStatus: (state) => state.agencySearchStatus,
  agencySearchByCountyStatus: (state) => state.agencySearchByCountyStatus,
  countySearchStatus: (state) => state.countySearchStatus,
  courseSearchStatus: (state) => state.courseSearchStatus,
  deleteCourseStatus: (state) => state.deleteCourseStatus,
  courseSectionSearchStatus: (state) => state.courseSectionSearchStatus,
  courseSectionDetailStatus: (state) => state.courseSectionDetailStatus,
  deleteSectionStatus: (state) => state.deleteSectionStatus,
  coursePropsStatus: (state) => state.coursePropsStatus,
  courseRegistrationStatus: (state) => state.courseRegistrationStatus,
  courseWaitlistStatus: (state) => state.courseWaitlistStatus,
  requestRegistrationStatus: (state) => state.requestRegistrationStatus,
  acceptRejectOverrideRequestStatus: (state) => state.acceptRejectOverrideRequestStatus,
  cancelRegistrationStatus: (state) => state.cancelRegistrationStatus,
  setAttendanceStatus: (state) => state.setAttendanceStatus,
  regionCheckStatus: (state) => state.regionCheckStatus,
  getOwnOrgsStatus: (state) => state.getOwnOrgsStatus,
  getUserOrgsStatus: (state) => state.getUserOrgsStatus,
  pilotCheckStatus: (state) => state.pilotCheckStatus,
  emailConfirmStatus: (state) => state.emailconfirmStatus,
  calendarEventStatus: (state) => state.calendarEventStatus,
  trainerCalendarEventStatus: (state) => state.trainerCalendarEventStatus,
  courseSectionPropsStatus: (state) => state.courseSectionPropsStatus,
  enrollmentPropsStatus: (state) => state.enrollmentPropsStatus,
  organizationEmployeesReportStatus: (state) => state.organizationEmployeesReportStatus,
  complianceReportStatus: (state) => state.complianceReportStatus,
  cachedComplianceReportStatus: (state) => state.cachedComplianceReportStatus,
  agencyReportStatus: (state) => state.agencyReportStatus,
  verificationHistoryRequestStatus: (state) => state.verificationHistoryRequestStatus,
  verificationRequestRequestStatus: (state) => state.verificationRequestRequestStatus,
  roleSwitchRequestStatus: (state) => state.roleSwitchRequestStatus,
  directorReportRequestStatus: (state) => state.directorReportRequestStatus,
  adminReportRequestStatus: (state) => state.adminReportRequestStatus,
  supervisorReportRequestStatus: (state) => state.supervisorReportRequestStatus,
  coachReportRequestStatus: (state) => state.coachReportRequestStatus,
  preSubmissionValidationRequestStatus: (state) => state.preSubmissionValidationRequestStatus,
  actionHistoryRequestStatus: (state) => state.actionHistoryRequestStatus,
  getNotificationsStatus: (state) => state.getNotificationsStatus,
  getNotificationCountStatus: (state) => state.getNotificationCountStatus,
  notificationActionStatus: (state) => state.notificationActionStatus,
  getAlertsStatus: (state) => state.getAlertsStatus,
  dismissAlertStatus: (state) => state.dismissAlertStatus,
  coachRelationshipActionStatus: (state) => state.coachRelationshipActionStatus,
  requestedCoachesStatus: (state) => state.requestedCoachesStatus,
  unlockUserStatus: (state) => state.unlockUserStatus,
  fileDownloadStatus: (state) => state.fileDownloadStatus,
  fileDeleteStatus: (state) => state.fileDeleteStatus,
  staticFileDownloadStatus: (state) => state.staticFileDownloadStatus,
  reportDownloadStatus: (state) => state.reportDownloadStatus,
  staticFileUrlStatus: (state) => state.staticFileUrlStatus,
  createResumeStatus: (state) => state.createResumeStatus,
  viewResumeFieldsStatus: (state) => state.viewResumeFieldsStatus,
  editResumeStatus: (state) => state.editResumeStatus,
  publishResumeStatus: (state) => state.publishResumeStatus,
  downloadResumeStatus: (state) => state.downloadResumeStatus,
  submitReportStatus: (state) => state.submitReportStatus,
  reportResponseStatus: (state) => state.reportResponseStatus,
  qrisCycleStatus: (state) => state.qrisCycleStatus,
  getArchivedCyclesStatus: (state) => state.getArchivedCyclesStatus,
  getArchiveSummaryReportStatus: (state) => state.getArchiveSummaryReportStatus,
  getArchivedMembersByOrgCycleStatus: (state) => state.getArchivedMembersByOrgCycleStatus,
  createReferenceStatus: (state) => state.createReferenceStatus, //TODO: Not sure, which of these will remain
  viewReferenceFieldsStatus: (state) => state.viewReferenceFieldsStatus,
  editReferenceStatus: (state) => state.editReferenceStatus,
  trainTNAccessStatus: (state) => state.trainTNAccessStatus,
  getOrgByIdStatus: (state) => state.getOrgByIdStatus,
  attestationListStatus: (state) => state.attestationListStatus,
  setAttestationStatus: (state) => state.setAttestationStatus,
  quickSightEnabled: (state) => state.quickSightEnabled,
  endorsementDetailStatus: (state) => state.endorsementDetailStatus
};

function decodeToken(token) {
  if (token) {
    const base64Url = token.split('.')[1];
    if (base64Url) {
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      if (jsonPayload) {
        const decodedToken = JSON.parse(jsonPayload);
        if (decodedToken) {
          return decodedToken;
        }
      }
    }
  }
  return {};
}

function extractRolesFromDecodedToken(decodedToken) {
  for (const prop in decodedToken) {
    if (prop.indexOf('identity/claims/role') > 0 && Object.prototype.hasOwnProperty.call(decodedToken, prop)) {
      const roles = decodedToken[prop];
      if (roles.length > 0) {
        return roles;
      }
    }
  }
  return [];
}

function extractClaimsFromDecodedToken(decodedToken) {
  for (const prop in decodedToken) {
    if (prop.indexOf('Permission') >= 0 && Object.prototype.hasOwnProperty.call(decodedToken, prop)) {
      const claims = decodedToken[prop];
      if (claims.length > 0) {
        return JSON.parse(claims);
      }
    }
  }
  return [];
}

function hasTimePassed(receivedAt, timeInterval) {
  return receivedAt + timeInterval < new Date().getTime();
}

function getOffsetSecondsDate(offset) {
  const d1 = new Date();
  const d2 = new Date(d1);
  d2.setSeconds(d1.getSeconds() + offset);
  return d2;
}

// MUTATIONS
const mutations = {
  [MAIN_SET_COUNTER](state, obj) {
    state.counter = obj.counter;
  },
  authRequest: (state) => {
    state.authStatus = 'attempting authentication request';
  },
  authSuccess: (state, data) => {
    state.authStatus = 'authentication succeeded';
    state.authenticated = true;
    state.expiresIn = (data && data.expiresIn ? data.expiresIn : 18000) * 1000;
    state.refreshAt = (data && data.refreshAt ? data.refreshAt : 900) * 1000;
    state.tokenReceivedTime = new Date().getTime();
  },
  authLogout: (state) => {
    state.authenticated = false;
    // need to use this assignment to maintain state observer
    Object.assign(state, getDefaultState());
  },
  authError: (state) => {
    state.authStatus = 'error';
  },
  regRequest: (state) => {
    state.regStatus = 'attempting registration request';
  },
  regSuccess: (state) => {
    state.regStatus = 'registration succeeded';
  },
  regError: (state) => {
    state.regStatus = 'error';
  },
  notifyRequest: (state) => {
    state.notifyStatus = 'awaiting notify request';
  },
  notifySuccess: (state) => {
    state.notifyStatus = 'success';
  },
  notifyError: (state) => {
    state.notifyStatus = 'error';
  },
  resendNotifyRequest: (state) => {
    state.resendNotifyStatus = 'awaiting resend notify request';
  },
  resendNotifySuccess: (state) => {
    state.resendNotifyStatus = 'success';
  },
  resendNotifyError: (state) => {
    state.resendNotifyStatus = 'error';
  },
  seedRequest: (state) => {
    state.seedStatus = 'awaiting seed request';
  },
  seedSuccess: (state) => {
    state.seedStatus = 'success';
  },
  seedError: (state) => {
    state.seedStatus = 'error';
  },
  profileRequest: (state) => {
    state.profileStatus = 'attempting profile request';
  },
  profileSuccess: (state, data) => {
    Vue.set(state, 'profile', data);
    if (data.id) {
      state.userId = data.id;
    }
    state.profileStatus = 'success';
  },
  profileError: (state) => {
    state.profileStatus = 'error';
  },
  confirmationRequest: (state) => {
    state.confirmationStatus = 'attempting confirmation request';
  },
  confirmationSuccess: (state) => {
    state.confirmationStatus = 'confirmation succeeded';
  },
  confirmationError: (state) => {
    state.confirmationStatus = 'error';
  },
  confirmSecondaryRequest: (state) => {
    state.confirmSecondaryStatus = 'attempting confirm secondary request';
  },
  confirmSecondarySuccess: (state) => {
    state.confirmSecondaryStatus = 'confirm secondary succeeded';
  },
  confirmSecondaryError: (state) => {
    state.confirmSecondaryStatus = 'error';
  },
  secondaryEmailRequest: (state) => {
    state.secondaryEmailStatus = 'attempting secondary email request';
  },
  secondaryEmailSuccess: (state) => {
    state.secondaryEmailStatus = 'secondary email succeeded';
  },
  secondaryEmailError: (state) => {
    state.secondaryEmailStatus = 'error';
  },
  configRequest: (state) => {
    state.configStatus = 'attempting configuration request';
  },
  configSuccess: (state) => {
    state.configStatus = 'configuration request succeeded';
  },
  configError: (state) => {
    state.configStatus = 'error';
  },
  forgotPasswordRequest: (state) => {
    state.forgotPasswordStatus = 'attempting forgot password request';
  },
  forgotPasswordSuccess: (state) => {
    state.forgotPasswordStatus = 'forgot password succeeded';
  },
  forgotPasswordError: (state) => {
    state.forgotPasswordStatus = 'error';
  },
  resetPasswordRequest: (state) => {
    state.resetPasswordStatus = 'attempting reset password request';
  },
  resetPasswordSuccess: (state) => {
    state.resetPasswordStatus = 'reset password succeeded';
  },
  resetPasswordError: (state) => {
    state.resetPasswordStatus = 'error';
  },
  formRequest: (state) => {
    state.formStatus = 'attempting form request';
  },
  formSuccess: (state) => {
    state.formStatus = 'form request succeeded';
  },
  formError: (state) => {
    state.formStatus = 'error';
  },
  formResponseRequest: (state) => {
    state.formResponseStatus = 'attempting form request';
  },
  formResponseSuccess: (state) => {
    state.formResponseStatus = 'form request succeeded';
  },
  formResponseError: (state) => {
    state.formResponseStatus = 'error';
  },
  fileUploadRequest: (state) => {
    state.fileUploadStatus = 'attempting file upload request';
  },
  fileUploadSuccess: (state) => {
    state.fileUploadStatus = 'file upload succeeded';
  },
  fileUploadError: (state) => {
    state.fileUploadStatus = 'error';
  },
  fileDownloadRequest: (state) => {
    state.fileDownloadStatus = 'attempting file download request';
  },
  fileDownloadSuccess: (state) => {
    state.fileDownloadStatus = 'file download succeeded';
  },
  fileDownloadError: (state) => {
    state.fileDownloadStatus = 'error';
  },
  fileDeleteRequest: (state) => {
    state.fileDeleteStatus = 'attempting file delete request';
  },
  fileDeleteSuccess: (state) => {
    state.fileDeleteStatus = 'file delete succeeded';
  },
  fileDeleteError: (state) => {
    state.fileDeleteStatus = 'error';
  },
  staticFileDownloadRequest: (state) => {
    state.staticFileDownloadStatus = 'attempting static file download request';
  },
  staticFileDownloadSuccess: (state) => {
    state.staticFileDownloadStatus = 'static file download succeeded';
  },
  staticFileDownloadError: (state) => {
    state.staticFileDownloadStatus = 'error';
  },
  reportDownloadRequest: (state) => {
    state.reportDownloadStatus = 'attempting report download request';
  },
  reportDownloadSuccess: (state) => {
    state.reportDownloadStatus = 'report download succeeded';
  },
  reportDownloadError: (state) => {
    state.reportDownloadStatus = 'error';
  },
  staticFileUrlRequest: (state) => {
    state.staticFileUrlStatus = 'attempting static url download request';
  },
  staticFileUrlSuccess: (state) => {
    state.staticFileUrlStatus = 'static file url succeeded';
  },
  staticFileUrlError: (state) => {
    state.staticFileUrlStatus = 'static file url error';
  },

  toggleFirstTime: (state, status) => {
    state.firstTime = status;
  },
  toggleTacFlag: (state, status) => {
    state.tacFlag = status;
  },
  setUserId: (state, id) => {
    state.userId = id;
  },
  gridDataRequest: (state) => {
    state.gridDataStatus = 'attempting grid data request';
  },
  gridDataRequestSuccess: (state) => {
    state.gridDataStatus = 'grid data request succeeded';
  },
  gridDataRequestError: (state) => {
    state.gridDataStatus = 'error';
  },
  gridSummaryRequest: (state) => {
    state.gridSummaryStatus = 'attempting grid summary request';
  },
  gridSummaryRequestSuccess: (state) => {
    state.gridSummaryStatus = 'grid summary request succeeded';
  },
  gridSummaryRequestError: (state) => {
    state.gridSummaryStatus = 'error';
  },
  gridDisableRequest: (state) => {
    state.gridDisableStatus = 'attempting grid disable request';
  },
  gridDisableRequestSuccess: (state) => {
    state.gridDisableStatus = 'grid disable request succeeded';
  },
  gridDisableRequestError: (state) => {
    state.gridDisableStatus = 'error';
  },
  gridDeleteRequest: (state) => {
    state.gridDeleteStatus = 'attempting grid delete request';
  },
  gridDeleteRequestSuccess: (state) => {
    state.gridDeleteStatus = 'grid delete request succeeded';
  },
  gridDeleteRequestError: (state) => {
    state.gridDeleteStatus = 'error';
  },
  termsAcceptRequest: (state) => {
    state.termsAcceptStatus = 'attempting terms accept request';
  },
  termsAcceptRequestSuccess: (state) => {
    state.termsAcceptStatus = 'terms accept request succeeded';
  },
  termsAcceptRequestError: (state) => {
    state.termsAcceptStatus = 'error';
  },
  emailChangeRequest: (state) => {
    state.emailChangeStatus = 'attempting email change request';
  },
  emailChangeRequestSuccess: (state) => {
    state.emailChangeStatus = 'email change request succeeded';
  },
  emailChangeRequestError: (state) => {
    state.emailChangeStatus = 'error';
  },
  autoCompleteSearchRequest: (state) => {
    state.autoCompleteSearchStatus = 'attempting auto complete search request';
  },
  autoCompleteSearchRequestSuccess: (state) => {
    state.autoCompleteSearchStatus = 'auto complete search request succeeded';
  },
  autoCompleteSearchRequestError: (state) => {
    state.autoCompleteSearchStatus = 'error';
  },
  entityCrossReferenceSearchRequest: (state) => {
    state.entityCrossReferenceSearchStatus = 'attempting entity cross reference search request';
  },
  entityCrossReferenceSearchRequestSuccess: (state) => {
    state.entityCrossReferenceSearchStatus = 'entity cross reference search request succeeded';
  },
  entityCrossReferenceSearchRequestError: (state) => {
    state.entityCrossReferenceSearchStatus = 'error';
  },
  entityCrossReferencePropertySearchRequest: (state) => {
    state.entityCrossReferencePropertySearchStatus = 'attempting entity cross reference property search request';
  },
  entityCrossReferencePropertySearchRequestSuccess: (state) => {
    state.entityCrossReferencePropertySearchStatus = 'entity cross reference property search request succeeded';
  },
  entityCrossReferencePropertySearchRequestError: (state) => {
    state.entityCrossReferencePropertySearchStatus = 'error';
  },
  rolesSuccess: (state, data) => {
    Vue.set(state, 'roles', data);
  },
  claimsSuccess: (state, data) => {
    Vue.set(state, 'claims', data);
  },
  sendConfEmailRequest: (state) => {
    state.emailConfStatus = 'attempting confirmation email request';
  },
  sendConfEmailRequestSuccess: (state) => {
    state.emailConfStatus = 'confirmation email request succeeded';
  },
  sendConfEmailRequestError: (state) => {
    state.emailConfStatus = 'error';
  },
  sendMessageRequest: (state) => {
    state.sendMessageStatus = 'attempting send message request';
  },
  sendMessageRequestSuccess: (state) => {
    state.sendMessageStatus = 'send message request succeeded';
  },
  sendMessageRequestError: (state) => {
    state.sendMessageStatus = 'error';
  },
  getMessagesRequest: (state) => {
    state.getMessagesStatus = 'attempting message request';
  },
  getMessagesRequestSuccess: (state) => {
    state.getMessagesStatus = 'message request succeeded';
  },
  getMessagesRequestError: (state) => {
    state.getMessagesStatus = 'error';
  },
  getMessageCountRequest: (state) => {
    state.getMessageCountStatus = 'attempting message count request';
  },
  getMessageCountRequestSuccess: (state) => {
    state.getMessageCountStatus = 'message count request succeeded';
  },
  getMessageCountRequestError: (state) => {
    state.getMessageCountStatus = 'error';
  },
  messageActionRequest: (state) => {
    state.messageActionStatus = 'attempting message action request';
  },
  messageActionRequestSuccess: (state) => {
    state.messageActionStatus = 'message action request succeeded';
  },
  messageActionRequestError: (state) => {
    state.messageActionStatus = 'error';
  },
  messageUserSearchRequest: (state) => {
    state.messageUserSearchStatus = 'attempting message user search request';
  },
  messageUserSearchRequestSuccess: (state) => {
    state.messageUserSearchStatus = 'message user search request succeeded';
  },
  messageUserSearchRequestError: (state) => {
    state.messageUserSearchStatus = 'error';
  },
  formFlatFileRequest: (state) => {
    state.formFlatFileStatus = 'attempting form flat file request';
  },
  formFlatFileRequestSuccess: (state) => {
    state.formFlatFileStatus = 'form flat file request succeeded';
  },
  formFlatFileRequestError: (state) => {
    state.formFlatFileStatus = 'error';
  },
  userOrganizationRolesRequest: (state) => {
    state.userOrganizationRolesStatus = 'attempting user organization roles request';
  },
  userOrganizationRolesRequestSuccess: (state) => {
    state.userOrganizationRolesStatus = 'user organization roles request succeeded';
  },
  userOrganizationRolesRequestError: (state) => {
    state.userOrganizationRolesStatus = 'error';
  },
  userOrganizationRolesUpdateRequest: (state) => {
    state.userOrganizationRolesUpdateStatus = 'attempting user organization roles update request';
  },
  userOrganizationRolesUpdateRequestSuccess: (state) => {
    state.userOrganizationRolesUpdateStatus = 'user organization roles update request succeeded';
  },
  userOrganizationRolesUpdateRequestError: (state) => {
    state.userOrganizationRolesUpdateStatus = 'error';
  },
  addAccountRoleRequest: (state) => {
    state.addAccountRoleStatus = 'attempting add account role request';
  },
  addAccountRoleRequestSuccess: (state) => {
    state.addAccountRoleStatus = 'add account role request succeeded';
  },
  addAccountRoleRequestError: (state) => {
    state.addAccountRoleStatus = 'error';
  },
  removeAccountRoleRequest: (state) => {
    state.removeAccountRoleStatus = 'attempting remove account role request';
  },
  removeAccountRoleRequestSuccess: (state) => {
    state.removeAccountRoleStatus = 'remove account role request succeeded';
  },
  removeAccountRoleRequestError: (state) => {
    state.removeAccountRoleStatus = 'error';
  },
  expirationCheckTimeoutSuccess: (state, data) => {
    state.expirationCheckTimeout = data;
  },
  idleVue: (state, data) => {
    state.isIdle = data;
  },
  rolePromotionRequest: (state) => {
    state.rolePromotionStatus = 'attempting role promotion request';
  },
  rolePromotionRequestSuccess: (state) => {
    state.rolePromotionStatus = 'role promotion request succeeded';
  },
  rolePromotionRequestError: (state) => {
    state.rolePromotionStatus = 'error';
  },
  userOrganizationRolesStorageRequestSuccess: (state, data) => {
    state.userOrganizationRoles = data;
    state.rolesSuccess = true;
    state.userOrganizationRolesStatus = 'success';
  },
  activeRoleChange: (state, data) => {
    state.activeRole = data;
  },
  organizationRequests: (state) => {
    state.organizationRequestsStatus = 'attempting organization requests request';
  },
  organizationRequestsSuccess: (state) => {
    state.organizationRequestsStatus = 'organization requests request succeeded';
  },
  organizationRequestsError: (state) => {
    state.organizationRequestsStatus = 'error';
  },
  requestActionStarted: (state) => {
    state.requestActionStatus = 'attempting request action request';
  },
  requestActionSuccess: (state) => {
    state.requestActionStatus = 'request action request succeeded';
  },
  requestActionError: (state) => {
    state.requestActionStatus = 'error';
  },
  verificationActionStarted: (state) => {
    state.verificationActionStatus = 'attempting verification action request';
  },
  verificationActionSuccess: (state) => {
    state.verificationActionStatus = 'verification action request succeeded';
  },
  verificationActionError: (state) => {
    state.verificationActionStatus = 'error';
  },
  agencySearchRequest: (state) => {
    state.agencySearchStatus = `attempting ${getText('agency').toLowerCase()}.toLowerCase() search request`;
  },
  agencySearchRequestSuccess: (state) => {
    state.agencySearchStatus = `${getText('agency').toLowerCase()}.toLowerCase() search request succeeded`;
  },
  agencySearchRequestError: (state) => {
    state.agencySearchStatus = 'error';
  },
  agencySearchByCountyRequest: (state) => {
    state.agencySearchByCountyStatus = `attempting ${getText(
      'agency'
    ).toLowerCase()}.toLowerCase() search request by county`;
  },
  agencySearchByCountyRequestSuccess: (state) => {
    state.agencySearchByCountyStatus = `${getText(
      'agency'
    ).toLowerCase()}.toLowerCase() search request by county succeeded`;
  },
  agencySearchByCountyRequestError: (state) => {
    state.agencySearchByCountyStatus = 'error';
  },
  countySearchRequest: (state) => {
    state.countySearchStatus = `attempting county search request`;
  },
  countySearchRequestSuccess: (state) => {
    state.countySearchStatus = `county search request succeeded`;
  },
  countySearchRequestError: (state) => {
    state.countySearchStatus = 'error';
  },
  courseSearchRequest: (state) => {
    state.courseSearchStatus = `attempting course search request`;
  },
  courseSearchRequestSuccess: (state) => {
    state.courseSearchStatus = `course search request succeeded`;
  },
  courseSearchRequestError: (state) => {
    state.courseSearchStatus = 'error';
  },
  courseSectionSearchRequest: (state) => {
    state.courseSectionSearchStatus = `attempting course section search request`;
  },
  courseSectionSearchRequestSuccess: (state) => {
    state.courseSectionSearchStatus = `course section search request succeeded`;
  },
  courseSectionSearchRequestError: (state) => {
    state.courseSectionSearchStatus = 'error';
  },
  courseSectionDetailRequest: (state) => {
    state.courseSectionDetailStatus = `attempting course section detail request`;
  },
  courseSectionDetailRequestSuccess: (state) => {
    state.courseSectionDetailStatus = `course section detail request succeeded`;
  },
  courseSectionDetailRequestError: (state) => {
    state.courseSectionDetailStatus = 'error';
  },
  coursePropsRequest: (state) => {
    state.coursePropsStatus = `attempting course props request`;
  },
  coursePropsRequestSuccess: (state) => {
    state.coursePropsStatus = `course props request succeeded`;
  },
  coursePropsRequestError: (state) => {
    state.coursePropsStatus = 'error';
  },
  courseRegistrationRequest: (state) => {
    state.courseRegistrationStatus = `attempting course registration request`;
  },
  courseRegistrationRequestSuccess: (state) => {
    state.courseRegistrationStatus = `course registration request succeeded`;
  },
  courseRegistrationRequestError: (state) => {
    state.courseRegistrationStatus = 'error';
  },
  courseWaitlistRequest: (state) => {
    state.courseWaitlistStatus = `attempting course waitlist request`;
  },
  courseWaitlistRequestSuccess: (state) => {
    state.courseWaitlistStatus = `course waitlist request succeeded`;
  },
  courseWaitlistRequestError: (state) => {
    state.courseWaitlistStatus = 'error';
  },
  requestRegistrationRequest: (state) => {
    state.requestRegistrationStatus = `attempting request registration request`;
  },
  requestRegistrationRequestSuccess: (state) => {
    state.requestRegistrationStatus = `request registration request succeeded`;
  },
  requestRegistrationRequestError: (state) => {
    state.requestRegistrationStatus = 'error';
  },
  acceptRejectOverrideRequest: (state) => {
    state.acceptRejectOverrideRequestStatus = `attempting registration accept/reject request`;
  },
  acceptRejectOverrideRequestSuccess: (state) => {
    state.acceptRejectOverrideRequestStatus = `registration request accept/reject succeeded`;
  },
  acceptRejectOverrideRequestError: (state) => {
    state.acceptRejectOverrideRequestStatus = 'error';
  },
  cancelRegistrationRequest: (state) => {
    state.cancelRegistrationStatus = `attempting cancel registration request`;
  },
  cancelRegistrationRequestSuccess: (state) => {
    state.cancelRegistrationStatus = `cancel registration request succeeded`;
  },
  cancelRegistrationRequestError: (state) => {
    state.cancelRegistrationStatus = 'error';
  },
  deleteCourseRequest: (state) => {
    state.deleteCourseStatus = `attempting delete course request`;
  },
  deleteCourseRequestSuccess: (state) => {
    state.deleteCourseStatus = `delete course request succeeded`;
  },
  deleteCourseRequestError: (state) => {
    state.deleteCourseStatus = 'error';
  },
  deleteSectionRequest: (state) => {
    state.deleteSectionStatus = `attempting delete course section request`;
  },
  deleteSectionRequestSuccess: (state) => {
    state.deleteSectionStatus = `delete course section request succeeded`;
  },
  deleteSectionRequestError: (state) => {
    state.deleteSectionStatus = 'error';
  },
  setAttendanceRequest: (state) => {
    state.setAttendanceStatus = `attempting set attendance request`;
  },
  setAttendanceRequestSuccess: (state) => {
    state.setAttendanceStatus = `set attendance request succeeded`;
  },
  setAttendanceRequestError: (state) => {
    state.setAttendanceStatus = 'error';
  },
  regionCheckRequest: (state) => {
    state.regionCheckStatus = `attempting region check request`;
  },
  regionCheckRequestSuccess: (state) => {
    state.regionCheckStatus = `region check request succeeded`;
  },
  regionCheckRequestError: (state) => {
    state.regionCheckStatus = 'error';
  },
  getOwnOrgsRequest: (state) => {
    state.getOwnOrgsStatus = `attempting get own orgs request`;
  },
  getOwnOrgsRequestSuccess: (state) => {
    state.getOwnOrgsStatus = `get own orgs request succeeded`;
  },
  getOwnOrgsRequestError: (state) => {
    state.getOwnOrgsStatus = 'error';
  },
  getUserOrgsRequest: (state) => {
    state.getUserOrgsStatus = `attempting get user orgs request`;
  },
  getUserOrgsRequestSuccess: (state) => {
    state.getUserOrgsStatus = `get user orgs request succeeded`;
  },
  getUserOrgsRequestError: (state) => {
    state.getUserOrgsStatus = 'error';
  },
  pilotCheckRequest: (state) => {
    state.pilotCheckStatus = `attempting pilot check request`;
  },
  pilotCheckRequestSuccess: (state) => {
    state.pilotCheckStatus = `pilot check request succeeded`;
  },
  pilotCheckRequestError: (state) => {
    state.pilotCheckStatus = 'error';
  },
  emailConfirmRequest: (state) => {
    state.emailConfirmStatus = `attempting email confirm request`;
  },
  emailConfirmRequestSuccess: (state) => {
    state.emailConfirmStatus = `email confirm request succeeded`;
  },
  emailConfirmRequestError: (state) => {
    state.emailConfirmStatus = 'error';
  },
  calendarEventRequest: (state) => {
    state.calendarEventStatus = `attempting calendar event request`;
  },
  calendarEventRequestSuccess: (state) => {
    state.calendarEventStatus = `calendar event request succeeded`;
  },
  calendarEventRequestError: (state) => {
    state.calendarEventStatus = 'error';
  },
  trainerCalendarEventRequest: (state) => {
    state.trainerCalendarEventStatus = `attempting trainer calendar event request`;
  },
  trainerCalendarEventRequestSuccess: (state) => {
    state.trainerCalendarEventStatus = `trainer calendar event request succeeded`;
  },
  trainerCalendarEventRequestError: (state) => {
    state.trainerCalendarEventStatus = 'error';
  },
  courseSectionPropsRequest: (state) => {
    state.courseSectionPropsStatus = `attempting course section props request`;
  },
  courseSectionPropsRequestSuccess: (state) => {
    state.courseSectionPropsStatus = `course section props request succeeded`;
  },
  courseSectionPropsRequestError: (state) => {
    state.courseSectionPropsStatus = 'error';
  },
  enrollmentPropsRequest: (state) => {
    state.enrollmentPropsStatus = `attempting course enrollment props request`;
  },
  enrollmentPropsRequestSuccess: (state) => {
    state.enrollmentPropsStatus = `course enrollment props request succeeded`;
  },
  enrollmentPropsRequestError: (state) => {
    state.enrollmentPropsStatus = 'error';
  },
  organizationEmployeesReportRequest: (state) => {
    state.organizationEmployeesReportStatus = 'attempting organization employees report request';
  },
  organizationEmployeesReportRequestSuccess: (state) => {
    state.organizationEmployeesReportStatus = 'organization employees report request succeeded';
  },
  organizationEmployeesReportRequestError: (state) => {
    state.organizationEmployeesReportStatus = 'error';
  },
  complianceReportRequest: (state) => {
    state.complianceReportStatus = 'attempting compliance report request';
  },
  complianceReportRequestSuccess: (state) => {
    state.complianceReportStatus = 'compliance report request succeeded';
  },
  complianceReportRequestError: (state) => {
    state.complianceReportStatus = 'error';
  },
  cachedComplianceReportRequest: (state) => {
    state.cachedComplianceReportStatus = 'attempting cached compliance report request';
  },
  cachedComplianceReportRequestSuccess: (state) => {
    state.cachedComplianceReportStatus = 'cached compliance report request succeeded';
  },
  cachedComplianceReportRequestError: (state) => {
    state.cachedComplianceReportStatus = 'error';
  },
  agencyReportRequest: (state) => {
    state.agencyReportStatus = 'attempting agency report request';
  },
  agencyReportRequestSuccess: (state) => {
    state.agencyReportStatus = 'agency report request succeeded';
  },
  agencyReportRequestError: (state) => {
    state.agencyReportStatus = 'error';
  },
  verificationHistoryRequest: (state) => {
    state.verificationHistoryRequestStatus = 'attempting verification history request';
  },
  verificationHistoryRequestSuccess: (state) => {
    state.verificationHistoryRequestStatus = 'verification history request succeeded';
  },
  verificationHistoryRequestError: (state) => {
    state.verificationHistoryRequestStatus = 'error';
  },
  verificationRequestRequest: (state) => {
    state.verificationRequestRequestStatus = 'attempting verification request request';
  },
  verificationRequestRequestSuccess: (state) => {
    state.verificationRequestRequestStatus = 'verification request request succeeded';
  },
  verificationRequestRequestError: (state) => {
    state.verificationRequestRequestStatus = 'error';
  },
  roleSwitchRequest: (state) => {
    state.roleSwitchRequestStatus = 'attempting role switch request';
  },
  roleSwitchRequestSuccess: (state) => {
    state.roleSwitchRequestStatus = 'role switch request succeeded';
  },
  roleSwitchRequestError: (state) => {
    state.roleSwitchRequestStatus = 'error';
  },
  directorReportRequest: (state) => {
    state.directorReportRequestStatus = 'attempting director report request';
  },
  directorReportRequestSuccess: (state) => {
    state.directorReportRequestStatus = 'director report request succeeded';
  },
  directorReportRequestError: (state) => {
    state.directorReportRequestStatus = 'error';
  },
  adminReportRequest: (state) => {
    state.adminReportRequestStatus = 'attempting admin report request';
  },
  adminReportRequestSuccess: (state) => {
    state.adminReportRequestStatus = 'admin report request succeeded';
  },
  adminReportRequestError: (state) => {
    state.adminReportRequestStatus = 'error';
  },
  supervisorReportRequest: (state) => {
    state.supervisorReportRequestStatus = 'attempting supervisor report request';
  },
  supervisorReportRequestSuccess: (state) => {
    state.supervisorReportRequestStatus = 'supervisor report request succeeded';
  },
  supervisorReportRequestError: (state) => {
    state.supervisorReportRequestStatus = 'error';
  },
  coachReportRequest: (state) => {
    state.coachReportRequestStatus = 'attempting coach report request';
  },
  coachReportRequestSuccess: (state) => {
    state.coachReportRequestStatus = 'coach report request succeeded';
  },
  coachReportRequestError: (state) => {
    state.coachReportRequestStatus = 'error';
  },
  qeaReportRequest: (state) => {
    state.qeaReportRequestStatus = 'attempting qea report request';
  },
  qeaReportRequestSuccess: (state) => {
    state.qeaReportRequestStatus = 'qea report request succeeded';
  },
  qeaReportRequestError: (state) => {
    state.qeaReportRequestStatus = 'error';
  },
  preSubmissionValidationRequest: (state) => {
    state.preSubmissionValidationRequestStatus = 'attempting pre submission validation request';
  },
  preSubmissionValidationRequestSuccess: (state) => {
    state.preSubmissionValidationRequestStatus = 'pre submission validation request succeeded';
  },
  preSubmissionValidationRequestError: (state) => {
    state.preSubmissionValidationRequestStatus = 'error';
  },
  actionHistoryRequest: (state) => {
    state.actionHistoryRequestStatus = 'attempting action history request';
  },
  actionHistoryRequestSuccess: (state) => {
    state.actionHistoryRequestStatus = 'action history request succeeded';
  },
  actionHistoryRequestError: (state) => {
    state.actionHistoryRequestStatus = 'error';
  },
  getNotificationsRequest: (state) => {
    state.getNotificationsStatus = 'attempting notification request';
  },
  getNotificationsRequestSuccess: (state) => {
    state.getNotificationsStatus = 'notification request succeeded';
  },
  getNotificationsRequestError: (state) => {
    state.getNotificationsStatus = 'error';
  },
  getNotificationCountRequest: (state) => {
    state.getNotificationCountStatus = 'attempting notification count request';
  },
  getNotificationCountRequestSuccess: (state) => {
    state.getNotificationCountStatus = 'notification count request succeeded';
  },
  getNotificationCountRequestError: (state) => {
    state.getNotificationCountStatus = 'error';
  },
  notificationActionRequest: (state) => {
    state.notificationActionStatus = 'attempting notification action request';
  },
  notificationActionRequestSuccess: (state) => {
    state.notificationActionStatus = 'notification action request succeeded';
  },
  notificationActionRequestError: (state) => {
    state.notificationActionStatus = 'error';
  },
  getAlertsRequest: (state) => {
    state.getAlertsStatus = 'attempting get alerts request';
  },
  getAlertsRequestSuccess: (state) => {
    state.getAlertsStatus = 'get alerts request succeeded';
  },
  getAlertsRequestError: (state) => {
    state.getAlertsStatus = 'error';
  },
  dismissAlertRequest: (state) => {
    state.dismissAlertStatus = 'attempting dismiss alert request';
  },
  dismissAlertRequestSuccess: (state) => {
    state.dismissAlertStatus = 'dismiss alert request succeeded';
  },
  dismissAlertRequestError: (state) => {
    state.dismissAlertStatus = 'error';
  },
  coachRelationshipActionRequest: (state) => {
    state.coachRelationshipActionStatus = 'attempting coaching request';
  },
  coachRelationshipActionRequestSuccess: (state) => {
    state.coachRelationshipActionStatus = 'coaching request succeeded';
  },
  coachRelationshipActionRequestError: (state) => {
    state.coachRelationshipActionStatus = 'error';
  },
  requestedCoachesRequest: (state) => {
    state.requestedCoachesStatus = 'attempting requested coaches request';
  },
  requestedCoachesRequestSuccess: (state) => {
    state.requestedCoachesStatus = 'requested coaches request succeeded';
  },
  requestedCoachesRequestError: (state) => {
    state.requestedCoachesStatus = 'error';
  },
  unlockUserRequest: (state) => {
    state.unlockUserStatus = 'attempting unlock user request';
  },
  unlockUserRequestSuccess: (state) => {
    state.unlockUserStatus = 'unlock user request succeeded';
  },
  unlockUserRequestError: (state) => {
    state.unlockUserStatus = 'error';
  },
  createResumeRequest: (state) => {
    state.createResumeStatus = 'attempting create resume';
  },
  createResumeRequestSuccess: (state) => {
    state.createResumeStatus = 'create resume succeeded';
  },
  createResumeRequestError: (state) => {
    state.createResumeStatus = 'error';
  },
  createReferenceRequest: (state) => {
    state.createReferenceStatus = 'attempting create reference';
  },
  createReferenceRequestSuccess: (state) => {
    state.createReferenceStatus = 'create reference succeeded';
  },
  createReferenceRequestError: (state) => {
    state.createReferenceStatus = 'error';
  },
  viewResumeFieldsRequest: (state) => {
    state.viewResumeFieldsStatus = 'attempting view resume fields';
  },
  viewResumeFieldsRequestSuccess: (state) => {
    state.viewResumeFieldsStatus = 'view resume fields succeeded';
  },
  viewResumeFieldsRequestError: (state) => {
    state.viewResumeFieldsStatus = 'error';
  },
  editResumeRequest: (state) => {
    state.editResumeStatus = 'attempting edit resume';
  },
  editResumeRequestSuccess: (state) => {
    state.editResumeStatus = 'edit resume succeeded';
  },
  editResumeRequestError: (state) => {
    state.editResumeStatus = 'error';
  },
  editReferenceRequest: (state) => {
    state.editReferenceStatus = 'attempting edit reference';
  },
  editReferenceRequestSuccess: (state) => {
    state.editReferenceStatus = 'edit reference succeeded';
  },
  editReferenceRequestError: (state) => {
    state.editReferenceStatus = 'error';
  },
  trainTNAccessRequest: (state) => {
    state.trainTNAccessStatus = 'attempting to set Train TN access';
  },
  trainTNAccessRequestSuccess: (state) => {
    state.trainTNAccessStatus = 'set Train TN access succeeded';
  },
  trainTNAccessRequestError: (state) => {
    state.trainTNAccessStatus = 'error';
  },
  publishResumeRequest: (state) => {
    state.publishResumeStatus = 'attempting puslish resume';
  },
  publishResumeRequestSuccess: (state) => {
    state.publishResumeStatus = 'publish resume succeeded';
  },
  publishResumeRequestError: (state) => {
    state.publishResumeStatus = 'error';
  },
  resumeDownloadRequest: (state) => {
    state.resumeDownloadStatus = 'attempting resume download request';
  },
  resumeDownloadSuccess: (state) => {
    state.resumeDownloadStatus = 'resume download succeeded';
  },
  resumeDownloadError: (state) => {
    state.resumeDownloadStatus = 'error';
  },
  submitReportRequest: (state) => {
    state.submitReportStatus = 'attempting report submission';
  },
  submitReportRequestSuccess: (state) => {
    state.submitReportStatus = 'report submission succeeded';
  },
  submitReportRequestError: (state) => {
    state.submitReportStatus = 'error';
  },
  reportResponseRequest: (state) => {
    state.reportResponseStatus = 'attempting report response';
  },
  reportResponseRequestSuccess: (state) => {
    state.reportResponseStatus = 'report response succeeded';
  },
  reportResponseRequestError: (state) => {
    state.reportResponseStatus = 'error';
  },
  qrisCycleRequest: (state) => {
    state.qrisCycleStatus = 'attempting to get QRIS cycle';
  },
  qrisCycleRequestSuccess: (state) => {
    state.qrisCycleStatus = 'QRIS cycle successfully retrieved';
  },
  qrisCycleRequestError: (state) => {
    state.qrisCycleStatus = 'error';
  },
  getArchivedCyclesRequest: (state) => {
    state.getArchivedCyclesStatus = 'attempting to get archived cycles';
  },
  getArchivedCyclesRequestSuccess: (state) => {
    state.getArchivedCyclesStatus = 'Archived cycles successfully retrieved';
  },
  getArchivedCyclesRequestError: (state) => {
    state.getArchivedCyclesStatus = 'error';
  },
  getArchiveSummaryReportRequest: (state) => {
    state.getArchiveSummaryReportStatus = 'attempting to get archive summary report';
  },
  getArchiveSummaryReportRequestSuccess: (state) => {
    state.getArchiveSummaryReportStatus = 'Archive summary report successfully retrieved';
  },
  getArchiveSummaryReportRequestError: (state) => {
    state.getArchiveSummaryReportStatus = 'error';
  },
  getArchivedMembersByOrgCycleRequest: (state) => {
    state.getArchivedMembersByOrgCycleStatus = 'attempting to get members with archived cycles for this org';
  },
  getArchivedMembersByOrgCycleRequestSuccess: (state) => {
    state.getArchivedMembersByOrgCycleStatus = 'Archived cycle members successfully retrieved';
  },
  getArchivedMembersByOrgCycleRequestError: (state) => {
    state.getArchivedMembersByOrgCycleStatus = 'error';
  },
  getOrgByIdRequest: (state) => {
    state.getOrgByIdStatus = 'attempting get org by id';
  },
  getOrgByIdRequestSuccess: (state) => {
    state.getOrgByIdStatus = 'get org by id succeeded';
  },
  getOrgByIdRequestError: (state) => {
    state.getOrgByIdStatus = 'error';
  },
  attestationListRequest: (state) => {
    state.attestationListStatus = 'attempting attestation list';
  },
  attestationListRequestSuccess: (state) => {
    state.attestationListStatus = 'attestation list succeeded';
  },
  attestationListRequestError: (state) => {
    state.attestationListStatus = 'error';
  },
  setAttestationRequest: (state) => {
    state.setAttestationStatus = 'attempting set attestation';
  },
  setAttestationRequestSuccess: (state) => {
    state.setAttestationStatus = 'set attestation succeeded';
  },
  setAttestationRequestError: (state) => {
    state.setAttestationStatus = 'error';
  },
  enableQuickSight: (state) => {
    state.quickSightEnabled = true;
  },
  endorsementDetailRequest: (state) => {
    state.endorsementDetailStatus = 'attempting get endorsement detail';
  },
  endorsementDetailRequestSuccess: (state) => {
    state.endorsementDetailStatus = 'get endorsement detail succeeded';
  },
  endorsementDetailRequestError: (state) => {
    state.endorsementDetailStatus = 'error';
  }
};

// ACTIONS
const actions = {
  authRequest: ({ commit, dispatch, getters }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('authRequest');
      sessionStorage.removeItem('auth-token');
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      apiService
        .login(credentials)
        .then((result) => {
          if (result.apiOnly) {
            const err = 'Account not authorized for front end login';
            commit('authError', err);
            sessionStorage.removeItem('auth-token');
            document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            reject(err);
            return;
          }
          result.jwt = JSON.parse(result.jwt);
          if (result.jwt && result.jwt.auth_token) {
            sessionStorage.setItem('auth-token', result.jwt.auth_token); // stash the auth token in sessionStorage
            commit('authSuccess', {
              expiresIn: result.jwt.expires_in,
              refreshAt: result.jwt.refresh_at
            });
            dispatch('setCookieForAdmin', { token: result.jwt.auth_token, expires: result.jwt.expires_in });
            dispatch('localRolesRequest', result.jwt.auth_token);
            dispatch('localClaimsRequest', result.jwt.auth_token);
            dispatch('checkTokenExpiration');
            dispatch('restoreContext')
              .then((result2) => {
                if (getters.userOrganizationRoles && getters.userOrganizationRoles.length > 0) {
                  dispatch('setActiveRole', getters.userOrganizationRoles[0]);
                }
                resolve(result);
              })
              .catch(() => {
                sessionStorage.removeItem('auth-token');
                document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                reject();
              });
          } else {
            commit('authError', 'No Jwt Token');
            sessionStorage.removeItem('auth-token');
            document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            reject('No Jwt Token');
          }
        })
        .catch((errors) => {
          commit('authError', errors);
          sessionStorage.removeItem('auth-token');
          document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          reject(errors);
        });
    });
  },
  setCookieForAdmin: ({ commit, dispatch }, tokenInfo) => {
    //cookie contains a copy of the jwt token, it's only used to authenticate the user to the hangfire dashboard
    return new Promise((resolve, reject) => {
      if (tokenInfo.token) {
        const decodedToken = decodeToken(tokenInfo.token);
        if (decodedToken) {
          const roles = extractRolesFromDecodedToken(decodedToken);
          if (
            tokenInfo.expires &&
            roles &&
            roles.length > 0 &&
            (roles == 'AdminUser' || (roles.some && roles.some((role) => role == 'AdminUser')))
          ) {
            const offsetDate = getOffsetSecondsDate(tokenInfo.expires);
            const expiresString = 'expires=' + offsetDate.toUTCString();
            document.cookie = 'auth_token=' + tokenInfo.token + ';' + expiresString + '; path=/;';
            resolve(true);
          }
        }
      }
      resolve(false);
    });
  },
  registrationRequest: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('regRequest');
      apiService
        .register(credentials)
        .then((result) => {
          commit('regSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('regError', errors);
          reject(errors);
        });
    });
  },
  refreshTokenRequest: ({ commit, dispatch, getters }) => {
    if (!getters.isIdle && getters.refreshAt != 0) {
      return new Promise((resolve, reject) => {
        apiService
          .heartbeat()
          .then((result) => {
            resolve(result);
          })
          .catch((errors) => {
            reject(errors);
          });
      });
    }
  },
  authLogout: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('auth-token');
      if (token) {
        const decoded = decodeToken(token);
        if (decoded && decoded.id) {
          dispatch('leaveMessageGroup', decoded.id).then((result) => {
            Vue.prototype.stopSignalR();
          });
        }
      }
      sessionStorage.removeItem('auth-token');
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      dispatch('removeFirstTimeLogin');
      dispatch('removeTacFlag');
      dispatch('removeActiveRole');
      commit('authLogout');
      resolve();
    });
  },
  notifyRequest: ({ commit, dispatch }, firstTime) => {
    if (firstTime) {
      dispatch('removeFirstTimeLogin');
    }
    return new Promise((resolve, reject) => {
      commit('notifyRequest');
      apiService
        .notify()
        .then((result) => {
          commit('notifySuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('notifyError');
          reject(errors);
        });
    });
  },
  resendNotifyRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('resendNotifyRequest');
      apiService
        .resendNotify(data)
        .then((result) => {
          commit('resendNotifySuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('resendNotifyError');
          reject(errors);
        });
    });
  },
  seedRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('seedRequest');
      apiService
        .seed()
        .then((result) => {
          commit('seedSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('seedError');
          reject(errors);
        });
    });
  },
  profileRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('profileRequest');
      apiService
        .profile()
        .then((result) => {
          if (result && result.id) dispatch('joinMessageGroup', result.id);
          commit('profileSuccess', result);
          resolve(result);
        })
        .catch((errors) => {
          commit('profileError');
          dispatch('authLogout', null, { root: true });
          reject(errors);
        });
    });
  },
  confirmationRequest: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('confirmationRequest');
      apiService
        .confirm(credentials)
        .then((result) => {
          commit('confirmationSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('confirmationError', errors);
          reject(errors);
        });
    });
  },
  confirmSecondaryRequest: ({ commit, dispatch }, key) => {
    return new Promise((resolve, reject) => {
      commit('confirmSecondaryRequest');
      apiService
        .confirmSecondaryEmail(key)
        .then((result) => {
          commit('confirmSecondarySuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('confirmSecondaryError', errors);
          reject(errors);
        });
    });
  },
  secondaryEmailRequest: ({ commit, dispatch }, key) => {
    return new Promise((resolve, reject) => {
      commit('secondaryEmailRequest');
      apiService
        .secondaryEmail(key)
        .then((result) => {
          commit('secondaryEmailSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('secondaryEmailError', errors);
          reject(errors);
        });
    });
  },
  configRequest: ({ commit, dispatch }, key) => {
    return new Promise((resolve, reject) => {
      commit('configRequest');
      apiService
        .config(key)
        .then((result) => {
          commit('configSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('configError', errors);
          reject(errors);
        });
    });
  },
  forgotPasswordRequest: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('forgotPasswordRequest');
      apiService
        .forgotPassword(credentials)
        .then((result) => {
          commit('forgotPasswordSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('forgotPasswordError', errors);
          reject(errors);
        });
    });
  },
  resetPasswordRequest: ({ commit, dispatch }, credentials) => {
    return new Promise((resolve, reject) => {
      commit('resetPasswordRequest');
      apiService
        .resetPassword(credentials)
        .then((result) => {
          commit('resetPasswordSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('resetPasswordError', errors);
          reject(errors);
        });
    });
  },
  formRequest: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      commit('formRequest');
      apiService
        .form(params)
        .then((result) => {
          commit('formSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('formError', errors);
          reject(errors);
        });
    });
  },
  formResponseRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('formResponseRequest');
      apiService
        .formResponse(response)
        .then((result) => {
          commit('formResponseSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('formResponseError', errors);
          reject(errors);
        });
    });
  },
  fileUploadRequest: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit('fileUploadRequest');
      const file = payload.file;
      const fileSetId = payload.fileSetId;
      const entityTypeId = payload.entityTypeId;
      const formElementId = payload.formelementid;
      apiService
        .fileUpload(file, fileSetId, entityTypeId, formElementId)
        .then((result) => {
          commit('fileUploadSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('fileUploadError', errors);
          reject(errors);
        });
    });
  },
  fileDownloadRequest: ({ commit, dispatch }, fileKey) => {
    return new Promise((resolve, reject) => {
      commit('fileDownloadRequest');
      apiService
        .fileDownload(fileKey)
        .then((result) => {
          commit('fileDownloadSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('fileDownloadError', errors);
          reject(errors);
        });
    });
  },
  staticFileDownloadRequest: ({ commit, dispatch }, fileKey) => {
    return new Promise((resolve, reject) => {
      commit('staticFileDownloadRequest');
      apiService
        .staticFileDownload(fileKey)
        .then((result) => {
          commit('staticFileDownloadSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('staticFileDownloadError', errors);
          reject(errors);
        });
    });
  },
  reportDownloadRequest: ({ commit, dispatch }, fileName) => {
    return new Promise((resolve, reject) => {
      commit('reportDownloadRequest');
      apiService
        .reportDownload(fileName)
        .then((result) => {
          commit('reportDownloadSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('reportDownloadError', errors);
          reject(errors);
        });
    });
  },
  staticFileUrlRequest: ({ commit, dispatch }, fileKey) => {
    return new Promise((resolve, reject) => {
      commit('staticFileUrlRequest');
      apiService
        .staticFileUrl(fileKey)
        .then((result) => {
          commit('staticFileUrlSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('staticFileUrlError', errors);
          reject(errors);
        });
    });
  },
  fileDeleteRequest: ({ commit, dispatch }, fileKey) => {
    return new Promise((resolve, reject) => {
      commit('fileDeleteRequest');
      apiService
        .fileDelete(fileKey)
        .then((result) => {
          commit('fileDeleteSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('fileDeleteError', errors);
          reject(errors);
        });
    });
  },
  limitedLogin({ commit, dispatch }, route) {
    if (route == 'profile-personal') {
      dispatch('firstTimeLogin');
    }
    if (route == 'accept-terms') {
      dispatch('needsToAcceptTac');
    }
  },
  firstTimeLogin({ commit }) {
    sessionStorage.setItem('first-time', true);
    commit('toggleFirstTime', true);
  },
  removeFirstTimeLogin({ commit }) {
    sessionStorage.removeItem('first-time');
    commit('toggleFirstTime', false);
  },
  removeTacFlag({ commit }) {
    sessionStorage.removeItem('tac-flag');
    commit('toggleTacFlag', false);
  },
  needsToAcceptTac({ commit }) {
    sessionStorage.setItem('tac-flag', true);
    commit('toggleTacFlag', true);
  },
  hasAcceptedTac({ commit, dispatch }) {
    dispatch('removeTacFlag');
    dispatch('notifyRequest');
    dispatch('seedRequest');
  },
  gridDataRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('gridDataRequest');
      apiService
        .gridData(response)
        .then((result) => {
          commit('gridDataRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('gridDataRequestError', errors);
          reject(errors);
        });
    });
  },
  gridSummaryRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('gridSummaryRequest');
      apiService
        .gridSummary(response)
        .then((result) => {
          commit('gridSummaryRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('gridSummaryRequestError', errors);
          reject(errors);
        });
    });
  },
  gridDisableRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('gridDisableRequest');
      apiService
        .gridDisable(response)
        .then((result) => {
          commit('gridDisableRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('gridDisableRequestError', errors);
          reject(errors);
        });
    });
  },
  gridDeleteRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('gridDeleteRequest');
      apiService
        .gridDelete(response)
        .then((result) => {
          commit('gridDeleteRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('gridDeleteRequestError', errors);
          reject(errors);
        });
    });
  },
  termsAcceptRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('termsAcceptRequest');
      apiService
        .termsAccept(response)
        .then((result) => {
          commit('termsAcceptRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('termsAcceptRequestError', errors);
          reject(errors);
        });
    });
  },
  emailChangeRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('emailChangeRequest');
      apiService
        .emailChange(response)
        .then((result) => {
          commit('emailChangeRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('emailChangeRequestError', errors);
          reject(errors);
        });
    });
  },
  autoCompleteSearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('autoCompleteSearchRequest');
      apiService
        .autoCompleteSearch(response)
        .then((result) => {
          commit('autoCompleteSearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('autoCompleteSearchRequestError', errors);
          reject(errors);
        });
    });
  },
  entityCrossReferenceSearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('entityCrossReferenceSearchRequest');
      apiService
        .entityCrossReferenceSearch(response)
        .then((result) => {
          commit('entityCrossReferenceSearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('entityCrossReferenceSearchRequestError', errors);
          reject(errors);
        });
    });
  },
  entityCrossReferencePropertySearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('entityCrossReferencePropertySearchRequest');
      apiService
        .entityCrossReferencePropertySearch(response)
        .then((result) => {
          commit('entityCrossReferencePropertySearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('entityCrossReferencePropertySearchRequestError', errors);
          reject(errors);
        });
    });
  },
  localRolesRequest: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      if (token) {
        const decodedToken = decodeToken(token);
        if (decodedToken) {
          const roles = extractRolesFromDecodedToken(decodedToken);
          if (roles && roles.length > 0) {
            commit('rolesSuccess', roles);
            resolve(roles);
          }
        }
        reject('no roles in token');
      }
    });
  },
  localClaimsRequest: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      if (token) {
        const decodedToken = decodeToken(token);
        if (decodedToken) {
          const claims = extractClaimsFromDecodedToken(decodedToken);
          if (claims && claims.length > 0) {
            commit('claimsSuccess', claims);
            resolve(claims);
          }
        }
        reject('no claims in token');
      }
    });
  },
  localAuthRequest: ({ commit, dispatch }, token) => {
    return new Promise((resolve, reject) => {
      if (token) {
        const jwt = JSON.parse(token);
        if (jwt && jwt.auth_token) {
          sessionStorage.setItem('auth-token', jwt.auth_token); // stash the auth token in sessionStorage
          commit('authSuccess', {
            expiresIn: jwt.expires_in,
            refreshAt: jwt.refresh_at
          });
          dispatch('setCookieForAdmin', { token: jwt.auth_token, expires: jwt.expires_in });
          dispatch('localRolesRequest', jwt.auth_token);
          dispatch('localClaimsRequest', jwt.auth_token);
          resolve(jwt);
        } else {
          commit('authError', 'No Jwt Token');
          sessionStorage.removeItem('auth-token');
          document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          reject('No Jwt Token');
        }
      }
    });
  },
  showToastError: ({ commit, dispatch }, errmsg) => {
    return new Promise((resolve, reject) => {
      if (errmsg) {
        Vue.prototype.toast.error(errmsg, 'Error');
        resolve(errmsg);
      } else {
        reject('No error message');
      }
    });
  },
  sendMessageRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('sendMessageRequest');
      apiService
        .sendMessage(response)
        .then((result) => {
          commit('sendMessageRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('sendMessageRequestError', errors);
          reject(errors);
        });
    });
  },
  getMessagesRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getMessagesRequest');
      apiService
        .getMessages(response)
        .then((result) => {
          commit('getMessagesRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getMessagesRequestError', errors);
          reject(errors);
        });
    });
  },
  getMessageCountRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getMessageCountRequest');
      apiService
        .getMessageCount(response)
        .then((result) => {
          commit('getMessageCountRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getMessageCountRequestError', errors);
          reject(errors);
        });
    });
  },
  joinMessageGroup: (_, id) => {
    if (id && Vue && Vue.prototype && Vue.prototype.$messageHub && Vue.prototype.$messageHub.userActive)
      Vue.prototype.$messageHub.userActive(id);
  },
  leaveMessageGroup: (_, id) => {
    return new Promise((resolve, reject) => {
      if (id && Vue && Vue.prototype && Vue.prototype.$messageHub && Vue.prototype.$messageHub.userInactive)
        Vue.prototype.$messageHub.userInactive(id);
    });
  },
  messageActionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('messageActionRequest');
      apiService
        .messageAction(response)
        .then((result) => {
          commit('messageActionRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('messageActionRequestError', errors);
          reject(errors);
        });
    });
  },
  restoreContext({ commit, dispatch, getters, state }) {
    return new Promise((resolve, reject) => {
      if (getters.isAuthenticated) {
        const token = sessionStorage.getItem('auth-token');
        Vue.prototype.startSignalR(token);
        dispatch('profileRequest')
          .then((result) => {
            const body = { userId: getters.profile.id, getClaims: true };
            dispatch('userOrganizationRolesStorageRequest', body)
              .then((result) => {
                if (getters.hasActiveRole && getters.activeRoleId) {
                  const orgRole = result.find((or) => or.role.roleId == getters.activeRoleId);
                  if (orgRole) dispatch('setActiveRole', orgRole);
                }
                resolve(true);
              })
              .catch(() => {
                reject();
              });
          })
          .catch(() => {
            reject();
          });
      }
    });
  },
  messageUserSearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('messageUserSearchRequest');
      apiService
        .messageUserSearch(response)
        .then((result) => {
          commit('messageUserSearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('messageUserSearchRequestError', errors);
          reject(errors);
        });
    });
  },
  sendConfEmailRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('sendConfEmailRequest');
      apiService
        .sendConfEmailRequest(response)
        .then((result) => {
          commit('sendConfEmailRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('sendConfEmailRequestError', errors);
          reject(errors);
        });
    });
  },
  formFlatFileRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('formFlatFileRequest');
      apiService
        .formFlatFile(response)
        .then((result) => {
          commit('formFlatFileRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('formFlatFileRequestError', errors);
          reject(errors);
        });
    });
  },
  userOrganizationRolesRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('userOrganizationRolesRequest');
      apiService
        .userOrganizationRoles(response)
        .then((result) => {
          commit('userOrganizationRolesRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('userOrganizationRolesRequestError', errors);
          reject(errors);
        });
    });
  },
  userOrganizationRolesUpdateRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('userOrganizationRolesUpdateRequest');
      apiService
        .userOrganizationRolesUpdate(response)
        .then((result) => {
          commit('userOrganizationRolesUpdateRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('userOrganizationRolesUpdateRequestError', errors);
          reject(errors);
        });
    });
  },
  addAccountRoleRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('addAccountRoleRequest');
      apiService
        .addAccountRole(response)
        .then((result) => {
          commit('addAccountRoleRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('addAccountRoleRequestError', errors);
          reject(errors);
        });
    });
  },
  removeAccountRoleRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('removeAccountRoleRequest');
      apiService
        .removeAccountRole(response)
        .then((result) => {
          commit('removeAccountRoleRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('removeAccountRoleRequestError', errors);
          reject(errors);
        });
    });
  },
  checkTokenExpiration: ({ commit, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      if (getters.expiresIn > 0) {
        if (getters.expirationCheckTimeout) {
          clearTimeout(getters.expirationCheckTimeout);
        }
        const timeoutHandle = setTimeout(resolve, 5000);
        commit('expirationCheckTimeoutSuccess', timeoutHandle);
      } else {
        reject('Logged out');
      }
    })
      .then(() => {
        if (getters.isAuthenticated && hasTimePassed(getters.tokenReceivedTime, getters.expiresIn)) {
          dispatch('authLogout').then((result) => {
            Vue.prototype.$dialogs.alert('Your session has expired due to inactivity.', {
              title: 'Session Expired',
              size: 'sm'
            });
            router.push({ name: 'home' });
          });
        } else {
          if (hasTimePassed(getters.tokenReceivedTime, getters.refreshAt) && !getters.isIdle) {
            dispatch('refreshTokenRequest');
          }
          dispatch('checkTokenExpiration');
        }
      })
      .catch(() => {
        state.tokenReceivedTime = 0;
        state.refreshAt = 0;
        state.expiresIn = 0;
      });
  },
  appIdleStatusRequest: ({ commit, dispatch, getters }, status) => {
    commit('idleVue', status);
    if (status && getters.isAuthenticated) {
      if (getters.expirationCheckTimeout) {
        clearTimeout(getters.expirationCheckTimeout);
      }
      dispatch('authLogout').then((result) => {
        Vue.prototype.$dialogs.alert('Your session has expired due to inactivity.', {
          title: 'Session Expired',
          size: 'sm'
        });
        router.push({ name: 'home' });
      });
    }
    if (!status && getters.isAuthenticated && hasTimePassed(getters.tokenReceivedTime, getters.refreshAt)) {
      dispatch('refreshTokenRequest');
    }
  },
  rolePromotionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('rolePromotionRequest');
      apiService
        .rolePromotionRequest(response)
        .then((result) => {
          commit('rolePromotionRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('rolePromotionRequestError', errors);
          reject(errors);
        });
    });
  },
  userOrganizationRolesStorageRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('userOrganizationRolesRequest');
      apiService
        .userOrganizationRoles(response)
        .then((result) => {
          commit('userOrganizationRolesStorageRequestSuccess', result);
          resolve(result);
        })
        .catch((errors) => {
          commit('userOrganizationRolesRequestError', errors);
          reject(errors);
        });
    });
  },
  setActiveRole: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      if (data && data.role && data.role.roleId) {
        sessionStorage.setItem('active-role', data.role.roleId);
        commit('activeRoleChange', data);
      } else {
        dispatch('removeActiveRole');
        commit('activeRoleChange', {});
      }
      resolve(true);
    });
  },
  roleSwitchRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('roleSwitchRequest');
      apiService
        .roleSwitch(data)
        .then((result) => {
          commit('roleSwitchRequestSuccess');
          dispatch('setActiveRole', data);
          resolve(result);
        })
        .catch((errors) => {
          commit('roleSwitchRequestError', errors);
          reject(errors);
        });
    });
  },
  removeActiveRole({ commit }) {
    sessionStorage.removeItem('active-role');
  },
  organizationRequests: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('organizationRequests');
      apiService
        .organizationRequests(response)
        .then((result) => {
          commit('organizationRequestsSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('organizationRequestsError', errors);
          reject(errors);
        });
    });
  },
  requestAction: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('requestActionStarted');
      apiService
        .requestAction(response)
        .then((result) => {
          commit('requestActionSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('requestActionError', errors);
          reject(errors);
        });
    });
  },
  verificationAction: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('verificationActionStarted');
      apiService
        .verificationAction(response)
        .then((result) => {
          commit('verificationActionSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('verificationActionError', errors);
          reject(errors);
        });
    });
  },
  agencySearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('agencySearchRequest');
      apiService
        .agencySearch(response)
        .then((result) => {
          commit('agencySearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('agencySearchRequestError', errors);
          reject(errors);
        });
    });
  },
  agencySearchByCountyRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('agencySearchByCountyRequest');
      apiService
        .agencySearchByCounty(response)
        .then((result) => {
          commit('agencySearchByCountyRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('agencySearchByCountyRequestError', errors);
          reject(errors);
        });
    });
  },
  countySearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('countySearchRequest');
      apiService
        .countySearch(response)
        .then((result) => {
          commit('countySearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('countySearchRequestError', errors);
          reject(errors);
        });
    });
  },
  courseSearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseSearchRequest');
      apiService
        .courseSearch(response)
        .then((result) => {
          commit('courseSearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseSearchRequestError', errors);
          reject(errors);
        });
    });
  },
  courseSectionSearchRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseSectionSearchRequest');
      apiService
        .courseSectionSearch(response)
        .then((result) => {
          commit('courseSectionSearchRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseSectionSearchRequestError', errors);
          reject(errors);
        });
    });
  },
  courseSectionDetailRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseSectionDetailRequest');
      apiService
        .courseSectionDetail(response)
        .then((result) => {
          commit('courseSectionDetailRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseSectionDetailRequestError', errors);
          reject(errors);
        });
    });
  },
  deleteCourseRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('deleteCourseRequest');
      apiService
        .deleteCourse(response)
        .then((result) => {
          commit('deleteCourseRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('deleteCourseRequestError', errors);
          reject(errors);
        });
    });
  },
  deleteSectionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('deleteSectionRequest');
      apiService
        .deleteSection(response)
        .then((result) => {
          commit('deleteSectionRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('deleteSectionRequestError', errors);
          reject(errors);
        });
    });
  },
  coursePropsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('coursePropsRequest');
      apiService
        .courseProps(response)
        .then((result) => {
          commit('coursePropsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('coursePropsRequestError', errors);
          reject(errors);
        });
    });
  },
  regionCheckRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('regionCheckRequest');
      apiService
        .regionCheck(response)
        .then((result) => {
          commit('regionCheckRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('regionCheckRequestError', errors);
          reject(errors);
        });
    });
  },
  getOwnOrgsRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('getOwnOrgsRequest');
      apiService
        .getOwnOrgs()
        .then((result) => {
          commit('getOwnOrgsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getOwnOrgsRequestError', errors);
          reject(errors);
        });
    });
  },
  getUserOrgsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getUserOrgsRequest');
      apiService
        .getUserOrgs(response)
        .then((result) => {
          commit('getUserOrgsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getUserOrgsRequestError', errors);
          reject(errors);
        });
    });
  },
  pilotCheckRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('pilotCheckRequest');
      apiService
        .pilotCheck()
        .then((result) => {
          commit('pilotCheckRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('pilotCheckRequestError', errors);
          reject(errors);
        });
    });
  },
  emailConfirmRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('emailConfirmRequest');
      apiService
        .emailConfirm(response)
        .then((result) => {
          commit('emailConfirmRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('emailConfirmRequestError', errors);
          reject(errors);
        });
    });
  },
  courseSectionPropsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseSectionPropsRequest');
      apiService
        .courseSectionProps(response)
        .then((result) => {
          commit('courseSectionPropsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseSectionPropsRequestError', errors);
          reject(errors);
        });
    });
  },
  calendarEventRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('calendarEventRequest');
      apiService
        .calendarEvents()
        .then((result) => {
          commit('calendarEventRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('calendarEventRequestError', errors);
          reject(errors);
        });
    });
  },
  trainerCalendarEventRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('trainerCalendarEventRequest');
      apiService
        .trainerCalendarEvents()
        .then((result) => {
          commit('trainerCalendarEventRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('trainerCalendarEventRequestError', errors);
          reject(errors);
        });
    });
  },
  courseRegistrationRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseRegistrationRequest');
      apiService
        .courseRegistration(response)
        .then((result) => {
          commit('courseRegistrationRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseRegistrationRequestError', errors);
          reject(errors);
        });
    });
  },
  courseWaitlistRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('courseWaitlistRequest');
      apiService
        .courseWaitlist(response)
        .then((result) => {
          commit('courseWaitlistRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('courseWaitlistRequestError', errors);
          reject(errors);
        });
    });
  },
  requestRegistrationRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('requestRegistrationRequest');
      apiService
        .requestRegistration(response)
        .then((result) => {
          commit('requestRegistrationRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('requestRegistrationRequestError', errors);
          reject(errors);
        });
    });
  },
  acceptRejectOverrideRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('acceptRejectOverrideRequest');
      apiService
        .acceptRejectOverrideRequest(response)
        .then((result) => {
          commit('acceptRejectOverrideRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('acceptRejectOverrideRequestError', errors);
          reject(errors);
        });
    });
  },
  cancelRegistrationRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('cancelRegistrationRequest');
      apiService
        .cancelRegistration(response)
        .then((result) => {
          commit('cancelRegistrationRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('cancelRegistrationRequestError', errors);
          reject(errors);
        });
    });
  },
  setAttendanceRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('setAttendanceRequest');
      apiService
        .setAttendance(response)
        .then((result) => {
          commit('setAttendanceRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('setAttendanceRequestError', errors);
          reject(errors);
        });
    });
  },
  enrollmentPropsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('enrollmentPropsRequest');
      apiService
        .enrollmentProps(response)
        .then((result) => {
          commit('enrollmentPropsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('enrollmentPropsRequestError', errors);
          reject(errors);
        });
    });
  },
  organizationEmployeesReportRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('organizationEmployeesReportRequest');
      apiService
        .directorReport(response)
        .then((result) => {
          commit('organizationEmployeesReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('organizationEmployeesReportRequestError', errors);
          reject(errors);
        });
    });
  },
  complianceReportRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('complianceReportRequest');
      apiService
        .complianceReport(response)
        .then((result) => {
          commit('complianceReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('complianceReportRequestError', errors);
          reject(errors);
        });
    });
  },
  cachedComplianceReportRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('cachedComplianceReportRequest');
      apiService
        .cachedComplianceReport(response)
        .then((result) => {
          commit('cachedComplianceReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('cachedComplianceReportRequestError', errors);
          reject(errors);
        });
    });
  },
  agencyReportRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('agencyReportRequest');
      apiService
        .agencyReport(response)
        .then((result) => {
          commit('agencyReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('agencyReportRequestError', errors);
          reject(errors);
        });
    });
  },
  verificationHistoryRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('verificationHistoryRequest');
      apiService
        .verificationHistory(response)
        .then((result) => {
          commit('verificationHistoryRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('verificationHistoryRequestError', errors);
          reject(errors);
        });
    });
  },
  verificationRequestRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('verificationRequestRequest');
      apiService
        .verificationRequest(response)
        .then((result) => {
          commit('verificationRequestRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('verificationRequestRequestError', errors);
          reject(errors);
        });
    });
  },
  directorReportRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('directorReportRequest');
      apiService
        .directorReport(data)
        .then((result) => {
          commit('directorReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('directorReportRequestError', errors);
          reject(errors);
        });
    });
  },
  adminReportRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('adminReportRequest');
      apiService
        .adminReport(data)
        .then((result) => {
          commit('adminReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('adminReportRequestError', errors);
          reject(errors);
        });
    });
  },
  supervisorReportRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('supervisorReportRequest');
      apiService
        .supervisorReport(data)
        .then((result) => {
          commit('supervisorReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('supervisorReportRequestError', errors);
          reject(errors);
        });
    });
  },
  coachReportRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('coachReportRequest');
      apiService
        .coachReport(data)
        .then((result) => {
          commit('coachReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('coachReportRequestError', errors);
          reject(errors);
        });
    });
  },
  qeaReportRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('qeaReportRequest');
      apiService
        .qeaReport(data)
        .then((result) => {
          commit('qeaReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('qeaReportRequestError', errors);
          reject(errors);
        });
    });
  },
  preSubmissionValidationRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('preSubmissionValidationRequest');
      apiService
        .preSubmissionValidation(data)
        .then((result) => {
          commit('preSubmissionValidationRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('preSubmissionValidationRequestError', errors);
          reject(errors);
        });
    });
  },
  actionHistoryRequest: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      commit('actionHistoryRequest');
      apiService
        .actionHistory(data)
        .then((result) => {
          commit('actionHistoryRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('actionHistoryRequestError', errors);
          reject(errors);
        });
    });
  },
  getNotificationsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getNotificationsRequest');
      apiService
        .getNotifications(response)
        .then((result) => {
          commit('getNotificationsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getNotificationsRequestError', errors);
          reject(errors);
        });
    });
  },
  getNotificationCountRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getNotificationCountRequest');
      apiService
        .getNotificationCount(response)
        .then((result) => {
          commit('getNotificationCountRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getNotificationCountRequestError', errors);
          reject(errors);
        });
    });
  },
  notificationActionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('notificationActionRequest');
      apiService
        .notificationAction(response)
        .then((result) => {
          commit('notificationActionRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('notificationActionRequestError', errors);
          reject(errors);
        });
    });
  },
  getAlertsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getAlertsRequest');
      apiService
        .getAlerts(response)
        .then((result) => {
          commit('getAlertsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getAlertsRequestError', errors);
          reject(errors);
        });
    });
  },
  dismissAlertRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('dismissAlertRequest');
      apiService
        .dismissAlert(response)
        .then((result) => {
          commit('dismissAlertRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('dismissAlertRequestError', errors);
          reject(errors);
        });
    });
  },
  coachRelationshipActionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('coachRelationshipActionRequest');
      apiService
        .coachRelationshipAction(response)
        .then((result) => {
          commit('coachRelationshipActionRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('coachRelationshipActionRequestError', errors);
          reject(errors);
        });
    });
  },
  requestedCoachesRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('requestedCoachesRequest');
      apiService
        .requestedCoaches(response)
        .then((result) => {
          commit('requestedCoachesRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('requestedCoachesRequestError', errors);
          reject(errors);
        });
    });
  },
  unlockUserRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('unlockUserRequest');
      apiService
        .unlockUser(response)
        .then((result) => {
          commit('unlockUserRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('unlockUserRequestError', errors);
          reject(errors);
        });
    });
  },
  createResumeRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('createResumeRequest');
      apiService
        .createResume(response)
        .then((result) => {
          commit('createResumeRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('createResumeRequestError', errors);
          reject(errors);
        });
    });
  },
  viewResumeFieldsRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('viewResumeFieldsRequest');
      apiService
        .viewResumeFields(response)
        .then((result) => {
          commit('viewResumeFieldsRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('viewResumeFieldsRequestError', errors);
          reject(errors);
        });
    });
  },
  editResumeRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('editResumeRequest');
      apiService
        .editResume(response)
        .then((result) => {
          commit('editResumeRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('editResumeRequestError', errors);
          reject(errors);
        });
    });
  },
  publishResumeRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('publishResumeRequest');
      apiService
        .publishResume(response)
        .then((result) => {
          commit('publishResumeRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('publishResumeRequestError', errors);
          reject(errors);
        });
    });
  },
  resumeDownloadRequest: ({ commit, dispatch }, fileKey) => {
    return new Promise((resolve, reject) => {
      commit('resumeDownloadRequest');
      apiService
        .downloadResume(fileKey)
        .then((result) => {
          commit('resumeDownloadSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('resumeDownloadError', errors);
          reject(errors);
        });
    });
  },
  createReferenceRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('createReferenceRequest');
      apiService
        .createReference(response)
        .then((result) => {
          commit('createReferenceRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('createReferenceRequestError', errors);
          reject(errors);
        });
    });
  },
  editReferenceRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('editReferenceRequest');
      apiService
        .editReference(response)
        .then((result) => {
          commit('editReferenceRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('editReferenceRequestError', errors);
          reject(errors);
        });
    });
  },
  trainTNAccessRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('trainTNAccessRequest');
      apiService
        .trainTNAccess(response)
        .then((result) => {
          commit('trainTNAccessRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('trainTNAccessRequestError', errors);
          reject(errors);
        });
    });
  },
  getOrgByIdRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getOrgByIdRequest');
      apiService
        .orgInfoById(response)
        .then((result) => {
          commit('getOrgByIdRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getOrgByIdRequestError', errors);
          reject(errors);
        });
    });
  },
  attestationListRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('attestationListRequest');
      apiService
        .attestationList(response)
        .then((result) => {
          commit('attestationListRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('attestationListRequestError', errors);
          reject(errors);
        });
    });
  },
  setAttestationRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('setAttestationRequest');
      apiService
        .setAttestation(response)
        .then((result) => {
          commit('setAttestationRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('setAttestationRequestError', errors);
          reject(errors);
        });
    });
  },
  reportSubmissionRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('submitReportRequest');
      apiService
        .submitReport(response)
        .then((result) => {
          commit('submitReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('submitReportRequestError', errors);
          reject(errors);
        });
    });
  },
  reportResponseRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('reportResponseRequest');
      apiService
        .reportResponse(response)
        .then((result) => {
          commit('reportResponseRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('reportResponseRequestError', errors);
          reject(errors);
        });
    });
  },
  qrisCycleRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('qrisCycleRequest');
      apiService
        .qrisCycle()
        .then((result) => {
          commit('qrisCycleRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('qrisCycleRequestError', errors);
          reject(errors);
        });
    });
  },
  archivedCyclesRequest: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit('getArchivedCyclesRequest');
      apiService
        .archivedCycles()
        .then((result) => {
          commit('getArchivedCyclesRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getArchivedCyclesRequestError', errors);
          reject(errors);
        });
    });
  },
  archiveSummaryReport: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('getArchiveSummaryReportRequest');
      apiService
        .archiveSummaryReport(response)
        .then((result) => {
          commit('getArchiveSummaryReportRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('getArchiveSummaryReportRequestError', errors);
          reject(errors);
        });
    });
  },
  endorsementDetailRequest: ({ commit, dispatch }, response) => {
    return new Promise((resolve, reject) => {
      commit('endorsementDetailRequest');
      apiService
        .endorsementDetail(response)
        .then((result) => {
          commit('endorsementDetailRequestSuccess');
          resolve(result);
        })
        .catch((errors) => {
          commit('endorsementDetailRequestError', errors);
          reject(errors);
        });
    });
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
